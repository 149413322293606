import React from "react";
import OwlCarousel from "react-owl-carousel";
import cardicn from "../../assets/images/cardicn.svg";
import GetMessage from "../../utils/GetMessage";

const Cardfacility = ({ product, loading }) => {
  const options = {
    loop: false,
    dots: false,
    items: 5,
    autoplay: false,
    nav: true,
    margin: 40,
    responsive: {
      0: {
        items: 2,
      },
      480: {
        items: 3,
      },
      768: {
        items: 4,
      },
      1024: {
        items: 5,
      },
      1440: {
        items: 5,
      },
    },
    responsiveBaseElement: ".card-facility-wrp",
  };
  if (loading) {
    return (
      <section className="card-facility-wrp">
        <div className="">
          <div className="card-facility-in">
            <div className="row">
              <div className="col-md-12">
                <div className="card-fac-list">
                  <>
                    <div className="card-fac-single">
                      <div className="skeleton-text" style={{ height: "300px" }} />
                    </div>
                    <div className="card-fac-single">
                      <div className="skeleton-text" style={{ height: "300px" }} />
                    </div>
                    <div className="card-fac-single">
                      <div className="skeleton-text" style={{ height: "300px" }} />
                    </div>
                    <div className="card-fac-single">
                      <div className="skeleton-text" style={{ height: "300px" }} />
                    </div>
                    <div className="card-fac-single">
                      <div className="skeleton-text" style={{ height: "300px" }} />
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
  if (!product?.product_features?.length > 0) {
    return null;
  }

  return (
    <section className="card-facility-wrp">
      <div className="">
        <div className="card-facility-in">
          <div className="row">
            <div className="col-md-12">
              <h3 className="f-30">{product?.feature_title}</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="">
                <OwlCarousel className="rate-slider" {...options}>
                  {product?.product_features.map((data) => (
                    <div className="card-fac-single">
                      <div className="card-fac-icn">
                        <img src={data?.feature_image_url} alt={data?.title} title={data?.title} />
                      </div>
                      <h5 className="f-18">{data?.title}</h5>
                    </div>
                  ))}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cardfacility;
