import React from "react";
import { Link } from "react-router-dom";
import rate from "../../src/assets/images/r1.png";
import { Dropdown } from "bootstrap";
import OwlCarousel from "react-owl-carousel";
import GetMessage from "../utils/GetMessage";

const Rateproviders = () => {
  const owlCarouselOptions = {
    loop: true,
    dots: false,
    items: 7,
    autoplay: false,
    nav: true,
    margin: 20,
    responsive: {
      0: {
        items: 2,
        margin: 10,
      },
      480: {
        items: 4,
      },
      768: {
        items: 5,
      },
      1024: {
        items: 6,
      },
      1440: {
        items: 7,
      },
    },
    responsiveBaseElement: ".Rateprovider-wrp",
  };
  return (
    <section className="Rateprovider-wrp">
      <div className="container">
        <h3>{GetMessage("home_company_slider_title")}</h3>
        <p className="big-p">{GetMessage("home_company_slider_subtitle")}</p>
        <OwlCarousel className="rate-slider owl-carousel" {...owlCarouselOptions}>
          {/* Add your slide components here */}

          {/* <ul className="rate-slider owl-carousel"> */}
          <div className="ul">
            <div className="rate-in">
              <div className="inner-sv">
                <img src={rate} alt={rate} />
              </div>
            </div>
            <p>Abu Dhabi Commercial Bank</p>
          </div>
          <div className="ul">
            <div className="rate-in">
              <div className="inner-sv">
                <img src={rate} alt={rate} />
              </div>
            </div>
            <p>Dubai Bank</p>
          </div>
          <div className="ul">
            <div className="rate-in">
              <div className="inner-sv">
                <img src={rate} alt={rate} />
              </div>
            </div>
            <p>Al Hilal Bank</p>
          </div>
          <div className="ul">
            <div className="rate-in">
              <div className="inner-sv">
                <img src={rate} alt={rate} />
              </div>
            </div>
            <p>Commercial Bank of Dubai</p>
          </div>
          <div className="ul">
            <div className="rate-in">
              <div className="inner-sv">
                <img src={rate} alt={rate} />
              </div>
            </div>
            <p>Noor Bank</p>
          </div>
          <div className="ul">
            <div className="rate-in">
              <div className="inner-sv">
                <img src={rate} alt={rate} />
              </div>
            </div>
            <p>Emirates Islamic</p>
          </div>
          <div className="ul">
            <div className="rate-in">
              <div className="inner-sv">
                <img src={rate} alt={rate} />
              </div>
            </div>
            <p>First Abu Dhabi Bank</p>
          </div>
          <div className="ul">
            <div className="rate-in">
              <div className="inner-sv">
                <img src={rate} alt={rate} />
              </div>
            </div>
            <p>Noor Bank</p>
          </div>
          <div className="ul">
            <div className="rate-in">
              <div className="inner-sv">
                <img src={rate} alt={rate} />
              </div>
            </div>
            <p>Emirates Islamic</p>
          </div>
          <div className="ul">
            <div className="rate-in">
              <div className="inner-sv">
                <img src={rate} alt={rate} />
              </div>
            </div>
            <p>First Abu Dhabi Bank</p>
          </div>
          {/* Add more slides as needed */}
        </OwlCarousel>
      </div>
    </section>
  );
};

export default Rateproviders;
