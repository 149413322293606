import React from "react";
import Product from "./Product";
import redc from "../../assets/images/redc.png";
import NoResultFound from "../NoResultFound/NoResultFound";
import ProductSkeleton from "../ProductSkeleton";

const ProductList = ({ CurrentCategoryPath, ProductListArray, renderLoadMore, renderIsListingArray, loading, error, topCatId }) => {
  if ((!ProductListArray?.length > 0 && !loading) || error) {
    return (
      <div className="right-product-list">
        <NoResultFound title="NO PRODUCT FOUND!" subTitle="There were no products found matching your request." />
      </div>
    );
  }
  return (
    <div className="right-product-list">
      {ProductListArray?.map((singleProduct) => (
        <Product product={singleProduct} CurrentCategoryPath={CurrentCategoryPath} renderIsListingArray={renderIsListingArray} topCatId={topCatId} />
      ))}
      {loading && (
        <>
          <ProductSkeleton />
          <ProductSkeleton />
          <ProductSkeleton />
        </>
      )}
      {renderLoadMore()}
    </div>
  );
};

export default ProductList;
