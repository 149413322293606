import React from "react";
import OwlCarousel from "react-owl-carousel";
import GetMessage from "../utils/GetMessage";
import { GetTestimonials } from "../query/Graphql";

const Clientreview = () => {
  const owlCarouselOptions = {
    loop: false,
    dots: false,
    items: 3,
    autoplay: false,
    nav: true,
    margin: 30,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 2,
      },
      768: {
        items: 3,
      },
    },
    responsiveBaseElement: ".client-wrp",
  };
  const Testimonials = GetTestimonials();
  const renderTestimonialList = () => {
    return (
      <OwlCarousel className="client-review-slider owl-carousel" {...owlCarouselOptions}>
        {Testimonials.map((data) => (
          <div className="review-single">
            <div className="review-name">
              <div className="cli-img">
                <img src={data.testimonial_image_url} alt="" title="" />
              </div>
              <div className="client-name">
                {data.name}
                <span>{data.position}</span>
              </div>
            </div>
            <p>{data.content}</p>
          </div>
        ))}
      </OwlCarousel>
    );
  };
  return (
    <section className="client-wrp">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3>{GetMessage("testimonial_title") || <div className="skeleton-text" style={{ minHeight: "26px", marginBottom: "20px" }}></div>}</h3>
            <p className="big-p">
              {GetMessage("testimonial_subtitle") || <div className="skeleton-text" style={{ minHeight: "20px", marginBottom: "20px" }}></div>}
            </p>
          </div>
        </div>

        {Testimonials
          ? renderTestimonialList()
          : Array.from({ length: 1 }).map((_, index) => (
              <div className="review-single">
                <div className="skeleton-container" style={{ minHeight: "200px" }}></div>
              </div>
            ))}
      </div>
    </section>
  );
};

export default Clientreview;
