import React from "react";
import { GetCompanyDetails } from "../../query/Graphql";
import WithBannersLayout from "../../utils/WithBannersLayout/WithBannersLayout";
import WithMetaByData from "../../utils/WithMetaByData";
import CompanyDirectoryTop from "./CompanyDirectoryGallery/CompanyDirectoryTop";
import CompanyDirectoryProducts from "./CompanyDirectoryProducts/CompanyDirectoryProducts";

const RenderCompanyDetails = ({ CurrentId }) => {
  const companyData = CurrentId && GetCompanyDetails(CurrentId);
  // console.log("companyData", companyData);

  return (
    <>
      <div className="mb-4"></div>
      <WithBannersLayout
        leftBanners={companyData?.bannergallery?.filter((item) => item.banner_position == "3")}
        rightBanners={companyData?.bannergallery?.filter((item) => item.banner_position == "4")}
      >
        <WithMetaByData data={companyData}>
          <div>
            <CompanyDirectoryTop companyData={companyData} />
            <CompanyDirectoryProducts companyData={companyData} />
          </div>
        </WithMetaByData>
      </WithBannersLayout>
    </>
  );
};

export default RenderCompanyDetails;
