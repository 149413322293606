import { useQuery } from "@apollo/client";
import React from "react";
import {
  GET_BANNER,
  GET_CMS_DATA,
  GET_COMPANY_LIST,
  GET_FAQ_DATA,
  GET_GUIDES_DATA,
  GET_CATEGORIES,
  GET_HEADER_TICKER,
  GET_KNOWLEDGE_NUGGETS_WITH_ID,
  GET_KNOWLEDGE_NUGGETS_WITH_TERM,
  GET_META_DATA,
  GET_META_DATA_ONLY,
  GET_SYSTEM_MESSAGE,
  GET_TESTIMONIALS,
  GET_CATEGORIES_CMS_BLOCK,
  GET_COMPANY_DATA,
  GET_POPULAR_CATS,
  GET_ALL_URL_DATA,
  GET_CURRENT_CAT_DATA,
  GET_CRYPTO_DETAILS,
  GET_CRYPTO_TRADE_LINK,
  GET_FINANCIAL_BANNER,
  GET_COMPANY_BY_CAT,
  GET_ALL_CATEGORY_BY_LEVEL,
} from "./query";

export const GetAllHeaderData = () => {
  const { loading, error, data } = useQuery(GET_ALL_CATEGORY_BY_LEVEL);
  return data?.allcategory?.allcategory;
};
export const GetHeaderData = (id) => {
  const { loading, error, data } = useQuery(GET_CATEGORIES, {
    variables: { id: id },
  });
  return data?.result?.category;
};
export const GetHeaderTickerData = () => {
  const { loading, error, data } = useQuery(GET_HEADER_TICKER);
  return data?.headertickerdata?.headertickerdata;
};
export const GetTestimonials = () => {
  const { loading, error, data } = useQuery(GET_TESTIMONIALS);
  return data?.testimonial?.testimonial;
};
export const GetSystemMessage = () => {
  const { loading, error, data } = useQuery(GET_SYSTEM_MESSAGE);
  return data;
};
export const GetBanners = (name) => {
  const { loading, error, data } = useQuery(GET_BANNER, {
    variables: { name: name },
  });
  return data?.singlebannerdata?.singlebannerdata;
};
export const GetCMSData = (slug) => {
  const { loading, error, data } = useQuery(GET_CMS_DATA, {
    variables: { slug: slug },
  });
  return data?.cmsdata?.cmsdata;
};
export const GetFAQDataForCategory = (id) => {
  const { loading, error, data } = useQuery(GET_FAQ_DATA, {
    variables: { type: "category", id: id },
  });
  return data?.singlefaqdata?.singlefaqdata;
};
export const GetFAQDataForProduct = (id) => {
  const { loading, error, data } = useQuery(GET_FAQ_DATA, {
    variables: { type: "product", id: id },
  });
  return data?.singlefaqdata?.singlefaqdata;
};
export const GetGuidesDataForCategory = (id) => {
  const { loading, error, data } = useQuery(GET_GUIDES_DATA, {
    variables: { type: "category", id: id },
  });
  return data?.singleguidesdata?.singleguidesdata;
};
// export const GetKnowledgeNuggetsWithTerm = (keyword) => {
//   const { loading, error, data } = useQuery(GET_KNOWLEDGE_NUGGETS_WITH_TERM, {
//     variables: { keyword: keyword?.toString() },
//   });
//   return data?.searchknowledgenuggets?.searchknowledgenuggets;
// };
export const GetKnowledgeNuggetsWithID = (id) => {
  const { loading, error, data } = useQuery(GET_KNOWLEDGE_NUGGETS_WITH_ID, {
    variables: { id: id },
  });
  return data?.knowledgenuggetsdata?.knowledgenuggetsdata;
};
export const GetMetaData = (name) => {
  const { loading, error, data } = useQuery(GET_META_DATA_ONLY, {
    variables: { name: name },
  });
  return data?.singlepagedata?.singlepagedata;
};
export const GetSinglePagesDataFromSlug = (name) => {
  const { loading, error, data } = useQuery(GET_META_DATA, {
    variables: { name: name },
  });
  return data?.singlepagedata?.singlepagedata || data?.singlepagedata;
};
export const GetCompanyDetails = (id) => {
  const { loading, error, data } = useQuery(GET_COMPANY_DATA, {
    variables: { id: Number(id) },
  });
  return data?.companydata?.companydata;
};
export const GetPopularCategory = () => {
  const { loading, error, data } = useQuery(GET_POPULAR_CATS, {
    variables: { popular: 1, comparewidget: 0 },
  });
  return data?.filtercategory?.filtercategory;
};
export const GetPopularCategoryForCompareWidget = () => {
  const { loading, error, data } = useQuery(GET_POPULAR_CATS, {
    variables: { popular: 0, comparewidget: 1 },
  });
  return data?.filtercategory?.filtercategory || (error && "error");
};
// export const GetCompanyList = () => {
//   const { loading, error, data } = useQuery(GET_COMPANY_LIST);
//   return data?.allcompany?.allcompany;
// };
export const GetIdFromUrlCategory = (name) => {
  const { loading, error, data } = useQuery(GET_ALL_URL_DATA, {
    variables: { pagetype: "category", name: name },
  });
  return data?.allurldata?.urldata;
};
export const GetIdFromUrlKnowledgeNugget = (name) => {
  const { loading, error, data } = useQuery(GET_ALL_URL_DATA, {
    variables: { pagetype: "knowledgenuggets", name: name },
  });
  return data?.allurldata?.urldata;
};
export const GetIdFromUrlCompany = (name) => {
  const { loading, error, data } = useQuery(GET_ALL_URL_DATA, {
    variables: { pagetype: "company", name: name },
  });
  return data?.allurldata?.urldata;
};
// export const GetCryptoDataFromCode = (code) => {
//   const { loading, error, data } = useQuery(GET_CRYPTO_DETAILS, {
//     variables: { code: code, title: "" },
//   });
//   return data?.singlecrypto?.singlecrypto;
// };
// export const GetCryptoTradLinkFromCode = (code) => {
//   const { loading, error, data } = useQuery(GET_CRYPTO_TRADE_LINK, {
//     variables: { code: code, title: "" },
//   });
//   return data?.singlecrypto?.singlecrypto?.trade_button_link;
// };
export const GetCurrentCatData = (id) => {
  const { loading, error, data } = useQuery(GET_CURRENT_CAT_DATA, {
    variables: { id: id?.toString() },
  });
  return data?.singlecategory?.singlecategory;
};
export const GetCurrentCatCompanyData = (id) => {
  const { loading, error, data } = useQuery(GET_COMPANY_BY_CAT, {
    variables: { id: id },
  });
  return data;
};
export const GetFinancialBannerAndMeta = (postslug) => {
  const { loading, error, data } = useQuery(GET_FINANCIAL_BANNER, {
    variables: { postslug },
  });
  return data?.financialbanner;
};
