import React, { useEffect, useState } from "react";
import { GetKnowledgeNuggetsWithID } from "../../query/Graphql";
import BottomBannerSlider from "../../utils/BannerLayout/BottomBannerSlider";
import TopBannerSlider from "../../utils/BannerLayout/TopBannerSlider";
import Html from "../../utils/Html";
import WithBannersLayout from "../../utils/WithBannersLayout/WithBannersLayout";
import WithMetaByData from "../../utils/WithMetaByData";

const RenderKnowledgeNuggetDetails = ({ CurrentId }) => {
  const KnowledgeNuggetsWithID = CurrentId && GetKnowledgeNuggetsWithID(CurrentId);
  const [Nugget, setNugget] = useState(KnowledgeNuggetsWithID || "");
  console.log("KnowledgeNuggetsWithID", Nugget);
  useEffect(() => {
    if (KnowledgeNuggetsWithID?.id) {
      setNugget("");
      setNugget(KnowledgeNuggetsWithID);
    } else if (KnowledgeNuggetsWithID == null) {
      setNugget("");
    }
  }, [KnowledgeNuggetsWithID]);
  return (
    <>
      <div className="mb-2"></div>
      <WithBannersLayout
        leftBanners={Nugget?.banner?.filter((item) => item.banner_position == "3")}
        rightBanners={Nugget?.banner?.filter((item) => item.banner_position == "4")}
      >
        <WithMetaByData data={Nugget}></WithMetaByData>
        <div className="container">
          <TopBannerSlider topBanners={Nugget?.banner?.filter((item) => item.banner_position == "0")} />
          {Nugget ? (
            <>
              <div className="KnowledgeNuggetList-row one">
                <div className="title big-p text-black font-bold d-flex justify-content-between">
                  <h5>{Nugget?.keyword}</h5>
                </div>
              </div>

              <div className="mb-4">
                <Html html={Nugget?.description} />
              </div>
            </>
          ) : (
            Array.from({ length: 5 }).map((_, index) => <div className="skeleton-text" style={{ height: "130px", marginTop: "30px" }}></div>)
          )}
          <BottomBannerSlider bottomBanner={Nugget?.banner?.filter((item) => item.banner_position == "1")} />
          <div className="mt-4">
            <Html html={Nugget?.footer?.description} />
          </div>
        </div>
      </WithBannersLayout>
    </>
  );
};

export default RenderKnowledgeNuggetDetails;
