import React, { useEffect, useState } from "react";
import Financedetail from "./Financedetail";
import Recentcoments from "./Recentcoments";
import Recentpostslider from "./Recentpostslider";
import { useParams } from "react-router-dom";
import { BlogBaseUrl } from "../..";
import axios from "axios";
import { GetFinancialBannerAndMeta } from "../../query/Graphql";
import WithMetaByData from "../../utils/WithMetaByData";
import TopBannerSlider from "../../utils/BannerLayout/TopBannerSlider";
import WithBannersLayout from "../../utils/WithBannersLayout/WithBannersLayout";
import BottomBannerSlider from "../../utils/BannerLayout/BottomBannerSlider";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

const FinancialLiteracyDetail = () => {
  const { BlogSlug } = useParams();
  const [loading, setLoading] = useState(false);
  const [Blog, setBlog] = useState([]);
  useEffect(() => {
    if (BlogSlug) {
      setLoading(true);
      axios
        .get(BlogBaseUrl + `wp-json/custom/v1/post-by-slug/${BlogSlug}`)
        .then((response) => {
          setBlog(response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    }
  }, [BlogSlug]);
  const bannerAndMeta = BlogSlug && GetFinancialBannerAndMeta(BlogSlug);
  console.log("bannerAndMeta", Blog);

  return (
    <>
      <div>
        <Breadcrumb />
        <div className="container">
          <WithBannersLayout
            leftBanners={bannerAndMeta?.financialbanner?.filter((item) => item.banner_position == "3")}
            // rightBanners={bannerAndMeta?.financialbanner?.filter((item) => item.banner_position == "4")}
          >
            <TopBannerSlider topBanners={bannerAndMeta?.financialbanner?.filter((item) => item.banner_position == "0")} />
            <WithMetaByData data={bannerAndMeta?.financialmeta} />
            <Financedetail Blog={Blog} loading={loading} bannerAndMeta={bannerAndMeta} />
            <Recentcoments Blog={Blog} loading={loading} />
            <Recentpostslider Blog={Blog} loading={loading} />
            <div className="my-4">
              <BottomBannerSlider bottomBanner={bannerAndMeta?.financialbanner?.filter((item) => item.banner_position == "1")} />
            </div>
          </WithBannersLayout>
        </div>
      </div>
    </>
  );
};

export default FinancialLiteracyDetail;
