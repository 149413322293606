import React, { useEffect, useState } from "react";
import RenderBannerByLayout from "./RenderBannerByLayout";
import Html from "../utils/Html";

const BottomBannerItem = ({ bottomBanner }) => {
  const [BannerImages, setBannerImages] = useState();
  const [BannerScript, setBannerScript] = useState();
  useEffect(() => {
    if (bottomBanner) {
      if (bottomBanner?.banner_upload_type == 0) {
        // it is aray of image
        setBannerImages(bottomBanner?.banner_image);
      } else if (bottomBanner?.banner_upload_type == 1) {
        // it is script
        setBannerScript(bottomBanner?.banner_script);
      } else if (bottomBanner?.banner_upload_type == 2) {
        // it is html
        setBannerScript(bottomBanner?.banner_html);
      }
    }
  }, [bottomBanner]);

  // console.log("BannerImages", bottomBanner);

  return (
    <section className="post-wrp">
      <div className="">
        {BannerScript ? (
          <div className="post-is-script">
            <Html html={BannerScript} />
          </div>
        ) : (
          <RenderBannerByLayout itemsArray={BannerImages} />
        )}
      </div>
    </section>
  );
};

export default BottomBannerItem;
