import { useLazyQuery, useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { GetCurrentCatCompanyData, GetCurrentCatData } from "../query/Graphql";
import { GET_COMPANY_BY_CAT, PRODUCT_LISTING_QUERY } from "../query/query";
import BottomBannerSlider from "../utils/BannerLayout/BottomBannerSlider";
import WithBannersLayout from "../utils/WithBannersLayout/WithBannersLayout";
import WithMetaByData from "../utils/WithMetaByData";
import CompanyProviderForCategory from "./CompanyProviderForCategory";
import FaqAndGuidesTabs from "./FaqAndGuidesTabs";
import Cardslocking from "./product/Cardslocking";
import Productbanner from "./product/Productbanner";
import ProductList from "./product/ProductList";
import ProductListSection from "./product/ProductListSection";
import ProductSkeleton from "./ProductSkeleton";
import Rateproviders from "./Rateproviders";
import PopularProductByCIDWidget from "./Widgets/PopularProductByCIDWidget";

const RenderProductListPage = ({ CurrentId }) => {
  const location = useLocation();
  const currentCatData = CurrentId && GetCurrentCatData(CurrentId);
  const data = CurrentId && GetCurrentCatCompanyData(CurrentId);
  // const [fetchCompanyList, { loading, error, data }] = useLazyQuery(GET_COMPANY_BY_CAT, {
  //   fetchPolicy: "no-cache",
  // });
  // useEffect(() => {
  //   if (CurrentId) {
  //     fetchCompanyList({
  //       variables: { id: CurrentId },
  //     });
  //   }
  // }, [CurrentId]);

  const renderPlaceHolder = () => {
    return (
      <>
        <section className="product-inner-listing-wrp">
          <div className="container">
            <div className="row">
              <div className="col-md-12 d-flex">
                <div className="left">
                  <h3>
                    <div className="skeleton-text" style={{ width: "150px", marginBottom: "15px" }} />
                  </h3>
                  <p className="big-p">
                    <div className="skeleton-text" style={{ width: "150px" }} />
                  </p>
                </div>
                <div className="right">
                  <select name="" id="">
                    <option value="">
                      <div className="skeleton-text" style={{ width: "150px" }} />
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="bothcol">
                  <div className="sidebar">
                    <div className="skeleton-text" style={{ height: "80px", marginBottom: "15px" }} />
                    <div className="skeleton-text" style={{ height: "80px", marginBottom: "15px" }} />
                    <div className="skeleton-text" style={{ height: "80px", marginBottom: "15px" }} />
                    <div className="below-filter-aboutSection">
                      <div className="skeleton-text" style={{ height: "22px" }} />
                    </div>
                  </div>
                  <div className="right-product-list">
                    <>
                      <ProductSkeleton />
                      <ProductSkeleton />
                      <ProductSkeleton />
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };
  console.log("catcomapnylisting", data);
  return (
    <WithMetaByData data={currentCatData}>
      <div className="mb-3" />
      <WithBannersLayout leftBanners={[]} rightBanners={currentCatData?.catbanner?.filter((item) => item.banner_position == "4")}>
        <section>
          <div className="container pt-4 mb-4">
            <h3 className="big-p text-black font-bold">
              {currentCatData?.catname ? currentCatData?.catname : <div className="skeleton-text" style={{ height: "50px" }} />}
            </h3>
          </div>
        </section>
        <Productbanner CurrentSubCategory={currentCatData} />
        {/* <Productsaving /> */}
        {currentCatData ? (
          <ProductListSection
            CurrentCategoryPath={location.pathname?.replace("/productList/", "")}
            CurrentSubCategory={currentCatData}
            companyList={data?.catcomapnylisting?.catcomapnylisting}
          />
        ) : (
          renderPlaceHolder()
        )}
        <section>
          <div className="container">
            <PopularProductByCIDWidget categoryId={CurrentId} />
            {/* <Cardslocking /> */}
          </div>
        </section>
        {currentCatData?.catbanner?.filter((item) => item.banner_position == "1") && (
          <section>
            <div className="container my-4">
              <BottomBannerSlider bottomBanner={currentCatData?.catbanner?.filter((item) => item.banner_position == "1")} />
            </div>
          </section>
        )}
        <FaqAndGuidesTabs CurrentSubCategory={currentCatData} />
        {data?.catcomapnylisting?.catcomapnylisting && <CompanyProviderForCategory companyList={data?.catcomapnylisting?.catcomapnylisting} />}
        {/* <Rateproviders /> */}
      </WithBannersLayout>
    </WithMetaByData>
  );
};

export default RenderProductListPage;
