import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import redc from "../../assets/images/redc.png";
import discounticn from "../../assets/images/discount.svg";
import star from "../../assets/images/star.svg";
import viewcard from "../../assets/images/view-more-rgt.svg";
import ProductList from "./ProductList";
import r1 from "../../assets/images/r1.png";
import r2 from "../../assets/images/r2.png";
import r3 from "../../assets/images/r3.png";
import r4 from "../../assets/images/r4.png";
import down from "../../assets/images/dwn.svg";
import score from "../../assets/images/score.png";
import RangeInput from "../RangeInput";
import Accordion from "../Accordion/Accordion";
import AccordionItem from "../Accordion/AccordionItem";
import FilterWithChecklist from "../filters/FilterWithChecklist";
import FilterWithRange from "../filters/FilterWithRange";
import FilterWithRadioBtn from "../filters/FilterWithRadioBtn";
import { useLazyQuery, useQuery } from "@apollo/client";
import { PRODUCT_LISTING_QUERY } from "../../query/query";
import LeftBannerItem from "../../utils/WithBannersLayout/LeftBannerItem";
import FilterWithCategoryChecklist from "../filters/FilterWithCategoryChecklist";
import GetMessage from "../../utils/GetMessage";
import FilterWithCompanyChecklist from "../filters/FilterWithCompanyChecklist";

const ProductListSection = ({ CurrentCategoryPath, CurrentSubCategory, companyList }) => {
  const abortController = new AbortController();
  const location = useLocation();
  const [fetchAgain, { loading, error, data }] = useLazyQuery(PRODUCT_LISTING_QUERY, {
    fetchPolicy: "no-cache", // Disable cache for this query
  });
  const [HideLoadMore, setHideLoadMore] = useState(false);
  const [pageCount, setPageCount] = useState(10);
  const [page, setPage] = useState(1);
  const [AllFiltersOptions, setAllFiltersOptions] = useState([]);
  const [CategoryFilterOptions, setCategoryFilterOptions] = useState([]);
  const [CompanyFilterOptions, setCompanyFilterOptions] = useState([]);
  const [CategoryFilters, setCategoryFilters] = useState([]);
  const [CompanyFilters, setCompanyFilters] = useState([]);
  const [filters, setFilters] = useState();
  const [value, setValue] = useState(0);
  const [ProductListArray, setProductListArray] = useState([]);
  const [SelectedFilterArray, setSelectedFilterArray] = useState([]);
  const [selectedOption, setSelectedOption] = useState("ASC");
  const [FetchAgainWithStateChange, setFetchAgainWithStateChange] = useState(false);
  useEffect(() => {
    fetchAgain({
      variables: {
        categoryId: Number(CurrentSubCategory.id),
        page: page,
        per_page: pageCount,
        sort: selectedOption,
        filterCategory: CategoryFilters.length > 0 ? CategoryFilters?.join(",") : "",
        company: CompanyFilters.length > 0 ? CompanyFilters?.join(",") : "",
        filters: SelectedFilterArray,
      },
    });
    return () => {
      abortController.abort();
    };
  }, [selectedOption, CategoryFilters, CompanyFilters, page, pageCount, FetchAgainWithStateChange]);
  console.log("CompanyFilters", CompanyFilters);
  useEffect(() => {
    // clear state on category changes
    clearAllStates();
  }, [CurrentSubCategory]);

  useEffect(() => {
    // clear state on unmount
    return () => {
      clearAllStates();
      abortController.abort();
    };
  }, []);
  useEffect(() => {
    if (!loading && data && data?.productlistingdata?.data?.product?.length > 0) {
      const newDataList = data?.productlistingdata?.data?.product?.filter((newItem) => !ProductListArray.some((item) => item.id === newItem.id));
      if (newDataList.length > 0) {
        setProductListArray((prevDataList) => [...prevDataList, ...newDataList]);
      }
      setTimeout(() => {
        console.log("dhruvin page", ProductListArray.length, data?.productlistingdata?.total);
        if (ProductListArray.length == data?.productlistingdata?.total) {
          setHideLoadMore(true);
        } else {
          setHideLoadMore(false);
        }
      }, 500);
    } else if (!loading && data && data?.productlistingdata?.data?.product?.length == 0) {
      setProductListArray([]);
      setHideLoadMore(true);
    }

    if (CurrentSubCategory && data && page == 1 && !SelectedFilterArray?.length > 0 && !CategoryFilters?.length > 0) {
      // setting category filter
      data?.productlistingdata?.data?.category && setCategoryFilterOptions(data?.productlistingdata?.data?.category);
      companyList && setCompanyFilterOptions(companyList);
      // setting filters
      const allFilterOP = data?.productlistingdata?.data?.attributes
        ?.map(
          (item) =>
            item.is_filter == 1 && {
              name: item.attrName,
              attrId: item.attribute_attr_id,
              attrType: item.attrType,
              filter_type: item.filter_type,
              filter_range_min: item.filter_type == 3 && item.filter_range_min,
              filter_range_max: item.filter_type == 3 && item.filter_range_max,
              attrValue: getAttributeValueAsPerType(item),
              cbvalue: (item.filter_type == 1 || item.filter_type == 2) && item.cbvalue,
              cbimage: (item.filter_type == 1 || item.filter_type == 2) && item.cbimage,
            }
        )
        ?.filter(Boolean);
      allFilterOP?.length > 0 && setAllFiltersOptions(allFilterOP);
    }
  }, [data]);

  const clearAllStates = () => {
    setCategoryFilters([]);
    setCompanyFilters([]);
    setSelectedFilterArray([]);
    setProductListArray([]);
    setPageCount(10);
    setPage(1);
    setFilters([]);
    setHideLoadMore(false);
    setCategoryFilterOptions([]);
    setCompanyFilterOptions([]);
    setAllFiltersOptions([]);
  };
  const clearAllStatesOnFilterChange = () => {
    setProductListArray([]);
    setPageCount(10);
    setPage(1);
    setHideLoadMore(false);
  };
  const handleLoadMore = () => {
    if (ProductListArray.length != data?.productlistingdata?.total) {
      setPage(page + 1);
    }
  };
  const renderLoadMore = () => {
    return (
      !HideLoadMore &&
      ProductListArray.length != data?.productlistingdata?.total &&
      ProductListArray.length > 0 && (
        <div className="bottomOfListPage viewmore-div">
          {loading ? (
            <>
              <div className="spinner-border text-primary" role="status" style={{ height: "22px", width: "22px" }} />
            </>
          ) : (
            <Link className="viewmore" onClick={() => handleLoadMore()}>
              Load more
            </Link>
          )}
        </div>
      )
    );
  };
  //   return (
  //     <>
  //       {loading && (
  //         <>
  //           <div className="bonus-s">
  //             <p>
  //               <div
  //                 className="skeleton-text"
  //                 style={{ height: "22px", width: "100px" }}
  //               />
  //             </p>
  //             <h6>
  //               <div
  //                 className="skeleton-text"
  //                 style={{ height: "22px", width: "100px" }}
  //               />
  //             </h6>
  //           </div>
  //           <div className="bonus-s">
  //             <p>
  //               <div
  //                 className="skeleton-text"
  //                 style={{ height: "22px", width: "100px" }}
  //               />
  //             </p>
  //             <h6>
  //               <div
  //                 className="skeleton-text"
  //                 style={{ height: "22px", width: "100px" }}
  //               />
  //             </h6>
  //           </div>
  //           <div className="bonus-s">
  //             <p>
  //               <div
  //                 className="skeleton-text"
  //                 style={{ height: "22px", width: "100px" }}
  //               />
  //             </p>
  //             <h6>
  //               <div
  //                 className="skeleton-text"
  //                 style={{ height: "22px", width: "100px" }}
  //               />
  //             </h6>
  //           </div>
  //           <div className="bonus-s">
  //             <p>
  //               <div
  //                 className="skeleton-text"
  //                 style={{ height: "22px", width: "100px" }}
  //               />
  //             </p>
  //             <h6>
  //               <div
  //                 className="skeleton-text"
  //                 style={{ height: "22px", width: "100px" }}
  //               />
  //             </h6>
  //           </div>
  //         </>
  //       )}
  //       {data?.productlistingdata?.data?.attributes
  //         ?.filter((item) => item.is_listing == 1)
  //         .map((item) => (
  //           <div className="bonus-s">
  //             <p>{item.attrName}</p>
  //             <h6>{getAttributeValueAsPerType(item)}</h6>
  //           </div>
  //         ))}
  //     </>
  //   );
  // };
  const getAttributeValueAsPerType = (item) => {
    if (item.attrType == 4) {
      return item.stringval || null;
    } else if (item.attrType == 3) {
      return item.decvalue || null;
    } else if (item.attrType == 2) {
      return item.decvalue || null;
    } else if (item.attrType == 1) {
      return item.cbtext || null;
    }
  };
  function updateAttributeById(arr, id, appendingName, filterType, attType) {
    if (filterType == 1) {
      let idFound = false;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].attrId == id) {
          // If the ID is found
          arr[i].attrValue = appendingName;
          idFound = true;
          break;
        }
      }
      if (!idFound) {
        // If the ID is not found, create a new object and add it to the array
        arr.push({
          attrId: id,
          attrType: attType,
          attrValue: appendingName,
        });
      }
      return arr;
    } else if (filterType == 2) {
      let idFound = false;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].attrId === id) {
          // If the ID is found, split the attrValue by comma and remove any existing name
          let attrValues = arr[i].attrValue.split(",");
          if (attrValues.includes(appendingName)) {
            arr[i].attrValue = attrValues.filter((value) => value !== appendingName).join(",");
            if (arr[i].attrValue == "") {
              // remove if all filter is unselected
              arr.splice(i, 1);
            }
          } else {
            // Append "1" and join the values back with a comma
            arr[i].attrValue = attrValues.concat(appendingName).join(",");
          }
          idFound = true;
          break;
        }
      }
      if (!idFound) {
        // If the ID is not found, create a new object and add it to the array
        arr.push({
          attrId: id,
          attrType: attType,
          attrValue: appendingName,
        });
      }
      return arr;
    } else if (filterType == 3) {
      // 3 is range
      let idFound = false;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].attrId === id) {
          // If the ID is found, split the attrValue by comma and remove any existing name
          let attrValues = arr[i].attrValue;
          if (attrValues == appendingName) {
            // arr.splice(i, 1);
          } else {
            arr[i].attrValue = appendingName;
          }
          idFound = true;
          break;
        }
      }
      if (!idFound) {
        // If the ID is not found, create a new object and add it to the array
        arr.push({
          attrId: id,
          attrType: attType,
          attrValue: appendingName,
        });
      }
      return arr;
    }
  }
  const handleResetSingleSelection = (e) => {
    let removedFilters = SelectedFilterArray;
    for (let i = 0; i < SelectedFilterArray.length; i++) {
      if (SelectedFilterArray[i].attrId == e.target.name) {
        removedFilters.splice(i, 1);
        break;
      }
    }
    if (removedFilters) {
      setSelectedFilterArray(removedFilters);
      clearAllStatesOnFilterChange();
      setFetchAgainWithStateChange(!FetchAgainWithStateChange);
    }
  };
  const handleSingleSelectChange = (e) => {
    // type 1 for multi selection
    // const newFilters = updateAttributeById(SelectedFilterArray, attId, value, filtType);
    const newFilters = updateAttributeById(SelectedFilterArray, e.target.name, e.target.id, "1", e.target.value);
    if (newFilters) {
      setSelectedFilterArray(newFilters);
      clearAllStatesOnFilterChange();
      setFetchAgainWithStateChange(!FetchAgainWithStateChange);
    }
  };
  const handleMultiSelectChange = (e) => {
    // type 2 for multi selection
    // const newFilters = updateAttributeById(SelectedFilterArray, attId, value, filtType);
    console.log(SelectedFilterArray, e.target.name, e.target.id, e.target.value);
    const newFilters = updateAttributeById(SelectedFilterArray, e.target.name, e.target.id, "2", e.target.value);
    if (newFilters) {
      setSelectedFilterArray(newFilters);
      clearAllStatesOnFilterChange();
      setFetchAgainWithStateChange(!FetchAgainWithStateChange);
    }
  };
  const handleRangeChange = (e) => {
    // type 3 is range
    // const newFilters = updateAttributeById(SelectedFilterArray, attId, value, filtType);

    const newFilters = updateAttributeById(SelectedFilterArray, e.target.id, e.target.value, "3", e.target.name);
    if (newFilters) {
      setSelectedFilterArray(newFilters);
      clearAllStatesOnFilterChange();
      setFetchAgainWithStateChange(!FetchAgainWithStateChange);
    }
  };
  const handleCategoryFilterChange = (id) => {
    if (CategoryFilters?.includes(id)) {
      setCategoryFilters(CategoryFilters?.filter((cat) => cat !== id));
      clearAllStatesOnFilterChange();
    } else {
      setCategoryFilters((prevFilters) => [...prevFilters, id]);
      clearAllStatesOnFilterChange();
    }
  };
  const handleCompanyFilterChange = (id) => {
    if (CompanyFilters?.includes(id)) {
      setCompanyFilters(CompanyFilters?.filter((cat) => cat !== id));
      clearAllStatesOnFilterChange();
    } else {
      setCompanyFilters((prevFilters) => [...prevFilters, id]);
      clearAllStatesOnFilterChange();
    }
  };
  const handleSortChange = (event) => {
    console.log(event.target.value);
    setSelectedOption(event.target.value);
    clearAllStatesOnFilterChange();
  };
  const renderFilterByType = (filterObj) => {
    if (filterObj?.filter_type == 1) {
      // 1 = single selection
      const attrValueArray = filterObj?.attrValue?.split(",");
      const cbValueArray = filterObj?.cbvalue?.split(",");
      const cbImageArray = filterObj?.cbimage?.split(",");
      const resultArray = [];

      for (let i = 0; i < attrValueArray?.length; i++) {
        resultArray?.push({
          id: filterObj?.attrId,
          filterId: filterObj?.attrId,
          qty: cbValueArray[i],
          name: attrValueArray[i],
          image: cbImageArray?.length > 0 && cbImageArray[i],
          attrType: filterObj?.attrType,
        });
      }
      return (
        <FilterWithRadioBtn
          title={filterObj?.name}
          attrType={filterObj?.attrType}
          handleChange={handleSingleSelectChange}
          radioList={resultArray}
          handleResetSingleSelection={handleResetSingleSelection}
          SelectedFilterArray={SelectedFilterArray}
        />
      );
    } else if (filterObj?.filter_type == 2) {
      // 2= multiple selection
      const attrValueArray = filterObj?.attrValue?.split(",");
      const cbValueArray = filterObj?.cbvalue?.split(",");
      const cbImageArray = filterObj?.cbimage?.split(",");
      const resultArray = [];

      for (let i = 0; i < attrValueArray?.length; i++) {
        resultArray?.push({
          id: filterObj?.attrId,
          filterId: filterObj?.attrId,
          qty: cbValueArray[i],
          name: attrValueArray[i],
          image: cbImageArray?.length > 0 && cbImageArray[i],
          attrType: filterObj?.attrType,
        });
      }
      return <FilterWithChecklist title={filterObj?.name} handleChange={handleMultiSelectChange} checkList={resultArray} />;
    } else if (filterObj?.filter_type == 3) {
      // 3 = reange filter
      return (
        <FilterWithRange
          min={filterObj?.filter_range_min}
          max={filterObj?.filter_range_max}
          value={filterObj?.attrValue}
          id={filterObj?.attrId}
          name={filterObj?.attrType}
          title={filterObj?.name}
          handleChange={handleRangeChange}
        />
      );
    }
  };
  console.log("SelectedFilterArray", SelectedFilterArray);
  return (
    <section className="product-inner-listing-wrp">
      <div className="container">
        <div className="row">
          <div className="col-md-12 d-flex">
            <div className="left">
              <h3>{GetMessage("filters")}</h3>
              <p className="big-p d-flex">
                {loading ? (
                  <div className="skeleton-text" style={{ width: "30px", height: "24px" }} />
                ) : data?.productlistingdata?.total && data?.productlistingdata?.total != 0 ? (
                  data?.productlistingdata?.total
                ) : (
                  "No"
                )}{" "}
                {CurrentSubCategory?.catname}
                {!loading && data?.productlistingdata?.total == 0 && " " + "Found"}
              </p>
            </div>
            <div className="right">
              <select value={selectedOption} onChange={handleSortChange}>
                <option value="ASC">Sort by : Alphabet A - Z</option>
                <option value="DESC">Sort by : Alphabet Z - A</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="bothcol">
              <div className="sidebar">
                {CategoryFilterOptions && CategoryFilterOptions?.length > 0 && (
                  <FilterWithCategoryChecklist
                    title="Category"
                    handleChange={handleCategoryFilterChange}
                    CategoryFilters={CategoryFilters}
                    checkList={CategoryFilterOptions}
                  />
                )}
                {AllFiltersOptions?.length > 0 ? (
                  AllFiltersOptions?.map((item) => renderFilterByType(item))
                ) : !AllFiltersOptions?.length > 0 && loading ? (
                  <>
                    <div className="skeleton-text" style={{ height: "80px", marginBottom: "15px" }} />
                    <div className="skeleton-text" style={{ height: "80px", marginBottom: "15px" }} />
                    <div className="skeleton-text" style={{ height: "80px", marginBottom: "15px" }} />
                  </>
                ) : null}
                {CompanyFilterOptions && CompanyFilterOptions?.length > 0 && (
                  <FilterWithCompanyChecklist
                    title="Provider"
                    handleChange={handleCompanyFilterChange}
                    CategoryFilters={CompanyFilters}
                    checkList={CompanyFilterOptions}
                  />
                )}
                {CurrentSubCategory?.catbanner?.filter((item) => item.banner_position == "3")?.length > 0 && <div className="pb-4"></div>}
                {CurrentSubCategory?.catbanner
                  ?.filter((item) => item.banner_position == "3")
                  ?.map((item) => (
                    <LeftBannerItem LeftBanner={item} />
                  ))}

                {CurrentSubCategory?.catname ? (
                  <>
                    {CurrentSubCategory?.cms_page_content && (
                      <div
                        className="below-filter-aboutSection"
                        style={{
                          borderTop:
                            CurrentSubCategory?.catbanner?.filter((item) => item.banner_position == "3")?.length > 0 ? "1px solid #00000033" : "none",
                          margin: !CurrentSubCategory?.catbanner?.filter((item) => item.banner_position == "3")?.length > 0 && "0px",
                        }}
                      >
                        <Link className="aboutSection-link" to={CurrentSubCategory?.cms_page_content}>
                          <span className="blu-txt">About</span>
                          <span className="ps-1"></span>

                          <span className="green-txt">{CurrentSubCategory?.catname}</span>
                        </Link>
                      </div>
                    )}
                  </>
                ) : !CurrentSubCategory ? (
                  <div className="skeleton-text" style={{ height: "22px" }} />
                ) : null}
              </div>
              <ProductList
                CurrentCategoryPath={CurrentCategoryPath}
                ProductListArray={ProductListArray}
                renderLoadMore={renderLoadMore}
                loading={loading}
                error={error}
                topCatId={data?.productlistingdata?.data?.topCatId}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductListSection;
