import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import cardc from "../../assets/images/card1.jpg";
import { setCompareItems } from "../../stores/rootReducer";
import GetMessage from "../../utils/GetMessage";
import sendNotification from "../../utils/SendNotification";
import "./CompareBar.css";

const CompareBar = () => {
  const compareItems = useSelector((state) => state.rootReducer?.CompareItems);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log("compareItems", compareItems);

  const handleClearCompareList = () => {
    dispatch(setCompareItems([]));
  };
  const error_compare_minimum_items = GetMessage("error_compare_minimum_items");
  const handleCompareClick = () => {
    if (compareItems?.length > 1) {
      navigate("/Compare Products");
    } else {
      // sendNotification("error", "please add minimum 2 items to compare");
      sendNotification("error", error_compare_minimum_items);
      // alert("please add minimum 2 items to compare");
    }
  };
  if (window.location.pathname?.includes("/Compare") || compareItems?.length == 0) {
    return null;
  }
  if (window.location.pathname == "/Redirect") {
    return null;
  }
  return (
    <section className="card-comparison-wrp">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="card-com-inr">
              <div className="card1">
                <div className="card-img">
                  <img src={compareItems[0]?.product_image_url} alt={compareItems[0]?.id} />
                </div>
                <p>{compareItems[0]?.name}</p>
              </div>
              {compareItems?.length > 1 ? (
                <div className="card1">
                  <div className="card-img">
                    <img src={compareItems[1]?.product_image_url} alt={compareItems[1]?.id} />
                  </div>
                  <p>{compareItems[1]?.name}</p>
                </div>
              ) : (
                <div className="card1 addcard">
                  <div className="card-img">
                    <a href="#">+</a>
                  </div>
                  <p>Add Product</p>
                </div>
              )}
              {compareItems?.length > 2 ? (
                <div className="card1">
                  <div className="card-img">
                    <img src={compareItems[2]?.product_image_url} alt={compareItems[2]?.id} />
                  </div>
                  <p>{compareItems[2]?.name}</p>
                </div>
              ) : (
                <div className="card1 addcard">
                  <div className="card-img">
                    <a href="#">+</a>
                  </div>
                  <p>Add Product</p>
                </div>
              )}
              <div className="compare card1">
                <a onClick={() => handleCompareClick()} className="action-btn small-btn" style={{ cursor: "pointer" }}>
                  Compare
                </a>
                <a onClick={() => handleClearCompareList()} className="clear" style={{ cursor: "pointer" }}>
                  Clear
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompareBar;
