import React from "react";

import star from "../../assets/images/star.svg";
import starEmpty from "../../assets/images/starEmpty.svg";
import starHalf from "../../assets/images/starHalf.svg";

// const RatingStars = ({ averageRating }) => {
//   // Round the average rating to the nearest half and convert to a scale of 1 to 5
//   const filledStars = Math.round(averageRating * 2) / 2;

//   return (
//     <>
//       {Array.from({ length: 5 }, (_, index) => (
//         <img key={index} className={index + 1 <= filledStars ? "" : "empty"} src={index + 1 <= filledStars ? star : starEmpty} alt="" title="" />
//       ))}
//     </>
//   );
// };

// export default RatingStars;
const RatingStars = ({ averageRating }) => {
  const fullStars = Math.floor(averageRating);
  // Gets the number of full stars. averageRating is the rating, for example
  // if the rating were 4.3, fullStars would now be 4.

  const starArr = [];
  // Create an empty array. We will add 1s, 0s, and a decimal value for the
  // partial star.

  for (let i = 1; i <= fullStars; i++) {
    starArr.push(1);
  }
  // This adds a 1 to the array for each full star in our rating

  if (averageRating < 5) {
    // Wrapped in an if block because the following only needs to occur if
    // it's not a full 5.

    const partialStar = averageRating - fullStars;
    // Calculates the partial star. For example 4.3 - 4 = 0.3. 0.3 will get
    // added to the array in the next line to represent the partial star

    starArr.push(partialStar);
    // Adds the partial star to the array

    const emptyStars = 5 - starArr.length;
    // Calculates the number of empty stars

    for (let i = 1; i <= emptyStars; i++) {
      starArr.push(0);
    }
    // This for loop adds 0s to the array to represent empty stars
  }
  console.log(starArr);

  const stars = starArr.map((val, i) => {
    if (val == 1) {
      return <img key={i} className="" src={star} alt="" title="" />;
    } else if (val == 0) {
      return <img key={i} className="" src={starEmpty} alt="" title="" />;
    } else {
      return <img key={i} className="" src={starHalf} alt="" title="" />;
    }
    return;
  });
  // This last block is explained in the following paragraphs below

  return <>{stars}</>;
};

export default RatingStars;
