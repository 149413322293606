import React from "react";
import { Link } from "react-router-dom";
import money from "../../src/assets/images/money.jpg";
import flag1 from "../../src/assets/images/flg1.png";
import flag2 from "../../src/assets/images/flg2.jpg";
import Card from "../../src/assets/images/card1.svg";
import { Dropdown } from "bootstrap";
import down from "../../src/assets/images/dwn.svg";
import GoldRateWidget from "./Widgets/GoldRateWidget";
import CurrencyConverterWidget from "./Widgets/CurrencyConverterWidget";

const Converter = () => {
  return (
    <section className="converter-wrp">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <CurrencyConverterWidget />
          </div>
          <div className="col-md-4">
            <GoldRateWidget />
          </div>
          <div className="col-md-4">
            <div className="convrt-in">
              <div className="convrt-img">
                <img src={money} alt={money} />
              </div>
              <div className="convrt-content">
                <h5>Calculator & Tools</h5>
                <div className="loan-option">
                  <Link to={"/Calculators/car loan"} className="action-btn small-btn btn-shine">
                    Car Loan
                  </Link>
                  <Link to={"/Calculators/DBR Calculator"} className="action-btn small-btn">
                    DBR Calculator
                  </Link>
                  <Link to={"/Calculators/Home Loan"} className="action-btn small-btn">
                    Home Loan
                  </Link>
                  <Link to={"/Calculators/Interest"} className="action-btn small-btn">
                    Interest
                  </Link>
                </div>
                <Link to={"/Calculators"} className="viewmore font-16">
                  View All Calculators <img src={down} alt={down} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Converter;
