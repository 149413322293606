import React from "react";
import Accordion from "../Accordion/Accordion";
import AccordionItem from "../Accordion/AccordionItem";

const FilterWithRadioBtn = ({ title, handleChange, radioList, handleResetSingleSelection, SelectedFilterArray }) => {
  const isChecked = (myvalue) => {
    for (let i = 0; i < SelectedFilterArray.length; i++) {
      if (SelectedFilterArray[i].attrId == radioList[0].filterId) {
        console.log("dhru", SelectedFilterArray[i].attrId);
        if (SelectedFilterArray[i].attrValue == myvalue) {
          return true;
          break;
        }
      }
    }
  };
  const isFilterSelected = () => {
    for (let i = 0; i < SelectedFilterArray.length; i++) {
      if (SelectedFilterArray[i].attrId == radioList[0].filterId) {
        return true;
        break;
      }
    }
    return;
  };
  return (
    <div className="category-div">
      <div className="drop-down-wrp">
        <Accordion>
          <AccordionItem title={title}>
            <div className="sidebar-list">
              {isFilterSelected() && (
                <button
                  className="mb-3 px-4 py-1"
                  style={{ background: "#2196f3 0% 0% no-repeat padding-box", border: "none", borderRadius: "4px", color: "#fff" }}
                  onClick={handleResetSingleSelection}
                  name={radioList[0].filterId}
                >
                  Clear
                </button>
              )}
              <ul>
                {radioList.map((item) => (
                  <li className={`add-comp ${item?.image && "withImage"}`}>
                    <label style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                      <input
                        type="radio"
                        name={item.filterId}
                        id={item.qty}
                        value={item.attrType}
                        checked={isChecked(item.qty)}
                        onChange={handleChange}
                      />
                      {item.name}{" "}
                    </label>
                    {/* <span>({item.qty})</span> */}
                  </li>
                ))}
              </ul>
            </div>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
};

export default FilterWithRadioBtn;
