import React from "react";

const Html = ({ html }) => {
  return (
    <>
      <span
        className="HtmlContentDiv"
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
    </>
  );
};

export default Html;
