import React, { useEffect } from "react";
import "./RedirectionPage.css";

const RedirectionPage = (props) => {
  //   const { data } = props.location?.state;
  //   const location = useLocation();
  console.log("Redirection", window?.applynow_button_url);
  useEffect(() => {
    if (window?.applynow_button_url) {
      var metaTag = document.createElement("meta");
      metaTag.setAttribute("http-equiv", "refresh");
      metaTag.setAttribute("content", `0; url = ${window?.applynow_button_url}`);
      document.head.appendChild(metaTag);
    }
    // Cleanup: remove the link when the component is unmounted
    return () => {
      document.head.removeChild(metaTag);
    };
  }, []);

  //   return (
  //     <div className="text-center" style={{ maxWidth: "400px", margin: "auto" }}>
  //       redirecting.............
  //       <div>Please wait as we redirect you</div>
  //       <div>You'll arrive at the website soon.</div>
  //       <div>
  //         <div className="loading-container">
  //           <div className="loading-text">
  //             <span>R</span>
  //             <span>E</span>
  //             <span>D</span>
  //             <span>I</span>
  //             <span>R</span>
  //             <span>E</span>
  //             <span>C</span>
  //             <span>T</span>
  //             <span>I</span>
  //             <span>N</span>
  //             <span>G</span>
  //           </div>
  //         </div>
  //       </div>
  //       <div>As always, we’re still here to help.</div>
  //       <div>If you have not been redirected, please Click here</div>
  //     </div>
  //   );
  // };
  return (
    <div className="redirectScreen text-center" style={{ height: "100vh", background: "#ecf5fa" }}>
      <div className="" style={{ width: "400px", paddingTop: "10vh", marginLeft: "auto", marginRight: "auto" }}>
        <h1>Redirecting...</h1>
        <div className="slider">
          <div className="line"></div>
          <div className="break dot1"></div>
          <div className="break dot2"></div>
          <div className="break dot3"></div>
        </div>
        <h5 style={{ paddingTop: "15px" }}>You'll arrive at the website soon.</h5>
      </div>
      <div className="loaderCust">
        <div className="duo duo1">
          <div className="dot dot-a"></div>
          <div className="dot dot-b"></div>
        </div>
        <div className="duo duo2">
          <div className="dot dot-a"></div>
          <div className="dot dot-b"></div>
        </div>
      </div>
      <div className="" style={{ paddingBottom: "10vh" }}>
        <div>As always, we’re still here to help.</div>
        <div>
          If you have not been redirected, please <a href={window?.applynow_button_url}>Click here.</a>
        </div>
      </div>
    </div>
  );
};

export default RedirectionPage;
