import React, { useEffect, useState } from "react";
import "./SignUp.css";
import newFKCROPLOGO from "../../assets/images/newFKCROPLOGO.svg";
import { Link, useNavigate } from "react-router-dom";
import EmailIcon from "../../assets/icons/EmailIcon";
import PasswordIcon from "../../assets/icons/PasswordIcon";
import PhoneIcon from "../../assets/icons/PhoneIcon";
import UserNameIcon from "../../assets/icons/UserNameIcon";
import { useMutation } from "@apollo/client";
import sendNotification from "../../utils/SendNotification";
import { REGISTER_MUTATION } from "../../query/mutation";
import { GetSinglePagesDataFromSlug } from "../../query/Graphql";
import AuthLayoutBySlug from "./AuthLayoutBySlug";
import CountryCodes from "../../assets/CountryPhoneCode/CountryCodes.json";
import axios from "axios";
import Html from "../../utils/Html";

const SignUp = () => {
  const navigate = useNavigate();
  const pageData = GetSinglePagesDataFromSlug("customer-register");
  const [GeoState, setGeoState] = useState({
    ip: "",
    countryName: "",
    countryCode: "",
    city: "",
    timezone: "",
  });
  const [FName, setFName] = useState();
  const [LName, setLName] = useState();
  const [Email, setEmail] = useState();
  const [selectedCountryCode, setSelectedCountryCode] = useState(GeoState?.countryCode || "");
  const [Phone, setPhone] = useState();
  const [Password, setPassword] = useState();
  const [ConfPassword, setConfPassword] = useState();
  const [FNameError, setFNameError] = useState(false);
  const [LNameError, setLNameError] = useState(false);
  const [EmailError, setEmailError] = useState(false);
  const [PhoneError, setPhoneError] = useState(false);
  const [SelectedCountryCodeError, setSelectedCountryCodeError] = useState(false);
  const [PassError, setPassError] = useState(false);
  const [ConfPassError, setConfPassError] = useState(false);
  const [register, { loading, error }] = useMutation(REGISTER_MUTATION);
  let strongPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/;
  let mediumPassword = /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/;
  let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  let PhoneRegex = /^\d{6,11}$/;

  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setGeoState({
          ...GeoState,
          ip: data.ip,
          countryName: data.country_name,
          countryCode: data.country_calling_code,
          city: data.city,
          timezone: data.timezone,
        });
        setSelectedCountryCode(data?.country_calling_code);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getGeoInfo();
  }, []);

  const handleInputChange = (e) => {
    switch (e.target.id) {
      case "Fname":
        setFName(e.target.value);
        if (e.target.value) {
          setFNameError(false);
        } else {
          setFNameError(true);
        }
        break;
      case "Lname":
        setLName(e.target.value);
        if (e.target.value) {
          setLNameError(false);
        } else {
          setLNameError(true);
        }
        break;
      case "email":
        setEmail(e.target.value);
        if (e.target.value && emailRegex.test(e.target.value)) {
          setEmailError(false);
        } else {
          setEmailError(true);
        }
        break;
      case "PhoneCode":
        setSelectedCountryCode(e.target.value);
        if (e.target.value) {
          setSelectedCountryCodeError(false);
        } else {
          setSelectedCountryCodeError(true);
        }
        break;
      case "Phone":
        setPhone(e.target.value);
        if (e.target.value && PhoneRegex.test(e.target.value)) {
          setPhoneError(false);
        } else {
          setPhoneError(true);
        }
        break;
      case "password":
        setPassword(e.target.value);
        if (e.target.value && strongPassword.test(e.target.value)) {
          setPassError(false);
        } else {
          setPassError(true);
        }
        break;
      case "ConfPassword":
        setConfPassword(e.target.value);
        if (e.target.value) {
          setConfPassError(false);
        } else {
          setConfPassError(true);
        }
        break;
      default:
        break;
    }
  };
  const handleSubmitClick = async (e) => {
    let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let PhoneRegex = /^\d{6,11}$/;
    if (
      FName &&
      LName &&
      Email &&
      emailRegex.test(Email) &&
      selectedCountryCode &&
      Phone &&
      PhoneRegex.test(Phone) &&
      Password &&
      strongPassword.test(Password) &&
      ConfPassword &&
      Password == ConfPassword
    ) {
      try {
        const { data } = await register({
          variables: {
            first_name: FName,
            last_name: LName,
            email: Email,
            password: Password,
            country_code: selectedCountryCode,
            mobileno: Phone,
            social_id: "",
            social_type: "",
          },
        });
        // Handle the response data
        console.log(data?.register?.success);
        if (data?.register?.success == true) {
          // alert(data?.register?.success);
          sendNotification("success", data?.register?.message);
          // sessionStorage.setItem("User", JSON.stringify(data?.register?.regcustomer));
          navigate("/Registration_success");
        } else {
          sendNotification("error", data?.register?.message);
        }
      } catch (error) {
        sendNotification("error", error);
      }
    } else {
      if (!FName) {
        FNameError && setFNameError(false);
        setTimeout(() => {
          setFNameError(true);
        }, 50);
      }
      if (!LName) {
        LNameError && setLNameError(false);
        setTimeout(() => {
          setLNameError(true);
        }, 50);
      }
      if (!Email || !emailRegex.test(Email)) {
        EmailError && setEmailError(false);
        setTimeout(() => {
          setEmailError(true);
        }, 50);
      }
      if (!selectedCountryCode) {
        SelectedCountryCodeError && setSelectedCountryCodeError(false);
        setTimeout(() => {
          setSelectedCountryCodeError(true);
        }, 50);
      }
      if (!Phone || !PhoneRegex.test(Phone)) {
        PhoneError && setPhoneError(false);
        setTimeout(() => {
          setPhoneError(true);
        }, 50);
      }
      if (!strongPassword.test(Password)) {
        PassError && setPassError(false);
        setTimeout(() => {
          setPassError(true);
        }, 50);
      }
      if (!ConfPassword || ConfPassword !== Password) {
        ConfPassError && setConfPassError(false);
        setTimeout(() => {
          setConfPassError(true);
        }, 50);
      }
    }
  };
  return (
    <AuthLayoutBySlug pageData={pageData} contentSlug="customer-register">
      <div className="wrapper">
        <div className="log-in formDiv">
          {/* <img className="authLogo" src={newFKCROPLOGO} alt="find Kaizen" title="find Kaizen" /> */}
          <div className="w-100">
            <h5 className="text-center text-center formTitle mb-4">Register</h5>
          </div>
          <div className="floating-label">
            <input
              value={FName}
              onChange={(e) => handleInputChange(e)}
              placeholder="First Name"
              type="name"
              name="Fname"
              id="Fname"
              autoComplete="off"
              required
              className={`${FNameError ? "error" : ""}`}
            />
            <label htmlFor="Fname">First Name:</label>
            <div className={`icon ${FNameError ? "error" : ""}`}>
              <UserNameIcon />
            </div>
          </div>
          <div className="floating-label">
            <input
              value={LName}
              onChange={(e) => handleInputChange(e)}
              placeholder="Last Name"
              type="name"
              name="Lname"
              id="Lname"
              autoComplete="off"
              required
              className={`${LNameError ? "error" : ""}`}
            />
            <label htmlFor="Fname">Last Name:</label>
            <div className={`icon ${LNameError ? "error" : ""}`}>
              <UserNameIcon />
            </div>
          </div>
          <div className="floating-label">
            <input
              value={Email}
              onChange={(e) => handleInputChange(e)}
              placeholder="Email"
              type="email"
              name="email"
              id="email"
              autoComplete="off"
              required
              pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
              title="Enter a valid email address"
              className={`${EmailError ? "error" : ""}`}
            />
            <label htmlFor="email">Email:</label>
            <div className={`icon ${EmailError ? "error" : ""}`}>
              <EmailIcon />
            </div>
          </div>
          {/* <div className="floating-label"> */}
          {/* </div> */}
          <div className="floating-label">
            <select
              value={selectedCountryCode}
              id="PhoneCode"
              onChange={(event) => handleInputChange(event)}
              style={{ borderBottom: selectedCountryCode ? "solid 1px #7fc558" : SelectedCountryCodeError ? "solid 1px red" : "" }}
            >
              <option value="" disabled selected>
                Select
              </option>
              {CountryCodes?.map((cntry) => (
                <option key={cntry.dial_code} value={cntry?.dial_code}>
                  {cntry?.dial_code}
                </option>
              ))}
            </select>
            <input
              value={Phone}
              onChange={(e) => handleInputChange(e)}
              placeholder="Phone"
              type="Phone"
              name="Phone"
              id="Phone"
              autoComplete="off"
              required
              pattern="^\d{6,11}$"
              // pattern="(?:\+971|00971|0)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}"
              title="Enter a valid mobile number"
              className={`inputWithLeftSpace ${PhoneError ? "error" : ""}`}
            />
            <label htmlFor="Phone">Phone:</label>
            <div className={`icon ${PhoneError ? "error" : ""}`}>
              <PhoneIcon />
            </div>
          </div>
          {console.log("PassError", PassError)}
          <div className="floating-label">
            <input
              value={Password}
              onChange={(e) => handleInputChange(e)}
              placeholder="Password"
              type="password"
              name="password"
              id="password"
              autoComplete="off"
              required
              pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
              title="Enter Password"
              className={`${PassError ? "error" : ""}`}
            />
            <label htmlFor="password" className="w-100">
              Password:
              <span
                style={{
                  float: "right",
                  marginTop: "15px",
                  fontSize: "18px",
                  lineHeight: "28px",
                  letterSpacing: "0.4px",
                  opacity: 0.8,
                  fontFamily: "GilroyMedium",
                  color: strongPassword.test(Password) ? "#42B700" : mediumPassword.test(Password) ? "#ff7600" : "red",
                }}
              >
                {strongPassword.test(Password) ? "Strong" : mediumPassword.test(Password) ? "Medium" : "Weak"}
              </span>
            </label>
            <div className={`icon ${PassError ? "error" : ""}`}>
              <PasswordIcon />
            </div>
          </div>
          <div className="floating-label">
            <input
              value={ConfPassword}
              onChange={(e) => handleInputChange(e)}
              placeholder="Confirm Password"
              type="Password"
              name="ConfPassword"
              id="ConfPassword"
              autoComplete="off"
              required
              pattern={Password}
              title="Passwords must match"
              className={`${ConfPassError ? "error" : ""}`}
            />
            <label htmlFor="ConfPassword">Confirm Password:</label>
            <div className={`icon ${ConfPassError ? "error" : ""}`}>
              <PasswordIcon />
            </div>
          </div>

          <Link to="/SignIn" className="discrete">
            Already have an account?
          </Link>
          <button className="action-btn small-btn w-100 my-2 py-2" onClick={(e) => handleSubmitClick(e)}>
            {loading ? (
              <div className="spinner-border text-primary" role="status" style={{ margin: "auto", height: "15px", width: "15px" }}>
                {/* <span className="sr-only">Loading...</span> */}
              </div>
            ) : (
              "Register"
            )}
          </button>
          <Html html={pageData?.cms?.find((item) => item.slug == "customer-register-new")?.description} />
        </div>
      </div>
    </AuthLayoutBySlug>
  );
};

export default SignUp;
