import React from "react";
import Accordion from "../Accordion/Accordion";
import AccordionItem from "../Accordion/AccordionItem";
import arrowSubDown from "../../assets/images/arrow-sub-down.svg";

const FilterWithCompanyChecklist = ({ title, handleChange, CategoryFilters, checkList }) => {
  return (
    <div className="category-div">
      <div className="drop-down-wrp">
        <Accordion>
          <AccordionItem title={title}>
            <div className="sidebar-list">
              <ul>
                {checkList?.map((item) => (
                  <>
                    <li className={`add-comp ${item?.logo_url && "withImage"}`}>
                      <input
                        type="checkbox"
                        name={item.name}
                        id={item.id}
                        onChange={() => handleChange(item.id)}
                        checked={CategoryFilters?.includes(item.id)}
                      />
                      {/* <label>{item.name}</label> */}
                      <label onClick={() => handleChange(item.id)} style={{ cursor: "pointer" }}>
                        {item.name}
                      </label>
                      {item?.logo_url && (
                        <div className="chk-img">
                          <img src={item?.logo_url} alt={item?.logo_alt_tag} title={item?.logo_alt_tag} />
                        </div>
                      )}
                      {/* <span>({item.total_product})</span> */}
                    </li>
                  </>
                ))}
              </ul>
            </div>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
};

export default FilterWithCompanyChecklist;
