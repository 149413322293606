import React, { useState } from "react";
import {
  GetFAQDataForCategory,
  GetGuidesDataForCategory,
} from "../query/Graphql";
import Html from "../utils/Html";

const FaqAndGuidesTabs = ({ CurrentSubCategory }) => {
  const [activeTab, setActiveTab] = useState(
    CurrentSubCategory?.guides ? 2 : 1
  );
  const handleTabChange = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  const renderFAQTab = () => {
    if (!CurrentSubCategory?.categoryfaq || !CurrentSubCategory) {
      return <></>;
    }
    return (
      <>
        {CurrentSubCategory?.categoryfaq &&
          CurrentSubCategory?.categoryfaq?.map((FAQ, index) => (
            <div className="accordion-item">
              <h2 className="accordion-header" id={FAQ.question}>
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#${FAQ.id}`}
                  aria-expanded="false"
                  aria-controls={FAQ.id}
                >
                  {FAQ.question}
                </button>
              </h2>
              <div
                id={FAQ.id}
                className="accordion-collapse collapse"
                aria-labelledby={FAQ.question}
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <Html html={FAQ.answer} />
                </div>
              </div>
            </div>
          ))}
      </>
    );
  };
  const renderGuidesTab = () => {
    if (!CurrentSubCategory?.guides || !CurrentSubCategory) {
      return <></>;
    }
    return (
      <>
        {CurrentSubCategory?.guides?.length > 0 && (
          <div className="accordion-item">
            <div className="accordion-body">
              <Html html={CurrentSubCategory?.guides[0]?.description} />
            </div>
          </div>
        )}
      </>
    );
  };
  if (
    !CurrentSubCategory?.categoryfaq?.length > 0 &&
    !CurrentSubCategory?.guides?.length > 0
  ) {
    return <></>;
  }
  return (
    <div className="CompareProducts-wrp pro-list-acodian-tab">
      <div className="container">
        <div className="CompareProductsInner">
          <div>
            <ul
              className={`outerNav nav nav-tabs nav-fill ${
                (!CurrentSubCategory?.categoryfaq?.length > 0 ||
                  !CurrentSubCategory?.guides?.length > 0) &&
                "d-flex"
              }`}
            >
              {CurrentSubCategory?.categoryfaq?.length > 0 && (
                <li className="nav-item">
                  <button
                    className={`nav-link ${activeTab === 1 ? "active" : ""}`}
                    onClick={() => handleTabChange(1)}
                  >
                    FAQs
                  </button>
                </li>
              )}
              {CurrentSubCategory?.guides?.length > 0 && (
                <li className="nav-item">
                  <button
                    className={`nav-link ${activeTab === 2 ? "active" : ""}`}
                    onClick={() => handleTabChange(2)}
                  >
                    Guides
                  </button>
                </li>
              )}
            </ul>
            <div className="tab-content mt-3">
              <div
                className={`tab-pane ${activeTab === 1 ? "active" : ""}`}
                id="tab1"
              >
                <div className="accordion" id="accordionExample">
                  {renderFAQTab()}
                </div>
              </div>
              <div
                className={`tab-pane ${activeTab === 2 ? "active" : ""}`}
                id="tab2"
              >
                {renderGuidesTab()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqAndGuidesTabs;
