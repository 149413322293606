import React from "react";
import { useParams } from "react-router-dom";
import RenderBannerByLayout from "../../components/RenderBannerByLayout";
import { GetSinglePagesDataFromSlug } from "../../query/Graphql";
import TopBannerSlider from "../../utils/BannerLayout/TopBannerSlider";
import BottomBannerSlider from "../../utils/BannerLayout/BottomBannerSlider";
import Html from "../../utils/Html";
import WithMetaByData from "../../utils/WithMetaByData";
import ErrorPage from "../ErrorPage/ErrorPage";
import WithBannersLayout from "../../utils/WithBannersLayout/WithBannersLayout";

const CMSPageFromSlug = () => {
  const { CMSPageName } = useParams();
  const CmsPageData = GetSinglePagesDataFromSlug(CMSPageName);
  // console.log("CMSPageData", CmsPageData);
  if (!CmsPageData) {
    return (
      <>
        <section>
          <div className="container my-4">
            <div
              className="skeleton-container"
              style={{ minHeight: "50vh" }}
            ></div>
          </div>
        </section>
      </>
    );
  }
  if (CmsPageData?.success == false) {
    return (
      <>
        <ErrorPage />
      </>
    );
  }
  return (
    <div className="pt-4">
      <WithBannersLayout
        leftBanners={CmsPageData?.banners?.filter(
          (item) => item.banner_position == "3"
        )}
        rightBanners={CmsPageData?.banners?.filter(
          (item) => item.banner_position == "4"
        )}
      >
        <WithMetaByData data={CmsPageData?.page_meta}>
          <section>
            <div className="container">
              <TopBannerSlider
                topBanners={CmsPageData?.banners?.filter(
                  (item) => item.banner_position == "0"
                )}
              />
              {CmsPageData?.cms?.length > 0 && (
                <div className="py-4">
                  <Html html={CmsPageData?.cms[0]?.description} />
                </div>
              )}
              <BottomBannerSlider
                bottomBanner={CmsPageData?.banners?.filter(
                  (item) => item.banner_position == "1"
                )}
              />
              <div className="py-4">
                <Html html={CmsPageData?.footer?.description} />
              </div>
            </div>
          </section>
        </WithMetaByData>
      </WithBannersLayout>
    </div>
  );
};

export default CMSPageFromSlug;
