import React, { useEffect, useState } from "react";
import CalculatorLayoutFromSlug from "./CalculatorLayoutFromSlug";
import Chart from "react-apexcharts";
// import DonutChart from "./DonutChart";

const Intrest = () => {
  const [Amount, setAmount] = useState(50000);
  const [Rate, setRate] = useState(8.5);
  const [Tenure, setTenure] = useState(10);
  const [EMI, setEMI] = useState(0);
  const [TotalAmountToBePaid, setTotalAmountToBePaid] = useState(0);
  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        id: "apexchart-example",
        type: "donut",
      },
      labels: ["Loan Amount", "Interest"],
      colors: ["#2196f3", "#7fc558"],
      legend: {
        show: false,
      },
      plotOptions: {
        pie: {
          donut: {
            expandOnClick: false,
            labels: {
              show: true,

              total: {
                show: true,
                showAlways: true,
                label: "Total amount:",
                fontFamily: "GilroyBold",
                formatter: function (value) {
                  const totalNum = value.globals.seriesTotals
                    .reduce((a, b) => {
                      return a + b;
                    }, 0)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  return totalNum;
                },
              },
            },
          },
        },
      },
    },
  });
  useEffect(() => {
    const InterestRatePerMonth = Math.round((Rate / 12 / 100) * 1e6) / 1e6;
    const LoanTenureInMonths = Tenure * 12;
    const result = Math.round(
      (Amount * InterestRatePerMonth * Math.pow(1 + InterestRatePerMonth, LoanTenureInMonths)) /
        (Math.pow(1 + InterestRatePerMonth, LoanTenureInMonths) - 1)
    );
    setEMI(result);
    setTotalAmountToBePaid(result * 12 * Tenure);

    console.log("chart updated", Amount, result * 12 * Tenure - Amount);
    setChartData({
      series: [Number(Amount), result * 12 * Tenure - Amount],
      options: { ...chartData.options },
    });
  }, [Amount, Rate, Tenure]);

  const formatAmountWithCommas = (amount) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " " + "AED";
  };
  // Dynamic linear gradient style
  const linearGradientStyle1 = {
    background: `linear-gradient(to right, #2196f3 0%, #2196f3 ${((Amount - 50000) / (10000000 - 50000)) * 100}%, #ddd ${
      ((Amount - 50000) / (10000000 - 50000)) * 100
    }%, #ddd 100%)`,
  };
  const linearGradientStyle3 = {
    background: `linear-gradient(to right, #2196f3 0%, #2196f3 ${((Rate - 1) / (30 - 1)) * 100}%, #ddd ${((Rate - 1) / (30 - 1)) * 100}%, #ddd 100%)`,
  };
  const linearGradientStyle4 = {
    background: `linear-gradient(to right, #2196f3 0%, #2196f3 ${((Tenure - 1) / (30 - 1)) * 100}%, #ddd ${
      ((Tenure - 1) / (30 - 1)) * 100
    }%, #ddd 100%)`,
  };

  return (
    <CalculatorLayoutFromSlug slug={"intrest"}>
      <div className="interestEmi slidecontainer calculatorCard">
        <div className="cardContent">
          <div className="cardContentLeft">
            <div className="mb-4">
              <div className="d-flex justify-content-between">
                <div className="calculatorFont">Principal loan amount</div>
                <div className="calculatorFont">{formatAmountWithCommas(Amount)}</div>
              </div>
              <div className="calculatorRange">
                <input
                  type="range"
                  // name={name}
                  min={50000}
                  max={10000000}
                  step={100}
                  value={Amount}
                  // id={id}
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                  // onMouseUp={handleChange}
                  className="range-input"
                  style={linearGradientStyle1}
                  // style={{ height: "7px" }}
                />
              </div>
            </div>
            <div className="mb-4">
              <div className="d-flex justify-content-between">
                <div className="calculatorFont">Yearly Interest rate</div>
                <div className="calculatorFont">{Rate}%</div>
              </div>
              <div className="calculatorRange">
                <input
                  type="range"
                  // name={name}
                  min={1}
                  max={30}
                  step={0.5}
                  value={Rate}
                  // id={id}
                  onChange={(e) => {
                    setRate(e.target.value);
                  }}
                  // onMouseUp={handleChange}
                  className="range-input"
                  style={linearGradientStyle3}
                  // style={{ height: "7px" }}
                />
              </div>
            </div>
            <div className="mb-4">
              <div className="d-flex justify-content-between">
                <div className="calculatorFont">Loan Tenure</div>
                <div className="calculatorFont">{Tenure} years</div>
              </div>
              <div className="calculatorRange">
                <input
                  type="range"
                  // name={name}
                  min={1}
                  max={30}
                  value={Tenure}
                  // id={id}
                  onChange={(e) => {
                    setTenure(e.target.value);
                  }}
                  // onMouseUp={handleChange}
                  className="range-input"
                  style={linearGradientStyle4}
                  // style={{ height: "7px" }}
                />
              </div>
            </div>
            {/* <div className="gFPGhj">
              <div className="esBYzD">Monthly EMI payment:</div>
              <div className="hXMcYL">{formatAmountWithCommas(EMI)}</div>
            </div> */}
          </div>
          <div className="cardContentRight">
            <div className="d-flex justify-content-center">
              <Chart options={chartData.options} series={chartData.series} type="donut" width="350" />
            </div>

            <div className="">
              <div className="hoGkOo">
                <div className="AnswerName calculatorFont">
                  <div color="purple" className="bAEzSQBlue" />
                  Principal amount:
                </div>
                <div className="AnswerAmount calculatorFont">{formatAmountWithCommas(Amount)}</div>
              </div>
              <div className="hoGkOo">
                <div className="AnswerName calculatorFont">
                  <div color="blue" className="bAEzSQGreen" />
                  Total interest:
                </div>
                <div className="AnswerAmount calculatorFont">{formatAmountWithCommas(TotalAmountToBePaid - Amount)}</div>
              </div>
              <div style={{ paddingTop: "10px", borderBottom: "1px solid #0000001b" }}></div>
              <div className="hoGkOo">
                <div className="AnswerName calculatorFont">total amount:</div>
                <div className="AnswerAmount calculatorFont">{formatAmountWithCommas(TotalAmountToBePaid)}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="cardFooter">
          {/* <div className="col1">Calculate Interest</div> */}
          <div className="gFPGhj">
            <div className="esBYzD">Monthly EMI payment:</div>
            <div className="hXMcYL">{formatAmountWithCommas(EMI)}</div>
          </div>
          {/* <a className="action-btn small-btn" style={{ paddingTop: "10px" }}>
            Back
          </a> */}
        </div>
      </div>
    </CalculatorLayoutFromSlug>
  );
};

export default Intrest;
