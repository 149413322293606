import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  messages: null,
  bannerList: null,
  currentNuggetTerm: "",
  currentNuggetId: "",
  mainHeaderCatData: null,
  currentCompanyId: localStorage.getItem("companyId") || "",
  BreadCrumbsArray: "",
  CompareItems: [],
  ApplyFormOn: "false",
  ApplyProductLink: "",
  ReviewingProductLink: "",
};

export const rootSlice = createSlice({
  name: "rootReducer",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
    setBannerList: (state, action) => {
      state.bannerList = action.payload;
    },
    setCurrentNuggetTerm: (state, action) => {
      state.currentNuggetTerm = action.payload;
    },
    setCurrentNuggetId: (state, action) => {
      state.currentNuggetId = action.payload;
    },
    setMainHeaderCatData: (state, action) => {
      state.mainHeaderCatData = action.payload;
    },
    setCurrentCompanyId: (state, action) => {
      state.currentCompanyId = action.payload;
    },
    setBreadCrumbsArray: (state, action) => {
      state.BreadCrumbsArray = action.payload;
    },
    setCompareItems: (state, action) => {
      state.CompareItems = action.payload;
    },
    setApplyFormOn: (state, action) => {
      state.ApplyFormOn = action.payload;
    },
    setApplyProductLink: (state, action) => {
      state.ApplyProductLink = action.payload;
    },
    setReviewingProductLink: (state, action) => {
      state.ReviewingProductLink = action.payload;
    },
  },
});

export const {
  setMessages,
  setLoading,
  setBannerList,
  setCurrentNuggetTerm,
  setCurrentNuggetId,
  setMainHeaderCatData,
  setCurrentCompanyId,
  setBreadCrumbsArray,
  setCompareItems,
  setApplyFormOn,
  setApplyProductLink,
  setReviewingProductLink,
} = rootSlice.actions;

export default rootSlice.reducer;
