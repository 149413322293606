import React, { useState } from "react";
import { Link } from "react-router-dom";
import { GetHeaderData } from "../query/Graphql";
import arrowSubDown from "../assets/images/arrow-sub-down.svg";
import { setBreadCrumbsArray } from "../stores/rootReducer";
import { useDispatch } from "react-redux";

const RenderWithSubItem = ({ data, setIsSubMenuOpen, parentCatName, parentBreadCrumbs, handleMenuClick }) => {
  const dispatch = useDispatch();
  // const subSubCatData = data?.id && GetHeaderData(data?.id);
  const [IsSubCatClickedMobile, setIsSubCatClickedMobile] = useState(false);
  // console.log("subSubCatData", subSubCatData);
  if (!data?.subcategories?.length > 0) {
    return (
      <li>
        <Link
          to={`/productList/${parentCatName}/${data.catname}`}
          onClick={() => {
            handleMenuClick();
            dispatch(setBreadCrumbsArray([...parentBreadCrumbs, { name: data.catname, id: data.id }]));
            sessionStorage.setItem("CurrentSubCategory", JSON.stringify(data));
            setTimeout(() => {
              setIsSubMenuOpen(false);
            }, 200);
          }}
        >
          <img src={data.iconimage_url} alt={data.catname} /> {data.catname}
        </Link>
      </li>
    );
  }
  return (
    <>
      <li className={`subSubCatWrap ${IsSubCatClickedMobile ? "active" : ""}`}>
        <Link
          to={`/productList/${parentCatName}/${data.catname}`}
          onClick={() => {
            handleMenuClick();
            dispatch(setBreadCrumbsArray([...parentBreadCrumbs, { name: data.catname, id: data.id }]));
            sessionStorage.setItem("CurrentSubCategory", JSON.stringify(data));
            setTimeout(() => {
              setIsSubMenuOpen(false);
            }, 200);
          }}
          className="subWrapInner"
        >
          <img src={data.iconimage_url} alt={data.catname} /> {data.catname} <span className="plusOfSub ps-2">+</span>
          <a
            className={`dropOfSub ${IsSubCatClickedMobile ? "active" : ""}`}
            onClick={(e) => {
              e.stopPropagation(); // Prevent event propagation
              e.preventDefault(); // Prevent the default behavior
              setIsSubCatClickedMobile(!IsSubCatClickedMobile);
            }}
          />
        </Link>
        {data?.subcategories?.length > 0 &&
          data?.subcategories?.map((subSubCat, i) => (
            <Link
              className="subSubCat"
              to={`/productList/${parentCatName}/${data.catname}/${subSubCat.catname}`}
              onClick={() => {
                handleMenuClick();
                dispatch(
                  setBreadCrumbsArray([...parentBreadCrumbs, { name: data.catname, id: data.id }, { name: subSubCat.catname, id: subSubCat.id }])
                );
                sessionStorage.setItem("CurrentSubCategory", JSON.stringify(subSubCat));
                setTimeout(() => {
                  setIsSubMenuOpen(false);
                }, 200);
              }}
            >
              {i == 0 && <img className="subCatArrowIcon" src={arrowSubDown} alt="" />}
              <img src={subSubCat.iconimage_url} alt={subSubCat?.catname} /> {subSubCat?.catname}
            </Link>
          ))}
      </li>
    </>
  );
};

export default RenderWithSubItem;
