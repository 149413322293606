import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Link, useLocation } from "react-router-dom";
import { GetCryptoDataFromCode } from "../../query/Graphql";
import RenderTradeBtn from "./RenderTradeBtn";

const RenderTableRow = ({ coin }) => {
  const location = useLocation();
  //   const cryptoData = GetCryptoDataFromCode(coin.symbol);
  //   console.log("cryptoData", cryptoData);
  //   if (!coin) {
  //     return null;
  //   }
  const [ChartOption, setChartOption] = useState({
    series1: [
      {
        data: coin?.sparkline?.filter((item) => item !== null)?.map((value) => (value !== null ? Math.round(parseInt(value)) : 0)),
      },
    ],
    options1: {
      chart: {
        type: "line",
        width: 100,
        height: 35,
        sparkline: {
          enabled: true,
        },
      },
      tooltip: {
        fixed: {
          enabled: false,
        },
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function (seriesName) {
              return "";
            },
          },
        },
        marker: {
          show: false,
        },
      },
      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: undefined,
        width: 2,
        dashArray: 0,
      },
    },
  });

  function formatStringToBillionOrMillion(numberString) {
    if (!numberString || isNaN(numberString)) {
      return "0";
    }

    const number = parseFloat(numberString);

    if (number >= 1000000000) {
      return (number / 1000000000).toFixed(1) + "B";
    } else if (number >= 1000000) {
      return (number / 1000000).toFixed(1) + "M";
    } else {
      return number.toString();
    }
  }
  function checkNegative(value) {
    var numericValue = parseFloat(value);
    if (numericValue < 0) {
      return true;
    } else {
      return false;
    }
  }

  // const ChartOption = {
  //   series1: [
  //     {
  //       data: coin?.sparkline?.map((value) =>
  //         value !== null ? Math.round(parseInt(value)) : 0
  //       ),
  //     },
  //   ],
  //   options1: {
  //     chart: {
  //       type: "line",
  //       width: 100,
  //       height: 35,
  //       sparkline: {
  //         enabled: true,
  //       },
  //     },
  //     tooltip: {
  //       fixed: {
  //         enabled: false,
  //       },
  //       x: {
  //         show: false,
  //       },
  //       y: {
  //         title: {
  //           formatter: function (seriesName) {
  //             return "";
  //           },
  //         },
  //       },
  //       marker: {
  //         show: false,
  //       },
  //     },
  //     stroke: {
  //       show: true,
  //       curve: "smooth",
  //       lineCap: "butt",
  //       colors: undefined,
  //       width: 2,
  //       dashArray: 0,
  //     },
  //   },
  // };

  return (
    <tr className="align-middle my-0" key={coin.id}>
      <td className="text-start">
        <Link to={location.pathname + "/" + coin?.symbol + "/" + coin?.name + "/" + coin?.uuid} className="d-flex align-items-center">
          <div>
            <img
              src={coin.iconUrl}
              alt={coin.name}
              title={coin.name}
              style={{
                height: "30px",
                width: "auto",
                marginRight: "15px",
              }}
            />
          </div>
          <div>
            <div>{coin.name}</div>
            <div>{coin.symbol}</div>
          </div>
        </Link>
      </td>
      <td className="text-end">${parseFloat(coin.price)?.toFixed(2)}</td>
      <td className="text-center miniChart">
        {/* {coin.symbol} */}
        <ReactApexChart options={ChartOption.options1} series={ChartOption.series1} type="line" height={35} width={80} />
      </td>
      <td className={`text-center ${checkNegative(coin.change) && "red"}`}>{coin.change || 0}%</td>
      <td className="text-end">${formatStringToBillionOrMillion(coin.marketCap)}</td>
      <td className="text-end">${formatStringToBillionOrMillion(coin["24hVolume"])}</td>
      <td className="text-center">
        <RenderTradeBtn code={coin.symbol} />
      </td>
    </tr>
  );
};

export default RenderTableRow;
