import axios from "axios";
import React, { useEffect, useState } from "react";
import { CurrencyRateUrl } from "../..";
import CalculatorLayoutFromSlug from "./CalculatorLayoutFromSlug";

const CurrencyConverter = () => {
  const [loading, setLoading] = useState(false);
  const [From, setFrom] = useState("AED");
  const [To, setTo] = useState("INR");
  const [Amount, setAmount] = useState(1);
  const [Answer, setAnswer] = useState();
  // const [SelectedPurity, setSelectedPurity] = useState("24K");
  // const [SelectedCurrency, setSelectedCurrency] = useState("AED");
  // const [FinalValue, setFinalValue] = useState();
  useEffect(() => {
    if (!loading) {
      setLoading(true);
      axios
        .get(CurrencyRateUrl + `&base=${From}&currencies=${To}`)
        .then((response) => {
          console.log("Answer", response?.data);
          const myAnswer = Amount * response?.data?.rates[To];
          setAnswer(myAnswer);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    }
  }, [From, To, Amount]);
  const handleInputChange = (e) => {
    switch (e.target.id) {
      case "From":
        setFrom(e.target.value);
        break;
      case "To":
        setTo(e.target.value);
        break;
      case "Amount":
        if (e.target.value < 1) {
          setAmount(1);
        } else {
          setAmount(e.target.value);
        }
        break;
      default:
        break;
    }
  };
  const formatAmountWithCommas = (amount) => {
    // Convert amount to a string
    const amountString = amount.toString();

    // Split the amount into whole number and fractional parts
    const [wholeNumber, fractionalPart] = amountString.split(".");

    // Format the whole number part with commas
    const formattedWholeNumber = wholeNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // If there is a fractional part, concatenate it back to the formatted whole number
    const formattedAmount = fractionalPart ? `${formattedWholeNumber}.${fractionalPart}` : formattedWholeNumber;

    return formattedAmount;
  };
  const GetNameFromCurrencyCode = (code) => {
    switch (code) {
      case "INR":
        return "Indian Rupee";
        break;
      case "AED":
        return "UAE Dirham";
        break;
      case "USD":
        return "United States Dollar";
        break;
      case "GBP":
        return "Pound Sterling";
        break;
      case "EUR":
        return "European Euro";
        break;
      default:
        break;
    }
  };
  return (
    <CalculatorLayoutFromSlug slug={"currency_converter"}>
      <div className="calculatorCard">
        <div className="cardContent d-flex flex-column p-4 gap-3">
          <div className="row" style={{ maxWidth: "500px", alignItems: "center" }}>
            <label htmlFor="Amount" className="col calculatorFont">
              Amount:
            </label>
            <input
              className="col calculatorFont"
              value={Amount}
              onChange={(e) => handleInputChange(e)}
              placeholder="Amount"
              type="number"
              name="Amount"
              id="Amount"
              autoComplete="off"
              required
              title="Amount"
              style={{ background: "transparent", border: "1px solid", borderRadius: "4px", height: "40px" }}
            />
          </div>
          <div className="row" style={{ maxWidth: "500px", alignItems: "center" }}>
            <label htmlFor="From" className="col calculatorFont">
              From:
            </label>
            <select style={{ height: "40px" }} className="col calculatorFont" value={From} id="From" onChange={(event) => handleInputChange(event)}>
              <option value={"AED"} selected>
                AED - {GetNameFromCurrencyCode("AED")}
              </option>
              <option value={"INR"}>INR - {GetNameFromCurrencyCode("INR")}</option>
              <option value={"USD"}>USD - {GetNameFromCurrencyCode("USD")}</option>
              <option value={"GBP"}>GBP - {GetNameFromCurrencyCode("GBP")}</option>
              <option value={"EUR"}>EUR - {GetNameFromCurrencyCode("EUR")}</option>
            </select>
          </div>
          <div className="row" style={{ maxWidth: "500px", alignItems: "center" }}>
            <label htmlFor="To" className="col calculatorFont">
              To:
            </label>
            <select style={{ height: "40px" }} className="col calculatorFont" value={To} id="To" onChange={(event) => handleInputChange(event)}>
              <option value={"INR"} selected>
                INR - {GetNameFromCurrencyCode("INR")}
              </option>
              <option value={"AED"}>AED - {GetNameFromCurrencyCode("AED")}</option>
              <option value={"USD"}>USD - {GetNameFromCurrencyCode("USD")}</option>
              <option value={"GBP"}>GBP - {GetNameFromCurrencyCode("GBP")}</option>
              <option value={"EUR"}>EUR - {GetNameFromCurrencyCode("EUR")}</option>
            </select>
          </div>
        </div>
        <div className="cardFooter">
          <div className="gFPGhj">
            <div className="esBYzD">
              {formatAmountWithCommas(Amount)} {From} =
            </div>
            <div className="hXMcYL row" style={{ marginLeft: "5px" }}>
              {loading || !Answer ? (
                <div className="skeleton-text" style={{ height: "30px", width: "100px", marginRight: "5px", marginLeft: "5px" }} />
              ) : (
                formatAmountWithCommas(parseFloat(Answer.toFixed(4)))
              )}
            </div>
            <div className="esBYzD ms-3">{GetNameFromCurrencyCode(To)}.</div>
          </div>
          {/* <a className="action-btn small-btn" style={{ paddingTop: "10px" }}>
            Back
          </a> */}
        </div>
      </div>
    </CalculatorLayoutFromSlug>
  );
};

export default CurrencyConverter;
