import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import procard from "../../assets/images/cards.png";
import TopBannerSlider from "../../utils/BannerLayout/TopBannerSlider";
import Html from "../../utils/Html";

const Productbanner = ({ CurrentSubCategory }) => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [location.pathname]);

  // console.log("CurrentSubCategory", loading, CurrentSubCategory);
  // if (!CurrentSubCategory) {
  //   return null;
  // }
  return (
    <section className="product-banner">
      <div className="container text-center">
        <TopBannerSlider
          topBanners={CurrentSubCategory?.catbanner?.filter(
            (item) => item.banner_position == "0"
          )}
        />
      </div>
      {CurrentSubCategory?.description ? (
        <div className="container">
          <div className="py-4">
            {/* following is Description */}
            <Html html={CurrentSubCategory?.description} />
          </div>
        </div>
      ) : !CurrentSubCategory ? (
        <div className="container">
          <div className="skeleton-text" style={{ height: "330px" }} />
        </div>
      ) : null}
    </section>
  );
};

export default Productbanner;
