import React, { useEffect, useState } from "react";
import "./ProductDetailPage.css";
import Cardslocking from "../../components/product/Cardslocking";
import Productdetailbanner from "../../components/productlistpage/Productdetailbanner";
import Productdetailaccodian from "../../components/productlistpage/Productdetailaccodian";
import Productdetailscontent from "../../components/productlistpage/Productdetailcontent";
import Productlistaddslider from "../../components/productlistpage/Productlistaddslider";
import Cardfacility from "../../components/productlistpage/Cardfacility";
import Productsuggetion from "../../components/productlistpage/Productsuggetion";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { useQuery } from "@apollo/client";
import { GET_SINGLE_PRODUCT_DATA } from "../../query/query";
import { useParams } from "react-router-dom";
import WithBannersLayout from "../../utils/WithBannersLayout/WithBannersLayout";
import WithMetaByData from "../../utils/WithMetaByData";
import TopBannerSlider from "../../utils/BannerLayout/TopBannerSlider";
import PopularProductByPIDWidget from "../../components/Widgets/PopularProductByPIDWidget";
import ApplyForm from "../../components/ApplyForm/ApplyForm";
import { useDispatch, useSelector } from "react-redux";
import { setApplyFormOn, setApplyProductLink, setReviewingProductLink } from "../../stores/rootReducer";
import ReviewWithPagination from "../../components/ReviewWithPagination/ReviewWithPagination";

const ProductDetailPage = () => {
  const { productId } = useParams();
  const dispatch = useDispatch();
  const { loading, error, data } = useQuery(GET_SINGLE_PRODUCT_DATA, {
    variables: { id: productId },
  });
  const [IsApplyForm, setIsApplyForm] = useState(false);
  const ApplyFormOn = useSelector((state) => state.rootReducer?.ApplyFormOn);
  useEffect(() => {
    if (ApplyFormOn == true) {
      console.log("ProductDetailPage IsApplyForm ", ApplyFormOn);
      setIsApplyForm(true);
      dispatch(setApplyFormOn(false));
    }
  }, [ApplyFormOn]);
  const ApplyProductLink = useSelector((state) => state.rootReducer?.ApplyProductLink);
  useEffect(() => {
    if (ApplyProductLink && ApplyProductLink != "") {
      console.log("ApplyProductLink ", ApplyProductLink);
      setIsApplyForm(true);
      dispatch(setApplyProductLink(""));
    }
  }, [ApplyProductLink]);
  const ReviewProductLink = useSelector((state) => state.rootReducer?.ReviewingProductLink);
  useEffect(() => {
    if (ReviewProductLink && ReviewProductLink != "") {
      console.log("ReviewProductLink ", ReviewProductLink);
      setIsApplyForm(true);
      dispatch(setReviewingProductLink(""));
    }
  }, [ReviewProductLink]);

  return (
    <div className="productListPage">
      <Breadcrumb />
      <Productsuggetion product={data?.singleproductdata?.singleproductdata} setIsApplyForm={setIsApplyForm} />
      <div>
        <div className="container pt-4">
          <WithBannersLayout
            leftBanners={data?.singleproductdata?.singleproductdata?.banners?.filter((item) => item.banner_position == "3")}
            rightBanners={data?.singleproductdata?.singleproductdata?.banners?.filter((item) => item.banner_position == "4")}
          >
            <WithMetaByData data={data?.singleproductdata?.singleproductdata}>
              <TopBannerSlider topBanners={data?.singleproductdata?.singleproductdata?.banners?.filter((item) => item.banner_position == "0")} />
              {IsApplyForm && <ApplyForm product={data?.singleproductdata?.singleproductdata} loadingProduct={loading} />}
              {/* <Productdetailbanner product={data?.singleproductdata?.singleproductdata} /> */}
              <Cardfacility product={data?.singleproductdata?.singleproductdata} loading={loading} />
              <Productlistaddslider product={data?.singleproductdata?.singleproductdata} loading={loading} />
              <Productdetailscontent product={data?.singleproductdata?.singleproductdata} />
              <ReviewWithPagination product={data?.singleproductdata?.singleproductdata} />
              <Productdetailaccodian product={data?.singleproductdata?.singleproductdata} />
              <PopularProductByPIDWidget productId={data?.singleproductdata?.singleproductdata?.id} />
            </WithMetaByData>
          </WithBannersLayout>
        </div>
      </div>
    </div>
  );
};
export default ProductDetailPage;
