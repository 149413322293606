import React from "react";
import cardm from "../assets/images/pro-card.png";
import cardb from "../assets/images/card-back.jpg";
import { useQuery } from "@apollo/client";
import { COMPARE_WIDGET_QUERY } from "../query/query";
import { Link, useNavigate } from "react-router-dom";
import NoResultFound from "./NoResultFound/NoResultFound";

const RenderInternalTabContent = ({ activeInternalTab, mainTabCatName }) => {
  const navigate = useNavigate();
  const { loading, error, data, refetch } = useQuery(COMPARE_WIDGET_QUERY, {
    variables: { comparewidget: 1, cat_id: activeInternalTab },

    fetchPolicy: "no-cache",
  });
  console.log("RenderInternalTabContent", data?.comparewidgetcat?.comparewidgetPro?.products);
  const products = data?.comparewidgetcat?.comparewidgetPro?.products;
  if (!loading && products?.length == 0) {
    return <NoResultFound title={"No Product found!!"} subTitle={"Please change category."} />;
  }
  if (!loading && !products) {
    return <NoResultFound title={"No Product found!!"} subTitle={"Please change category."} />;
  }
  return (
    <div className={`tab-pane fade ${true ? "show active" : ""}`} id="internalTab1" role="tabpanel">
      <div className="popu-card-scroll scrollbar">
        <ul className="saving-list">
          {loading && (
            <>
              <li>
                <div className="pro-sav-img">
                  <div className="skeleton-text" style={{ height: "100px", margin: "0px 60px" }} />
                </div>
                <div className="pro-sav-cont">
                  <p style={{ maxWidth: "300px" }}>
                    <div className="skeleton-text" style={{ height: "40px", margin: "10px 30px" }} />
                  </p>
                  <div className="skeleton-text" style={{ height: "80px", margin: "10px 30px" }} />
                  <div className="skeleton-text" style={{ height: "30px", margin: "10px 30px" }} />
                </div>
              </li>
              <li>
                <div className="pro-sav-img">
                  <div className="skeleton-text" style={{ height: "100px", margin: "0px 60px" }} />
                </div>
                <div className="pro-sav-cont">
                  <p style={{ maxWidth: "300px" }}>
                    <div className="skeleton-text" style={{ height: "40px", margin: "10px 30px" }} />
                  </p>
                  <div className="skeleton-text" style={{ height: "80px", margin: "10px 30px" }} />
                  <div className="skeleton-text" style={{ height: "30px", margin: "10px 30px" }} />
                </div>
              </li>
              <li>
                <div className="pro-sav-img">
                  <div className="skeleton-text" style={{ height: "100px", margin: "0px 60px" }} />
                </div>
                <div className="pro-sav-cont">
                  <p style={{ maxWidth: "300px" }}>
                    <div className="skeleton-text" style={{ height: "40px", margin: "10px 30px" }} />
                  </p>
                  <div className="skeleton-text" style={{ height: "80px", margin: "10px 30px" }} />
                  <div className="skeleton-text" style={{ height: "30px", margin: "10px 30px" }} />
                </div>
              </li>
            </>
          )}
          {products?.length > 0 &&
            products?.map((pro) => (
              <li onClick={() => navigate(`/Product/${mainTabCatName}/${pro.name}/${pro.id}`)} style={{ cursor: "pointer" }}>
                <div className="pro-sav-img">
                  {/* <img src={cardb} alt="" title="" /> */}
                  <img src={pro.product_image_url} alt={pro?.image_alt_tag} title={pro?.image_alt_tag} className="card-pro" />
                </div>
                <div className="pro-sav-cont">
                  <p style={{ maxWidth: "300px" }}>{pro.name}</p>
                  <ul className="square-list" style={{ padding: "0", display: "flex", justifyContent: "center", minHeight: "96px" }}>
                    <li style={{ maxWidth: "200px" }}>
                      {pro?.short_desc.slice(0, 80)}
                      {pro?.short_desc.length > 80 && "..."}
                    </li>
                  </ul>
                  <Link to={`/Product/${mainTabCatName}/${pro.name}/${pro.id}`} className="action-btn small-btn">
                    View Details
                  </Link>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default RenderInternalTabContent;
