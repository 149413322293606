import React from "react";
import { useSelector } from "react-redux";
import Accordion from "../Accordion/Accordion";
import AccordionItem from "../Accordion/AccordionItem";

const ProductCompareTable = ({ product1, product2, product3, loading }) => {
  const compareItems = useSelector((state) => state.rootReducer?.CompareItems);
  const renderPlaceHolder = () => {
    return (
      <>
        <table className="table table-striped mb-4">
          {/* <thead>
            <tr>
              <th scope="col">
                <div className="skeleton-text" style={{ height: "25px", margin: "15px" }} />
              </th>
              <th scope="col"></th>
              <th scope="col"></th>
              {compareItems?.length > 2 && <th scope="col"></th>}
            </tr>
          </thead> */}
          <tbody>
            <tr>
              <th scope="row">
                <div className="skeleton-text" style={{ height: "25px", margin: "15px" }} />
              </th>
              <td>
                <div className="skeleton-text" style={{ height: "25px", margin: "15px" }} />
              </td>
              <td>
                <div className="skeleton-text" style={{ height: "25px", margin: "15px" }} />
              </td>
              {compareItems?.length > 2 && (
                <td>
                  <div className="skeleton-text" style={{ height: "25px", margin: "15px" }} />
                </td>
              )}
            </tr>
            <tr>
              <th scope="row">
                <div className="skeleton-text" style={{ height: "25px", margin: "15px" }} />
              </th>
              <td>
                <div className="skeleton-text" style={{ height: "25px", margin: "15px" }} />
              </td>
              <td>
                <div className="skeleton-text" style={{ height: "25px", margin: "15px" }} />
              </td>
              {compareItems?.length > 2 && (
                <td>
                  <div className="skeleton-text" style={{ height: "25px", margin: "15px" }} />
                </td>
              )}
            </tr>
            <tr>
              <th scope="row">
                <div className="skeleton-text" style={{ height: "25px", margin: "15px" }} />
              </th>
              <td>
                <div className="skeleton-text" style={{ height: "25px", margin: "15px" }} />
              </td>
              <td>
                <div className="skeleton-text" style={{ height: "25px", margin: "15px" }} />
              </td>
              {compareItems?.length > 2 && (
                <td>
                  <div className="skeleton-text" style={{ height: "25px", margin: "15px" }} />
                </td>
              )}
            </tr>
            <tr>
              <th scope="row">
                <div className="skeleton-text" style={{ height: "25px", margin: "15px" }} />
              </th>
              <td>
                <div className="skeleton-text" style={{ height: "25px", margin: "15px" }} />
              </td>
              <td>
                <div className="skeleton-text" style={{ height: "25px", margin: "15px" }} />
              </td>
              {compareItems?.length > 2 && (
                <td>
                  <div className="skeleton-text" style={{ height: "25px", margin: "15px" }} />
                </td>
              )}
            </tr>
            <tr>
              <th scope="row">
                <div className="skeleton-text" style={{ height: "25px", margin: "15px" }} />
              </th>
              <td>
                <div className="skeleton-text" style={{ height: "25px", margin: "15px" }} />
              </td>
              <td>
                <div className="skeleton-text" style={{ height: "25px", margin: "15px" }} />
              </td>
              {compareItems?.length > 2 && (
                <td>
                  <div className="skeleton-text" style={{ height: "25px", margin: "15px" }} />
                </td>
              )}
            </tr>
          </tbody>
        </table>
      </>
    );
  };
  if (loading) {
    return renderPlaceHolder();
  }
  if (!product1 || !product2 || loading) {
    return null;
  }
  const renderTable = () => {
    const combinedData = product3 ? [...product1, ...product2, ...product3] : [...product1, ...product2];
    const uniqueGroups = new Map();
    combinedData.forEach((item) => {
      const groupName = item.group_name;
      if (!uniqueGroups.has(groupName)) {
        uniqueGroups.set(groupName, [item]);
      } else {
        uniqueGroups.get(groupName).push(item);
      }
    });
    const uniqueGroupsArray = Array.from(uniqueGroups.values());

    return (
      <>
        {uniqueGroupsArray.map((group) => {
          return (
            <div className="b-border">
              <div>{renderSingleGroupTable(group)}</div>
            </div>
          );
        })}
      </>
    );
  };

  function renderSingleGroupTable(group) {
    // console.log(`Table for Group: ${group[0].group_name}`);
    // console.log("| Attribute Name | Value | Value |");
    // console.log("|-----------------|-------|-------|");

    // group[0].compare_group_attributes.forEach((attribute, index) => {
    //   const attrName = attribute.product_attribute.attribute_library.attrName;
    //   const value =
    //     attribute.product_attribute.pro_attr_string[0]?.value ||
    //     attribute.product_attribute.pro_attr_decimal[0]?.value ||
    //     attribute.product_attribute.pro_attr_integer_with_cond[0]?.value ||
    //     "";
    //   const value2 =
    //     group[1].compare_group_attributes[index].product_attribute.pro_attr_string[0]?.value ||
    //     group[1].compare_group_attributes[index].product_attribute.pro_attr_decimal[0]?.value ||
    //     group[1].compare_group_attributes[index].product_attribute.pro_attr_integer_with_cond[0]?.value ||
    //     "";

    //   console.log(`| ${attrName} | ${value} | ${value2}`);
    // });
    // console.log("\n");

    return (
      <div className="renderSingleGroupTable">
        {/* <div>Table for Group: {group[0].group_name}</div> */}
        <Accordion>
          <AccordionItem title={group[0].group_name} isHideCloseBtn={true}>
            {/* <h5>{group[0].group_name}</h5> */}
            <table className="table table-striped mb-4">
              {/* <thead>
            <tr>
              <th scope="col">{group[0].group_name}</th>
              <th scope="col"></th>
              <th scope="col"></th>
              {compareItems?.length > 2 && <th scope="col"></th>}
            </tr>
          </thead> */}

              <tbody>
                {group[0].compare_group_attributes.map((attribute, index) => (
                  <tr>
                    <th scope="row">{attribute?.product_attribute?.attribute_library.attrName}</th>
                    <td>
                      {attribute?.product_attribute?.pro_attr_string[0]?.value ||
                        attribute?.product_attribute?.pro_attr_decimal[0]?.value ||
                        attribute?.product_attribute?.pro_attr_integer_with_cond[0]?.value ||
                        "-"}
                    </td>
                    <td>
                      {group[1].compare_group_attributes[index]?.product_attribute?.pro_attr_string[0]?.value ||
                        group[1].compare_group_attributes[index]?.product_attribute?.pro_attr_decimal[0]?.value ||
                        group[1].compare_group_attributes[index]?.product_attribute?.pro_attr_integer_with_cond[0]?.value ||
                        "-"}
                    </td>
                    {product3 && (
                      <td>
                        {group[2].compare_group_attributes[index]?.product_attribute?.pro_attr_string[0]?.value ||
                          group[2].compare_group_attributes[index]?.product_attribute?.pro_attr_decimal[0]?.value ||
                          group[2].compare_group_attributes[index]?.product_attribute?.pro_attr_integer_with_cond[0]?.value ||
                          "-"}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </AccordionItem>
        </Accordion>
      </div>
    );
  }

  return <>{renderTable()}</>;
};

export default ProductCompareTable;
