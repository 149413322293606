import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { ApolloClient, InMemoryCache, ApolloProvider, gql } from "@apollo/client";
import { Provider } from "react-redux";
import stores from "./stores";
import { GoogleOAuthProvider } from "@react-oauth/google";

export const FacebookAppId = "792517981848575";
export const GoogleClientId = "534266539768-7l8mhcjdhs6m3tkvnftaqgek3f9rckom.apps.googleusercontent.com";
export const GoogleClientSecrete = "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";
export const BlogBaseUrl = "https://finekaizenblog.mobilegiz.com/";
// export const CurrencyRateUrl = "https://api.metalpriceapi.com/v1/latest?api_key=11a6414559a78f51cc5b694ed368414d";
export const CurrencyRateUrl = "https://api.metalpriceapi.com/v1/latest?api_key=a77cfc1e9cc7bf5a3670fbaa7691a79b";
// live
// export const BaseUrl = "https://findkaizen.mobilegiz.com/";
//staging
export const BaseUrl = "https://findkaizenstaging.mobilegiz.com/";

const client = new ApolloClient({
  uri: BaseUrl + "control/graphqli",
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ApolloProvider client={client}>
    <GoogleOAuthProvider clientId={GoogleClientId}>
      <Provider store={stores}>
        <App />
      </Provider>
    </GoogleOAuthProvider>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
