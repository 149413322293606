import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import NoResultFound from "../NoResultFound/NoResultFound";
import RenderTableRow from "./RenderTableRow";
import RenderTradeBtn from "./RenderTradeBtn";

const TableWithPagination = ({ SearchInput }) => {
  const location = useLocation();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const visiblePages = 5;

  useEffect(() => {
    if (SearchInput?.length > 2) {
      const fetchData = async () => {
        try {
          const response = await axios.get(`https://api.coinranking.com/v2/coins`, {
            params: {
              offset: (currentPage - 1) * itemsPerPage,
              limit: itemsPerPage,
              orderBy: "marketCap",
              search: SearchInput,
            },
            headers: {
              "x-access-token": "coinrankingbbe0f960355261766eba2b4af92d608ee14c35d721afdee7",
            },
          });
          setData(response.data.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    } else {
      const fetchData = async () => {
        try {
          const response = await axios.get(`https://api.coinranking.com/v2/coins`, {
            params: {
              offset: (currentPage - 1) * itemsPerPage,
              limit: itemsPerPage,
              orderBy: "marketCap",
            },
            headers: {
              "x-access-token": "coinrankingbbe0f960355261766eba2b4af92d608ee14c35d721afdee7",
            },
          });
          setData(response.data.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
  }, [currentPage, SearchInput]);

  const totalPages = Math.ceil(data?.stats?.total / itemsPerPage);

  let pageNumbers = [];
  if (totalPages) {
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  }

  const renderPageNumbers = pageNumbers.map((number) => {
    if (totalPages <= visiblePages) {
      return (
        <li key={number} className={`page-item ${currentPage === number ? "active" : ""}`}>
          <button className="page-link" onClick={() => setCurrentPage(number)}>
            {number}
          </button>
        </li>
      );
    } else if (number === 1 || number === totalPages || (number >= currentPage - 2 && number <= currentPage + 2)) {
      return (
        <li key={number} className={`page-item ${currentPage === number ? "active" : ""}`}>
          <button className="page-link" onClick={() => setCurrentPage(number)}>
            {number}
          </button>
        </li>
      );
    } else if (number === currentPage - 3 || number === currentPage + 3) {
      return (
        <li key={number} className="page-item disabled">
          <span className="page-link">...</span>
        </li>
      );
    }
    return null;
  });

  const renderTablePLaceholder = () => {
    return Array.from({ length: 10 }).map((_, index) => (
      <tr className="align-middle">
        <td className="text-start">
          <div className="skeleton-text" style={{ height: "32px" }} />
        </td>
        <td className="text-end">
          <div className="skeleton-text" style={{ height: "32px" }} />
        </td>
        <td className="text-center">
          <div className="skeleton-text" style={{ height: "32px" }} />
        </td>
        <td className="text-center">
          <div className="skeleton-text" style={{ height: "32px" }} />
        </td>
        <td className="text-end">
          <div className="skeleton-text" style={{ height: "32px" }} />
        </td>
        <td className="text-end">
          <div className="skeleton-text" style={{ height: "32px" }} />
        </td>
        <td className="text-center">
          <div className="skeleton-text" style={{ height: "32px" }} />
        </td>
      </tr>
    ));
  };

  if (data && data?.coins?.length == 0) {
    return <NoResultFound title={"No Coins Found!!"} />;
  }

  return (
    <div>
      <div className="scrol-table">
        <table className="table table-bordered">
          <thead className="rounded-top">
            <tr className="align-middle">
              <th className="text-start">Name</th>
              <th className="text-end">Price</th>
              <th className="text-center">Chart</th>
              <th className="text-center">Change</th>
              <th className="text-end">Market Cap</th>
              <th className="text-end">Volume (24h)</th>
              <th className="text-center"></th>
            </tr>
          </thead>
          <tbody>{data ? data?.coins?.map((coin) => <RenderTableRow coin={coin} />) : renderTablePLaceholder()}</tbody>
        </table>
      </div>
      <nav className="botom-page">
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <button className="page-link" onClick={() => setCurrentPage(currentPage - 1)}>
              Previous
            </button>
          </li>
          {renderPageNumbers}
          <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
            <button className="page-link" onClick={() => setCurrentPage(currentPage + 1)}>
              Next
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default TableWithPagination;
