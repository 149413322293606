import React, { useState } from "react";

import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import redc from "../../assets/images/redc.png";
import discounticn from "../../assets/images/discount.svg";
import star from "../../assets/images/star.svg";
import starEmpty from "../../assets/images/starEmpty.svg";
import viewcard from "../../assets/images/view-more-rgt.svg";
import OwlCarousel from "react-owl-carousel";
import { useDispatch, useSelector } from "react-redux";
import { setApplyFormOn, setApplyProductLink, setCompareItems } from "../../stores/rootReducer";
import sendNotification from "../../utils/SendNotification";
import GetMessage from "../../utils/GetMessage";
import RatingStars from "../RatingStars/RatingStars";

const Product = ({ product, CurrentCategoryPath, renderIsListingArray, topCatId }) => {
  const navigate = useNavigate();
  const { parentCatName, categoryName, subcategoryName } = useParams();
  const [isReadMore, setIsReadMore] = useState(false);
  const dispatch = useDispatch();
  const compareItems = useSelector((state) => state.rootReducer?.CompareItems);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const renderTags = (tagsString) => {
    const tagArray = tagsString?.split(",");
    return tagArray?.map((tag) => <span className="tages">{tag}</span>);
  };
  const renderIsListingArrayForProduct = () => {
    var tagArray = [];
    product?.stringAttributes?.map(
      (item) =>
        item?.pro_attr_string?.map((item) => item?.value).join(",") &&
        tagArray.push({
          name: item.attrName,
          value: item?.pro_attr_string?.map((item) => item?.value).join(","),
        })
    );
    product?.decimalAttributes?.map(
      (item) =>
        item?.pro_attr_decimal?.map((item) => item?.value && item?.value).join(",") &&
        tagArray.push({
          name: item.attrName,
          value: item?.pro_attr_decimal?.map((DesItem) => DesItem?.value && DesItem?.value).join(",") + (item?.attr_type == 2 ? "%" : ""),
        })
    );
    product?.masterAttributes?.map(
      (item) =>
        item?.pro_attr_integer_with_cond?.map((item) => item?.label).join(",") &&
        tagArray.push({
          name: item.attrName,
          value: item?.pro_attr_integer_with_cond?.map((item) => item?.label).join(","),
        })
    );
    const owlCarouselOptions = {
      loop: true,
      dots: false,
      items: 7,
      autoplay: false,
      nav: true,
      margin: 20,
      responsive: {
        0: {
          items: 2,
        },
        480: {
          items: 2,
        },
        768: {
          items: 4,
        },
        1024: {
          items: 4,
        },
        1440: {
          items: 4,
        },
      },
      responsiveBaseElement: ".pro-dtl-bonus",
    };

    return (
      <>
        {/* {data?.productlistingdata?.data?.attributes
          ?.filter((item) => item.is_listing == 1)
          .map((item) => (
            <div className="bonus-s">
              <p>{item.attrName}</p>
              <h6>{getAttributeValueAsPerType(item)}</h6>
            </div>
          ))} */}
        {/* {tagArray.length > 4 ? (
          <OwlCarousel
            className="rate-slider owl-carousel"
            {...owlCarouselOptions}
          >
            {tagArray?.map((item) => {
              return (
                <div className="bonus-s moreThenFourProductFeature">
                  <p>{item?.name}</p>
                  <h6>{item?.value}</h6>
                </div>
              );
            })}
          </OwlCarousel>
        ) : ( */}
        {tagArray?.map((item) => (
          <div className="bonus-s">
            <p>{item?.name}</p>
            <h6>{item?.value}</h6>
          </div>
        ))}
        {/* )} */}
      </>
    );
  };
  console.log("handleAddToCompareClick", compareItems);
  const error_compare_no_more_itmes = GetMessage("error_compare_no_more_itmes");
  const error_compare_diffrent_category = GetMessage("error_compare_diffrent_category");
  const handleAddToCompareClick = (product) => {
    if (compareItems.some((item) => item.id == product.id)) {
      // product already in compare list (remove it.)
      dispatch(setCompareItems(compareItems.filter((item) => item.id != product.id)));
    } else if (compareItems?.length > 0 && !compareItems.some((item) => item.topCatName == parentCatName)) {
      // sendNotification("error", "product category does not match with compare items.");
      sendNotification("error", error_compare_diffrent_category);
      // alert("product category does not match with compare items.");
    } else if (compareItems.length == 3) {
      // sendNotification("error", "max 3 items can be added to compare");
      sendNotification("error", error_compare_no_more_itmes);
      // alert("max 3 items can be added to compare");
    } else {
      // product is new (append it)
      // console.log("handleAddToCompareClick", compareItems);
      const applyBtn = () =>
        product?.applynow_button_url ? (
          <a
            onClick={() => {
              var newWindow = window.open("/Redirect");
              newWindow.applynow_button_url = product?.applynow_button_url;
            }}
            className="action-btn small-btn"
            style={{ cursor: "pointer" }}
          >
            {/* <a href={product?.applynow_button_url} target="_blank" className="action-btn small-btn"> */}
            {product?.applynow_button}
          </a>
        ) : (
          <a onClick={(e) => handleApplyClick(e)} className="action-btn small-btn" style={{ cursor: "pointer" }}>
            {product?.applynow_button}
          </a>
        );

      const comparePayload = {
        id: product.id,
        name: product.name,
        product_image_url: product.product_image_url,
        // topCatId: topCatId,
        topCatName: parentCatName,
        applyBtn: applyBtn,
        productLink: `/Product/${CurrentCategoryPath}/${product?.name}/${product?.id}`,
      };
      dispatch(setCompareItems([...compareItems, comparePayload]));
    }
  };
  const handleApplyClick = (e) => {
    e.preventDefault();
    // const UserId = sessionStorage.getItem("User") && JSON.parse(sessionStorage.getItem("User"))?.id;
    // if (UserId) {
    dispatch(setApplyFormOn(true));
    navigate(`/Product/${CurrentCategoryPath}/${product?.name}/${product?.id}`);
    // } else {
    //   navigate("/SignIn");
    //   dispatch(setApplyProductLink(`/Product/${CurrentCategoryPath}/${product?.name}/${product?.id}`));
    // }
  };
  return (
    <div className="single-product" key={product?.id}>
      <div className="pro-name-bth">
        <div className="left">
          {product?.is_popular == 1 && <span className="tages popularRedTag">{GetMessage("popular_tag_listing")}</span>}
          {renderTags(product?.tag)}
          <Link to={`/Product/${CurrentCategoryPath}/${product?.name}/${product?.id}`}>
            <h4>{product?.name}</h4>
          </Link>
          {product?.is_review && (
            <div className="starDivOnlyMobile star-div">
              <RatingStars averageRating={product?.average_rating || 0} />
              {product?.average_rating && <div>({product?.average_rating || 0})</div>}
            </div>
          )}
        </div>
        <div className="right">
          {product?.is_compare && (
            <div className="compare-btn" style={{ marginBottom: "8px" }}>
              <label>
                <input
                  type="checkbox"
                  onChange={() => handleAddToCompareClick(product)}
                  checked={compareItems?.some((item) => item.id == product?.id)}
                />
                <span className="checkmark"></span>Add to Compare
              </label>
            </div>
          )}
          {product?.applynow_button_url ? (
            <a
              onClick={() => {
                var newWindow = window.open("/Redirect");
                newWindow.applynow_button_url = product?.applynow_button_url;
              }}
              className="action-btn small-btn"
              style={{ marginBottom: "4px", cursor: "pointer" }}
            >
              {/* <a href={product?.applynow_button_url} target="_blank" className="action-btn small-btn" style={{ marginBottom: "4px" }}> */}
              {product?.applynow_button}
            </a>
          ) : (
            <a onClick={(e) => handleApplyClick(e)} className="action-btn small-btn" style={{ marginBottom: "4px", cursor: "pointer" }}>
              {product?.applynow_button}
            </a>
            //    <Link
            //    to={{
            //      pathname: UserId ? `/Product/${CurrentCategoryPath}/${product?.name}/${product?.id}` : "/SignIn",
            //      state: { turnOnApplyFormFromProduct: true },
            //    }}
            //    className="action-btn small-btn"
            //    style={{ marginBottom: "4px", cursor: "pointer" }}
            //  >
            //    {product?.applynow_button}
            //  </Link>
          )}
          {product?.applynow_button_url && (
            <p
              style={{
                textAlign: "center",
                marginBottom: "0px",
                fontSize: "12px",
                letterSpacing: "0.26px",
                fontFamily: "GilroySemiBold",
                color: "#969696",
              }}
            >
              On {product?.company_name}'s secure site
            </p>
          )}
          {/* <br /> */}
        </div>
      </div>
      <div className="card-cat-div">
        <div className="left">
          {product?.product_image_url && (
            <div className="card-div">
              <img src={product?.product_image_url} alt="" title="" />
            </div>
          )}
          {product?.is_review && (
            <div className="starDivOnlyDesktop star-div">
              {/* <img src={starEmpty} alt="" title="" />
            <img src={starEmpty} alt="" title="" />
            <img src={starEmpty} alt="" title="" />
            <img src={starEmpty} alt="" title="" />
            <img src={starEmpty} alt="" title="" /> */}
              <RatingStars averageRating={product?.average_rating || 0} />
              {product?.average_rating && <div>({parseFloat(Number(product?.average_rating).toFixed(1)) || 0})</div>}
            </div>
          )}
        </div>
        <div className="right">
          {product?.header && (
            <div className="discount-div">
              <div className="discount-icn">
                <img src={discounticn} alt="" title="" />
              </div>
              <div className="discount-content">
                <h5>{product?.header}</h5>
                {isReadMore ? (
                  <div>
                    <p>
                      {product?.short_desc}{" "}
                      <a className="read-more" style={{ cursor: "pointer" }} onClick={toggleReadMore}>
                        Read less
                      </a>
                    </p>
                  </div>
                ) : (
                  <div>
                    <p>
                      {product?.short_desc?.slice(0, 100)}
                      {product?.short_desc?.length > 100 && (
                        <>
                          ...
                          <a className="read-more" style={{ cursor: "pointer" }} onClick={toggleReadMore}>
                            Read more
                          </a>
                        </>
                      )}
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="pro-dtl-bonus">
            {renderIsListingArrayForProduct()}
            {/* <div className="bonus-s">
              <p>Minimum Salary</p>
              <h6>AED 00K-00K</h6>
            </div>
            <div className="bonus-s">
              <p>Interest Rate</p>
              <h6>AED 000</h6>
            </div>
            <div className="bonus-s">
              <p>Annual Fee</p>
              <h6>000</h6>
            </div>
            <div className="bonus-s">
              <p>Welcome Bonus</p>
              <h6>000</h6>
            </div> */}
          </div>
        </div>
      </div>
      <div className="viewmore-div">
        <Link to={`/Product/${CurrentCategoryPath}/${product?.name}/${product.id}`} className="view-more">
          View Card Details <img src={viewcard} alt="" title="" />
        </Link>
      </div>
    </div>
  );
};

export default Product;
