import React, { useEffect, useState } from "react";
import Blog1 from "../../../src/assets/images/blog1.jpg";
import Blog2 from "../../../src/assets/images/blog-m.jpg";
import Html from "../../utils/Html";
import PlaceHolder from "../../assets/images/PlaceHolder.png";
import FinancialDetailTabs from "./FinancialDetailTabs";
import RightBannerItem from "../../utils/WithBannersLayout/RightBannerItem";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestIcon,
  PinterestShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";
import { useParams } from "react-router-dom";
import axios from "axios";
import { BlogBaseUrl } from "../..";

const renderTag = (tag) => {
  console.log("tag", tag);
  if (tag != "Home Center" && tag != "Home Listing" && tag != " Home Center" && tag != " Home Listing") {
    return <span className="tagsSpan">{tag}</span>;
  }
};

const Financedetail = ({ Blog, loading, bannerAndMeta }) => {
  const { BlogSlug } = useParams();
  const [loadingMain, setLoadingMain] = useState(false);
  const [PopularPosts, setPopularPosts] = useState([]);
  const [LatestPosts, setLatestPosts] = useState([]);
  useEffect(() => {
    setLatestPosts();
    setPopularPosts();
    setLoadingMain(true);
    axios
      .get(BlogBaseUrl + `${"wp-json/custom/v1/posts-by-blog-slug-tag/" + BlogSlug + "/latest"}`)
      // .get(BlogBaseUrl + `${"wp-json/custom/v1/posts-by-tag?tag=latest"}`)
      .then((response) => {
        setLatestPosts(response.data.data?.filter((post) => post.slug != BlogSlug));
        setLoadingMain(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoadingMain(false);
      });
    setLoadingMain(true);
    axios
      .get(BlogBaseUrl + `${"wp-json/custom/v1/posts-by-blog-slug-tag/" + BlogSlug + "/popular"}`)
      // .get(BlogBaseUrl + `${"wp-json/custom/v1/posts-by-tag?tag=popular"}`)
      .then((response) => {
        setPopularPosts(response.data.data?.filter((post) => post.slug != BlogSlug));
        setLoadingMain(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoadingMain(false);
      });
  }, [BlogSlug]);
  return (
    <div className="articles-wrp finance-detail">
      <div className="">
        <div className="article-s">
          <div className="article-main">
            <div className="row">
              <div
                className={`${
                  PopularPosts?.length > 0 ||
                  LatestPosts?.length > 0 ||
                  bannerAndMeta?.financialbanner?.filter((item) => item.banner_position == "4")?.length > 0
                    ? "col-md-7"
                    : ""
                }`}
              >
                <div className="">
                  <div className="cat" style={{ justifyContent: "space-between" }}>
                    <div>{Blog?.tags && Blog?.tags?.split(",").map((tag) => renderTag(tag))}</div>
                    <div className="">
                      <FacebookShareButton url={window.location} className="Demo__some-network__share-button">
                        <FacebookIcon size={32} round />
                      </FacebookShareButton>
                      <a className="me-2" />
                      <TwitterShareButton url={window.location} title={Blog?.title} className="Demo__some-network__share-button">
                        <XIcon size={32} round />
                      </TwitterShareButton>
                      <a className="me-2" />
                      <WhatsappShareButton url={window.location} title={Blog?.title} separator=":: " className="Demo__some-network__share-button">
                        <WhatsappIcon size={32} round />
                      </WhatsappShareButton>
                      <a className="me-2" />
                      <LinkedinShareButton url={window.location} className="Demo__some-network__share-button">
                        <LinkedinIcon size={32} round />
                      </LinkedinShareButton>
                      <a className="me-2" />
                      <RedditShareButton
                        url={window.location}
                        title={Blog?.title}
                        windowWidth={660}
                        windowHeight={460}
                        className="Demo__some-network__share-button"
                      >
                        <RedditIcon size={32} round />
                      </RedditShareButton>
                      <a className="me-2" />
                      <EmailShareButton url={window.location} subject={Blog?.title} body="Blog:" className="Demo__some-network__share-button">
                        <EmailIcon size={32} round />
                      </EmailShareButton>
                      {/* <a className="me-2" />
                        <PinterestShareButton url={window.location} media={`${window.location}`} className="Demo__some-network__share-button">
                          <PinterestIcon size={32} round />
                        </PinterestShareButton> */}
                    </div>
                  </div>

                  {loading ? (
                    <h4>
                      <div className="skeleton-text" style={{ width: "200px", height: "32px" }}></div>
                    </h4>
                  ) : (
                    <h4>{Blog?.title}</h4>
                  )}

                  {/* <p className="small-p">
                    <a href={Blog?.blog_url} target="_blank" style={{ display: "flex", color: "inherit" }}>
                      {loading ? <div className="skeleton-text" style={{ width: "100%", height: "32px" }}></div> : Blog?.blog_url}
                    </a>
                  </p> */}

                  <div className="d-flex date-all" style={{ justifyContent: "space-between" }}>
                    {loading ? (
                      <div className="skeleton-text" style={{ width: "100%", height: "20px" }}></div>
                    ) : (
                      <>
                        <div className="date">{Blog?.publish_time}</div>
                        {/* <div className="posted-by">Posted By : {Blog?.author}</div> */}
                      </>
                    )}
                  </div>

                  {Blog?.image && (
                    <div className="fin-img">
                      {loading ? (
                        <div className="skeleton-text" style={{ width: "100%", height: "350px" }}></div>
                      ) : (
                        <img src={Blog?.image || PlaceHolder} alt="" />
                      )}
                    </div>
                  )}
                  {loading ? (
                    <div className="skeleton-text" style={{ width: "100%", height: "350px" }}></div>
                  ) : (
                    <div className="FLContent">
                      <Html html={Blog?.content} />
                    </div>
                  )}
                </div>
              </div>
              {(PopularPosts?.length > 0 ||
                LatestPosts?.length > 0 ||
                bannerAndMeta?.financialbanner?.filter((item) => item.banner_position == "4")?.length > 0) && (
                <div className="col-md-5">
                  <FinancialDetailTabs
                    currentBlogId={Blog?.Id?.Id}
                    categories={Blog?.categories}
                    loadingMain={loadingMain}
                    PopularPosts={PopularPosts}
                    LatestPosts={LatestPosts}
                  />
                  <div className="FLFullWidthBanner">
                    {bannerAndMeta?.financialbanner
                      ?.filter((item) => item.banner_position == "4")
                      ?.map((item) => (
                        <RightBannerItem rightBanner={item} />
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Financedetail;
