import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import rate from "../../src/assets/images/r1.png";
import { Dropdown } from "bootstrap";
import OwlCarousel from "react-owl-carousel";
import GetMessage from "../utils/GetMessage";
import { useLazyQuery } from "@apollo/client";
import { GET_COMPANY_BY_CAT } from "../query/query";

const CompanyProviderForCategory = ({ companyList }) => {
  //   const [fetchCompanyList, { loading, error, data }] = useLazyQuery(GET_COMPANY_BY_CAT, {
  //     fetchPolicy: "no-cache",
  //   });
  //   useEffect(() => {
  //     if (CurrentId && !loading) {
  //       fetchCompanyList({
  //         variables: { id: CurrentId },
  //       });
  //     }
  //   }, [CurrentId]);

  const owlCarouselOptions = {
    loop: false,
    dots: false,
    items: 7,
    autoplay: false,
    nav: true,
    margin: 20,
    responsive: {
      0: {
        items: 2,
        margin: 10,
      },
      480: {
        items: 4,
      },
      768: {
        items: 5,
      },
      1024: {
        items: 6,
      },
      1440: {
        items: 7,
      },
    },
    responsiveBaseElement: ".Rateprovider-wrp",
  };
  //   if (loading) {
  //     return <></>;
  //   }
  //   if (!data) {
  //     return <></>;
  //   }
  console.log("companyList", companyList);
  const handleCompanyFilterClick = (id) => {};
  return (
    <section className="Rateprovider-wrp">
      <div className="container">
        <h3>{GetMessage("company_providers_title")}</h3>
        <p className="big-p">{GetMessage("company_providers_subtitle")}</p>
        {companyList && (
          <OwlCarousel className="rate-slider owl-carousel" {...owlCarouselOptions}>
            {/* Add your slide components here */}

            {/* <ul className="rate-slider owl-carousel"> */}
            {companyList?.map((company) => (
              <div className="ul" onClick={() => handleCompanyFilterClick(company?.id)}>
                <div className="rate-in">
                  <div className="inner-sv">
                    <img src={company?.logo_url} alt={company?.logo_alt_tag} title={company?.logo_alt_tag} />
                  </div>
                </div>
                <p>{company?.name}</p>
              </div>
            ))}
            {/* Add more slides as needed */}
          </OwlCarousel>
        )}
      </div>
    </section>
  );
};

export default CompanyProviderForCategory;
