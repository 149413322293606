import React from "react";
import Blog1 from "../../assets/images/blog1.jpg";
import Blog2 from "../../assets/images/blog-m.jpg";
import { Link } from "react-router-dom";

const FinanceMultiple = ({ blogs }) => {
  const firstBlog = blogs?.length > 0 && blogs[0];
  const BlogsWithoutFirst = blogs?.length > 1 && blogs?.slice(1);
  const getTextFromHtml = (htmlContent) => {
    // Create a temporary element
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlContent;

    // Get text content
    const textFromHTML = tempElement.textContent || tempElement.innerText;
    return textFromHTML.slice(0, 100) + "...";
  };
  return (
    <>
      <div className="col-md-7">
        <div className="l-blog">
          {firstBlog?.image ? (
            <a>
              <div className="b-img">
                <img src={firstBlog?.image} alt={firstBlog?.ID} title={firstBlog?.title} />
              </div>
              <div className="rgt">
                <div className="date">{firstBlog?.publish_time}</div>
                {/* <div className="posted-by">Posted By : {firstBlog?.author}</div> */}
                <h4>
                  <Link to={`/Financial Literacy/${firstBlog?.slug}`} style={{ display: "flex", color: "inherit" }}>
                    {firstBlog?.title}{" "}
                  </Link>
                </h4>
                <p className="mb-0">{getTextFromHtml(firstBlog?.content)}</p>
                <Link className="read-more" to={`/Financial Literacy/${firstBlog?.slug}`}>
                  Read more
                </Link>
              </div>
            </a>
          ) : (
            <div className="rgt">
              <div className="date">{firstBlog?.publish_time}</div>
              {/* <div className="posted-by">Posted By : {firstBlog?.author}</div> */}
              <h4>
                <Link to={`/Financial Literacy/${firstBlog?.slug}`} style={{ display: "flex", color: "inherit" }}>
                  {firstBlog?.title}{" "}
                </Link>
              </h4>
              <p>{getTextFromHtml(firstBlog?.content)}</p>
              <Link className="read-more" to={`/Financial Literacy/${firstBlog?.slug}`}>
                Read more
              </Link>
            </div>
          )}
        </div>
        {/* <div className="news font-16">
          <span className="blu-txt">Top 10 News :</span> The European Far-Right: A long journey from the fringes to the mainstream
        </div> */}
      </div>
      <div className="col-md-5">
        <div className={`${BlogsWithoutFirst?.length > 3 && "scrl"}`}>
          <ul className="recent-blog mb-0">
            {BlogsWithoutFirst &&
              BlogsWithoutFirst.map((item) => (
                <li>
                  <a>
                    {item?.image && <img src={item?.image} alt={item?.ID} title={item?.title} />}
                    <div className="rgt">
                      <p className="date">{item?.publish_time}</p>
                      <h5>
                        <Link to={`/Financial Literacy/${item?.slug}`} style={{ display: "flex", color: "inherit" }}>
                          {item?.title}
                        </Link>
                      </h5>
                    </div>
                  </a>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default FinanceMultiple;
