import React, { useState } from "react";

const RangeInput = ({ min, max, id, onChange, value, name, mouseup }) => {
  return (
    <div className="range-container">
      <input
        type="range"
        name={name}
        min={min}
        max={max}
        value={value}
        id={id}
        onChange={onChange}
        onMouseUp={mouseup}
        className="range-input"
      />
      <div className="range-value">{value}</div>
    </div>
  );
};

export default RangeInput;
