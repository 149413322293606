import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
// import { Scrollbars } from 'react-custom-scrollbars';
import cardm from "../../assets/images/pro-card.png";
import cardb from "../../assets/images/card-back.jpg";
import { useQuery } from "@apollo/client";
import { GET_POPULAR_PRO_RELATED_CID, GET_POPULAR_PRO_RELATED_PID } from "../../query/query";
import GetMessage from "../../utils/GetMessage";

const PopularProductByCIDWidget = ({ categoryId }) => {
  const navigate = useNavigate();
  const { parentCatName } = useParams();
  const { loading, error, data } = useQuery(GET_POPULAR_PRO_RELATED_CID, {
    variables: { id: categoryId },
  });
  //   console.log("Popular Product-data", data?.istrendingproduct?.istrendingproduct);
  if (!data?.istrendingproduct?.istrendingproduct?.length > 0) {
    return null;
  }

  return (
    <section className="card-lock-banner">
      <div className="">
        <div className="row">
          <div className="col-md-12">
            <div className="popu-card-inner">
              <h3>{data?.istrendingproduct?.catdata?.listing_page_widget_title}</h3>
              <p className="big-p">{data?.istrendingproduct?.catdata?.listing_page_widget_sub_title}</p>
              <div className="popu-card-scroll scrollbar">
                <ul className="saving-list">
                  {data?.istrendingproduct?.istrendingproduct?.length > 0 &&
                    data?.istrendingproduct?.istrendingproduct.map((popular) => (
                      <li onClick={() => navigate(`/Product/${parentCatName}/${popular.name}/${popular.id}`)} style={{ cursor: "pointer" }}>
                        <div className="pro-sav-img">
                          {/* <img src={cardb} alt="" title="" /> */}
                          <img src={popular.product_image_url} alt="" title="" className="card-pro" />
                        </div>
                        <div className="pro-sav-cont">
                          <p style={{ minHeight: "52px" }}>{popular.name}</p>
                          <div style={{ minHeight: "72px" }}>
                            {popular?.short_desc?.slice(0, 80)}
                            {popular?.short_desc?.length > 80 && "..."}
                          </div>
                          <Link
                            to={`/Product/${parentCatName}/${popular.name}/${popular.id}`}
                            className="action-btn small-btn"
                            style={{ marginTop: "10px" }}
                          >
                            View Details
                          </Link>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PopularProductByCIDWidget;
