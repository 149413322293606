import axios from "axios";
import React, { useEffect, useState } from "react";
import { BlogBaseUrl } from "../..";
import Blog1 from "../../../src/assets/images/blog1.jpg";
import Blog2 from "../../../src/assets/images/blog-m.jpg";
import { Link, useParams } from "react-router-dom";
import NoResultFound from "../../components/NoResultFound/NoResultFound";

const FinancialDetailTabs = ({ currentBlogId, categories, loadingMain, PopularPosts, LatestPosts }) => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabChange = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  console.log("PopularPosts", PopularPosts);
  useEffect(() => {
    if (!loadingMain && PopularPosts?.length == 0) {
      setActiveTab(2);
    } else {
      setActiveTab(1);
    }
  }, [PopularPosts]);
  const CurrentSubCategory = {};
  const renderPopularItems = () => {
    if (loadingMain) {
      return null;
    }
    // if (!PopularPosts?.length > 0) {
    //   return <NoResultFound title={"No popular blog found!!"} />;
    // }
    return (
      <>
        <div className={`b-dtl ${PopularPosts?.length > 10 && "scrl"}`}>
          <ul className="recent-blog" style={{ listStyle: "none" }}>
            {PopularPosts?.map((blog) => (
              <li>
                <a>
                  {blog?.image && <img src={blog?.image} alt={blog?.ID} title={blog?.title} />}
                  <div className="rgt">
                    <p className="date">{blog?.publish_time}</p>{" "}
                    <h5>
                      <Link to={`/Financial Literacy/${blog?.slug}`} style={{ display: "flex", color: "inherit" }}>
                        {blog?.title}
                      </Link>
                    </h5>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  };
  const renderLatestItems = () => {
    if (loadingMain) {
      return null;
    }
    // if (!LatestPosts?.length > 0) {
    //   return <NoResultFound title={"No latest blog found!!"} />;
    // }
    return (
      <>
        <div className={`b-dtl ${LatestPosts?.length > 10 && "scrl"}`}>
          <ul className="recent-blog" style={{ listStyle: "none" }}>
            {LatestPosts?.map((blog) => (
              <li>
                <a>
                  {blog?.image && <img src={blog?.image} alt={blog?.ID} title={blog?.title} />}
                  <div className="rgt">
                    <p className="date">{blog?.publish_time}</p>
                    <h5>
                      <Link to={`/Financial Literacy/${blog?.slug}`} style={{ display: "flex", color: "inherit" }}>
                        {blog?.title?.slice(0, 50)}
                        {blog?.title?.length > 50 && "..."}
                      </Link>
                    </h5>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  };
  return (
    <div>
      <ul className={`FLDetailTabs outerNav nav nav-tabs nav-fill ${(!PopularPosts?.length > 0 || !LatestPosts?.length > 0) && "d-flex"}`}>
        {/* <li className="nav-item">
          <button className={`nav-link ${activeTab === 1 ? "active" : ""}`} onClick={() => handleTabChange(1)}>
            Popular
          </button>
        </li>

        <li className="nav-item">
          <button className={`nav-link ${activeTab === 2 ? "active" : ""}`} onClick={() => handleTabChange(2)}>
            Latest
          </button>
        </li> */}
        {PopularPosts?.length > 0 && (
          <li className="nav-item">
            <button className={`nav-link ${activeTab === 1 ? "active" : ""}`} onClick={() => handleTabChange(1)}>
              Popular
            </button>
          </li>
        )}
        {LatestPosts?.length > 0 && (
          <li className="nav-item">
            <button className={`nav-link ${activeTab === 2 ? "active" : ""}`} onClick={() => handleTabChange(2)}>
              Latest
            </button>
          </li>
        )}
      </ul>
      <div className="tab-content mt-3">
        <div className={`tab-pane ${activeTab === 1 ? "active" : ""}`} id="tab1">
          <div className="accordion" id="accordionExample">
            {renderPopularItems()}
          </div>
        </div>
        <div className={`tab-pane ${activeTab === 2 ? "active" : ""}`} id="tab2">
          {renderLatestItems()}
        </div>
      </div>
    </div>
  );
};

export default FinancialDetailTabs;
