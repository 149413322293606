import React from "react";
import Html from "../../utils/Html";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import "./AboutCategory.css";

const AboutCategory = () => {
  const currentCategory = JSON.parse(
    sessionStorage.getItem("CurrentSubCategory")
  );

  return (
    <>
      <Breadcrumb />
      <div className="py-4">
        <div className="container">
          <div className="my-4">
            <Html html={currentCategory?.cms_page_content} />
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutCategory;
