import axios from "axios";
import React, { useEffect, useState } from "react";
import { BlogBaseUrl } from "../..";

const RenderBlogCategories = ({ SelectedCategory, setSelectedCategory }) => {
  const [CatList, setCatList] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    axios
      .get(BlogBaseUrl + "wp-json/custom/v1/all-categories")
      .then((response) => {
        setCatList(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  const renderCatItem = (item) => {
    return (
      <li
        className={`BlogCategoryItem ${SelectedCategory?.name == item?.name && "active"}`}
        onClick={() => {
          setSelectedCategory(item);
          // document.getElementById("scrollHereOnCatChange")?.scrollIntoView({ behavior: "smooth" });
        }}
      >
        {item?.name}
      </li>
    );
  };
  return (
    <div className="FLCategories">
      <ul className="BlogCategory">
        {loading ? (
          <></>
        ) : (
          <>
            <li
              className={`BlogCategoryItem ${!SelectedCategory && "active"}`}
              onClick={() => {
                setSelectedCategory();
                // document.getElementById("scrollHereOnCatChange")?.scrollIntoView({ behavior: "smooth" });
              }}
            >
              All
            </li>
            {CatList?.map((item) => renderCatItem(item))}
          </>
        )}
      </ul>
    </div>
  );
};

export default RenderBlogCategories;
