import axios from "axios";
import React, { useEffect, useState } from "react";
import { CurrencyRateUrl } from "../..";
import CalculatorLayoutFromSlug from "./CalculatorLayoutFromSlug";

const GoldRate = () => {
  const [loading, setLoading] = useState(false);
  const [Rate, setRate] = useState();
  const [SelectedCurrencyCode, setSelectedCurrencyCode] = useState("Gold");
  const [SelectedPurity, setSelectedPurity] = useState("24K");
  const [Weight, setWeight] = useState(10);
  const [SelectedCurrency, setSelectedCurrency] = useState("AED");
  const [FinalValue, setFinalValue] = useState();
  useEffect(() => {
    console.log("useEffect triggered");
    console.log("useEffect triggered SelectedCurrencyCode:", SelectedCurrencyCode);
    console.log("useEffect triggered SelectedPurity:", SelectedPurity);
    console.log("useEffect triggered SelectedCurrency:", SelectedCurrency);

    if (!loading) {
      setLoading(true);
      axios
        .get(CurrencyRateUrl + `&base=${SelectedCurrency}&currencies=${SelectedCurrencyCode == "Gold" ? "XAU" : "XAG"}`)
        .then((response) => {
          console.log("rate", response?.data);
          setRate(response?.data?.rates?.XAU || response?.data?.rates?.XAG);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    }
  }, [SelectedCurrencyCode, SelectedPurity, SelectedCurrency]);
  useEffect(() => {
    if (!loading && Rate) {
      const OneGramInTryOunce = 0.0321508;
      const totalSelectedTryOunce = Weight * OneGramInTryOunce;
      const calculation = totalSelectedTryOunce / Rate;
      setFinalValue(calculation);
    }
  }, [Rate, SelectedCurrencyCode, SelectedPurity, Weight, SelectedCurrency]);
  const handleInputChange = (e) => {
    switch (e.target.id) {
      case "CurrencyCode":
        setSelectedCurrencyCode(e.target.value);
        break;
      case "Purity":
        setSelectedPurity(e.target.value);
        break;
      case "Weight":
        if (e.target.value < 1) {
          setWeight(1);
        } else {
          setWeight(e.target.value);
        }
        break;
      case "Currency":
        setSelectedCurrency(e.target.value);
        break;
      default:
        break;
    }
  };
  const formatAmountWithCommas = (amount) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <CalculatorLayoutFromSlug slug={"current_gold_rate_in"}>
      <div className="calculatorCard">
        <div className="cardContent d-flex flex-column p-4 gap-3">
          <div className="row" style={{ maxWidth: "500px", alignItems: "center" }}>
            <label htmlFor="CurrencyCode" className="col calculatorFont">
              Metal:
            </label>
            <select
              style={{ height: "40px" }}
              className="col calculatorFont"
              value={SelectedCurrencyCode}
              id="CurrencyCode"
              onChange={(event) => handleInputChange(event)}
            >
              <option value={"Gold"}>Gold</option>
              <option value={"Silver"}>Silver</option>
            </select>
          </div>
          <div className="row" style={{ maxWidth: "500px", alignItems: "center" }}>
            <label htmlFor="Purity" className="col calculatorFont">
              Purity:
            </label>
            <select
              style={{ height: "40px" }}
              className="col calculatorFont"
              value={SelectedPurity}
              id="Purity"
              onChange={(event) => handleInputChange(event)}
            >
              <option value={"24K"}>24-Carat</option>
              {/* <option value={"22K"}>22-Carat</option>
              <option value={"18K"}>18-Carat</option>
              <option value={"14K"}>14-Carat</option>
              <option value={"10K"}>10-Carat</option> */}
            </select>
          </div>
          <div className="row" style={{ maxWidth: "500px", alignItems: "center" }}>
            <label htmlFor="Weight" className="col calculatorFont">
              Weight (Grams):
            </label>
            <input
              className="col calculatorFont"
              value={Weight}
              onChange={(e) => handleInputChange(e)}
              placeholder="Weight"
              type="number"
              name="Weight"
              id="Weight"
              autoComplete="off"
              required
              title="Weight"
              style={{ background: "transparent", border: "1px solid", borderRadius: "4px", height: "40px" }}
            />
          </div>
          <div className="row" style={{ maxWidth: "500px", alignItems: "center" }}>
            <label htmlFor="Currency" className="col calculatorFont">
              Currency:
            </label>
            <select
              style={{ height: "40px" }}
              className="col calculatorFont"
              value={SelectedCurrency}
              id="Currency"
              onChange={(event) => handleInputChange(event)}
            >
              <option value={"AED"}>AED</option>
              <option value={"INR"}>INR</option>
              <option value={"USD"}>USD</option>
              <option value={"GBP"}>GBP</option>
              <option value={"EUR"}>EUR</option>
            </select>
          </div>
          {/* <div className="row" style={{ maxWidth: "500px", alignItems: "center" }}>
            <div className="col calculatorFont d-flex align-items-center" style={{ fontFamily: "GilroyBold", opacity: "0.8" }}>
              Today {Weight} grams {SelectedCurrencyCode} Price is{" "}
              {loading || !FinalValue ? (
                <div className="skeleton-text" style={{ height: "20px", width: "60px", marginRight: "5px", marginLeft: "5px" }} />
              ) : (
                FinalValue
              )}{" "}
              {SelectedCurrency}.
            </div>
          </div> */}
        </div>
        <div className="cardFooter">
          <div className="gFPGhj">
            <div className="esBYzD">
              Today's {Weight} grams {SelectedCurrencyCode} Price is
            </div>
            <div className="hXMcYL row" style={{ marginLeft: "5px" }}>
              {loading || !FinalValue ? (
                <div className="skeleton-text" style={{ height: "30px", width: "100px", marginRight: "5px", marginLeft: "5px" }} />
              ) : (
                formatAmountWithCommas(parseFloat(FinalValue.toFixed(2)))
              )}
            </div>
            <div className="esBYzD ms-3">{SelectedCurrency}.</div>
          </div>
          {/* <a className="action-btn small-btn" style={{ paddingTop: "10px" }}>
            Back
          </a> */}
        </div>
      </div>
    </CalculatorLayoutFromSlug>
  );
};

export default GoldRate;
