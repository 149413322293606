import React from "react";
import proadd from "../../assets/images/pro-add.jpg";
import BottomBannerSlider from "../../utils/BannerLayout/BottomBannerSlider";
import Html from "../../utils/Html";
const Productdetailscontent = ({ product }) => {
  return (
    <div className="product-details-wrp">
      <div className="">
        <div className="row">
          <div className="col-md-12">
            {product?.detail_is_visible && (
              <div className="pro-detl-cont-wrp" id={product?.detail_title}>
                <h3 className="f-30">{product?.detail_title}</h3>
                <Html html={product?.detail} />
              </div>
            )}
            {product?.eligibility_is_visible && (
              <div className="pro-detail-lict-dv" id={product?.eligibility_title}>
                <h3 className="f-30">{product?.eligibility_title}</h3>
                <Html html={product?.eligibility} />
                {/* <ul className="circle-list">
                <li>
                  <p>Between 21 and 70 years of age</p>
                </li>
                <li>
                  <p>
                    Self-employed individuals, proprietors, and partners of
                    partnership firms
                  </p>
                </li>
                <li>
                  <p>Minimum Income Tax Return of INR 7.5 lakhs</p>
                </li>
              </ul> */}
              </div>
            )}
            {product?.fees_and_charges_is_visible && (
              <div className="pro-detail-lict-dv" id={product?.fees_and_charges_title}>
                <h3 className="f-30">{product?.fees_and_charges_title}</h3>
                <Html html={product?.fees_and_charges} />
                {/* <ul className="circle-list">
                <li>
                  <p>
                    First Year Membership Fee of INR 399+ Applicable Taxes
                    waived off on total spends of AED 10,000 within 30 days of
                    card set up date
                  </p>
                </li>
                <li>
                  <p>
                    Renewal Membership Fee of INR 399+ Applicable Taxes waived
                    off on total retail spends of AED 1,00,000 within 12 months
                    prior to card renewal date
                  </p>
                </li>
                <li>
                  <p>
                    3.80% per month (45.6% annually) on Revolving Credit, Cash
                    Advances and Overdue Amount
                  </p>
                </li>
                <li>
                  <p>
                    Minimum 0.50% of transaction value or AED 1 whichever is
                    higher will be levied on all Rental and Wallet transactions
                    greater than AED 1. Please note that rental transactions are
                    capped at 3 per 30 days period.
                  </p>
                </li>
              </ul> */}
              </div>
            )}
            {product?.terms_and_conditions_is_visible && (
              <div className="pro-detl-cont-wrp" id={product?.terms_and_conditions_title}>
                <h3 className="f-30">{product?.terms_and_conditions_title}</h3>
                <Html html={product?.terms_and_conditions} />
              </div>
            )}
            {product?.product_fund_manger?.length > 0 && (
              <div className="productDetailFundManagerAvailable my-4">
                <h3 className="f-30">Fund Managers</h3>
                <div className="reviewList">
                  {product?.product_fund_manger?.map((person) => (
                    <div className="review-single">
                      <div className="review-name">
                        <div className="cli-img">
                          <img src={person?.fundmanger_profile_image_url} alt={person?.name} title={person?.name} />
                        </div>
                        <div className="client-name">
                          {person?.name}
                          <span>{person?.position}</span>
                        </div>
                      </div>
                      <p>"{person?.description}"</p>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {/* //footer */}
            <Html html={product?.footer?.description} />
            <BottomBannerSlider bottomBanner={product?.banners?.filter((item) => item.banner_position == "1")} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Productdetailscontent;
