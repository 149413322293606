import React, { useEffect, useState } from "react";
import CalculatorLayoutFromSlug from "./CalculatorLayoutFromSlug";
import Chart from "react-apexcharts";
// import DonutChart from "./DonutChart";

const DBRCalculator = () => {
  const [Amount, setAmount] = useState(50000);
  const [OtherPayment, setOtherPayment] = useState(0);
  const [MortgagePayment, setMortgagePayment] = useState(0);
  const [MonthlyRent, setMonthlyRent] = useState(0);
  const [PersonalLoan, setPersonalLoan] = useState(0);
  const [MonthlyCCAmount, setMonthlyCCAmount] = useState(0);
  const [CarLoans, setCarLoans] = useState(0);
  const [StudentLoan, setStudentLoan] = useState(0);
  const [OtherDEBTPayment, setOtherDEBTPayment] = useState(0);
  const totalIncome = Number(Amount) + Number(OtherPayment);
  const totalDebt =
    Number(MortgagePayment) +
    Number(MonthlyRent) +
    Number(PersonalLoan) +
    Number(MonthlyCCAmount) +
    Number(CarLoans) +
    Number(StudentLoan) +
    Number(OtherDEBTPayment);
  const DebtRate = (totalDebt * 100) / totalIncome;

  // const [EMI, setEMI] = useState(0);
  // const [TotalAmountToBePaid, setTotalAmountToBePaid] = useState(0);
  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        id: "apexchart-example",
        type: "donut",
      },
      labels: ["Total Income", "Monthly Debt"],
      colors: ["#2196f3", "#7fc558"],
      legend: {
        show: false,
      },
      plotOptions: {
        pie: {
          donut: {
            expandOnClick: false,
            labels: {
              show: true,

              total: {
                show: true,
                showAlways: true,
                label: "Total amount:",
                fontFamily: "GilroyBold",
                formatter: function (value) {
                  const totalNum = value.globals.seriesTotals
                    .reduce((a, b) => {
                      return a + b;
                    }, 0)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  return totalNum;
                },
              },
            },
          },
        },
      },
    },
  });
  useEffect(() => {
    setChartData({
      series: [totalIncome - totalDebt > 0 ? totalIncome - totalDebt : 0, totalDebt],
      options: { ...chartData.options },
    });
  }, [totalIncome, totalDebt]);
  // useEffect(() => {
  //   const InterestRatePerMonth = Math.round((Rate / 12 / 100) * 1e6) / 1e6;
  //   const LoanTenureInMonths = Tenure * 12;
  //   const result = Math.round(
  //     (Amount * InterestRatePerMonth * Math.pow(1 + InterestRatePerMonth, LoanTenureInMonths)) /
  //       (Math.pow(1 + InterestRatePerMonth, LoanTenureInMonths) - 1)
  //   );
  //   setEMI(result);
  //   setTotalAmountToBePaid(result * 12 * Tenure);

  //   console.log("chart updated", Amount, result * 12 * Tenure - Amount);
  //   setChartData({
  //     series: [Number(Amount), result * 12 * Tenure - Amount],
  //     options: { ...chartData.options },
  //   });
  // }, [Amount, Rate, Tenure]);

  const formatAmountWithCommas = (amount) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " " + "AED";
  };
  // Dynamic linear gradient style
  const linearGradientStyle1 = {
    background: `linear-gradient(to right, #2196f3 0%, #2196f3 ${((Amount - 10000) / (10000000 - 10000)) * 100}%, #ddd ${
      ((Amount - 10000) / (10000000 - 10000)) * 100
    }%, #ddd 100%)`,
  };
  const linearGradientStyle2 = {
    background: `linear-gradient(to right, #2196f3 0%, #2196f3 ${((OtherPayment - 0) / (1000000 - 0)) * 100}%, #ddd ${
      ((OtherPayment - 0) / (1000000 - 0)) * 100
    }%, #ddd 100%)`,
  };
  const linearGradientStyle3 = {
    background: `linear-gradient(to right, #2196f3 0%, #2196f3 ${((MortgagePayment - 0) / (1000000 - 0)) * 100}%, #ddd ${
      ((MortgagePayment - 0) / (1000000 - 0)) * 100
    }%, #ddd 100%)`,
  };
  const linearGradientStyle4 = {
    background: `linear-gradient(to right, #2196f3 0%, #2196f3 ${((MonthlyRent - 0) / (1000000 - 0)) * 100}%, #ddd ${
      ((MonthlyRent - 0) / (1000000 - 0)) * 100
    }%, #ddd 100%)`,
  };
  const linearGradientStyle5 = {
    background: `linear-gradient(to right, #2196f3 0%, #2196f3 ${((PersonalLoan - 0) / (1000000 - 0)) * 100}%, #ddd ${
      ((PersonalLoan - 0) / (1000000 - 0)) * 100
    }%, #ddd 100%)`,
  };
  const linearGradientStyle6 = {
    background: `linear-gradient(to right, #2196f3 0%, #2196f3 ${((MonthlyCCAmount - 0) / (1000000 - 0)) * 100}%, #ddd ${
      ((MonthlyCCAmount - 0) / (1000000 - 0)) * 100
    }%, #ddd 100%)`,
  };
  const linearGradientStyle7 = {
    background: `linear-gradient(to right, #2196f3 0%, #2196f3 ${((CarLoans - 0) / (1000000 - 0)) * 100}%, #ddd ${
      ((CarLoans - 0) / (1000000 - 0)) * 100
    }%, #ddd 100%)`,
  };
  const linearGradientStyle8 = {
    background: `linear-gradient(to right, #2196f3 0%, #2196f3 ${((StudentLoan - 0) / (1000000 - 0)) * 100}%, #ddd ${
      ((StudentLoan - 0) / (1000000 - 0)) * 100
    }%, #ddd 100%)`,
  };
  const linearGradientStyle9 = {
    background: `linear-gradient(to right, #2196f3 0%, #2196f3 ${((OtherDEBTPayment - 0) / (1000000 - 0)) * 100}%, #ddd ${
      ((OtherDEBTPayment - 0) / (1000000 - 0)) * 100
    }%, #ddd 100%)`,
  };

  return (
    <CalculatorLayoutFromSlug slug={"dbr-calculator"}>
      <div className="interestEmi slidecontainer calculatorCard">
        <div className="cardContent">
          <div className="cardContentLeft">
            <div className="mb-4">
              <div className="d-flex justify-content-between">
                <div className="calculatorFont">Monthly Income</div>
                <div className="calculatorFont">{formatAmountWithCommas(Amount)}</div>
              </div>
              <div className="calculatorRange">
                <input
                  type="range"
                  // name={name}
                  min={10000}
                  max={10000000}
                  step={100}
                  value={Amount}
                  // id={id}
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                  // onMouseUp={handleChange}
                  className="range-input"
                  style={linearGradientStyle1}
                  // style={{ height: "7px" }}
                />
              </div>
            </div>
            <div className="mb-4">
              <div className="d-flex justify-content-between">
                <div className="calculatorFont">Other Income (if any)</div>
                <div className="calculatorFont">{formatAmountWithCommas(OtherPayment)}</div>
              </div>
              <div className="calculatorRange">
                <input
                  type="range"
                  // name={name}
                  min={0}
                  max={1000000}
                  step={100}
                  value={OtherPayment}
                  // id={id}
                  onChange={(e) => {
                    setOtherPayment(e.target.value);
                  }}
                  // onMouseUp={handleChange}
                  className="range-input"
                  style={linearGradientStyle2}
                  // style={{ height: "7px" }}
                />
              </div>
            </div>
            <div className="mb-4">
              <div className="d-flex justify-content-between">
                <div className="calculatorFont">Mortgage Payment (if any)</div>
                <div className="calculatorFont">{formatAmountWithCommas(MortgagePayment)}</div>
              </div>
              <div className="calculatorRange">
                <input
                  type="range"
                  // name={name}
                  min={0}
                  max={1000000}
                  step={100}
                  value={MortgagePayment}
                  // id={id}
                  onChange={(e) => {
                    setMortgagePayment(e.target.value);
                  }}
                  // onMouseUp={handleChange}
                  className="range-input"
                  style={linearGradientStyle3}
                  // style={{ height: "7px" }}
                />
              </div>
            </div>
            <div className="mb-4">
              <div className="d-flex justify-content-between">
                <div className="calculatorFont">Monthly Rent (if any)</div>
                <div className="calculatorFont">{formatAmountWithCommas(MonthlyRent)}</div>
              </div>
              <div className="calculatorRange">
                <input
                  type="range"
                  // name={name}
                  min={0}
                  max={1000000}
                  step={100}
                  value={MonthlyRent}
                  // id={id}
                  onChange={(e) => {
                    setMonthlyRent(e.target.value);
                  }}
                  // onMouseUp={handleChange}
                  className="range-input"
                  style={linearGradientStyle4}
                  // style={{ height: "7px" }}
                />
              </div>
            </div>
            <div className="mb-4">
              <div className="d-flex justify-content-between">
                <div className="calculatorFont">Personal Loan (if any)</div>
                <div className="calculatorFont">{formatAmountWithCommas(PersonalLoan)}</div>
              </div>
              <div className="calculatorRange">
                <input
                  type="range"
                  // name={name}
                  min={0}
                  max={1000000}
                  step={100}
                  value={PersonalLoan}
                  // id={id}
                  onChange={(e) => {
                    setPersonalLoan(e.target.value);
                  }}
                  // onMouseUp={handleChange}
                  className="range-input"
                  style={linearGradientStyle5}
                  // style={{ height: "7px" }}
                />
              </div>
            </div>
            <div className="mb-4">
              <div className="d-flex justify-content-between">
                <div className="calculatorFont">Monthly Credit Card Amount (if any)</div>
                <div className="calculatorFont">{formatAmountWithCommas(MonthlyCCAmount)}</div>
              </div>
              <div className="calculatorRange">
                <input
                  type="range"
                  // name={name}
                  min={0}
                  max={1000000}
                  step={100}
                  value={MonthlyCCAmount}
                  // id={id}
                  onChange={(e) => {
                    setMonthlyCCAmount(e.target.value);
                  }}
                  // onMouseUp={handleChange}
                  className="range-input"
                  style={linearGradientStyle6}
                  // style={{ height: "7px" }}
                />
              </div>
            </div>
            <div className="mb-4">
              <div className="d-flex justify-content-between">
                <div className="calculatorFont">Car Loans (if any)</div>
                <div className="calculatorFont">{formatAmountWithCommas(CarLoans)}</div>
              </div>
              <div className="calculatorRange">
                <input
                  type="range"
                  // name={name}
                  min={0}
                  max={1000000}
                  step={100}
                  value={CarLoans}
                  // id={id}
                  onChange={(e) => {
                    setCarLoans(e.target.value);
                  }}
                  // onMouseUp={handleChange}
                  className="range-input"
                  style={linearGradientStyle7}
                  // style={{ height: "7px" }}
                />
              </div>
            </div>
            <div className="mb-4">
              <div className="d-flex justify-content-between">
                <div className="calculatorFont">Student Loan (if any)</div>
                <div className="calculatorFont">{formatAmountWithCommas(StudentLoan)}</div>
              </div>
              <div className="calculatorRange">
                <input
                  type="range"
                  // name={name}
                  min={0}
                  max={1000000}
                  step={100}
                  value={StudentLoan}
                  // id={id}
                  onChange={(e) => {
                    setStudentLoan(e.target.value);
                  }}
                  // onMouseUp={handleChange}
                  className="range-input"
                  style={linearGradientStyle8}
                  // style={{ height: "7px" }}
                />
              </div>
            </div>
            <div className="mb-4">
              <div className="d-flex justify-content-between">
                <div className="calculatorFont">Other Payment (if any)</div>
                <div className="calculatorFont">{formatAmountWithCommas(OtherDEBTPayment)}</div>
              </div>
              <div className="calculatorRange">
                <input
                  type="range"
                  // name={name}
                  min={0}
                  max={1000000}
                  step={100}
                  value={OtherDEBTPayment}
                  // id={id}
                  onChange={(e) => {
                    setOtherDEBTPayment(e.target.value);
                  }}
                  // onMouseUp={handleChange}
                  className="range-input"
                  style={linearGradientStyle9}
                  // style={{ height: "7px" }}
                />
              </div>
            </div>

            {/* <div className="gFPGhj">
              <div className="esBYzD">Monthly EMI payment:</div>
              <div className="hXMcYL">{formatAmountWithCommas(EMI)}</div>
            </div> */}
          </div>
          <div className="cardContentRight">
            <div className="d-flex justify-content-center">
              <Chart options={chartData.options} series={chartData.series} type="donut" width="350" />
            </div>

            <div className="">
              <div className="hoGkOo">
                <div className="AnswerName calculatorFont">
                  <div color="purple" className="bAEzSQBlue" />
                  Total Income:
                </div>
                <div className="AnswerAmount calculatorFont">{formatAmountWithCommas(totalIncome)}</div>
              </div>

              <div className="hoGkOo">
                <div className="AnswerName calculatorFont">
                  <div color="blue" className="bAEzSQGreen" />
                  Monthly Debt Payment:
                </div>
                <div className="AnswerAmount calculatorFont">{formatAmountWithCommas(totalDebt)}</div>
              </div>
              <div className="hoGkOo">
                <div className="AnswerName calculatorFont">Final Debt Rate:</div>
                <div className="AnswerAmount calculatorFont">{DebtRate.toFixed(2)}%</div>
              </div>
              <div style={{ paddingTop: "10px", borderBottom: "1px solid #0000001b" }}></div>
              <div className="hoGkOo">
                <div className="AnswerName calculatorFont">Remaining amount:</div>
                <div className="AnswerAmount calculatorFont">{formatAmountWithCommas(totalIncome - totalDebt)}</div>
              </div>
              <p
                style={{
                  marginTop: "15px",
                  opacity: "0.8",
                  fontFamily: "GilroyRegular",
                  fontSize: "15px",
                  color: "rgb(17, 20, 28)",
                  letterSpacing: "0.15px",
                }}
              >
                * Debt Rate over 40% indicate you might have difficulty making payments on any additional debt or loans
              </p>
            </div>
          </div>
        </div>
        <div className="cardFooter">
          {/* <div className="col1">Calculate Interest</div> */}
          <div className="gFPGhj">
            <div className="esBYzD">Debt Rate:</div>
            <div className="hXMcYL">{DebtRate.toFixed(2)}%</div>
          </div>
          {/* <a className="action-btn small-btn" style={{ paddingTop: "10px" }}>
            Back
          </a> */}
        </div>
      </div>
    </CalculatorLayoutFromSlug>
  );
};

export default DBRCalculator;
