import axios from "axios";
import React, { useEffect, useState } from "react";
import { BlogBaseUrl } from "../..";
import Financesingle from "./Financesingle";
import NoResultFound from "../NoResultFound/NoResultFound";

const BlogListings = ({ activeBlogCat }) => {
  const [loading, setLoading] = useState(false);
  const [BlogList, setBlogList] = useState([]);
  useEffect(() => {
    setLoading(true);
    axios
      .get(BlogBaseUrl + `wp-json/custom/v1/posts-by-category/${activeBlogCat?.cat_ID || 0}`)
      .then((response) => {
        setBlogList(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [activeBlogCat]);

  const renderBlogPost = (blog) => {
    return (
      <div className="row BlogListingItem">
        <Financesingle blog={blog} />
      </div>
    );
  };

  return (
    <section className="FLBlogListing articles-wrp">
      <div className="container">
        <div style={{ borderBottom: "1px solid #00000033" }}></div>
        <div className="article-s">
          <h3 className="pb-4">{activeBlogCat?.name || "All"} Blogs</h3>
          <div className="article-main">
            {!loading && BlogList?.length > 0 ? (
              BlogList?.map((blog) => renderBlogPost(blog))
            ) : loading ? (
              <>
                <div className="skeleton-text" style={{ height: "330px", marginBottom: "15px" }} />
                <div className="skeleton-text" style={{ height: "330px", marginBottom: "15px" }} />
              </>
            ) : (
              <NoResultFound title="No Blog Found" />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogListings;
