import React from "react";
import OwlCarousel from "react-owl-carousel";
import TopBannerItem from "../../components/TopBannerItem";

const TopBannerSlider = ({ topBanners }) => {
  const owlCarouselOptions = {
    loop: false,
    dots: false,
    items: 1,
    autoplay: false,
    nav: true,
  };
  if (!topBanners) {
    return null;
  }
  return (
    <OwlCarousel className="banner-slider owl-carousel" {...owlCarouselOptions}>
      {topBanners ? (
        topBanners.map((bnrItem) => <TopBannerItem topBanners={bnrItem} />)
      ) : (
        <></>
      )}
    </OwlCarousel>
  );
};

export default TopBannerSlider;
