import React from "react";
import { Link } from "react-router-dom";

const RenderBannerByLayout = ({ itemsArray }) => {
  if (itemsArray?.length) {
    const numberOfItems = itemsArray?.length;
    switch (numberOfItems) {
      case 1:
        return (
          <div className="post-in-1">
            {itemsArray.map((item) => {
              if (item.banner_url) {
                return (
                  <Link to={item.banner_url}>
                    <img src={item.banner_image_url} alt={item.banner_alt_tag} title={item.banner_alt_tag} />
                  </Link>
                );
              } else {
                return <img src={item.banner_image_url} alt={item.banner_alt_tag} title={item.banner_alt_tag} />;
              }
            })}
          </div>
        );

        break;
      case 2:
        return (
          <div className="post-in-2">
            {itemsArray[0].banner_url ? (
              <Link to={itemsArray[0].banner_url}>
                <img src={itemsArray[0]?.banner_image_url} alt={itemsArray[0]?.banner_alt_tag} title={itemsArray[0]?.banner_alt_tag} />
              </Link>
            ) : (
              <img src={itemsArray[0]?.banner_image_url} alt={itemsArray[0]?.banner_alt_tag} title={itemsArray[0]?.banner_alt_tag} />
            )}
            {itemsArray[1].banner_url ? (
              <Link to={itemsArray[1].banner_url}>
                <img src={itemsArray[1]?.banner_image_url} alt={itemsArray[1]?.banner_alt_tag} title={itemsArray[1]?.banner_alt_tag} />
              </Link>
            ) : (
              <img src={itemsArray[1]?.banner_image_url} alt={itemsArray[1]?.banner_alt_tag} title={itemsArray[1]?.banner_alt_tag} />
            )}
          </div>
        );
        break;
      case 3:
        return (
          <div className="post-in-3">
            <div className="left">
              <div className="p-img">
                {itemsArray[0].banner_url ? (
                  <Link to={itemsArray[0].banner_url}>
                    <img src={itemsArray[0]?.banner_image_url} alt={itemsArray[0]?.banner_alt_tag} title={itemsArray[0]?.banner_alt_tag} />
                  </Link>
                ) : (
                  <img src={itemsArray[0]?.banner_image_url} alt={itemsArray[0]?.banner_alt_tag} title={itemsArray[0]?.banner_alt_tag} />
                )}
              </div>
            </div>
            <div className="right">
              <div className="p-img">
                {itemsArray[1].banner_url ? (
                  <Link to={itemsArray[1].banner_url}>
                    <img src={itemsArray[1]?.banner_image_url} alt={itemsArray[1]?.banner_alt_tag} title={itemsArray[1]?.banner_alt_tag} />
                  </Link>
                ) : (
                  <img src={itemsArray[1]?.banner_image_url} alt={itemsArray[1]?.banner_alt_tag} title={itemsArray[1]?.banner_alt_tag} />
                )}
              </div>
              <div className="p-img">
                {itemsArray[2].banner_url ? (
                  <Link to={itemsArray[2].banner_url}>
                    <img src={itemsArray[2]?.banner_image_url} alt={itemsArray[2]?.banner_alt_tag} title={itemsArray[2]?.banner_alt_tag} />
                  </Link>
                ) : (
                  <img src={itemsArray[2]?.banner_image_url} alt={itemsArray[2]?.banner_alt_tag} title={itemsArray[2]?.banner_alt_tag} />
                )}
              </div>
            </div>
          </div>
        );
        break;
      case 4:
        return (
          <div className="post-in">
            <div className="left">
              <div className="p-img">
                {itemsArray[0].banner_url ? (
                  <Link to={itemsArray[0].banner_url}>
                    <img src={itemsArray[0]?.banner_image_url} alt={itemsArray[0]?.banner_alt_tag} title={itemsArray[0]?.banner_alt_tag} />
                  </Link>
                ) : (
                  <img src={itemsArray[0]?.banner_image_url} alt={itemsArray[0]?.banner_alt_tag} title={itemsArray[0]?.banner_alt_tag} />
                )}
              </div>
              <div className="p-img">
                {itemsArray[1].banner_url ? (
                  <Link to={itemsArray[1].banner_url}>
                    <img src={itemsArray[1]?.banner_image_url} alt={itemsArray[1]?.banner_alt_tag} title={itemsArray[1]?.banner_alt_tag} />
                  </Link>
                ) : (
                  <img src={itemsArray[1]?.banner_image_url} alt={itemsArray[1]?.banner_alt_tag} title={itemsArray[1]?.banner_alt_tag} />
                )}
              </div>
            </div>
            <div className="right">
              <div className="p-img">
                {itemsArray[2].banner_url ? (
                  <Link to={itemsArray[2].banner_url}>
                    <img src={itemsArray[2]?.banner_image_url} alt={itemsArray[2]?.banner_alt_tag} title={itemsArray[2]?.banner_alt_tag} />
                  </Link>
                ) : (
                  <img src={itemsArray[2]?.banner_image_url} alt={itemsArray[2]?.banner_alt_tag} title={itemsArray[2]?.banner_alt_tag} />
                )}
              </div>
              <div className="p-img">
                {itemsArray[3].banner_url ? (
                  <Link to={itemsArray[3].banner_url}>
                    <img src={itemsArray[3]?.banner_image_url} alt={itemsArray[3]?.banner_alt_tag} title={itemsArray[3]?.banner_alt_tag} />
                  </Link>
                ) : (
                  <img src={itemsArray[3]?.banner_image_url} alt={itemsArray[3]?.banner_alt_tag} title={itemsArray[3]?.banner_alt_tag} />
                )}
              </div>
            </div>
          </div>
        );
        break;
      default:
        break;
    }
    return null;
  }
  return null;
};

export default RenderBannerByLayout;
