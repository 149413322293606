import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
// import { Scrollbars } from 'react-custom-scrollbars';
import cardm from "../../assets/images/pro-card.png";
import cardb from "../../assets/images/card-back.jpg";
import { useQuery } from "@apollo/client";
import { GET_POPULAR_PRO_RELATED_PID } from "../../query/query";
import GetMessage from "../../utils/GetMessage";

const PopularProductByPIDWidget = ({ productId }) => {
  const navigate = useNavigate();
  const { parentCatName } = useParams();
  const related_product_widget_title = GetMessage("related_product_widget_title");
  const related_product_widget_subtitle = GetMessage("related_product_widget_subtitle");
  const { loading, error, data } = useQuery(GET_POPULAR_PRO_RELATED_PID, {
    variables: { id: productId },
  });
  //   console.log("Popular Product-data", data?.relatedpro?.relatedpro);
  if (!data?.relatedpro?.relatedpro?.length > 0) {
    return null;
  }

  return (
    <section className="card-lock-banner">
      <div className="">
        <div className="row">
          <div className="col-md-12">
            <div className="popu-card-inner">
              <h3>
                {data?.relatedpro?.procatdata?.detail_page_widget_title}
                {/* {related_product_widget_title} {parentCatName} */}
              </h3>
              <p className="big-p">
                {data?.relatedpro?.procatdata?.detail_page_widget_sub_title}
                {/* {related_product_widget_subtitle} {parentCatName} */}
              </p>
              {/* <Scrollbars style={{ width: '100%' }}> */}
              <div className="popu-card-scroll scrollbar">
                <ul className="saving-list">
                  {data?.relatedpro?.relatedpro?.length > 0 &&
                    data?.relatedpro?.relatedpro.map((popular) => (
                      <li onClick={() => navigate(`/Product/${parentCatName}/${popular.name}/${popular.id}`)} style={{ cursor: "pointer" }}>
                        <div className="pro-sav-img">
                          {/* <img src={cardb} alt="" title="" /> */}
                          <img src={popular.product_image_url} alt="" title="" className="card-pro" />
                        </div>
                        <div className="pro-sav-cont">
                          <p style={{ minHeight: "52px" }}>{popular.name}</p>
                          <div style={{ minHeight: "72px" }}>
                            {popular.short_desc?.slice(0, 80)}
                            {popular.short_desc?.length > 80 && "..."}
                          </div>
                          {/* <ul className="square-list">
                            {popular.features.map((feature) => (
                              <li>{feature}</li>
                            ))}
                          </ul> */}
                          <Link
                            to={`/Product/${parentCatName}/${popular.name}/${popular.id}`}
                            className="action-btn small-btn"
                            style={{ marginTop: "10px" }}
                          >
                            View Details
                          </Link>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
              {/* </Scrollbars> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PopularProductByPIDWidget;
