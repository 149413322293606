import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import money from "../../assets/images/money.jpg";
import down from "../../assets/images/dwn.svg";
import axios from "axios";
import { CurrencyRateUrl } from "../..";

const GoldRateWidget = () => {
  const [loading, setLoading] = useState(false);
  const [Rate, setRate] = useState();
  useEffect(() => {
    if (!loading && !Rate) {
      setLoading(true);
      axios
        .get(CurrencyRateUrl + `&base=AED&currencies=XAU&Symbol=XAU`)
        .then((response) => {
          console.log("rate", response?.data);
          setRate(response?.data?.rates?.XAU);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    }
  }, []);
  return (
    <div className="convrt-in">
      <div className="convrt-img">
        <img src={money} alt={money} />
      </div>
      <div className="convrt-content">
        <Link to={"/Gold Rate"}>
          <h5>Current Gold Rate</h5>
        </Link>
        <div className="curency-div">
          <select>
            <option>Abu Dhabi</option>
          </select>
        </div>
        {Rate && (
          <p>
            Gold (24k) = <br></br> <span className="blu-txt">{parseFloat((0.0321508 / Rate).toFixed(2))} AED</span> per Gram.
            {/* <span className="green-txt">+0.24%</span> */}
          </p>
        )}
        <Link to={"/Gold Rate"} className="viewmore font-16">
          View details <img src={down} alt={down} />
        </Link>
      </div>
    </div>
  );
};

export default GoldRateWidget;
