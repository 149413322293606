import React from "react";
import { GetLeftBanners, GetRightBanners } from "../GetBanners";
import LeftBannerItem from "./LeftBannerItem";
import RightBannerItem from "./RightBannerItem";
import "./WithBannersLayout.css";

const WithBannersLayout = ({ slug, children, leftBanners, rightBanners }) => {
  const LeftBanner = leftBanners || (slug && GetLeftBanners(slug));
  const rightBanner = rightBanners || (slug && GetRightBanners(slug));
  const isLeftBannerAvailable = LeftBanner?.length > 0;
  const isRightBannerAvailable = rightBanner?.length > 0;
  // const isBottomBannerAvailable = true;

  const renderLeftBannerLayout = () => {
    return (
      <div className="container WithBannersLayout-inner WithBannersLayout-left">
        {renderLeftBanner()}
        <span>{children}</span>
      </div>
    );
  };
  const renderRightBannerLayout = () => {
    return (
      <div className="container WithBannersLayout-inner WithBannersLayout-right">
        <span>{children}</span>
        {renderRightBanner()}
      </div>
    );
  };
  const renderLeftAndRightBannerLayout = () => {
    return (
      <div className="container WithBannersLayout-inner WithBannersLayout-left-and-right">
        {renderLeftBanner()}
        <span>{children}</span>
        {renderRightBanner()}
      </div>
    );
  };
  const renderLeftBanner = () => {
    return (
      <span className="banner-left">
        {LeftBanner ? (
          LeftBanner.map((bnrItem) => <LeftBannerItem LeftBanner={bnrItem} />)
        ) : (
          <></>
        )}
      </span>
    );
  };
  const renderRightBanner = () => {
    return (
      <span className="banner-right">
        {rightBanner ? (
          rightBanner.map((bnrItem) => (
            <RightBannerItem rightBanner={bnrItem} />
          ))
        ) : (
          <></>
        )}
      </span>
    );
  };
  return (
    <div className="WithBannersLayout-wrap">
      {isLeftBannerAvailable &&
        !isRightBannerAvailable &&
        renderLeftBannerLayout()}
      {!isLeftBannerAvailable &&
        isRightBannerAvailable &&
        renderRightBannerLayout()}
      {isLeftBannerAvailable &&
        isRightBannerAvailable &&
        renderLeftAndRightBannerLayout()}
      {!isLeftBannerAvailable && !isRightBannerAvailable && children}
      {/* {isBottomBannerAvailable && (
        <div className="container WithBannersLayout-bottomBanner">
          {renderBottomBanner()}
        </div>
      )} */}
    </div>
  );
};

export default WithBannersLayout;
