import React, { useEffect, useState } from "react";
import Html from "../utils/Html";
import RenderBannerByLayout from "./RenderBannerByLayout";

const TopBannerItem = ({ topBanners }) => {
  const [BannerImages, setBannerImages] = useState();
  const [BannerScript, setBannerScript] = useState();
  useEffect(() => {
    // console.log("topBanners", topBanners);
    if (topBanners) {
      if (topBanners?.banner_upload_type == 0) {
        // it is array of image
        setBannerImages(topBanners?.banner_image);
      } else if (topBanners?.banner_upload_type == 1) {
        // it is script
        setBannerScript(topBanners?.banner_script);
      } else if (topBanners?.banner_upload_type == 2) {
        // it is html
        setBannerScript(topBanners?.banner_html);
      }
    }
  }, [topBanners]);

  return (
    <section className="post-wrp">
      <div className="">
        {BannerScript ? (
          <div className="post-is-script">
            <Html html={BannerScript} />
          </div>
        ) : (
          <RenderBannerByLayout itemsArray={BannerImages} />
        )}
      </div>
    </section>
  );
};

export default TopBannerItem;
