import React, { useEffect, useState } from "react";
import Articles from "../../components/Articles";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import "./FinancialLiteracy.css";
import BlogLayout from "../../components/BlogLayout/BlogLayout";
import RenderBlogCategories from "../../components/BlogLayout/RenderBlogCategories";
import BlogListings from "../../components/BlogLayout/BlogListings";
import axios from "axios";
import { BlogBaseUrl } from "../..";
import { useLocation, useParams } from "react-router-dom";
import { GetCurrentCatData, GetIdFromUrlCategory } from "../../query/Graphql";
import WithMetaByData from "../../utils/WithMetaByData";
import BottomBannerSlider from "../../utils/BannerLayout/BottomBannerSlider";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_CURRENT_CAT_DATA } from "../../query/query";
import WithBannersLayout from "../../utils/WithBannersLayout/WithBannersLayout";
import TopBannerSlider from "../../utils/BannerLayout/TopBannerSlider";
import Html from "../../utils/Html";
import Financesingle from "../../components/BlogLayout/Financesingle";

const FinancialLiteracy = () => {
  const location = useLocation(); // Get the current location using react-router-dom
  const [CurrentId, setCurrentId] = useState();
  const [CurrentPageData, setCurrentPageData] = useState();
  const IDArrayFromURL = GetIdFromUrlCategory([decodeURIComponent(location.pathname?.substring(1))]);
  const [fetchCategory, { loading, error, data: currentCatData }] = useLazyQuery(GET_CURRENT_CAT_DATA, {
    variables: { id: CurrentId },
  });
  useEffect(() => {
    if (IDArrayFromURL?.length > 0) {
      setCurrentId(IDArrayFromURL[IDArrayFromURL.length - 1].id);
    }
  }, [IDArrayFromURL]);
  useEffect(() => {
    if (CurrentId) {
      fetchCategory();
    }
  }, [CurrentId]);
  useEffect(() => {
    setCurrentPageData(currentCatData?.singlecategory?.singlecategory);
  }, [currentCatData]);

  console.log("CurrentId", CurrentPageData);

  const [loadingMain, setLoadingMain] = useState(false);
  const [LoadingMainSearch, setLoadingMainSearch] = useState(false);
  const [PopularPosts, setPopularPosts] = useState([]);
  const [FeaturedPosts, setFeaturedPosts] = useState([]);
  const [LatestPosts, setLatestPosts] = useState([]);
  const [SelectedCategory, setSelectedCategory] = useState();
  const [SearchInput, setSearchInput] = useState("");
  const [SearchedPosts, setSearchedPosts] = useState([]);

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };
  useEffect(() => {
    if (SearchInput?.length > 2) {
      setLoadingMainSearch(true);
      axios
        .get(BlogBaseUrl + `${"wp-json/custom/v1/search/?title=" + SearchInput}`)
        .then((response) => {
          setSearchedPosts(response.data.data);
          setLoadingMainSearch(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoadingMainSearch(false);
        });
      setLoadingMainSearch(true);
    }
  }, [SearchInput]);

  useEffect(() => {
    setLatestPosts([]);
    setPopularPosts([]);
    setFeaturedPosts([]);
    setLoadingMain(true);
    axios
      .get(
        BlogBaseUrl +
          `${
            SelectedCategory
              ? "wp-json/custom/v1/posts-by-category-tag/" + SelectedCategory?.cat_ID + "/latest"
              : "wp-json/custom/v1/posts-by-tag?tag=latest"
          }`
      )
      .then((response) => {
        setLatestPosts(response.data.data);
        setLoadingMain(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoadingMain(false);
      });
    setLoadingMain(true);
    axios
      .get(
        BlogBaseUrl +
          `${
            SelectedCategory
              ? "wp-json/custom/v1/posts-by-category-tag/" + SelectedCategory?.cat_ID + "/popular"
              : "wp-json/custom/v1/posts-by-tag?tag=popular"
          }`
      )
      .then((response) => {
        setPopularPosts(response.data.data);
        setLoadingMain(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoadingMain(false);
      });
    setLoadingMain(true);
    axios
      .get(
        BlogBaseUrl +
          `${
            SelectedCategory
              ? "wp-json/custom/v1/posts-by-category-tag/" + SelectedCategory?.cat_ID + "/featured"
              : "wp-json/custom/v1/posts-by-tag?tag=featured"
          }`
      )
      .then((response) => {
        setFeaturedPosts(response.data.data);
        setLoadingMain(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoadingMain(false);
      });
  }, [SelectedCategory]);
  const renderSearchComponent = () => {
    return (
      <>
        <section className="articles-wrp">
          <div className="container">
            <div className="article-s">
              <h3 className="pb-4">Search results for "{SearchInput}"</h3>
              {LoadingMainSearch && (
                <div className="container">
                  <div className="skeleton-text" style={{ height: "350px", marginBottom: "50px", marginTop: "50px" }} />
                  <div className="skeleton-text" style={{ height: "350px", marginBottom: "50px" }} />
                  <div className="skeleton-text" style={{ height: "350px", marginBottom: "50px" }} />
                </div>
              )}
              <div className="article-main">
                {SearchedPosts?.map((post) => (
                  <div className="row BlogListingItem">
                    <Financesingle blog={post} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };
  return (
    <>
      <Breadcrumb />
      <div className="pt-4">
        <WithBannersLayout
          leftBanners={CurrentPageData?.catbanner?.filter((item) => item.banner_position == "3")}
          rightBanners={CurrentPageData?.catbanner?.filter((item) => item.banner_position == "4")}
        >
          <TopBannerSlider topBanners={CurrentPageData?.catbanner?.filter((item) => item.banner_position == "0")} />
          <section className="FinancialLiteracy">
            {CurrentPageData && <WithMetaByData data={CurrentPageData}></WithMetaByData>}
            <div className="container">
              <div className="FinancialLiteracy-row one">
                <div className="title big-p text-black font-bold d-flex justify-content-between">
                  <h5>Financial Literacy</h5>
                  <div className="search-dv">
                    <form className="search" action="search.php">
                      <input placeholder="Search..." type="search" value={SearchInput} onChange={handleSearchInputChange} />
                      <a className="src-icn">
                        <img src="/static/media/search-icn.0ddd2811f5c381afba35f97f9afb79a9.svg" alt />
                      </a>
                    </form>
                  </div>
                </div>
                {SearchInput?.length <= 2 && <RenderBlogCategories SelectedCategory={SelectedCategory} setSelectedCategory={setSelectedCategory} />}
              </div>
            </div>
          </section>

          {SearchInput?.length <= 2 ? (
            <>
              {loadingMain && (
                <div className="container">
                  <div className="skeleton-text" style={{ height: "350px", marginBottom: "50px", marginTop: "50px" }} />
                  <div className="skeleton-text" style={{ height: "350px", marginBottom: "50px" }} />
                  <div className="skeleton-text" style={{ height: "350px", marginBottom: "50px" }} />
                </div>
              )}
              <BlogLayout title="Latest Blogs" blogs={LatestPosts} />
              <BlogLayout title="Popular Blogs" blogs={PopularPosts} />
              <BlogLayout title="Featured Blogs" blogs={FeaturedPosts} />
              {/* <BlogLayout
                title="Articles, News And Trends"
                subTitle="Stay Informed With The Latest Articles, Breaking News, And Trending Topics."
              /> */}
              {/* <Articles /> */}
              <span id="scrollHereOnCatChange" />
              <BlogListings activeBlogCat={SelectedCategory} />
            </>
          ) : (
            renderSearchComponent()
          )}
          <div className="mb-4">
            <BottomBannerSlider bottomBanner={CurrentPageData?.catbanner?.filter((item) => item.banner_position == "1")} />
          </div>
          <div className="container py-4">
            <Html html={CurrentPageData?.footer?.description} />
          </div>
        </WithBannersLayout>
      </div>
    </>
  );
};

export default FinancialLiteracy;
