import React, { useEffect, useState } from "react";
import CalculatorLayoutFromSlug from "./CalculatorLayoutFromSlug";
import Chart from "react-apexcharts";
// import DonutChart from "./DonutChart";

const HomeLoan = () => {
  const [Amount, setAmount] = useState(100000);
  const [ProcessFee, setProcessFee] = useState(1);
  const [Rate, setRate] = useState(8.5);
  const [Tenure, setTenure] = useState(10);
  const [DownPayment, setDownPayment] = useState(10);
  const [EMI, setEMI] = useState(0);
  const [TotalAmountToBePaid, setTotalAmountToBePaid] = useState(0);
  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        id: "apexchart-example",
        type: "donut",
      },
      labels: ["Loan Amount", "Interest"],
      colors: ["#2196f3", "#7fc558"],
      legend: {
        show: false,
      },
      plotOptions: {
        pie: {
          donut: {
            expandOnClick: false,
            labels: {
              show: true,

              total: {
                show: true,
                showAlways: true,
                label: "Total amount:",
                fontFamily: "GilroyBold",
                formatter: function (value) {
                  const totalNum = value.globals.seriesTotals
                    .reduce((a, b) => {
                      return a + b;
                    }, 0)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  return totalNum;
                },
              },
            },
          },
        },
      },
    },
  });
  useEffect(() => {
    const InterestRatePerMonth = Rate / 12 / 100;
    const LoanTenureInMonths = Tenure * 12;
    const DownPaymentInAmount = Math.round((Amount * DownPayment) / 100, 8);
    const ProcessFeeInAmount = Math.round((DownPaymentInAmount * ProcessFee) / 100);
    const finalAmount = Amount - DownPaymentInAmount;
    const result =
      (finalAmount * InterestRatePerMonth * Math.pow(1 + InterestRatePerMonth, LoanTenureInMonths)) /
      (Math.pow(1 + InterestRatePerMonth, LoanTenureInMonths) - 1);
    setEMI(result);
    setTotalAmountToBePaid(result * (12 * Tenure));

    setChartData({
      series: [Number(finalAmount), Math.round(result * (12 * Tenure) - finalAmount + ProcessFeeInAmount)],
      options: { ...chartData.options },
    });
  }, [Amount, Rate, Tenure]);

  const formatAmountWithCommas = (amount) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " " + "AED";
  };
  // Dynamic linear gradient style
  const linearGradientStyle1 = {
    background: `linear-gradient(to right, #2196f3 0%, #2196f3 ${((Amount - 1000) / (1000000 - 1000)) * 100}%, #ddd ${
      ((Amount - 1000) / (1000000 - 1000)) * 100
    }%, #ddd 100%)`,
  };
  const linearGradientStyle2 = {
    background: `linear-gradient(to right, #2196f3 0%, #2196f3 ${((ProcessFee - 0) / (5 - 0)) * 100}%, #ddd ${
      ((ProcessFee - 0) / (5 - 0)) * 100
    }%, #ddd 100%)`,
  };
  const linearGradientStyle3 = {
    background: `linear-gradient(to right, #2196f3 0%, #2196f3 ${((Rate - 1) / (30 - 1)) * 100}%, #ddd ${((Rate - 1) / (30 - 1)) * 100}%, #ddd 100%)`,
  };
  const linearGradientStyle4 = {
    background: `linear-gradient(to right, #2196f3 0%, #2196f3 ${((Tenure - 1) / (30 - 1)) * 100}%, #ddd ${
      ((Tenure - 1) / (30 - 1)) * 100
    }%, #ddd 100%)`,
  };
  const linearGradientStyle5 = {
    background: `linear-gradient(to right, #2196f3 0%, #2196f3 ${((DownPayment - 15) / (80 - 15)) * 100}%, #ddd ${
      ((DownPayment - 15) / (80 - 15)) * 100
    }%, #ddd 100%)`,
  };
  return (
    <CalculatorLayoutFromSlug slug={"home_loan"}>
      <div className="home slidecontainer calculatorCard">
        <div className="cardContent">
          <div className="cardContentLeft">
            <div className="mb-4 mt-4">
              <div className="d-flex justify-content-between">
                <div className="calculatorFont">Property Value</div>
                <div className="calculatorFont">{formatAmountWithCommas(Amount)}</div>
              </div>
              <div className="calculatorRange">
                <input
                  type="range"
                  // name={name}
                  min={1000}
                  max={1000000}
                  step={1000}
                  value={Amount}
                  // id={id}
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                  // onMouseUp={handleChange}
                  className="range-input"
                  style={linearGradientStyle1}
                  // style={{ height: "7px" }}
                />
              </div>
            </div>
            <div className="mb-4 mt-4">
              <div className="d-flex justify-content-between">
                <div className="calculatorFont">Processing Fee</div>
                <div className="calculatorFont">{ProcessFee}%</div>
              </div>
              <div className="calculatorRange">
                <input
                  type="range"
                  // name={name}
                  min={0}
                  max={5}
                  step={0.1}
                  value={ProcessFee}
                  // id={id}
                  onChange={(e) => {
                    setProcessFee(e.target.value);
                  }}
                  // onMouseUp={handleChange}
                  className="range-input"
                  style={linearGradientStyle2}
                  // style={{ height: "7px" }}
                />
              </div>
            </div>

            <div className="mb-4 mt-4">
              <div className="d-flex justify-content-between">
                <div className="calculatorFont">Yearly Interest rate</div>
                <div className="calculatorFont">{Rate}%</div>
              </div>
              <div className="calculatorRange">
                <input
                  type="range"
                  // name={name}
                  min={1}
                  max={30}
                  step={0.5}
                  value={Rate}
                  // id={id}
                  onChange={(e) => {
                    setRate(e.target.value);
                  }}
                  // onMouseUp={handleChange}
                  className="range-input"
                  style={linearGradientStyle3}
                  // style={{ height: "7px" }}
                />
              </div>
            </div>
            <div className="mb-4 mt-4">
              <div className="d-flex justify-content-between">
                <div className="calculatorFont">Loan Tenure</div>
                <div className="calculatorFont">{Tenure} years</div>
              </div>
              <div className="calculatorRange">
                <input
                  type="range"
                  // name={name}
                  min={1}
                  max={30}
                  value={Tenure}
                  // id={id}
                  onChange={(e) => {
                    setTenure(e.target.value);
                  }}
                  // onMouseUp={handleChange}
                  className="range-input"
                  style={linearGradientStyle4}
                  // style={{ height: "7px" }}
                />
              </div>
            </div>
            <div className="mb-4 mt-4">
              <div className="d-flex justify-content-between">
                <div className="calculatorFont">Down Payment</div>
                <div className="calculatorFont">{DownPayment}%</div>
              </div>
              <div className="calculatorRange">
                <input
                  type="range"
                  // name={name}
                  min={15}
                  max={80}
                  value={DownPayment}
                  // id={id}
                  onChange={(e) => {
                    setDownPayment(e.target.value);
                  }}
                  // onMouseUp={handleChange}
                  className="range-input"
                  style={linearGradientStyle5}
                  // style={{ height: "7px" }}
                />
              </div>
            </div>
            {/* <div className="gFPGhj">
              <div className="esBYzD">Monthly EMI payment:</div>
              <div className="hXMcYL">{formatAmountWithCommas(Math.round(EMI))}</div>
            </div> */}
          </div>
          <div className="cardContentRight">
            <div className="d-flex justify-content-center">
              <Chart options={chartData.options} series={chartData.series} type="donut" width="350" />
            </div>

            <div className="">
              <div className="hoGkOo">
                <div className="AnswerName calculatorFont">
                  <div color="purple" className="bAEzSQBlue" />
                  Home Loan Amount:
                </div>
                <div className="AnswerAmount calculatorFont">{formatAmountWithCommas(Amount - (Amount * DownPayment) / 100)}</div>
              </div>
              <div className="hoGkOo">
                <div className="AnswerName calculatorFont">
                  {/* <div color="purple" className="bAEzSQBlue" /> */}
                  Down Payment:
                </div>
                <div className="AnswerAmount calculatorFont">{formatAmountWithCommas((Amount * DownPayment) / 100)}</div>
              </div>
              <div className="hoGkOo">
                <div className="AnswerName calculatorFont">
                  {/* <div color="purple" className="bAEzSQBlue" /> */}
                  Processing Fee:
                </div>
                <div className="AnswerAmount calculatorFont">
                  {formatAmountWithCommas(Math.round(((Amount - (Amount * DownPayment) / 100) * ProcessFee) / 100))}
                </div>
              </div>
              <div className="hoGkOo">
                <div className="AnswerName calculatorFont">
                  {/* <div color="purple" className="bAEzSQBlue" /> */}
                  Tenure:
                </div>
                <div className="AnswerAmount calculatorFont">{Tenure * 12} Month</div>
              </div>
              <div className="hoGkOo">
                <div className="AnswerName calculatorFont">
                  <div color="blue" className="bAEzSQGreen" />
                  Total Interest Amount:
                </div>
                <div className="AnswerAmount calculatorFont">
                  {/* {formatAmountWithCommas(TotalAmountToBePaid + Math.round((Amount * ProcessFee) / 100, 8) - (Amount - (Amount * DownPayment) / 100))} */}
                  {formatAmountWithCommas(Math.round(TotalAmountToBePaid + (Amount * ProcessFee) / 100 - (Amount - (Amount * DownPayment) / 100)))}
                </div>
              </div>
              <div style={{ paddingTop: "10px", borderBottom: "1px solid #0000001b" }}></div>
              <div className="hoGkOo">
                <div className="AnswerName calculatorFont">Total payable:</div>
                <div className="AnswerAmount calculatorFont">
                  {/* {formatAmountWithCommas(Math.round(TotalAmountToBePaid + Math.round((Amount * ProcessFee) / 100, 8)))} */}
                  {formatAmountWithCommas(Math.round(EMI * Tenure * 12 + ((Amount - (Amount * DownPayment) / 100) * ProcessFee) / 100))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cardFooter">
          {/* <div className="col1">Calculate home loan</div> */}
          <div className="gFPGhj">
            <div className="esBYzD">Monthly EMI payment:</div>
            <div className="hXMcYL">{formatAmountWithCommas(Math.round(EMI))}</div>
          </div>
          {/* <a className="action-btn small-btn" style={{ paddingTop: "10px" }}>
            Back
          </a> */}
        </div>
      </div>
    </CalculatorLayoutFromSlug>
  );
};

export default HomeLoan;
