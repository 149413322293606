import React from "react";
import TopBannerSlider from "../utils/BannerLayout/TopBannerSlider";

const Homebanner = ({ HomePageData }) => {
  // const topHomeBanner = GetTopBanners("home");
  // console.log("topHomeBanner", topHomeBanner);

  if (!HomePageData?.banners) {
    return (
      <section className="home-banner">
        <div className="container top-bnr">
          <div className="skeleton-text" style={{ height: "421px" }} />
        </div>
      </section>
    );
  }
  if (HomePageData?.banners?.length == 0) {
    return null;
  }

  return (
    <section className="home-banner">
      <div className="container top-bnr">
        <TopBannerSlider
          topBanners={HomePageData?.banners?.filter(
            (item) => item.banner_position == "0"
          )}
        />
      </div>
    </section>
  );
};

export default Homebanner;
