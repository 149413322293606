import React, { useEffect, useState } from "react";
import "./Home.css";
import "./Responsive.css";
import Homebanner from "../../components/Homebanner";
import Homesaving from "../../components/Homesaving";
import Testimonial from "../../components/Testimonial";
import Articles from "../../components/Articles";
import Rateproviders from "../../components/Rateproviders";
import Addposters from "../../components/Addposters";
import Whyhome from "../../components/Whyhome";
import Converter from "../../components/Converter";
import Clientreview from "../../components/Clientreview";
import CompareProducts from "../../components/CompareProducts";
import GetMessage from "../../utils/GetMessage";
import { GetSinglePagesDataFromSlug } from "../../query/Graphql";
import WithMetaByData from "../../utils/WithMetaByData";
import WithBannersLayout from "../../utils/WithBannersLayout/WithBannersLayout";
import BlogLayout from "../../components/BlogLayout/BlogLayout";
import axios from "axios";
import { BlogBaseUrl } from "../..";
import BlogLayoutForHome from "../../components/BlogLayout/BlogLayoutForHome";
import CompareProductWidget from "../../components/Widgets/CompareProductWidget";
import { useDispatch, useSelector } from "react-redux";
import { setApplyFormOn } from "../../stores/rootReducer";
import { useNavigate, useParams } from "react-router-dom";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // redirectTo form if formLink is there
  const ApplyProductLink = useSelector((state) => state.rootReducer?.ApplyProductLink);
  useEffect(() => {
    if (ApplyProductLink && ApplyProductLink != "") {
      console.log("ApplyProductLink ", ApplyProductLink);
      navigate(ApplyProductLink);
    }
  }, [ApplyProductLink]);
  // redirectTo form if formLink is there
  // redirectTo review is there
  const ReviewProductLink = useSelector((state) => state.rootReducer?.ReviewingProductLink);
  useEffect(() => {
    if (ReviewProductLink && ReviewProductLink != "") {
      console.log("ReviewProductLink ", ReviewProductLink);
      navigate(ReviewProductLink);
    }
  }, [ReviewProductLink]);
  // redirectTo review is there
  const HomePageData = GetSinglePagesDataFromSlug("home");
  const [loadingMain, setLoadingMain] = useState(false);
  const [homeCenterBlog, setHomeCenterBlog] = useState([]);
  const [homeListBlog, setHomeListBlog] = useState([]);
  // console.log("HomePageData", HomePageData);
  useEffect(() => {
    axios
      .get(BlogBaseUrl + `${"wp-json/custom/v1/posts-by-tag?tag=Home Center"}`)
      .then((response) => {
        setHomeCenterBlog(response.data.data);
        setLoadingMain(false);
      })
      .catch((error) => {
        setLoadingMain(false);
      });
    setLoadingMain(true);
    axios
      .get(BlogBaseUrl + `${"wp-json/custom/v1/posts-by-tag?tag=Home Listing"}`)
      .then((response) => {
        setHomeListBlog(response.data.data);
        setLoadingMain(false);
      })
      .catch((error) => {
        setLoadingMain(false);
      });
    setLoadingMain(true);
  }, []);

  return (
    <WithMetaByData data={HomePageData?.page_meta}>
      <div className="Home pt-4">
        <WithBannersLayout
          leftBanners={HomePageData?.banners?.filter((item) => item.banner_position == "3")}
          rightBanners={HomePageData?.banners?.filter((item) => item.banner_position == "4")}
        >
          {/* {GetMessage("test")}
      {GetMessage("cat_mn_nm")} */}
          <Homebanner HomePageData={HomePageData} />
          <Homesaving HomePageData={HomePageData} />
          <Testimonial HomePageData={HomePageData} />
          {/* <Articles /> */}
          {loadingMain ? (
            <div className="container">
              <div className="skeleton-text" style={{ height: "330px" }} />
            </div>
          ) : (
            <>
              <BlogLayoutForHome title="Popular Blogs" Centerblogs={homeCenterBlog} homeListBlog={homeListBlog} />
            </>
          )}
          <Rateproviders />
          <div style={{ padding: "20px 0 45px" }}>
            <Addposters HomePageData={HomePageData} />
          </div>
          <Whyhome />
          <Converter />
          {/* <CompareProducts /> */}
          <CompareProductWidget />
          <Clientreview />
        </WithBannersLayout>
      </div>
    </WithMetaByData>
  );
};

export default Home;
