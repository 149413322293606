import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import star from "../../assets/images/star.svg";
import starEmpty from "../../assets/images/starEmpty.svg";
import { setApplyFormOn, setApplyProductLink, setCompareItems, setReviewingProductLink } from "../../stores/rootReducer";
import GetMessage from "../../utils/GetMessage";
import sendNotification from "../../utils/SendNotification";
import ReviewModal from "../Modals/ReviewModal";
import RatingStars from "../RatingStars/RatingStars";

const Productsuggetion = ({ product, setIsApplyForm }) => {
  const navigate = useNavigate();
  const { parentCatName, categoryName, subcategoryName } = useParams();
  const [menuOpen2, setMenuOpen2] = useState(false);
  const compareItems = useSelector((state) => state.rootReducer?.CompareItems);
  const dispatch = useDispatch();
  function scrollToElement(id) {
    var element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }
  const error_compare_no_more_itmes = GetMessage("error_compare_no_more_itmes");
  const error_compare_diffrent_category = GetMessage("error_compare_diffrent_category");
  const handleAddToCompareClick = (product) => {
    if (compareItems.some((item) => item.id == product.id)) {
      // product already in compare list (remove it.)
      dispatch(setCompareItems(compareItems.filter((item) => item.id != product.id)));
    } else if (compareItems?.length > 0 && !compareItems.some((item) => item.topCatName == parentCatName)) {
      // sendNotification("error", "product category does not match with compare items.");
      sendNotification("error", error_compare_no_more_itmes);
      // alert("product category does not match with compare items.");
    } else if (compareItems.length == 3) {
      // sendNotification("error", "max 3 items can be added to compare");
      sendNotification("error", error_compare_diffrent_category);
      // alert("max 3 items can be added to compare");
    } else {
      // product is new (append it)
      // console.log("handleAddToCompareClick", compareItems);
      const applyBtn = () => (
        <a href={product?.applynow_button_url} target="_blank" className="action-btn white-btn small-btn">
          {product?.applynow_button}
        </a>
      );
      const comparePayload = {
        id: product.id,
        name: product.name,
        product_image_url: product.product_image_url,
        // topCatId: topCatId,
        topCatName: parentCatName,
        applyBtn: applyBtn,
        productLink: window.location?.pathname,
      };
      dispatch(setCompareItems([...compareItems, comparePayload]));
    }
  };
  const handleApplyClick = (e) => {
    e.preventDefault();
    // const UserId = sessionStorage.getItem("User") && JSON.parse(sessionStorage.getItem("User"))?.id;
    // if (UserId) {
    setIsApplyForm(true);
    scrollToElement("ApplyForm");
    // } else {
    //   dispatch(setApplyProductLink(window.location?.pathname));
    //   navigate("/SignIn");
    // }
  };
  const handleStarClick = () => {
    const UserId = sessionStorage.getItem("User") && JSON.parse(sessionStorage.getItem("User"))?.id;
    if (!UserId) {
      dispatch(setReviewingProductLink(window.location?.pathname));
      navigate("/SignIn");
    } else {
      const myHiddenModelTriggerElement = document.querySelector('[data-bs-target="#staticBackdrop"]');
      myHiddenModelTriggerElement.click();
    }
  };
  return (
    <section className="pro-suggetion-wrp">
      <div data-bs-toggle="modal" data-bs-target="#staticBackdrop" />
      <div className="first-row">
        <div className="container">
          {product ? (
            <div className="col-md-12">
              <div className="leftm" style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                <h3>{product?.name}</h3>
                {product?.reviewscore != "review_disable" && (
                  <div className="star-div" style={{ marginLeft: "10px" }} type="button" onClick={() => handleStarClick()}>
                    <RatingStars averageRating={product?.reviewscore || 0} />
                    {product?.reviewscore && <div>({parseFloat(Number(product?.reviewscore).toFixed(1)) || 0})</div>}
                  </div>
                )}
                <ReviewModal productId={product?.id} />
              </div>
            </div>
          ) : (
            <div className="d-flex">
              <div className="skeleton-text me-4" style={{ height: "50px" }} />
              <div className="skeleton-text" style={{ height: "50px" }} />
            </div>
          )}
        </div>
      </div>
      <div className="second-row">
        <div className="container">
          <div className="rightm">
            {/* <div className="star-div">
              <img src={starEmpty} alt="" title="" />
              <img src={starEmpty} alt="" title="" />
              <img src={starEmpty} alt="" title="" />
              <img src={starEmpty} alt="" title="" />
              <img src={starEmpty} alt="" title="" />
              <div>(00)</div>
            </div> */}
            <div className={`comp-menu ${menuOpen2 ? "slide-in" : "slide-out"}`} style={{ display: menuOpen2 ? "block" : "none" }}>
              <ul>
                {product?.detail_is_visible && (
                  // <li className="active">
                  <li className="" style={{ cursor: "pointer" }} onClick={() => scrollToElement(product?.detail_title)}>
                    <a>{product?.detail_title}</a>
                  </li>
                )}
                {product?.eligibility_is_visible && (
                  <li className="" style={{ cursor: "pointer" }} onClick={() => scrollToElement(product?.eligibility_title)}>
                    <a>{product?.eligibility_title}</a>
                  </li>
                )}
                {product?.fees_and_charges_is_visible && (
                  <li className="" style={{ cursor: "pointer" }} onClick={() => scrollToElement(product?.fees_and_charges_title)}>
                    <a>{product?.fees_and_charges_title}</a>
                  </li>
                )}
                {product?.terms_and_conditions_is_visible && (
                  <li className="" style={{ cursor: "pointer" }} onClick={() => scrollToElement(product?.terms_and_conditions_title)}>
                    <a>{product?.terms_and_conditions_title}</a>
                  </li>
                )}
                {/* <li className="active">
                    <a href="#">Details</a>
                  </li>
                  <li>
                    <a href="#">Eligibility</a>
                  </li>
                  <li>
                    <a href="#">Fees & Charges</a>
                  </li>
                  <li>
                    <a href="#">T&C</a>
                  </li>
                  <li>
                    <a href="#">Reviews & Rating</a>
                  </li> */}
              </ul>
            </div>
            <div className="bothbtn" style={{ position: "relative" }}>
              <div id="menu-icon2" onClick={() => setMenuOpen2(!menuOpen2)} className={menuOpen2 ? "active" : ""}>
                <span className="first"></span>
                <span className="second"></span>
                <span className="third"></span>
              </div>
              {product?.applynow_button_url ? (
                <>
                  {product?.applynow_button && (
                    <a href={product?.applynow_button_url} target="_blank" className="action-btn white-btn small-btn">
                      {product?.applynow_button}
                    </a>
                  )}
                </>
              ) : (
                <>
                  {product?.applynow_button && (
                    <a onClick={(e) => handleApplyClick(e)} target="_blank" className="action-btn white-btn small-btn" style={{ cursor: "pointer" }}>
                      {product?.applynow_button}
                    </a>
                  )}
                </>
              )}
              {product?.category?.is_compare && (
                <div className="compare-btn">
                  <label>
                    <input
                      type="checkbox"
                      onChange={() => handleAddToCompareClick(product)}
                      checked={compareItems?.some((item) => item.id == product?.id)}
                    />
                    <span className="checkmark"></span>Add to Compare
                  </label>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Productsuggetion;
