import React from "react";
import { Link } from "react-router-dom";
import cardm from "../../assets/images/pro-card.png";
import cardb1 from "../../assets/images/card-ban1.jpg";
import close from "../../assets/images/close.svg";
import apply from "../../assets/images/apply-now.svg";
import { useDispatch, useSelector } from "react-redux";
import { setCompareItems } from "../../stores/rootReducer";
import Product from "../product/Product";

const Comparecards = () => {
  const compareItems = useSelector((state) => state.rootReducer?.CompareItems);
  const dispatch = useDispatch();
  const handleRemoveToCompareClick = (productId) => {
    if (compareItems.some((item) => item.id == productId)) {
      // product already in compare list (remove it.)
      dispatch(setCompareItems(compareItems.filter((item) => item.id != productId)));
    }
  };
  return (
    <section className="comparePageCard compare-page-banner">
      <div className="">
        <div className="row">
          <div className="col-md-12">
            <div className="comp-in">
              <div className="cleft">
                <h2 className="mt-1">Compare {compareItems[0]?.topCatName}</h2>
                <p className="big-p">{compareItems?.length} Items</p>
              </div>
              <div className="cright">
                <ul className="saving-list">
                  {compareItems.map((item) => (
                    <li style={{ display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "spaceBetween" }}>
                      {compareItems?.length > 2 && (
                        <div className="close" onClick={() => handleRemoveToCompareClick(item?.id)} style={{ cursor: "pointer" }}>
                          <a>
                            <img src={close} alt="" title="" />
                          </a>
                        </div>
                      )}
                      <div className="pro-sav-img">
                        {/* <img src={cardb1} alt="" /> */}
                        <img src={item.product_image_url} alt={item.name} title={item.name} className="card-pro" />
                      </div>
                      <div
                        className="pro-sav-cont"
                        style={{ display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "spaceBetween" }}
                      >
                        {item?.productLink ? (
                          <Link to={item?.productLink}>
                            <p>{item.name}</p>
                          </Link>
                        ) : (
                          <p>{item.name}</p>
                        )}

                        {/* <ul className="square-list">
                          {popular.features.map((feature) => (
                            <li>{feature}</li>
                          ))}
                        </ul> */}
                        {item?.applyBtn()}
                        {/* <a href="#" className="action-btn small-btn apply-now">
                          Apply Now <img src={apply} alt="" />
                        </a> */}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Comparecards;
