import React, { useState } from "react";
import Accordion from "../Accordion/Accordion";
import AccordionItem from "../Accordion/AccordionItem";
import RangeInput from "../RangeInput";

const FilterWithRange = ({ min, max, id, value, name, title, handleChange }) => {
  const [NewValue, setNewValue] = useState(max);
  return (
    <div className="category-div">
      <div className="slidecontainer">
        <Accordion>
          <AccordionItem title={title}>
            <RangeInput
              min={min}
              max={max}
              value={NewValue}
              name={name}
              id={id}
              // class={slider}
              onChange={(e) => {
                setNewValue(e.target.value);
              }}
              mouseup={handleChange}
            />
            <div className="min-max-valu">
              <span>{min}</span>
              <span>{max}</span>
            </div>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
};

export default FilterWithRange;
