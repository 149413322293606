import React, { useEffect } from "react";
import LogoImage from "../assets/images/logo.png";
// import newLogoFindKaizenCroped from "../assets/images/newLogoFindKaizenCroped.svg";
// import newLogoFindKaizenCropedWOSlogan from "../assets/images/newLogoFindKaizen.png";
import newFKCROPLOGO from "../assets/images/newFKCROPLOGO.svg";
import MenuImg from "../assets/images/menu1.svg";
import MenuBan from "../assets/images/menu-ban.jpg";
import Addimage from "../assets/images/add.png";
import Search from "../assets/images/search-icn.svg";
import Card from "../assets/images/card1.jpg";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { GetAllHeaderData, GetHeaderData, GetHeaderTickerData, Graphql } from "../query/Graphql";
import RenderMenuListItem from "./RenderMenuListItem";
import { Link, useNavigate } from "react-router-dom";
import { GetHeaderBanners } from "../utils/GetBanners";
import Html from "../utils/Html";
import RenderBannerByLayout from "./RenderBannerByLayout";
import { setMainHeaderCatData } from "../stores/rootReducer";
import { useDispatch } from "react-redux";
import AccountIcon from "../assets/icons/AccountIcon";
import SettingIcon from "../assets/icons/SettingIcon";
import LogInIcon from "../assets/icons/LogInIcon";
import LogOutIcon from "../assets/icons/LogOutIcon";
import UserNameIcon from "../assets/icons/UserNameIcon";
import sendNotification from "../utils/SendNotification";
import { googleLogout } from "@react-oauth/google";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const HeaderSmallBannerArray = GetHeaderBanners("home");
  const HeaderSmallBanner = HeaderSmallBannerArray && HeaderSmallBannerArray.length > 0 && HeaderSmallBannerArray[0];
  const [menuOpen, setMenuOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [BannerImages, setBannerImages] = useState();
  const [BannerScript, setBannerScript] = useState();
  const [UserData, setUserData] = useState(sessionStorage.getItem("User") && JSON.parse(sessionStorage.getItem("User")));
  useEffect(() => {
    if (sessionStorage.getItem("User")) {
      setUserData(JSON.parse(sessionStorage.getItem("User")));
    } else {
      setUserData();
    }
  }, [window.location?.pathname]);

  useEffect(() => {
    if (HeaderSmallBanner) {
      if (HeaderSmallBanner?.banner_upload_type == 0) {
        // it is aray of image
        setBannerImages(HeaderSmallBanner?.banner_image);
      } else if (HeaderSmallBanner?.banner_upload_type == 1) {
        // it is script
        setBannerScript(HeaderSmallBanner?.banner_script);
      } else {
        // it is html
        setBannerScript(HeaderSmallBanner?.banner_script);
      }
    }
  }, [HeaderSmallBanner]);

  const HeaderMainMenuCatData = GetAllHeaderData();
  const HeaderTickerData = GetHeaderTickerData();
  useEffect(() => {
    dispatch(setMainHeaderCatData(HeaderMainMenuCatData));
  }, [HeaderMainMenuCatData]);

  const handleMenuClick = () => {
    setMenuOpen((prevMenuOpen) => !prevMenuOpen);
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const renderTicker = (ticker) => {
    if (ticker?.url) {
      return (
        <div className="track">
          <Link to={ticker?.url}>
            <p>
              <span>{ticker.ticker_message}</span>
            </p>
          </Link>
        </div>
      );
    }
    return (
      <div className="track">
        <p>
          <span>{ticker.ticker_message}</span>
          {/* <span className="blu-txt">Gold (24k) :</span> AED 2,794.65{" "}
              <span className="green-txt">+0.24%</span> */}
        </p>
      </div>
    );
  };
  const renderMarquee = () => {
    // console.log("HeaderTickerData", HeaderTickerData);
    if (!HeaderTickerData) {
      return (
        <div className="marquee-loop-track">
          {Array.from({ length: 10 }).map((_, index) => (
            <div className="track">
              <p>
                <div className="skeleton-text" style={{ width: "100px" }}></div>
              </p>
            </div>
          ))}
        </div>
      );
    }
    return <div className="marquee-loop-track">{HeaderTickerData.map((ticker) => renderTicker(ticker))}</div>;
  };
  const renderAllMenuList = () => {
    if (!HeaderMainMenuCatData) {
      return (
        <ul className="mega-col">
          {Array.from({ length: 10 }).map((_, index) => (
            <li>
              <a href="#" className="w-100">
                <div className="skeleton-container">
                  {/* <div className="skeleton-avatar"></div> */}
                  {/* <div className="skeleton-text"></div> */}
                </div>
              </a>
            </li>
          ))}
        </ul>
      );
    }
    console.log("renderMenuListItemReturn", HeaderMainMenuCatData);
    return (
      <ul>
        <li>
          <Link to={"/"} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={() => handleMenuClick()}>
            Home
          </Link>
        </li>
        {HeaderMainMenuCatData && HeaderMainMenuCatData.map((item) => renderMenuListItemReturn(item))}
      </ul>
    );
  };
  const renderMenuListItemReturn = (item) => {
    if (item.other_category_display == "1") {
      return null;
    }
    if (item.contact_type == "crypto") {
      return (
        <li>
          <Link
            to={"/Cryptocurrency/Crypto Prices"}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleMenuClick()}
          >
            {item.catname}
          </Link>
        </li>
      );
    }
    if (item.contact_type == "blog") {
      return (
        <li>
          <Link to={"/Financial Literacy"} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={() => handleMenuClick()}>
            {item.catname}
          </Link>
        </li>
      );
    }

    return (
      <RenderMenuListItem item={item} handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave} handleMenuClick={handleMenuClick} />
    );
  };
  if (window.location.pathname == "/Redirect") {
    return null;
  }
  return (
    <div id="header">
      <div className="first-header">
        <div className="container">{renderMarquee()}</div>
      </div>
      {/* <div className="container" style={{ maxWidth: "1411px" }}> */}
      <div className="container">
        <div className="header-second">
          <div className="logo">
            <Link to={"/"}>
              {/* <img src={LogoImage} alt="logo" title="logo" /> */}
              <img src={newFKCROPLOGO} alt="find Kaizen" title="find Kaizen" />
              {/* <div style={{ fontSize: "10px", color: "black", width: "100%" }}>Empowering You to Make Informed Financial Decision</div> */}
              {/* <img src={newLogoFindKaizenCroped} alt="logo" title="logo" /> */}
            </Link>
          </div>
          <div className="advrtise">
            {/* <img src={Addimage} alt="" title="" /> */}
            {BannerScript ? (
              <div className="">
                <Html html={BannerScript} />
              </div>
            ) : (
              <RenderBannerByLayout itemsArray={BannerImages} />
            )}
          </div>
          <div className="search-dv">
            <form className="search" action="search.php">
              <input placeholder="Search for credit card, loans, insurance..." type="search"></input>
              <a className="src-icn">
                <img src={Search} alt="" title="" />
              </a>
            </form>
          </div>

          <div
            id="myAccountIconWrapper"
            className="text-center cursor-pointer"
            data-bs-toggle={UserData?.id && "dropdown"}
            aria-expanded="false"
            data-bs-offset="-10,10"
            onClick={() => !UserData?.id && navigate("/SignIn")}
          >
            <AccountIcon />
            {UserData ? (
              <div
                className="dropdown-toggle "
                style={{
                  fontSize: "15px",
                  letterSpacing: "0.3px",
                  fontFamily: "GilroySemiBold",
                  color: "#363636",
                }}
              >
                {UserData?.first_name || UserData?.last_name || "My account"}
              </div>
            ) : (
              <div
                className=""
                style={{
                  fontSize: "15px",
                  letterSpacing: "0.3px",
                  fontFamily: "GilroySemiBold",
                  color: "#363636",
                }}
              >
                Login
              </div>
            )}
          </div>
          <ul id="myAccountIconWrapperUL" className="dropdown-menu dropdown-menu-end" style={!UserData?.id ? { display: "none" } : {}}>
            {UserData?.id ? (
              <>
                <li>
                  <Link
                    to={"/UserDetails"}
                    className="dropdown-item d-flex align-items-center gap-1"
                    style={{
                      fontSize: "15px",
                      letterSpacing: "0.3px",
                      fontFamily: "GilroySemiBold",
                      color: "#363636",
                    }}
                    type="button"
                  >
                    <UserNameIcon /> Profile
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <Link
                    to={"/ChangePassword"}
                    className="dropdown-item d-flex align-items-center gap-1"
                    style={{
                      fontSize: "15px",
                      letterSpacing: "0.3px",
                      fontFamily: "GilroySemiBold",
                      color: "#363636",
                    }}
                    type="button"
                  >
                    <SettingIcon /> Change password
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li>
                  <button
                    className="dropdown-item d-flex align-items-center gap-1"
                    style={{
                      fontSize: "15px",
                      letterSpacing: "0.3px",
                      fontFamily: "GilroySemiBold",
                      color: "#363636",
                    }}
                    type="button"
                    onClick={() => {
                      sessionStorage.removeItem("User");
                      setUserData();
                      googleLogout();
                      sendNotification("success", "Logged out successfully.");
                      window.location.pathname == "/ChangePassword" && navigate("/");
                      window.location.pathname == "/UserDetails" && navigate("/");
                    }}
                  >
                    <LogOutIcon /> Log Out
                  </button>
                </li>
              </>
            ) : (
              <li>
                <hr className="dropdown-divider" />
              </li>
            )}
          </ul>

          <div id="menu-icon" onClick={handleMenuClick} className={menuOpen ? "active" : ""}>
            <span className="first"></span>
            <span className="second"></span>
            <span className="third"></span>
          </div>
        </div>
        <div className={`header-three ${isHovered ? "active" : ""}`}>
          <nav style={{ display: menuOpen ? "block" : "none" }} className={menuOpen ? "slide-in" : "slide-out"}>
            {/* {renderMenuList()} */}
            {renderAllMenuList()}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Header;
