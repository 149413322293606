import React from "react";

const ProductSkeleton = () => {
  return (
    <div className="single-product">
      <div className="pro-name-bth">
        <div className="left">
          <div className="skeleton-text" style={{ width: "100px" }}></div>
          <a href="/Product/Credit%20Cards/Etihad Guest Visa Elevate/2">
            <div className="skeleton-text" style={{ height: "32px", width: "250px", marginTop: "10px" }}></div>
          </a>
        </div>
        <div className="right">
          <div className="skeleton-text" style={{ height: "32px", width: "150px" }}></div>
          <div className="skeleton-text" style={{ height: "32px", width: "150px", marginTop: "10px" }}></div>
        </div>
      </div>
      <div className="card-cat-div">
        <div className="left">
          <div className="skeleton-text" style={{ height: "110px", width: "100%", marginTop: "10px" }}></div>
        </div>
        <div className="right pb-4">
          <div className="skeleton-text" style={{ height: "50px", width: "100%", marginTop: "10px" }}></div>
          <div className="skeleton-text" style={{ height: "50px", width: "100%", marginTop: "10px" }}></div>
        </div>
      </div>
    </div>
  );
};

export default ProductSkeleton;
