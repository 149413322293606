import React from "react";
import { BaseUrl } from "../..";
import Accordion from "../Accordion/Accordion";
import AccordionItem from "../Accordion/AccordionItem";

const FilterWithChecklist = ({ title, handleChange, CategoryFilters, checkList }) => {
  console.log("checkList", checkList);
  return (
    <div className="category-div">
      <div className="drop-down-wrp">
        <Accordion>
          <AccordionItem title={title}>
            <div className="sidebar-list">
              <ul>
                {checkList?.map((item) => (
                  <>
                    <li className={`add-comp ${item?.image && "withImage"}`}>
                      <label style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                        <input
                          type="checkbox"
                          name={item.filterId}
                          id={item.qty}
                          value={item.attrType}
                          onChange={handleChange}
                          checked={CategoryFilters?.includes(item.id)}
                        />
                        <div>{item.name}</div>
                        {item?.image && (
                          <div className="chk-img">
                            <img src={`${BaseUrl}control/public/storage/images/attrvalue/` + item?.image} alt="" />
                          </div>
                        )}
                      </label>
                    </li>
                  </>
                ))}
              </ul>
            </div>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
};

export default FilterWithChecklist;
