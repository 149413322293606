import React from "react";
import vect from "../../assets/images/9169253.png";
import "./NoResultFound.css";

const NoResultFound = ({ title, subTitle }) => {
  return (
    <div className="no-result-found-image">
      <img className="" src={vect} />
      <div className="">{title}</div>
      <p className="">{subTitle}</p>
    </div>
  );
};

export default NoResultFound;
