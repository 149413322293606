import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GetHeaderData } from "../query/Graphql";
import { Dropdown } from "bootstrap";

const RenderFooterCol = ({ item }) => {
  // const subCatData = GetHeaderData(item.id);
  const subCatData = item?.subcategories;
  const [SubCatList, setSubCatList] = useState();
  useEffect(() => {
    if (subCatData) {
      setSubCatList(subCatData?.slice(0, 3));
    }
    // return setSubCatList([]);
  }, [subCatData]);
  console.log("item.contact_type", item.contact_type);
  if (item.contact_type == "crypto") {
    return (
      <div className="s-col">
        <h5>{item.catname}</h5>
        <ul>
          {SubCatList &&
            SubCatList.map((data) => (
              <li>
                <Link to={`/${item.catname}/${data.catname}`}>{data.catname}</Link>
              </li>
            ))}
        </ul>
        {subCatData?.length > 3 && (
          <>
            {SubCatList?.length == 3 ? (
              <span className="viewmore" style={{ cursor: "pointer" }} onClick={() => setSubCatList(subCatData)}>
                View more
              </span>
            ) : (
              <span className="viewless" style={{ cursor: "pointer" }} onClick={() => setSubCatList(subCatData?.slice(0, 3))}>
                View less
              </span>
            )}
          </>
        )}
      </div>
    );
  }
  if (item.contact_type == "blog") {
    return (
      <div className="s-col">
        <Link to={`/${item.catname}`}>
          <h5>{item.catname}</h5>
        </Link>
      </div>
    );
  }

  return (
    <div className="s-col">
      <Link to={`/productList/${item.catname}`}>
        <h5>{item.catname}</h5>
      </Link>
      <ul>
        {SubCatList &&
          SubCatList.map((data) => (
            <li>
              <Link to={`/productList/${item.catname}/${data.catname}`}>{data.catname}</Link>
            </li>
          ))}
      </ul>
      {subCatData?.length > 3 && (
        <>
          {SubCatList?.length == 3 ? (
            <span className="viewmore" style={{ cursor: "pointer" }} onClick={() => setSubCatList(subCatData)}>
              View more
            </span>
          ) : (
            <span className="viewless" style={{ cursor: "pointer" }} onClick={() => setSubCatList(subCatData?.slice(0, 3))}>
              View less
            </span>
          )}
        </>
      )}
    </div>
  );
};

export default RenderFooterCol;
