import React from "react";
import "./CompanyDirectoryProducts.css";
import proadd from "../../../assets/images/pro-add.jpg";
import CompareProducts from "../../../components/CompareProducts";
import links from "../../../assets/images/link.png";
import Html from "../../../utils/Html";
import { GetFAQDataForProduct } from "../../../query/Graphql";
import BottomBannerSlider from "../../../utils/BannerLayout/BottomBannerSlider";
import { Link } from "react-router-dom";
import CompareCompanyProductWidget from "../../Widgets/CompareCompanyProductWidget";

const CompanyDirectoryProducts = ({ companyData }) => {
  const FAQData = companyData?.companyfaq;
  return (
    <div className="CompanyDirectoryProducts">
      <section className="product-details-wrp">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="pro-detl-cont-wrp pb-5">
                <h3 className="f-30">Gallery</h3>
                {companyData ? (
                  <BottomBannerSlider bottomBanner={companyData?.companybannergallery} />
                ) : (
                  <div className="skeleton-container" style={{ minHeight: "200px" }}></div>
                )}
              </div>
              {/* commanted static compare widget */}
              {/* <CompareProducts /> */}
              {companyData?.id && <CompareCompanyProductWidget companyId={companyData?.id} />}
              <div className="per-add wth-border">
                <BottomBannerSlider bottomBanner={companyData?.bannergallery?.filter((item) => item.banner_position == "1")} />
              </div>
              <div className="pro-detl-cont-wrp">
                <h3 className="f-30">Details</h3>
                {companyData ? (
                  <div className="pro-detail-cnt-dv">
                    <Html html={companyData?.footer?.description} />
                  </div>
                ) : (
                  <div className="skeleton-container" style={{ minHeight: "200px" }}></div>
                )}
              </div>
              {/* <div className="per-add">
                <img src={proadd} alt="" title="" />
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <section className="useful-links">
        <div className="container">
          <div className="useful-link-inr">
            <div className="row">
              <div className="col-md-12">
                <h3>Useful Links</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <ul>
                  {companyData ? (
                    companyData?.company_user_link.map((link) => (
                      <li>
                        {link.link ? (
                          <a href={link.link} target="_blank">
                            <img src={link.icon_url} alt={link.name} />
                            {link.name}
                          </a>
                        ) : (
                          <Link>
                            <img src={link.icon_url} alt={link.name} />
                            {link.name}
                          </Link>
                        )}
                      </li>
                    ))
                  ) : (
                    <>
                      <li>
                        <div className="skeleton-container" style={{ minHeight: "50px" }}></div>
                      </li>
                      <li>
                        <div className="skeleton-container" style={{ minHeight: "50px" }}></div>
                      </li>
                      <li>
                        <div className="skeleton-container" style={{ minHeight: "50px" }}></div>
                      </li>
                      <li>
                        <div className="skeleton-container" style={{ minHeight: "50px" }}></div>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {FAQData?.length > 0 && (
        <section className="CompareProducts-wrp pro-list-acodian-tab">
          <div className="container">
            <div className="row">
              <div className="container">
                <div className="col-md-12">
                  <h3>FAQs</h3>
                </div>
              </div>
            </div>
            <div className="CompareProductsInner compare-page-banner">
              <div className="accordion" id="accordionExample">
                {FAQData.map((FAQ, index) => (
                  <div className="accordion-item">
                    <h2 className="accordion-header" id={FAQ.question}>
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#${FAQ.id}`}
                        aria-expanded="false"
                        aria-controls={FAQ.id}
                      >
                        {FAQ.question}
                      </button>
                    </h2>
                    <div id={FAQ.id} className="accordion-collapse collapse" aria-labelledby={FAQ.question} data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <Html html={FAQ.answer} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default CompanyDirectoryProducts;
