import React, { useState } from "react";
import starEmpty from "../../assets/images/starEmpty.svg";
import star from "../../assets/images/star.svg";

const StarRatingPicker = ({ rating, totalStars, onStarClick }) => {
  const [selectedStars, setSelectedStars] = useState(5);

  const handleStarClick = (star) => {
    setSelectedStars(star);
    onStarClick(star);
  };

  return (
    <div className=" align-items-center gap-1 text-center">
      {[...Array(totalStars)].map((_, index) => (
        <Star key={index} filled={index < selectedStars} onClick={() => handleStarClick(index + 1)} />
      ))}{" "}
      {/* {rating} */}
    </div>
  );
};

const Star = ({ filled, onClick }) => {
  return (
    <span style={{ cursor: "pointer", color: filled ? "gold" : "gray" }} onClick={onClick}>
      {/* ★ */}
      {filled ? <img className="" src={star} alt="" title="" /> : <img className="" src={starEmpty} alt="" title="" />}
    </span>
  );
};

export default StarRatingPicker;
