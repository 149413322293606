import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  GetIdFromUrlKnowledgeNugget,
  GetKnowledgeNuggetsWithID,
} from "../../query/Graphql";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Html from "../../utils/Html";
import "./KnowledgeNuggetDetails.css";
import RenderKnowledgeNuggetDetails from "./RenderKnowledgeNuggetDetails";

const KnowledgeNuggetDetails = () => {
  const location = useLocation();
  const { nuggetName } = useParams();
  const NuggetsIdFromUrl =
    nuggetName && GetIdFromUrlKnowledgeNugget(nuggetName);
  const [CurrentId, setCurrentId] = useState();
  useEffect(() => {
    setCurrentId();
  }, [location.pathname]);
  useEffect(() => {
    if (NuggetsIdFromUrl?.length > 0) {
      setCurrentId(NuggetsIdFromUrl[NuggetsIdFromUrl.length - 1].id);
    }
  }, [NuggetsIdFromUrl]);
  // const NuggetsId =
  //   useSelector((state) => state.rootReducer.currentNuggetId) ||
  //   localStorage.getItem("CurrentNuggetId");

  // console.log("KnowledgeNuggetsWithID", Nugget);

  return (
    <div className="KnowledgeNuggetDetail">
      <Breadcrumb />
      {CurrentId ? (
        <RenderKnowledgeNuggetDetails CurrentId={CurrentId} />
      ) : (
        <div className="container">
          {Array.from({ length: 5 }).map((_, index) => (
            <div
              className="skeleton-text"
              style={{ height: "130px", marginTop: "30px" }}
            ></div>
          ))}
        </div>
      )}
    </div>
  );
};

export default KnowledgeNuggetDetails;
