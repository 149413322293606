import { gql } from "@apollo/client";

export const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      success
      message
      loginuser {
        id
        first_name
        last_name
        email
        country_code
        mobileno
        password
        social_id
        social_type
      }
    }
  }
`;
export const RESET_PASS_MUTATION = gql`
  mutation requestpasswordreset($email: String!) {
    requestpasswordreset(email: $email) {
      success
      message
    }
  }
`;
export const REGISTER_MUTATION = gql`
  mutation register(
    $first_name: String
    $last_name: String
    $email: String
    $password: String
    $country_code: String
    $mobileno: String
    $social_type: String
    $social_id: String
  ) {
    register(
      first_name: $first_name
      last_name: $last_name
      email: $email
      password: $password
      country_code: $country_code
      mobileno: $mobileno
      social_type: $social_type
      social_id: $social_id
    ) {
      success
      message
      regcustomer {
        id
        first_name
        last_name
        email
        country_code
        mobileno
        password
        social_id
        social_type
      }
    }
  }
`;
export const EDIT_USER_MUTATION = gql`
  mutation editcustomer($customer_id: ID!, $first_name: String, $last_name: String, $email: String, $country_code: String, $mobileno: String) {
    editcustomer(
      customer_id: $customer_id
      first_name: $first_name
      last_name: $last_name
      email: $email

      country_code: $country_code
      mobileno: $mobileno
    ) {
      success
      message
      editcustomer {
        id
        first_name
        last_name
        email
        country_code
        mobileno
        password
        social_id
        social_type
      }
    }
  }
`;
export const RESET_PASSWORD_MUTATION = gql`
  mutation resetPassword($newPassword: String!, $token: String!) {
    resetPassword(newPassword: $newPassword, token: $token) {
      success
      message
    }
  }
`;
export const CHANGE_PASSWORD_MUTATION = gql`
  mutation changePassword($userid: ID!, $newPassword: String!, $oldPassword: String!) {
    changePassword(userid: $userid, newPassword: $newPassword, oldPassword: $oldPassword) {
      success
      message
      changepassword {
        id
        first_name
        last_name
        email
        country_code
        mobileno
        password
        social_id
        social_type
      }
    }
  }
`;
export const VERIFY_EMAIL_MUTATION = gql`
  mutation verifycustomer($token: String!) {
    verifycustomer(token: $token) {
      success
      message
      loginuser {
        id
        first_name
        last_name
        email
        country_code
        mobileno
        password
        social_id
        social_type
      }
    }
  }
`;
