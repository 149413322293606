import { useQuery } from "@apollo/client";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import CryptoChartMain from "../../../components/CryptoChart/CryptoChartMain";
import NoResultFound from "../../../components/NoResultFound/NoResultFound";
import TableWithPagination from "../../../components/TableWithPagination/TableWithPagination";
// import { GetCryptoDataFromCode } from "../../../query/Graphql";
import { GET_CRYPTO_DETAILS } from "../../../query/query";
import BottomBannerSlider from "../../../utils/BannerLayout/BottomBannerSlider";
import TopBannerSlider from "../../../utils/BannerLayout/TopBannerSlider";
import Html from "../../../utils/Html";
import WithBannersLayout from "../../../utils/WithBannersLayout/WithBannersLayout";
import WithMetaByData from "../../../utils/WithMetaByData";
import OwlCarousel from "react-owl-carousel";

const CryptoDetailPage = () => {
  const { selectedCrypto, currencyName, uuid } = useParams();
  const [DetailsData, setDetailsData] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.coinranking.com/v2/coin/${uuid}`, {
          params: {
            timePeriod: "24h",
          },
          headers: {
            "x-access-token": "coinrankingbbe0f960355261766eba2b4af92d608ee14c35d721afdee7",
          },
        });
        setDetailsData(response?.data?.data?.coin);
      } catch (error) {
        setDetailsData(null);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [uuid]);
  const { loading, error, data } = useQuery(GET_CRYPTO_DETAILS, {
    variables: { code: selectedCrypto },
  });
  // const CryptoDetails = GetCryptoDataFromCode(selectedCrypto);
  const CryptoDetails = data?.singlecrypto?.singlecrypto;
  // console.log("CryptoDetails", CryptoDetails, DetailsData);

  function formatStringToBillionOrMillion(numberString) {
    if (!numberString || isNaN(numberString)) {
      return "";
    }

    const number = parseInt(numberString);

    if (number >= 1000000000) {
      return (number / 1000000000).toFixed(1) + "B";
    } else if (number >= 1000000) {
      return (number / 1000000).toFixed(1) + "M";
    } else {
      return number.toString();
    }
  }
  function checkNegative(value) {
    var numericValue = parseInt(value);
    if (numericValue < 0) {
      return true;
    } else {
      return false;
    }
  }
  const owlCarouselOptions = {
    loop: true,
    dots: false,
    items: 7,
    autoplay: false,
    nav: true,
    margin: 20,
    responsive: {
      0: {
        items: 2,
        margin: 10,
      },
      480: {
        items: 4,
      },
      768: {
        items: 5,
      },
      1024: {
        items: 6,
      },
      1440: {
        items: 7,
      },
    },
    // responsiveBaseElement: ".CryptoPricesListWrapper",
  };
  const dataForFeatures = [
    {
      name: "Market Cap",
      value: "$" + formatStringToBillionOrMillion(DetailsData?.marketCap),
    },
    {
      name: "Volume(24H)",
      value: "$" + formatStringToBillionOrMillion(DetailsData && DetailsData["24hVolume"]),
    },
    {
      name: "price",
      value: "$" + formatStringToBillionOrMillion(DetailsData?.price),
    },
    {
      name: "Circulating Supply",
      value: formatStringToBillionOrMillion(DetailsData?.supply?.circulating),
    },
    {
      name: "Popularity",
      value: DetailsData?.rank,
    },
    {
      name: "All time high",
      value: "$" + formatStringToBillionOrMillion(DetailsData?.allTimeHigh?.price),
    },
    {
      name: "Price change (24H)",
      value: DetailsData?.change,
    },
  ];
  return (
    <>
      <Breadcrumb />
      <div className="my-4">
        <WithBannersLayout
          leftBanners={CryptoDetails?.banner?.filter((item) => item.banner_position == "3")}
          rightBanners={CryptoDetails?.banner?.filter((item) => item.banner_position == "4")}
        >
          <WithMetaByData data={CryptoDetails}>
            <section className="CryptoPricesList">
              <div className="CryptoPricesListWrapper container">
                <TopBannerSlider topBanners={CryptoDetails?.banner?.filter((item) => item.banner_position == "0")} />
                <div className="CryptoPricesList-row one">
                  <div className="title big-p text-black font-bold d-flex justify-content-between align-items-center">
                    <div className="titleWrapperCrypto">
                      {DetailsData && (
                        <div>
                          <img
                            src={DetailsData.iconUrl}
                            alt={DetailsData.name}
                            title={DetailsData.name}
                            style={{
                              height: "36px",
                              width: "auto",
                              marginRight: "10px",
                            }}
                          />
                        </div>
                      )}
                      <h5>
                        {currencyName}
                        <span className="CryptoSymbolOnHeader">{selectedCrypto}</span>
                      </h5>
                    </div>
                    <div className="search-dv">
                      <a href={CryptoDetails?.trade_button_link} target="_blank">
                        <button type="button" className="btn btn-primary cryptoTradeBtn">
                          Trade
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginBottom: "50px",
                    border: "1px solid #0000001b",
                    borderRadius: "6px",
                  }}
                >
                  <CryptoChartMain />
                </div>
                <div className="CryptoDivider"></div>
                <div className="py-4">
                  <h5>Market</h5>
                  <p>Market Stats</p>
                  <OwlCarousel className="rate-slider owl-carousel" {...owlCarouselOptions}>
                    {dataForFeatures.map(
                      (data) =>
                        data.value &&
                        data.value != "$" && (
                          <div className="marketFeature">
                            <div className="inner-sv">
                              <p className="font-16">{data.name}</p>
                              <h5 className="">{data.value}</h5>
                            </div>
                          </div>
                        )
                    )}
                  </OwlCarousel>
                </div>
                <div className="CryptoDivider"></div>
                {CryptoDetails?.detail ? (
                  <div className="py-4">
                    <Html html={CryptoDetails?.detail} />
                  </div>
                ) : CryptoDetails || CryptoDetails === null ? null : (
                  <div className="skeleton-text" style={{ height: "330px", marginBottom: "20px" }} />
                )}
                <BottomBannerSlider bottomBanner={CryptoDetails?.banner?.filter((item) => item.banner_position == "1")} />
                {CryptoDetails?.footer?.description ? (
                  <div className="py-4">
                    <Html html={CryptoDetails?.footer?.description} />
                  </div>
                ) : CryptoDetails || CryptoDetails === null ? null : (
                  <div className="skeleton-text" style={{ height: "330px", marginBottom: "20px" }} />
                )}
              </div>
            </section>
          </WithMetaByData>
        </WithBannersLayout>
      </div>
    </>
  );
};

export default CryptoDetailPage;
