import { useQuery } from "@apollo/client";
import React from "react";
import { GetSinglePagesDataFromSlug } from "../../query/Graphql";
import { GET_META_DATA } from "../../query/query";
import BottomBannerSlider from "../../utils/BannerLayout/BottomBannerSlider";
import TopBannerSlider from "../../utils/BannerLayout/TopBannerSlider";
import Html from "../../utils/Html";
import WithBannersLayout from "../../utils/WithBannersLayout/WithBannersLayout";
import WithMetaByData from "../../utils/WithMetaByData";
import CalculatorLayoutFromSlug from "./CalculatorLayoutFromSlug";
import personalIcon from "../../assets/images/personalIcon.png";
import { Link } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

const CalculatorsList = () => {
  const { loading, error, data } = useQuery(GET_META_DATA, {
    variables: { name: "calculator_list" },
  });
  // const pageData = GetSinglePagesDataFromSlug("calculator_list");
  return (
    <>
      <Breadcrumb />
      <div className="mt-3">
        <WithBannersLayout
          leftBanners={data?.singlepagedata?.singlepagedata?.banners?.filter((item) => item.banner_position == "3")}
          rightBanners={data?.singlepagedata?.singlepagedata?.banners?.filter((item) => item.banner_position == "4")}
        >
          <WithMetaByData data={data?.singlepagedata?.singlepagedata?.page_meta} />
          <div className="container calculator">
            <TopBannerSlider topBanners={data?.singlepagedata?.singlepagedata?.banners?.filter((item) => item.banner_position == "0")} />
            <div className="calculatorTitle title big-p text-black font-bold d-flex justify-content-between">
              <h5>{data?.singlepagedata?.singlepagedata?.cms?.length > 0 && data?.singlepagedata?.singlepagedata?.cms[0]?.title}</h5>
            </div>
            {loading ? (
              <>
                <div className="skeleton-text" style={{ height: "150px", marginBottom: "15px" }} />
                <div className="skeleton-text" style={{ height: "330px", marginBottom: "15px" }} />
              </>
            ) : (
              <>
                {data?.singlepagedata?.singlepagedata?.cms?.length > 0 && (
                  <div className="pb-4">
                    <Html html={data?.singlepagedata?.singlepagedata?.cms[0]?.description} />
                  </div>
                )}
                {/* <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", gap: "20px" }}>
                <div style={{ width: "48.5%", backgroundColor: "rgb(241, 241, 241)", padding: 8, borderRadius: 15 }}>
                  <div style={{ padding: "15px", background: "rgb(250, 250, 250)", borderRadius: "13px", height: "calc(100% - 76px)" }}>
                    <div style={{ textAlign: "center" }}>
                      <img
                        src="https://res.cloudinary.com/pricejugaad/image/upload/v1586498215/calculator-pages/car.png"
                        alt="Icon 4"
                        style={{ width: 100, height: 100 }}
                      />
                    </div>
                    <h3 style={{ marginTop: 10, color: "#333", paddingBottom: "10px" }}>Car Loan calculator</h3>
                    <p style={{ color: "#666" }}>
                      Car Loan Calculator helps you in getting a clear estimation of your payments on a car loan by taking the loan amount and
                      interest rates into consideration.
                    </p>
                  </div>
                  <div style={{ display: "flex", alignItems: "center", padding: "10px 10px", marginTop: "8px" }}>
                    <Link
                      to="/Calculators/car loan"
                      style={{
                        width: "100%",
                        backgroundColor: "#3498db",
                        color: "#fff",
                        padding: "8px 12px",
                        border: "none",
                        borderRadius: 4,
                        cursor: "pointer",
                        textAlign: "center",
                      }}
                    >
                      Calculate
                    </Link>
                  </div>
                </div>
                <div style={{ width: "48.5%", backgroundColor: "rgb(241, 241, 241)", padding: 8, borderRadius: 15 }}>
                  <div style={{ padding: "15px", background: "rgb(250, 250, 250)", borderRadius: "13px", height: "calc(100% - 76px)" }}>
                    <div style={{ textAlign: "center" }}>
                      <img
                        src="https://res.cloudinary.com/pricejugaad/image/upload/v1586498214/calculator-pages/personal.png"
                        alt="Icon 4"
                        style={{ width: 100, height: 100 }}
                      />
                    </div>
                    <h3 style={{ marginTop: 10, color: "#333", paddingBottom: "10px" }}>DBR Calculator calculator</h3>
                    <p style={{ color: "#666" }}>
                      Calculate your monthly installment amount on a DBR Calculator easily according to your specific rates using the EMI Calculator.
                    </p>
                  </div>
                  <div style={{ display: "flex", alignItems: "center", padding: "10px 10px", marginTop: "8px" }}>
                    <Link
                      to="/Calculators/DBR Calculator"
                      style={{
                        width: "100%",
                        backgroundColor: "#3498db",
                        color: "#fff",
                        padding: "8px 12px",
                        border: "none",
                        borderRadius: 4,
                        cursor: "pointer",
                        textAlign: "center",
                      }}
                    >
                      Calculate
                    </Link>
                  </div>
                </div>
                <div style={{ width: "48.5%", backgroundColor: "rgb(241, 241, 241)", padding: 8, borderRadius: 15 }}>
                  <div style={{ padding: "15px", background: "rgb(250, 250, 250)", borderRadius: "13px", height: "calc(100% - 76px)" }}>
                    <div style={{ textAlign: "center" }}>
                      <img
                        src="https://res.cloudinary.com/pricejugaad/image/upload/v1586498214/calculator-pages/mortage.png"
                        alt="Icon 4"
                        style={{ width: 100, height: 100 }}
                      />
                    </div>
                    <h3 style={{ marginTop: 10, color: "#333", paddingBottom: "10px" }}>Home Loan calculator</h3>
                    <p style={{ color: "#666" }}>
                      Calculate your mortgage loan installments instantly and check how much you can afford monthly on the home loan.
                    </p>
                  </div>
                  <div style={{ display: "flex", alignItems: "center", padding: "10px 10px", marginTop: "8px" }}>
                    <Link
                      to="/Calculators/Home Loan"
                      style={{
                        width: "100%",
                        backgroundColor: "#3498db",
                        color: "#fff",
                        padding: "8px 12px",
                        border: "none",
                        borderRadius: 4,
                        cursor: "pointer",
                        textAlign: "center",
                      }}
                    >
                      Calculate
                    </Link>
                  </div>
                </div>
                <div style={{ width: "48.5%", backgroundColor: "rgb(241, 241, 241)", padding: 8, borderRadius: 15 }}>
                  <div style={{ padding: "15px", background: "rgb(250, 250, 250)", borderRadius: "13px", height: "calc(100% - 76px)" }}>
                    <div style={{ textAlign: "center" }}>
                      <img
                        src="https://res.cloudinary.com/pricejugaad/image/upload/v1586498215/calculator-pages/emi.png"
                        alt="Icon 4"
                        style={{ width: 100, height: 100 }}
                      />
                    </div>
                    <h3 style={{ marginTop: 10, color: "#333", paddingBottom: "10px" }}>Interest calculator</h3>
                    <p style={{ color: "#666" }}>
                      Calculate your monthly payments on loans and on other expenses like gadgets, jewelry, etc., that are bought on installment
                      schemes using the EMI Calculator.
                    </p>
                  </div>
                  <div style={{ display: "flex", alignItems: "center", padding: "10px 10px", marginTop: "8px" }}>
                    <Link
                      to="/Calculators/Interest"
                      style={{
                        width: "100%",
                        backgroundColor: "#3498db",
                        color: "#fff",
                        padding: "8px 12px",
                        border: "none",
                        borderRadius: 4,
                        cursor: "pointer",
                        textAlign: "center",
                      }}
                    >
                      Calculate
                    </Link>
                  </div>
                </div>
              </div> */}
              </>
            )}
            <div className="mb-4"></div>
            <BottomBannerSlider bottomBanner={data?.singlepagedata?.singlepagedata?.banners?.filter((item) => item.banner_position == "1")} />
            <div className="py-4">
              <Html html={data?.singlepagedata?.singlepagedata?.footer?.description} />
            </div>
          </div>
        </WithBannersLayout>
      </div>
    </>
  );
};

export default CalculatorsList;
