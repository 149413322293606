import React, { useEffect, useState } from "react";
import "./ProductListPage.css";
import { useLocation, useParams } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { GetIdFromUrlCategory } from "../../query/Graphql";
import RenderProductListPage from "../../components/RenderProductListPage";

const ProductListPage = () => {
  const location = useLocation(); // Get the current location using react-router-dom
  const abortController = new AbortController();
  const { parentCatName, categoryName, subcategoryName } = useParams();
  const [CurrentId, setCurrentId] = useState();
  useEffect(() => {
    setCurrentId("");
  }, [location.pathname]);

  const createArray = () => {
    const array = [];
    if (parentCatName) {
      array.push(parentCatName);
    }
    if (categoryName) {
      array.push(categoryName);
    }
    if (subcategoryName) {
      array.push(subcategoryName);
    }
    return array;
  };

  const IDArrayFromURL = GetIdFromUrlCategory(createArray());
  useEffect(() => {
    if (IDArrayFromURL?.length > 0) {
      setCurrentId(IDArrayFromURL[IDArrayFromURL.length - 1].id);
    }
  }, [IDArrayFromURL]);
  useEffect(() => {
    // clear state on unmount
    return () => {
      abortController.abort();
    };
  }, [location.pathname]);
  return (
    <div className="productlist">
      <Breadcrumb />
      <RenderProductListPage CurrentId={CurrentId} />
    </div>
  );
};

export default ProductListPage;
