import React, { useEffect } from "react";
import "./ComparePage.css";
import Comparecards from "../../components/Compareproduct/Comparecards";
import Comparetable from "../../components/Compareproduct/Comparetable";
import Clarionimg from "../../components/Compareproduct/Clarionimg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetSinglePagesDataFromSlug } from "../../query/Graphql";
import WithBannersLayout from "../../utils/WithBannersLayout/WithBannersLayout";
import WithMetaByData from "../../utils/WithMetaByData";
import TopBannerSlider from "../../utils/BannerLayout/TopBannerSlider";
import BottomBannerSlider from "../../utils/BannerLayout/BottomBannerSlider";
import Html from "../../utils/Html";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

const ComparePage = () => {
  const compareItems = useSelector((state) => state.rootReducer?.CompareItems);
  const navigate = useNavigate();
  useEffect(() => {
    if (!compareItems?.length > 0) {
      navigate("/");
    }
  }, []);
  const pageData = GetSinglePagesDataFromSlug("compare_products");

  return (
    <>
      <Breadcrumb />
      <section className="mt-3">
        <WithBannersLayout
          leftBanners={pageData?.banners?.filter((item) => item.banner_position == "3")}
          rightBanners={pageData?.banners?.filter((item) => item.banner_position == "4")}
        >
          <WithMetaByData data={pageData?.page_meta} />
          <div className="container comparepage">
            <TopBannerSlider topBanners={pageData?.banners?.filter((item) => item.banner_position == "0")} />
            <div className={compareItems?.length == 2 && "twoItemsOnly"}>
              <Comparecards />
              <Comparetable />
            </div>
            {pageData?.cms?.length > 0 && (
              <div className="py-4">
                <Html html={pageData?.cms[0]?.description} />
              </div>
            )}
            <BottomBannerSlider bottomBanner={pageData?.banners?.filter((item) => item.banner_position == "1")} />
            <div className="py-4">
              <Html html={pageData?.footer?.description} />
            </div>
          </div>
        </WithBannersLayout>
      </section>
    </>
  );
};

export default ComparePage;
