import React from "react";
import { GetSinglePagesDataFromSlug } from "../../query/Graphql";
import BottomBannerSlider from "../../utils/BannerLayout/BottomBannerSlider";
import TopBannerSlider from "../../utils/BannerLayout/TopBannerSlider";
import Html from "../../utils/Html";
import WithBannersLayout from "../../utils/WithBannersLayout/WithBannersLayout";
import WithMetaByData from "../../utils/WithMetaByData";

const AuthLayoutBySlug = ({ pageData, contentSlug, children }) => {
  return (
    <div className="auth mt-3">
      <WithBannersLayout
        leftBanners={pageData?.banners?.filter((item) => item.banner_position == "3")}
        rightBanners={pageData?.banners?.filter((item) => item.banner_position == "4")}
      >
        <WithMetaByData data={pageData?.page_meta} />
        <div className="container">
          <TopBannerSlider topBanners={pageData?.banners?.filter((item) => item.banner_position == "0")} />
          {pageData ? (
            contentSlug && pageData?.cms?.find((item) => item.slug == contentSlug)?.description ? (
              <div className="row AuthBGContainer">
                <div className="col-lg-6">{children}</div>
                <div className="col-lg-6 htmlContentRight">
                  <Html html={pageData?.cms?.find((item) => item.slug == contentSlug)?.description} />
                </div>
              </div>
            ) : (
              <>{children}</>
            )
          ) : (
            <>
              {contentSlug && (
                <div className="row my-4">
                  <div className="col-lg-7">{children}</div>
                  <div className="col-lg-5">
                    {/* <div className="spinner-border text-primary" role="status" style={{ margin: "auto", height: "25px", width: "25px" }}></div> */}
                    <div className="skeleton-text" style={{ height: "100%", display: "flex" }}>
                      <div className="spinner-border text-primary" role="status" style={{ margin: "auto", height: "25px", width: "25px" }}></div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          <div className="mb-4"></div>
          <BottomBannerSlider bottomBanner={pageData?.banners?.filter((item) => item.banner_position == "1")} />
          <div className="py-4">
            <Html html={pageData?.footer?.description} />
          </div>
        </div>
      </WithBannersLayout>
    </div>
  );
};

export default AuthLayoutBySlug;
