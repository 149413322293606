import React from "react";
import { useNavigate } from "react-router-dom";
import { GetSinglePagesDataFromSlug } from "../../query/Graphql";
import Html from "../../utils/Html";
import AuthLayoutBySlug from "../Auth/AuthLayoutBySlug";

const RegistrationSuccess = () => {
  const navigate = useNavigate();
  const pageData = GetSinglePagesDataFromSlug("customer-register-success");
  return (
    <AuthLayoutBySlug pageData={pageData} contentSlug="customer-register-success">
      <div className="d-flex justify-content-center align-items-center" style={{ maxWidth: "450px", margin: "0 auto" }}>
        <div className="">
          <div className="border border-3 border-success" />
          <div className="card  bg-white shadow p-5">
            <div className="mb-4 text-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="text-success" width={75} height={75} fill="currentColor" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
              </svg>
            </div>
            <div className="text-center">
              <h1>{pageData?.cms?.find((item) => item.slug == "customer-register-success-new")?.title}</h1>
              <div className="mt-3">
                <Html html={pageData?.cms?.find((item) => item.slug == "customer-register-success-new")?.description} />
                {/* Success! A verification email has been sent to your registered email address. Please check your inbox and follow the instructions
                provided to complete the verification process. */}
              </div>
              <button className="action-btn small-btn" onClick={() => navigate("/SignIn")}>
                LogIn
              </button>
            </div>
          </div>
        </div>
      </div>
    </AuthLayoutBySlug>
  );
};

export default RegistrationSuccess;
