import React, { useState } from "react";
import "./Accordion.css"; // Import your custom CSS for styling

const AccordionItem = ({ title, isHideCloseBtn, children }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleAccordion = () => {
    if (!isHideCloseBtn) {
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <div className={`accordion-item ${isExpanded ? "expanded" : ""}`}>
      <button className={`accordion-button select-dropdown ${isHideCloseBtn ? "removeAfterForAcBtn" : ""}`} onClick={toggleAccordion}>
        {title}
      </button>
      <div className="accordion-content">{children}</div>
    </div>
  );
};

export default AccordionItem;
