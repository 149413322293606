import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GET_ALL_REVIEW } from "../../query/query";
import RatingStars from "../RatingStars/RatingStars";

const ReviewWithPagination = ({ product }) => {
  const commentList = [];
  const [pageCount, setPageCount] = useState(2);
  const [page, setPage] = useState(1);
  const [dataList, setDataList] = useState([]);
  const [HideLoadMore, setHideLoadMore] = useState(false);
  const [AvgRating, setAvgRating] = useState(0);
  const [getAllReview, { loading, error, data }] = useLazyQuery(GET_ALL_REVIEW, {
    fetchPolicy: "no-cache",
  });
  useEffect(() => {
    if (!loading && data && data?.allreview?.allreview?.data) {
      const newDataList = data?.allreview?.allreview?.data?.filter((newItem) => !dataList.some((item) => item.id === newItem.id));

      if (newDataList.length > 0) {
        setDataList((prevDataList) => [...prevDataList, ...newDataList]);
      }
      setAvgRating(data?.allreview?.average_rating);
      if (dataList.length == data?.allreview?.total_reviews) {
        setHideLoadMore(true);
      }
    }
  }, [loading, data, dataList]);
  useEffect(() => {
    if (product?.id && !loading) {
      setDataList([]);
      setPageCount(2);
      setPage(1);
      setAvgRating(0);
      getAllReview({
        variables: {
          pro_id: product?.id,
          company_id: "",
          perPage: pageCount,
          page: page,
        },
      });
    }
  }, [product?.id]);

  useEffect(() => {
    return () => {
      setDataList([]);
    };
  }, []);

  const handleLoadMore = () => {
    getAllReview({
      variables: {
        pro_id: product?.id,
        company_id: "",
        perPage: pageCount,
        page: page + 1,
      },
    });
    setPage(page + 1);
  };
  console.log("data?.allreview?.is_review", data?.allreview?.is_review);
  if (!data?.allreview?.is_review) {
    return null;
  }
  return (
    <div className="row ReviewWithPagination" style={{ marginTop: "30px", marginBottom: "30px" }}>
      {dataList?.length > 0 && (
        <div className="col-md-12">
          <h3 className="d-flex">
            <div className="blu-txt me-1">Product </div> <div className="green-txt">Reviews</div>
            {AvgRating && (
              <div className="star-div" style={{ marginLeft: "10px" }}>
                <RatingStars averageRating={AvgRating || 0} />
                {AvgRating && <div>({parseFloat(Number(AvgRating).toFixed(1)) || 0})</div>}
              </div>
            )}
          </h3>
          <ul className="recent-coment-list">
            {dataList?.map((com) => (
              <li key={com?.id}>
                <div className="frst d-flex">
                  <h5>{com?.cname || "Anonymous"}</h5>
                  <RatingStars averageRating={com?.rating || 0} />
                  {com?.rating && <div style={{ marginLeft: "5px", fontSize: "14px" }}>({parseFloat(Number(com?.rating).toFixed(1)) || 0})</div>}
                  {/* <p className="date">{formatDate(com.comment_date)}</p>{" "} */}
                </div>
                <div className="scnd">
                  <p>{com.comments}</p>
                </div>
              </li>
            ))}
            {loading &&
              Array.from({ length: pageCount }).map((_, index) => <div className="skeleton-text" style={{ height: "80px", marginBottom: "20px" }} />)}
          </ul>
          {!HideLoadMore && dataList.length > 0 && (
            <div className="viewmore-div">
              {loading ? (
                <>
                  <div className="spinner-border text-primary" role="status" style={{ height: "22px", width: "22px" }} />
                </>
              ) : (
                <Link className="viewmore" onClick={() => handleLoadMore()}>
                  Load more
                </Link>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ReviewWithPagination;
