import React from "react";
import BottomBannerSlider from "../utils/BannerLayout/BottomBannerSlider";

const Addposters = ({ HomePageData }) => {
  // BottomBannerItem
  // const BottomHomeBanner = GetBottomBanners("home");
  // console.log("BottomHomeBanner", BottomHomeBanner);

  if (!HomePageData?.banners) {
    return (
      <section className="post-wrp">
        <div className="home-banner2 container">
          <div className="skeleton-text" style={{ height: "330px" }} />
        </div>
      </section>
    );
  }
  if (HomePageData?.banners?.length == 0) {
    return null;
  }

  return (
    <section className="post-wrp">
      <div
        className="home-banner2 container"
        style={{ borderBottom: "1px solid #00000033" }}
      >
        <BottomBannerSlider
          bottomBanner={HomePageData?.banners?.filter(
            (item) => item.banner_position == "1"
          )}
        />
      </div>
    </section>
  );
};

export default Addposters;
