import React, { useState, useEffect } from "react";
import ApexCharts from "apexcharts";
import Chart from "react-apexcharts";
import "./CryptoChartMain.css";
import axios from "axios";
import { useParams } from "react-router-dom";

const CryptoChartMain = () => {
  const { selectedCrypto, currencyName, uuid } = useParams();
  const [tooltipData, setTooltipData] = useState({ x: null, y: null });
  // console.log("tooltipData", tooltipData);
  const [chartData, setChartData] = useState({
    series: [
      {
        data: [],
      },
    ],
    options: {
      chart: {
        id: "myCryptoChart",
        type: "area",
        height: 350,
        zoom: {
          enabled: false,
          type: "x",
          autoScaleYaxis: true,
          zoomedArea: {
            fill: {
              color: "#90CAF9",
              opacity: 0.4,
            },
            stroke: {
              color: "#0D47A1",
              opacity: 0.4,
              width: 1,
            },
          },
        },
        toolbar: {
          show: false,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: false,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true | '<img src="/static/icons/reset.png" width="20">',
            customIcons: [],
          },
        },
        events: {
          // mouseMove: function (event, chartContext, config) {
          //   handleDataPointSelection(event, chartContext, config);
          //   // Handle the event and update the state here
          //   // setHoveredPrice(value);
          // },
          mouseLeave: function (event, chartContext, config) {
            // console.log(
            //   "seriesseriesseriesseries",
            //   config?.config?.series[0]?.data.length > 0 &&
            //     config?.config?.series[0]?.data[0][1]
            // );
            setTooltipData({
              x: 0,
              y: config?.config?.series[0]?.data.length > 0 ? config?.config?.series[0]?.data[0][1] : 0,
            });
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
        style: "hollow",
      },
      xaxis: {
        type: "datetime",
        // min: new Date("01 Mar 2012").getTime(),
        max: 1698744600 * 1000,
        // min: 1696143600 * 1000,
        // tickAmount: 6,
        labels: {
          datetimeUTC: false,
        },
      },
      tooltip: {
        x: {
          // format: "dd MMM yyyy",
          formatter: (value) => {
            const date = new Date(value);
            let hours = date.getHours();
            let minutes = date.getMinutes();
            let ampm = hours >= 12 ? "PM" : "AM";

            // Convert to 12-hour format
            if (hours > 12) {
              hours -= 12;
            } else if (hours === 0) {
              hours = 12;
            }

            // Add leading zero to minutes if they are less than 10
            if (minutes < 10) {
              minutes = "0" + minutes;
            }

            const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${hours}:${minutes} ${ampm}`;

            // Customize the date format using JavaScript
            // const date = new Date(value);
            // const formattedDate = `${date.getDate()}/${
            //   date.getMonth() + 1
            // }/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
            return formattedDate;
          },
        },
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          setTooltipData({
            x: w.config.xaxis.categories[dataPointIndex],
            y: series[seriesIndex][dataPointIndex],
          });
          return null;
        },
      },
      colors: ["#B53606"],
      fill: {
        type: "pattern",
        colors: ["#B5360666"],
        pattern: {
          style: "circles",
          width: 5,
          height: 5,
          strokeWidth: 1,
        },
      },
      yaxis: {
        show: false,
      },
      grid: {
        show: false,
      },
      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: undefined,
        width: 2,
        dashArray: 0,
      },
    },
    selection: "one_year",
  });

  const updateSeriesAccordingData = (data) => {
    setTooltipData({
      x: 0,
      y: parseFloat(data[0].price).toFixed(2),
    });
    ApexCharts.exec("myCryptoChart", "updateSeries", [
      {
        data: data?.map((item, index) => [item.timestamp * 1000, parseFloat(item.price).toFixed(2)]),
      },
    ]);
  };

  function updateData(timeline) {
    switch (timeline) {
      case "one_days":
        fetchData("24h").then((data) => {
          if (data?.length > 0) {
            updateSeriesAccordingData(data);
          }
        });
        break;
      case "seven_days":
        fetchData("7d").then((data) => {
          if (data?.length > 0) {
            updateSeriesAccordingData(data);
          }
        });
        break;
      case "one_month":
        fetchData("30d").then((data) => {
          if (data?.length > 0) {
            updateSeriesAccordingData(data);
          }
        });
        break;
      case "three_months":
        fetchData("3m").then((data) => {
          if (data?.length > 0) {
            updateSeriesAccordingData(data);
          }
        });
        break;
      case "one_year":
        fetchData("1y").then((data) => {
          if (data?.length > 0) {
            updateSeriesAccordingData(data);
          }
        });
        break;
      default:
    }
  }
  useEffect(() => {
    updateData(chartData.selection);
  }, []);
  const fetchData = async (time) => {
    try {
      const response = await axios.get(`https://api.coinranking.com/v2/coin/${uuid}/history`, {
        params: {
          timePeriod: time,
        },
        headers: {
          "x-access-token": "coinrankingbbe0f960355261766eba2b4af92d608ee14c35d721afdee7",
        },
      });
      return response?.data?.data?.history;
    } catch (error) {
      return null;
      // console.error("Error fetching data:", error);
    }
  };
  return (
    <div id="chart">
      <div className="filterChartWrapper toolbar">
        <div className="ChartPriceWrapper">
          <p>Price</p>
          <h3>${tooltipData?.y}</h3>
          {/* <h5>(0.97%)</h5> */}
        </div>
        <div className="filterChartBtnWrapper">
          <div>
            <button
              id="one_days"
              onClick={() => {
                setChartData({ ...chartData, selection: "one_days" });
                updateData("one_days");
              }}
              className={chartData.selection === "one_days" ? "active" : ""}
            >
              1D
            </button>
            &nbsp;
            <button
              id="seven_days"
              onClick={() => {
                setChartData({ ...chartData, selection: "seven_days" });
                updateData("seven_days");
              }}
              className={chartData.selection === "seven_days" ? "active" : ""}
            >
              7D
            </button>
            &nbsp;
            <button
              id="one_month"
              onClick={() => {
                setChartData({ ...chartData, selection: "one_month" });
                updateData("one_month");
              }}
              className={chartData.selection === "one_month" ? "active" : ""}
            >
              1M
            </button>
            &nbsp;
            <button
              id="three_months"
              onClick={() => {
                setChartData({ ...chartData, selection: "three_months" });
                updateData("three_months");
              }}
              className={chartData.selection === "three_months" ? "active" : ""}
            >
              3M
            </button>
            &nbsp;
            <button
              id="one_year"
              onClick={() => {
                setChartData({ ...chartData, selection: "one_year" });
                updateData("one_year");
              }}
              className={chartData.selection === "one_year" ? "active" : ""}
            >
              1Y
            </button>
          </div>
        </div>
        {/* &nbsp;
        <button
          id="ytd"
          onClick={() => {
            setChartData({ ...chartData, selection: "ytd" });
            updateData("ytd");
          }}
          className={chartData.selection === "ytd" ? "active" : ""}
        >
          YTD
        </button> */}
        {/* &nbsp;
        <button
          id="all"
          onClick={() => {
            setChartData({ ...chartData, selection: "all" });
            updateData("all");
          }}
          className={chartData.selection === "all" ? "active" : ""}
        >
          ALL
        </button> */}
      </div>

      <div id="chart-timeline">
        <Chart options={chartData.options} series={chartData.series} type="area" height={350} />
      </div>
    </div>
  );
};

export default CryptoChartMain;
