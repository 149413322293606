import React, { useState } from "react";
import RangeInput from "../../components/RangeInput";
import { GetSinglePagesDataFromSlug } from "../../query/Graphql";
import WithBannersLayout from "../../utils/WithBannersLayout/WithBannersLayout";
import TopBannerSlider from "../../utils/BannerLayout/TopBannerSlider";
import BottomBannerSlider from "../../utils/BannerLayout/BottomBannerSlider";
import Html from "../../utils/Html";
import WithMetaByData from "../../utils/WithMetaByData";
import "./calculator.css";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

const CalculatorLayoutFromSlug = ({ slug, children }) => {
  const pageData = GetSinglePagesDataFromSlug(slug);
  return (
    <>
      <Breadcrumb />
      <div className="mt-3">
        <WithBannersLayout
          leftBanners={pageData?.banners?.filter((item) => item.banner_position == "3")}
          rightBanners={pageData?.banners?.filter((item) => item.banner_position == "4")}
        >
          <WithMetaByData data={pageData?.page_meta} />
          <div className="container calculator">
            <TopBannerSlider topBanners={pageData?.banners?.filter((item) => item.banner_position == "0")} />
            <div className="calculatorTitle title big-p text-black font-bold d-flex justify-content-between">
              <h5>{pageData?.cms?.length > 0 && pageData?.cms[0]?.title}</h5>
            </div>
            {children}
            {pageData?.cms?.length > 0 && (
              <div className="py-4">
                <Html html={pageData?.cms[0]?.description} />
              </div>
            )}
            <BottomBannerSlider bottomBanner={pageData?.banners?.filter((item) => item.banner_position == "1")} />
            <div className="py-4">
              <Html html={pageData?.footer?.description} />
            </div>
          </div>
        </WithBannersLayout>
      </div>
    </>
  );
};

export default CalculatorLayoutFromSlug;
