import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import Header from "./components/Header";
import Footer from "./components/footer/Footer";
import { GetSystemMessage } from "./query/Graphql";
import { useDispatch, useSelector } from "react-redux";
import { setBannerList, setMessages } from "./stores/rootReducer";
import ProductDetailPage from "./pages/ProductDetailPage/ProductDetailPage";
import ProductListPage from "./pages/ProductListPage/ProductListPage";
import ComparePage from "./pages/ComparePage/ComparePage";
import KnowledgeNuggetList from "./components/KnowledgeNugget/KnowledgeNuggetList";
import KnowledgeNuggetDetails from "./components/KnowledgeNugget/KnowledgeNuggetDetails";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import ScrollToTop from "./utils/ScrollToTop";
import WithMetaBySlug from "./utils/WithMetaBySlug";
import CompanyDirectoryDetails from "./components/CompanyDirectory/CompanyDirectoryDetails";
import CompanyDirectoryList from "./components/CompanyDirectory/CompanyDirectoryList";
import WithBannersLayout from "./utils/WithBannersLayout/WithBannersLayout";
import AboutCategory from "./components/AboutCategory/AboutCategory";
import CMSPageFromSlug from "./pages/CMSPageFromSlug/CMSPageFromSlug";
import CryptoPricesList from "./pages/Crypto/CryptoPricesList";
import CryptoDetailPage from "./pages/Crypto/CryptoDetailPage/CryptoDetailPage";
import FinancialLiteracy from "./pages/FinancialLiteracy/FinancialLiteracy";
import FinancialLiteracyDetail from "./pages/FinancialLiteracy/FinancialLiteracyDetail";
import CarLoan from "./pages/Calculators/CarLoan";
import DBRCalculator from "./pages/Calculators/DBRCalculator";
import HomeLoan from "./pages/Calculators/HomeLoan";
import Intrest from "./pages/Calculators/Intrest";
import GoldRate from "./pages/Calculators/GoldRate";
import CurrencyConverter from "./pages/Calculators/CurrencyConverter";
import CalculatorsList from "./pages/Calculators/CalculatorsList";
import CompareBar from "./components/CompareBar/CompareBar";
import SignUp from "./pages/Auth/SignUp";
import SignIn from "./pages/Auth/SignIn";
import RegistrationSuccess from "./pages/ThankyuAndSucess/RegistrationSuccess";
import UserDetails from "./pages/Auth/UserDetails";
import ResetPassword from "./pages/Auth/ResetPassword";
import VerifyEmailTokenSuccess from "./pages/ThankyuAndSucess/VerifyEmailTokenSuccess";
import ChangePassword from "./pages/Auth/ChangePassword";
import RedirectionPage from "./pages/RedirectionPage/RedirectionPage";

function App() {
  const dispatch = useDispatch();
  var data = GetSystemMessage();
  if (data?.systemmessage?.system_message) {
    dispatch(setMessages(data?.systemmessage?.system_message));
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header />
      <div
        className="notification-box d-flex flex-column align-items-center"
        style={{ position: "fixed", right: "0", top: "20px", minWidth: "300px", zIndex: "9999" }}
      >
        {/* <!-- Notification container --> */}
      </div>
      <Routes>
        <Route path="/SignIn" element={<SignIn />} />
        <Route path="/SignUp" element={<SignUp />} />
        <Route path="/ResetPassword/:resetToken" element={<ResetPassword />} />
        <Route path="/ChangePassword" element={<ChangePassword />} />
        <Route path="/UserDetails" element={<UserDetails />} />
        <Route path="/Registration_success" element={<RegistrationSuccess />} />
        <Route path="/VerifyMail/:verifyEmailToken" element={<VerifyEmailTokenSuccess />} />
        <Route path="/Redirect" element={<RedirectionPage />} />
        <Route
          exact
          path="/"
          element={
            <>
              {/* // <WithBannersLayout slug="home"> */}
              {/* <WithMetaBySlug slug="home"> */}
              <Home />
              {/* </WithMetaBySlug> */}
              {/* // </WithBannersLayout> */}
            </>
          }
        />
        <Route path="/:CMSPageName" element={<CMSPageFromSlug />} />
        <Route path="/productList/:parentCatName" element={<ProductListPage />} />
        <Route path="/productList/:parentCatName/:categoryName" element={<ProductListPage />} />
        <Route path="/productList/:parentCatName/:categoryName/:subcategoryName" element={<ProductListPage />} />
        <Route path="/productList/:categoryName/about" element={<AboutCategory />} />
        <Route path="/productList/:parentCatName/:categoryName/about" element={<AboutCategory />} />
        <Route path="/productList/:parentCatName/:categoryName/:subcategoryName/about" element={<AboutCategory />} />
        <Route path="/Product/:parentCatName/:productName/:productId" element={<ProductDetailPage />} />
        <Route path="/Product/:parentCatName/:categoryName/:productName/:productId" element={<ProductDetailPage />} />
        <Route path="/Product/:parentCatName/:categoryName/:subcategoryName/:productName/:productId" element={<ProductDetailPage />} />
        <Route path="/Compare Products" element={<ComparePage />} />
        <Route path="/Knowledge Nuggets" element={<KnowledgeNuggetList />} />
        <Route path="/Knowledge Nuggets/:nuggetName" element={<KnowledgeNuggetDetails />} />
        <Route path="/Company Directory" element={<CompanyDirectoryList />} />
        <Route path="/Company Directory/:companyName" element={<CompanyDirectoryDetails />} />
        <Route path="/Cryptocurrency/:SubNameCrypto" element={<CryptoPricesList />} />
        <Route path="/Cryptocurrency/:SubNameCrypto/:selectedCrypto/:currencyName/:uuid" element={<CryptoDetailPage />} />
        <Route path="/Financial Literacy" element={<FinancialLiteracy />} />
        <Route path="/Financial Literacy/:BlogSlug" element={<FinancialLiteracyDetail />} />
        <Route path="/Currency Converter" element={<CurrencyConverter />} />
        <Route path="/Gold Rate" element={<GoldRate />} />
        <Route path="/Calculators" element={<CalculatorsList />} />
        <Route path="/Calculators/car loan" element={<CarLoan />} />
        <Route path="/Calculators/DBR Calculator" element={<DBRCalculator />} />
        <Route path="/Calculators/Home Loan" element={<HomeLoan />} />
        <Route path="/Calculators/Interest" element={<Intrest />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer />
      <CompareBar />
    </BrowserRouter>
  );
}

export default App;
