import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import "./Breadcrumb.css";
import GetBreadCrumbArray from "../../utils/GetBreadCrumbArray";

const Breadcrumb = () => {
  const location = useLocation();
  const BreadCrumbsArray = GetBreadCrumbArray();
  useSelector((state) => state.rootReducer.BreadCrumbsArray) ||
    JSON.parse(localStorage.getItem("BreadCrumbsArray"));
  useEffect(() => {
    localStorage.setItem("BreadCrumbsArray", JSON.stringify(BreadCrumbsArray));
  }, [BreadCrumbsArray]);

  const pathnames = decodeURIComponent(location.pathname)
    .split("/")
    .filter((x) => x);
  // console.log("pathnames: ", pathnames);
  const modifiedPathNamesForUUID =
    pathnames.includes("Cryptocurrency") && pathnames.length > 2
      ? pathnames.slice(0, -2)
      : pathnames;
  const modifiedPathNamesForList = modifiedPathNamesForUUID.includes(
    "productList"
  )
    ? modifiedPathNamesForUUID.filter((item) => {
        return item !== "productList";
      })
    : modifiedPathNamesForUUID;
  const modifiedPathNamesForProduct = modifiedPathNamesForList.includes(
    "Product"
  )
    ? modifiedPathNamesForList
        .filter((item) => {
          return item !== "Product";
        })
        .slice(0, -1)
    : modifiedPathNamesForList;
  // console.log("modifiedPathNamesForProduct", modifiedPathNamesForProduct);

  const handleBreadCrumbClick = (index) => {
    // console.log("BreadCrumbsArray", BreadCrumbsArray);
    // BreadCrumbsArray[index];
  };

  const renderItems = (path, index) => {
    if (index == modifiedPathNamesForProduct?.length - 1) {
      return (
        <li className="breadcrumb-item active" key={path}>
          {path}
        </li>
      );
    } else if (pathnames.includes("productList")) {
      return (
        <li className="breadcrumb-item active" key={path}>
          <Link
            to={`/productList/${modifiedPathNamesForProduct
              ?.slice(0, index + 1)
              .join("/")}`}
            onClick={() => handleBreadCrumbClick(index)}
          >
            {path}
          </Link>
        </li>
      );
    } else if (pathnames.includes("Product")) {
      return (
        <li className="breadcrumb-item active" key={path}>
          <Link
            to={`/productList/${modifiedPathNamesForProduct
              ?.slice(0, index + 1)
              .join("/")}`}
          >
            {path}
          </Link>
        </li>
      );
    } else {
      return (
        <li className="breadcrumb-item active" key={path}>
          <Link
            to={`/${modifiedPathNamesForProduct
              ?.slice(0, index + 1)
              .join("/")}`}
          >
            {path}
          </Link>
        </li>
      );
    }
  };

  return (
    <div className="breadcrum-dv">
      <div className="container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/"}>Home</Link>
            </li>
            {modifiedPathNamesForProduct?.map((path, index) =>
              renderItems(path, index)
            )}
          </ol>
        </nav>
      </div>
    </div>
  );
};

export default Breadcrumb;
