import React from "react";
import OwlCarousel from "react-owl-carousel";
import BottomBannerItem from "../../components/BottomBannerItem";
import TopBannerItem from "../../components/TopBannerItem";

const BottomBannerSlider = ({ bottomBanner }) => {
  // console.log("dhruvin bottomBanner", bottomBanner);
  const owlCarouselOptions = {
    loop: false,
    dots: false,
    items: 1,
    autoplay: false,
    nav: true,
  };
  if (!bottomBanner) {
    return null;
  }
  return (
    <OwlCarousel className="banner-slider owl-carousel" {...owlCarouselOptions}>
      {bottomBanner ? (
        bottomBanner.map((bnrItem) => (
          <BottomBannerItem bottomBanner={bnrItem} />
        ))
      ) : (
        <></>
      )}
    </OwlCarousel>
  );
};

export default BottomBannerSlider;
