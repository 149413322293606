import React, { useState } from "react";
import MenuImg from "../assets/images/menu1.svg";
import { GetHeaderData } from "../query/Graphql";
import Card from "../assets/images/card1.jpg";
import MenuBan from "../assets/images/menu-ban.jpg";
import { Link } from "react-router-dom";
import SubCatItems from "./SubCatItems";
import { setBreadCrumbsArray } from "../stores/rootReducer";
import { useDispatch } from "react-redux";

const RenderMenuListItem = ({ item, handleMouseEnter, handleMouseLeave, handleMenuClick }) => {
  const dispatch = useDispatch();
  // const subCatData = GetHeaderData(item.id);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [SubCatForMobile, setSubCatForMobile] = useState(false);
  // if (subCatData) {
  return (
    <li
      key={item.id}
      className={`${item?.subcategories?.length > 0 ? "have-sub" : ""} ${isSubMenuOpen ? "hovering" : ""} ${SubCatForMobile ? "active" : ""}`}
      onMouseEnter={() => setIsSubMenuOpen(true)}
      onMouseLeave={() => setIsSubMenuOpen(false)}
    >
      <Link
        to={`/productList/${item.catname}`}
        onClick={() => {
          handleMenuClick();
          dispatch(setBreadCrumbsArray([{ name: item.catname, id: item.id }]));

          setTimeout(() => {
            setIsSubMenuOpen(false);
          }, 200);
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="outerCategoryWithDropdown"
      >
        {item.catname}
        {item?.subcategories?.length > 0 && (
          <a
            onClick={(e) => {
              e.stopPropagation(); // Prevent event propagation
              e.preventDefault(); // Prevent the default behavior
              setSubCatForMobile(!SubCatForMobile);
            }}
            className={`dropDownArrow ${SubCatForMobile ? "active" : ""}`}
          />
        )}
      </Link>
      {item?.subcategories?.length > 0 && (
        <ul className="mega-dropdown">
          <li className="container">
            <div className="row">
              <div className="col-sm-12 col-md-6 left">
                <h3 className="with-bdr">{item.catname}</h3>
                <SubCatItems
                  subCatData={item?.subcategories}
                  setIsSubMenuOpen={setIsSubMenuOpen}
                  parentCatName={item.catname}
                  parentBreadCrumbs={[{ name: item.catname, id: item.id }]}
                  handleMenuClick={handleMenuClick}
                />
              </div>
              {/* following center part of header need to discussed with client (disabled temporary) */}
              <div className="col-sm-3 col-md-3 center">
                <div className="sub-content">
                  {item?.minibannerimage_url && <img src={item.minibannerimage_url} alt="" title="" />}
                  <h3>{item.catname}</h3>
                  <p>{item.short_description}</p>
                  <Link
                    to={`/productList/${item.catname}`}
                    onClick={() => {
                      sessionStorage.setItem("CurrentSubCategory", JSON.stringify(item));
                      setTimeout(() => {
                        setIsSubMenuOpen(false);
                      }, 200);
                    }}
                    className="menu-link"
                  >
                    View All {item.catname}
                  </Link>
                </div>
              </div>
              <div className="col-sm-3 col-md-3 right">
                <h3 className="with-bdr">Popular Products</h3>
                <ul>
                  <li>
                    <a href="#">
                      <img src={Card} alt="" title="" /> Etihad Guest Visa Elevate
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={Card} alt="" title="" /> Skywards Infinite Credit Card
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={Card} alt="" title="" /> Noor Titanium Credit Card
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={Card} alt="" title="" /> U By Emaar Infinite Credit Card
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={Card} alt="" title="" /> Skywards Infinite Credit Card
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      )}
    </li>
  );
};

export default RenderMenuListItem;
