import { GetBanners } from "../query/Graphql";

export const GetBottomBanners = (key) => {
  const bannerData = GetBanners(key);
  if (bannerData) {
    const matchingItems = bannerData.filter(
      (item) => item.banner_position == "1"
    );
    if (matchingItems.length > 0) {
      return matchingItems;
    }
  }
  return null; // Return null if no matches are found
};
export const GetTopBanners = (key) => {
  const bannerData = GetBanners(key);
  if (bannerData) {
    const matchingItems = bannerData.filter(
      (item) => item.banner_position == "0"
    );
    if (matchingItems.length > 0) {
      return matchingItems;
    }
  }
  return null; // Return null if no match is found
};
export const GetHeaderBanners = (key) => {
  const bannerData = GetBanners(key);
  if (bannerData) {
    const matchingItems = bannerData.filter(
      (item) => item.banner_position == "2"
    );
    if (matchingItems.length > 0) {
      return matchingItems;
    }
  }
  return null; // Return null if no match is found
};
export const GetLeftBanners = (key) => {
  const bannerData = GetBanners(key);
  if (bannerData) {
    const matchingItems = bannerData.filter(
      (item) => item.banner_position == "3"
    );
    if (matchingItems.length > 0) {
      return matchingItems;
    }
  }
  return null; // Return null if no match is found
};
export const GetRightBanners = (key) => {
  const bannerData = GetBanners(key);
  if (bannerData) {
    const matchingItems = bannerData.filter(
      (item) => item.banner_position == "4"
    );
    if (matchingItems.length > 0) {
      return matchingItems;
    }
  }
  return null; // Return null if no match is found
};
