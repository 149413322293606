import React from "react";
import Accordion from "../Accordion/Accordion";
import AccordionItem from "../Accordion/AccordionItem";
import arrowSubDown from "../../assets/images/arrow-sub-down.svg";

const FilterWithCategoryChecklist = ({ title, handleChange, CategoryFilters, checkList }) => {
  const levelOneList = checkList?.filter((item) => {
    if (item?.level == 1) {
      return item;
    }
  });
  const levelTwoList = checkList?.filter((item) => {
    if (item?.level == 2) {
      return item;
    }
  });
  const handleParentLevelCatSelected = (id) => {
    const listOfChild = levelTwoList.filter((item) => item.parent_catId == id);
    handleChange(id);
    if (!CategoryFilters?.includes(id) && listOfChild?.length > 0) {
      listOfChild?.map((child) => {
        !CategoryFilters?.includes(child.id) && handleChange(child.id);
      });
    }
  };
  return (
    <div className="category-div">
      <div className="drop-down-wrp">
        <Accordion>
          <AccordionItem title={title}>
            <div className="sidebar-list">
              <ul>
                {levelOneList?.map((item) => (
                  <>
                    <li className={`add-comp ${item?.image && "withImage"}`}>
                      <input
                        type="checkbox"
                        name={item.catname}
                        id={item.id}
                        onChange={() => handleParentLevelCatSelected(item.id)}
                        checked={CategoryFilters?.includes(item.id)}
                      />
                      {/* <label>{item.catname}</label> */}
                      <label onClick={() => handleParentLevelCatSelected(item.id)} style={{ cursor: "pointer" }}>
                        {item.catname}
                      </label>
                      {item?.image && (
                        <div className="chk-img">
                          <img src={item?.image} alt="" />
                        </div>
                      )}
                      {/* <span>({item.total_product})</span> */}
                    </li>
                    {levelTwoList?.map(
                      (child, index) =>
                        child?.parent_catId == item?.id && (
                          <li className={`add-comp ${child?.image && "withImage"}`}>
                            {index == 0 && <img className="subCatArrowIconForCat" src={arrowSubDown} alt="" />}
                            <input
                              type="checkbox"
                              name={child.catname}
                              id={child.id}
                              onChange={() => handleChange(child.id)}
                              checked={CategoryFilters?.includes(child.id)}
                              className="subCatArrowIconForCatInput"
                            />
                            <label onClick={() => handleChange(child.id)} style={{ cursor: "pointer" }}>
                              {child.catname}{" "}
                            </label>
                            {/* <span>({item.total_product})</span> */}
                          </li>
                        )
                    )}
                  </>
                ))}
              </ul>
            </div>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
};

export default FilterWithCategoryChecklist;
