import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Dropdown } from "bootstrap";
import "./footer.css";
import { setCurrentNuggetTerm } from "../../stores/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { GetHeaderData } from "../../query/Graphql";
import RenderFooterCol from "../RenderFooterCol";

const Footer = () => {
  var currentDate = new Date();
  var currentYear = currentDate.getFullYear();
  const dispatch = useDispatch();
  const location = useLocation();
  const mainLevelCatData = useSelector((state) => state.rootReducer.mainHeaderCatData);
  const otherCatList = mainLevelCatData?.filter((cat) => cat.other_category_display == true);
  // console.log("otherCatList", otherCatList);
  const renderOtherItem = (OtherCat) => {
    console.log("OtherCat", OtherCat?.contact_type);
    if (OtherCat?.contact_type == "product") {
      return (
        <li>
          <Link to={`/productList/${OtherCat.catname}`}>{OtherCat.catname}</Link>
        </li>
      );
    }
    if (OtherCat?.contact_type == "crypto") {
      return (
        <li>
          <Link to={"/Cryptocurrency/Crypto Prices"}>{OtherCat.catname}</Link>
        </li>
      );
    }
    return (
      <li>
        <Link to={`/${OtherCat.catname}`}>{OtherCat.catname}</Link>
      </li>
    );
  };
  if (window.location.pathname == "/Redirect") {
    return null;
  }
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {location.pathname != "/Knowledge Nuggets" && (
              <>
                <div className="footer-row one">
                  <div className="s-col">
                    <h5>Knowledge Nuggets </h5>
                  </div>
                </div>
                <div className="footer-row abcd-sec">
                  <ul className="d-flex">
                    <li>
                      <Link to={"/Knowledge Nuggets"} onClick={() => dispatch(setCurrentNuggetTerm("A"))}>
                        A
                      </Link>
                    </li>
                    <li>
                      <Link to={"/Knowledge Nuggets"} onClick={() => dispatch(setCurrentNuggetTerm("B"))}>
                        B
                      </Link>
                    </li>
                    <li>
                      <Link to={"/Knowledge Nuggets"} onClick={() => dispatch(setCurrentNuggetTerm("C"))}>
                        C
                      </Link>
                    </li>
                    <li>
                      <Link to={"/Knowledge Nuggets"} onClick={() => dispatch(setCurrentNuggetTerm("D"))}>
                        D
                      </Link>
                    </li>
                    <li>
                      <Link to={"/Knowledge Nuggets"} onClick={() => dispatch(setCurrentNuggetTerm("E"))}>
                        E
                      </Link>
                    </li>
                    <li>
                      <Link to={"/Knowledge Nuggets"} onClick={() => dispatch(setCurrentNuggetTerm("F"))}>
                        F
                      </Link>
                    </li>
                    <li>
                      <Link to={"/Knowledge Nuggets"} onClick={() => dispatch(setCurrentNuggetTerm("G"))}>
                        G
                      </Link>
                    </li>
                    <li>
                      <Link to={"/Knowledge Nuggets"} onClick={() => dispatch(setCurrentNuggetTerm("H"))}>
                        H
                      </Link>
                    </li>
                    <li>
                      <Link to={"/Knowledge Nuggets"} onClick={() => dispatch(setCurrentNuggetTerm("I"))}>
                        I
                      </Link>
                    </li>
                    <li>
                      <Link to={"/Knowledge Nuggets"} onClick={() => dispatch(setCurrentNuggetTerm("J"))}>
                        J
                      </Link>
                    </li>
                    <li>
                      <Link to={"/Knowledge Nuggets"} onClick={() => dispatch(setCurrentNuggetTerm("K"))}>
                        K
                      </Link>
                    </li>
                    <li>
                      <Link to={"/Knowledge Nuggets"} onClick={() => dispatch(setCurrentNuggetTerm("L"))}>
                        L
                      </Link>
                    </li>
                    <li>
                      <Link to={"/Knowledge Nuggets"} onClick={() => dispatch(setCurrentNuggetTerm("M"))}>
                        M
                      </Link>
                    </li>
                    <li>
                      <Link to={"/Knowledge Nuggets"} onClick={() => dispatch(setCurrentNuggetTerm("N"))}>
                        N
                      </Link>
                    </li>
                    <li>
                      <Link to={"/Knowledge Nuggets"} onClick={() => dispatch(setCurrentNuggetTerm("O"))}>
                        O
                      </Link>
                    </li>
                    <li>
                      <Link to={"/Knowledge Nuggets"} onClick={() => dispatch(setCurrentNuggetTerm("P"))}>
                        P
                      </Link>
                    </li>
                    <li>
                      <Link to={"/Knowledge Nuggets"} onClick={() => dispatch(setCurrentNuggetTerm("Q"))}>
                        Q
                      </Link>
                    </li>
                    <li>
                      <Link to={"/Knowledge Nuggets"} onClick={() => dispatch(setCurrentNuggetTerm("R"))}>
                        R
                      </Link>
                    </li>
                    <li>
                      <Link to={"/Knowledge Nuggets"} onClick={() => dispatch(setCurrentNuggetTerm("S"))}>
                        S
                      </Link>
                    </li>
                    <li>
                      <Link to={"/Knowledge Nuggets"} onClick={() => dispatch(setCurrentNuggetTerm("T"))}>
                        T
                      </Link>
                    </li>
                    <li>
                      <Link to={"/Knowledge Nuggets"} onClick={() => dispatch(setCurrentNuggetTerm("U"))}>
                        U
                      </Link>
                    </li>
                    <li>
                      <Link to={"/Knowledge Nuggets"} onClick={() => dispatch(setCurrentNuggetTerm("V"))}>
                        V
                      </Link>
                    </li>
                    <li>
                      <Link to={"/Knowledge Nuggets"} onClick={() => dispatch(setCurrentNuggetTerm("W"))}>
                        W
                      </Link>
                    </li>
                    <li>
                      <Link to={"/Knowledge Nuggets"} onClick={() => dispatch(setCurrentNuggetTerm("X"))}>
                        X
                      </Link>
                    </li>
                    <li>
                      <Link to={"/Knowledge Nuggets"} onClick={() => dispatch(setCurrentNuggetTerm("Y"))}>
                        Y
                      </Link>
                    </li>
                    <li>
                      <Link to={"/Knowledge Nuggets"} onClick={() => dispatch(setCurrentNuggetTerm("Z"))}>
                        Z
                      </Link>
                    </li>
                    {/* <li>
                  <Link
                    to={"/Knowledge Nuggets"}
                    onClick={() => dispatch(setCurrentNuggetTerm("A"))}
                  >
                    View All
                  </Link>
                </li> */}
                  </ul>
                </div>
              </>
            )}
            <div className="footer-row two">
              <div className="f-colums-main">
                {mainLevelCatData &&
                  mainLevelCatData.map((item) => {
                    return item.other_category_display == true ? <></> : <RenderFooterCol item={item} />;
                  })}
                {otherCatList && (
                  <>
                    <div className="s-col">
                      <h5>Others</h5>
                      <ul>{otherCatList && otherCatList.map((data) => renderOtherItem(data))}</ul>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="footer-row three">
              <div className="f-colums-main">
                <div className="s-col">
                  <h5>Quick Links</h5>
                  <ul>
                    <li>
                      <a href="#">About Us</a>
                    </li>
                    <li>
                      <a href="#">Contact Us</a>
                    </li>
                    <li>
                      <a href="#">Travel Cards</a>
                    </li>
                    <li>
                      <a href="#">FAQs</a>
                    </li>
                    <li>
                      <a href="#">News and Trends</a>
                    </li>
                    <li>
                      <a href="#">Blogs</a>
                    </li>
                    <li>
                      <a href="#">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="#">Terms & Conditions</a>
                    </li>
                  </ul>
                </div>

                <div className="s-col">
                  <h5>Contact Us</h5>
                  <ul>
                    <li className="call">
                      Email:
                      <a href="mailto: abc@abc.com" target="_blank">
                        abc@abc.com
                      </a>
                    </li>
                    <li className="call">
                      Phone:
                      <a href="Tell: +91-1234567890" target="_blank">
                        +91-1234567890
                      </a>
                    </li>
                    <li className="call">
                      <p>
                        123, Cat Lane,<br></br> Opp. Level 2 Captials,,
                        <br /> Newyork, 12356, US
                      </p>
                    </li>
                  </ul>
                </div>
                {/* <div className="s-col">
                  <h5>Visit Us</h5>
                  <p>
                    123, Cat Lane,<br></br> Opp. Level 2 Captials,,
                    <br /> Newyork, 12356, US
                  </p>
                </div> */}
                <div className="s-col">
                  <h5>Social Media</h5>
                  <ul>
                    <li>facebook</li>
                    <li>instagram</li>
                    <li>twitter</li>
                  </ul>
                </div>
                <div className="s-col-full">
                  <h5>Stay Connected</h5>
                  <p>Stay Informed and Inspired with Our Newsletter! We will not share your details.</p>
                  <div className="subscribe">
                    <form action="">
                      <div className="col"></div>
                      <input type="text" placeholder="Enter your email address" />
                      <a href="#" className="action-btn">
                        Subscribe
                      </a>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-footer">© {currentYear} Find Kaizen. All Rights Reserved.</div>
    </footer>
  );
};

export default Footer;
