import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BlogBaseUrl } from "../..";

const Recentcoments = ({ Blog }) => {
  const { BlogSlug } = useParams();
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Comment, setComment] = useState("");
  const [SuccessMessage, setSuccessMessage] = useState("");
  const [FailMessage, setFailMessage] = useState("");
  const [Loading, setLoading] = useState(false);
  const [LoadingComment, setLoadingComment] = useState(false);
  const [commentList, setCommentList] = useState(Blog?.comments);
  useEffect(() => {
    if (FailMessage) {
      if (Name && Email && Comment) {
        setFailMessage("");
      }
    }
  }, [Name, Email, Comment]);
  useEffect(() => {
    setCommentList(Blog?.comments);
  }, [Blog]);
  useEffect(() => {
    setFailMessage("");
    setSuccessMessage("");
    setName("");
    setEmail("");
    setComment("");
  }, [window.location.pathname]);

  function formatDate(inputDate) {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const date = new Date(inputDate);
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    let hours = date.getHours();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight

    const minutes = date.getMinutes();

    const formattedDate = `${month} ${day < 10 ? "0" : ""}${day}, ${year} ${hours < 10 ? "0" : ""}${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes} ${ampm}`;

    return formattedDate;
  }
  const handleSubmitClick = () => {
    if (Name && Email && Comment) {
      setLoading(true);
      axios
        .get(BlogBaseUrl + `wp-json/custom/v1/add-comment`, {
          params: {
            comment_post_ID: Blog?.ID?.ID,
            author_name: Name,
            author_email: Email,
            comment_content: Comment,
            comment_approved: 1,
          },
        })
        .then((response) => {
          // setBlog(response.data.data);
          if (response.data.status) {
            setFailMessage("");
            setSuccessMessage(response.data.message);
            setName("");
            setEmail("");
            setComment("");
            // getting comments again
            // if (BlogSlug) {
            //   setLoadingComment(true);
            //   axios
            //     .get(BlogBaseUrl + `wp-json/custom/v1/post-by-slug/${BlogSlug}`)
            //     .then((response) => {
            //       setCommentList(response.data?.data?.comments);
            //       setLoadingComment(false);
            //     })
            //     .catch((error) => {
            //       console.error("Error fetching data:", error);
            //       setLoadingComment(false);
            //     });
            // }
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setSuccessMessage("");
          setFailMessage("Error in Adding Comment, Please try again.");
          setLoading(false);
        });
    } else {
      setSuccessMessage("");
      setFailMessage("Please fill all the fields.");
    }
  };
  return (
    <section className="recent-coment-wrp">
      <div className="container">
        <div className="row">
          {commentList?.length > 0 && (
            <div className="col-md-12">
              <h3>
                <span className="blu-txt">Recent </span>
                <span className="green-txt">Comments</span>
              </h3>
              <ul className="recent-coment-list">
                {LoadingComment && <div className="skeleton-text" style={{ height: "100px" }} />}
                {commentList?.map((com) => (
                  <li key={com?.comment_ID}>
                    <div className="frst d-flex">
                      <h5>{com?.comment_author}</h5>
                      <p className="date">{formatDate(com.comment_date)}</p>{" "}
                    </div>
                    <div className="scnd">
                      <p>{com.comment_content}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className="row coment-foam">
          <div className="col-md-12">
            <h3 className="mb-4">
              <span className="blu-txt">Write a </span>
              <span className="green-txt">Comment</span>
            </h3>
            <div>
              {SuccessMessage && (
                <div className="alert alert-success" role="alert">
                  {SuccessMessage}
                </div>
              )}
              {FailMessage && (
                <div className="alert alert-danger" role="alert">
                  {FailMessage}
                </div>
              )}
              <div className="col-2">
                <div className="form-outline">
                  <input type="text" id="Name" className="form-control" placeholder="Name" value={Name} onChange={(e) => setName(e.target.value)} />
                </div>
                <div className="form-outline">
                  <input
                    type="email"
                    id="Email"
                    className="form-control"
                    placeholder="Email"
                    value={Email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-1">
                <div className="form-outline">
                  <textarea
                    className="form-control"
                    id="form4Example3"
                    rows="4"
                    placeholder="Write Comment Here"
                    value={Comment}
                    onChange={(e) => setComment(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className="col-1 button-div">
                <button className="action-btn small-btn" onClick={() => handleSubmitClick()}>
                  {Loading ? (
                    <div className="spinner-border text-primary" role="status" style={{ margin: "auto", height: "15px", width: "15px" }}>
                      {/* <span className="sr-only">Loading...</span> */}
                    </div>
                  ) : (
                    "Post Comment"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Recentcoments;
