import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GetHeaderData } from "../query/Graphql";
import NoResultFound from "./NoResultFound/NoResultFound";
import RenderWithSubItem from "./RenderWithSubItem";

const SubCatItems = ({
  subCatData,
  setIsSubMenuOpen,
  parentCatName,
  parentBreadCrumbs,
  handleMenuClick,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a 3-second loading delay
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (!subCatData) {
    if (isLoading) {
      return (
        <ul className="mega-col">
          {Array.from({ length: 9 }).map((_, index) => (
            <li>
              <a href="#">
                <div className="skeleton-container">
                  {/* <div className="skeleton-avatar"></div> */}
                  {/* <div className="skeleton-text"></div> */}
                </div>
              </a>
            </li>
          ))}
        </ul>
      );
    }
    return (
      <ul className="mega-col">
        <NoResultFound title={"No Data Found!!"} />
      </ul>
    );
  }
  return (
    <ul className="mega-col">
      {subCatData &&
        subCatData.map((data) => (
          <RenderWithSubItem
            data={data}
            setIsSubMenuOpen={setIsSubMenuOpen}
            parentCatName={parentCatName}
            parentBreadCrumbs={parentBreadCrumbs}
            handleMenuClick={handleMenuClick}
          />
        ))}
    </ul>
  );
};

export default SubCatItems;
