import React from "react";
import OwlCarousel from "react-owl-carousel";
import Mainban from "../../assets/images/img1.png";
import Mainban2 from "../../assets/images/img2.png";
import Mainban3 from "../../assets/images/img3.png";
import Mainban4 from "../../assets/images/img4.png";
import bag from "../../assets/images/bag.png";
import GetMessage from "../../utils/GetMessage";

const Productlistaddslider = ({ product, loading }) => {
  // product?.top_benefits;
  const options = {
    loop: false,
    margin: 30,
    responsiveClass: true,
    nav: true,
    autoHeight: true,
    items: 3,
    dots: false,
    autoplay: false,
    slideBy: 1,
    smartSpeed: 800,
    responsive: {
      0: {
        items: 1,
        margin: 10,
      },
      768: {
        items: 2,
      },
      992: {
        items: 3,
      },
    },
  };
  if (loading) {
    return (
      <section className="product-add-slider">
        <div className="">
          <div className="slider-main">
            <div className="item first-bg">
              <div className="skeleton-text mb-4" style={{ height: "300px" }} />
              <div className="skeleton-text mb-4" style={{ height: "300px" }} />
              <div className="skeleton-text mb-4" style={{ height: "300px" }} />
            </div>
          </div>
        </div>
      </section>
    );
  }

  if (!product?.top_benefits?.length > 0) {
    return null;
  }
  const colorClass = ["first-bg", "secnd-bg", "thrd-bg", "forth-bg"];
  return (
    <section className="product-add-slider">
      <div className="">
        <h3 className="f-30 mt-4">{product?.benefits_title}</h3>
        <div className="slider-main">
          <OwlCarousel className="slider-wrp" {...options}>
            {product?.top_benefits.map((data, index) => (
              <>
                <div className={`item ${colorClass[index % colorClass.length]}`}>
                  <div className="content">
                    <h4>{data?.title}</h4>
                    <p>{data?.short_desc}</p>
                  </div>
                  <img src={data?.benefits_image_url} alt={data?.title} title={data?.title} className="inr-img" />
                </div>
              </>
            ))}
          </OwlCarousel>
        </div>
      </div>
    </section>
  );
};

export default Productlistaddslider;
