import React, { useEffect, useState } from "react";
import cardm from "../../assets/images/pro-card.png";
import cardb from "../../assets/images/card-back.jpg";
import { useQuery } from "@apollo/client";
import { COMPARE_WIDGET_QUERY } from "../../query/query";
import RenderInternalTab from "../RenderInternalTab";
import NoResultFound from "../NoResultFound/NoResultFound";
import GetMessage from "../../utils/GetMessage";

const CompareProductWidget = () => {
  const compare_product_widget_title = GetMessage("compare_product_widget_title");
  const compare_product_widget_subtitle = GetMessage("compare_product_widget_subtitle");
  const [activeTab, setActiveTab] = useState(1);
  const [activeInternalTab, setActiveInternalTab] = useState();
  const { loading, error, data, refetch } = useQuery(COMPARE_WIDGET_QUERY, {
    variables: { comparewidget: 1, cat_id: 0 },
  });
  console.log("compareWidget data", activeInternalTab, data?.comparewidgetcat?.comparewidgetcat);
  const TopLevelCats = data?.comparewidgetcat?.comparewidgetcat;
  // const TopLevelCats = data?.comparewidgetcat?.comparewidgetcat?.filter((item) => item.parent == null);
  useEffect(() => {
    if (TopLevelCats?.length > 0) {
      setActiveInternalTab(TopLevelCats[0]?.subcategories[0]?.id);
    }
  }, [TopLevelCats]);

  const handleTabChange = (tabNumber) => {
    setActiveTab(tabNumber);
    if (TopLevelCats[tabNumber - 1]?.subcategories?.length > 0) {
      setActiveInternalTab(TopLevelCats[tabNumber - 1]?.subcategories[0]?.id);
    } else {
      setActiveInternalTab();
    }
  };

  if (!data?.comparewidgetcat?.comparewidgetcat) {
    return null;
  }

  return (
    <section className="CompareProducts-wrp">
      <div className="container">
        <div className="CompareProductsInner">
          <h3>{compare_product_widget_title}</h3>
          <p className="big-p">{compare_product_widget_subtitle}</p>
          <div>
            <ul className="outerNav nav nav-tabs nav-fill">
              {TopLevelCats?.length > 0 &&
                TopLevelCats?.map((cat, index) => (
                  <li className="nav-item" key={cat.id}>
                    <button className={`nav-link ${activeTab === index + 1 ? "active" : ""}`} onClick={() => handleTabChange(index + 1)}>
                      {cat.catname}
                    </button>
                  </li>
                ))}
            </ul>
            <div className="tab-content mt-3">
              {TopLevelCats?.length > 0 &&
                TopLevelCats?.map((cat, index) => (
                  <div className={`tab-pane ${activeTab === index + 1 ? "active" : ""}`}>
                    {cat?.subcategories?.length == 0 ? (
                      <NoResultFound title={"No Product found!!"} subTitle={"Please change category."} />
                    ) : (
                      <RenderInternalTab
                        activeTab={activeTab}
                        mainTabCatName={cat?.catname}
                        subcategories={cat?.subcategories}
                        activeInternalTab={activeInternalTab}
                        setActiveInternalTab={setActiveInternalTab}
                      />
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompareProductWidget;
