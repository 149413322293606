import React, { useEffect, useState } from "react";
import RenderBannerByLayout from "../../components/RenderBannerByLayout";
import Html from "../Html";

const LeftBannerItem = ({ LeftBanner }) => {
  const [BannerImages, setBannerImages] = useState();
  const [BannerScript, setBannerScript] = useState();
  useEffect(() => {
    if (LeftBanner) {
      // console.log("LeftBanner");
      if (LeftBanner?.banner_upload_type == "0") {
        // it is array of image
        setBannerImages(LeftBanner?.banner_image);
      } else if (LeftBanner?.banner_upload_type == 1) {
        // it is script
        setBannerScript(LeftBanner?.banner_script);
      } else if (LeftBanner?.banner_upload_type == 2) {
        // it is html
        setBannerScript(LeftBanner?.banner_html);
      }
    }
  }, [LeftBanner]);

  return (
    <section className="pb-4">
      <div className="">
        {BannerScript && (
          <div className="post-is-script">
            <Html html={BannerScript} />
          </div>
        )}
        {BannerImages && <RenderBannerByLayout itemsArray={BannerImages} />}
      </div>
    </section>
  );
};

export default LeftBannerItem;
