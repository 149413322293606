import React from "react";
import "./CompanyDirectoryGallery.css";
import starEmpty from "../../../assets/images/starEmpty.svg";
import pr from "../../../assets/images/pr.jpg";
import loc from "../../../assets/images/loc.png";
import user from "../../../assets/images/user.png";
import call from "../../../assets/images/call.png";
import site from "../../../assets/images/site.png";
import mail from "../../../assets/images/mail.png";
import fb from "../../../assets/images/fb.png";
import instagram from "../../../assets/images/instagram.png";
import facebook from "../../../assets/images/facebook.png";
import youtube from "../../../assets/images/youtube.png";
import twitter from "../../../assets/images/twitter.png";
import linkedin from "../../../assets/images/linkedin.png";
import { Link, useParams } from "react-router-dom";
import Html from "../../../utils/Html";
import TopBannerSlider from "../../../utils/BannerLayout/TopBannerSlider";
import RatingStars from "../../RatingStars/RatingStars";

const CompanyDirectoryTop = ({ companyData }) => {
  const { companyName } = useParams();
  return (
    <div className="CompanyDirectoryGallery">
      <section className="compare-about">
        <div className="container">
          {companyData?.bannergallery && (
            <div className="mb-5">
              <TopBannerSlider topBanners={companyData?.bannergallery?.filter((item) => item.banner_position == "0")} />
            </div>
          )}
          <div className="cmp-inr">
            <div className="row abt-title-dv">
              <div className="col-md-12">
                <div className="d-flex">
                  <h1 style={{ marginRight: "10px" }}>{companyName || companyData?.name}</h1>
                  {companyData && companyData?.reviewscore != "review_disable" && (
                    <div className="star-div">
                      <RatingStars averageRating={companyData?.reviewscore || 0} />
                      {companyData?.reviewscore && <div>({companyData?.reviewscore || 0})</div>}
                    </div>
                  )}
                  {/* <p>
                    <span>5.0</span>
                    <b>(142 Reviews)</b>
                  </p> */}
                </div>
                <div className="">
                  <img style={{ maxHeight: "80px" }} src={companyData?.logo_url} alt={companyData?.logo_alt_tag} title={companyData?.logo_alt_tag} />
                </div>
              </div>
            </div>

            {companyData ? (
              <div className="row">
                <div className="col-md-9">
                  <div className="pro-detl-cont-wrp">
                    <h3 className="f-30">About</h3>
                    <div className="pro-detail-cnt-dv">
                      <Html html={companyData?.about_us} />
                    </div>
                  </div>
                </div>
                <div className="col-md-3 p-0">
                  <div className="abt-dtl-wrp">
                    <div className="abt-dtl">
                      <ul>
                        {companyData?.address && (
                          <li>
                            <Link>
                              <img src={loc} alt="" title="" />
                              <b>{companyName || companyData?.name}</b>
                              <br></br>
                              {companyData?.address}
                            </Link>
                          </li>
                        )}
                        {companyData?.contact_name && (
                          <li>
                            <Link>
                              <img src={user} alt="" title="" />
                              {companyData?.contact_name}
                            </Link>
                          </li>
                        )}
                        {companyData?.phonenumber && (
                          <li>
                            <Link to="Tel: +971 600 540000">
                              <img src={call} alt="" title="" />
                              {companyData?.phonenumber}
                            </Link>
                          </li>
                        )}
                        {companyData?.email && (
                          <li>
                            <Link to="mailto: emirates@nbd.com" className="lowercase">
                              <img src={mail} alt="" title="" />
                              {companyData?.email}
                            </Link>
                          </li>
                        )}
                        {companyData?.website && (
                          <li>
                            <a href={companyData?.website} target="_blank" className="lowercase">
                              <img src={site} alt="" title="" />
                              {companyData?.website}
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                    <div className="social-list">
                      {(companyData?.facebook ||
                        companyData?.instagram ||
                        companyData?.twitter ||
                        companyData?.linkedin ||
                        companyData?.youtubelink) && (
                        <p>
                          <b>{companyName || companyData?.name} on Social</b>
                        </p>
                      )}
                      <ul className="d-flex p-0">
                        {companyData?.facebook && (
                          <li>
                            <a href={companyData?.facebook} target="_blank">
                              <img src={facebook} alt="" title="" />
                            </a>
                          </li>
                        )}
                        {companyData?.instagram && (
                          <li>
                            <a href={companyData?.instagram} target="_blank">
                              <img src={instagram} alt="" title="" />
                            </a>
                          </li>
                        )}
                        {companyData?.twitter && (
                          <li>
                            <a href={companyData?.twitter} target="_blank">
                              <img src={twitter} alt="" title="" />
                            </a>
                          </li>
                        )}
                        {companyData?.linkedin && (
                          <li>
                            <a href={companyData?.linkedin} target="_blank">
                              <img src={linkedin} alt="" title="" />
                            </a>
                          </li>
                        )}
                        {companyData?.youtubelink && (
                          <li>
                            <a href={companyData?.youtubelink} target="_blank">
                              <img src={youtube} alt="" title="" />
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                    {companyData?.website && (
                      <div className="btn-div">
                        <a
                          onClick={() => {
                            var newWindow = window.open("/Redirect");
                            newWindow.applynow_button_url = companyData?.website;
                          }}
                          // href={companyData?.website}
                          className="action-btn small-btn"
                          target="_blank"
                          style={{ cursor: "pointer" }}
                        >
                          Visit Secure Site
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="skeleton-container" style={{ minHeight: "200px" }}></div>
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default CompanyDirectoryTop;
