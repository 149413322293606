import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import money from "../../assets/images/money.jpg";
import down from "../../assets/images/dwn.svg";
import axios from "axios";
import { CurrencyRateUrl } from "../..";

const CurrencyConverterWidget = () => {
  const [loading, setLoading] = useState(false);
  const [From, setFrom] = useState("AED");
  const [To, setTo] = useState("INR");
  const [Amount, setAmount] = useState(1);
  const [Answer, setAnswer] = useState();
  const handleInputChange = (e) => {
    switch (e.target.id) {
      case "From":
        setFrom(e.target.value);
        break;
      case "To":
        setTo(e.target.value);
        break;
      case "Amount":
        if (e.target.value < 1) {
          setAmount(1);
        } else {
          setAmount(e.target.value);
        }
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    if (!loading) {
      setLoading(true);
      axios
        .get(CurrencyRateUrl + `&base=${From}&currencies=${To}`)
        .then((response) => {
          console.log("Answer", response?.data);
          const myAnswer = Amount * response?.data?.rates[To];
          setAnswer(myAnswer);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    }
  }, [From, To, Amount]);
  const formatAmountWithCommas = (amount) => {
    // Convert amount to a string
    const amountString = amount.toString();

    // Split the amount into whole number and fractional parts
    const [wholeNumber, fractionalPart] = amountString.split(".");

    // Format the whole number part with commas
    const formattedWholeNumber = wholeNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // If there is a fractional part, concatenate it back to the formatted whole number
    const formattedAmount = fractionalPart ? `${formattedWholeNumber}.${fractionalPart}` : formattedWholeNumber;

    return formattedAmount;
  };
  const GetNameFromCurrencyCode = (code) => {
    switch (code) {
      case "INR":
        return "Indian Rupee";
        break;
      case "AED":
        return "UAE Dirham";
        break;
      case "USD":
        return "United States Dollar";
        break;
      case "GBP":
        return "Pound Sterling";
        break;
      case "EUR":
        return "European Euro";
        break;
      default:
        break;
    }
  };
  return (
    <div className="convrt-in">
      <div className="convrt-img">
        <img src={money} alt={money} />
      </div>
      <div className="convrt-content">
        <Link to={"/Currency Converter"}>
          <h5>Currency Converter</h5>
        </Link>
        <div className="curency-div d-flex align-items-center">
          <select className="calculatorFont" value={From} id="From" onChange={(event) => handleInputChange(event)}>
            <option value={"AED"} selected>
              AED
            </option>
            <option value={"INR"}>INR</option>
            <option value={"USD"}>USD</option>
            <option value={"GBP"}>GBP</option>
            <option value={"EUR"}>EUR</option>
          </select>
          <div className="px-2">To</div>
          <select className="calculatorFont" value={To} id="To" onChange={(event) => handleInputChange(event)}>
            <option value={"INR"} selected>
              INR
            </option>
            <option value={"AED"}>AED</option>
            <option value={"USD"}>USD</option>
            <option value={"GBP"}>GBP</option>
            <option value={"EUR"}>EUR</option>
          </select>
        </div>
        <p>
          1.00 {GetNameFromCurrencyCode(From)} =<br></br>{" "}
          <span className="blu-txt d-flex">
            {loading || !Answer ? (
              <div className="skeleton-text" style={{ height: "30px", width: "100px", marginRight: "5px", marginLeft: "5px" }} />
            ) : (
              formatAmountWithCommas(parseFloat(Answer.toFixed(4)))
            )}{" "}
            {GetNameFromCurrencyCode(To)}
          </span>
        </p>
        <Link to={"/Currency Converter"} className="viewmore font-16">
          View details <img src={down} alt={down} />
        </Link>
      </div>
    </div>
  );
};

export default CurrencyConverterWidget;
