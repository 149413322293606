import { useLazyQuery, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { GET_COMPARE_GROUP_BY_SINGLE_PID } from "../../query/query";
import ProductCompareTable from "./ProductCompareTable";

const Comparetable = () => {
  const compareItems = useSelector((state) => state.rootReducer?.CompareItems);
  const [fetchCompareProductData1, { loading, error, data: data1 }] = useLazyQuery(GET_COMPARE_GROUP_BY_SINGLE_PID, {
    fetchPolicy: "no-cache",
  });
  const [fetchCompareProductData2, { loading: loading2, error: error2, data: data2 }] = useLazyQuery(GET_COMPARE_GROUP_BY_SINGLE_PID, {
    fetchPolicy: "no-cache",
  });
  const [fetchCompareProductData3, { loading: loading3, error: error3, data: data3 }] = useLazyQuery(GET_COMPARE_GROUP_BY_SINGLE_PID, {
    fetchPolicy: "no-cache",
  });
  const [Pro1, setPro1] = useState();
  const [Pro2, setPro2] = useState();
  const [Pro3, setPro3] = useState();
  useEffect(() => {
    const fetchData = async () => {
      if (compareItems?.length > 0) {
        setPro1();
        setPro2();
        setPro3();
        try {
          if (compareItems?.length > 2) {
            const result1 = await fetchCompareProductData1({
              variables: { id: compareItems[0].id },
            });
            console.log("data1", result1.data);
            setPro1(result1.data);

            const result2 = await fetchCompareProductData2({
              variables: { id: compareItems[1].id },
            });
            console.log("data2", result2.data);
            setPro2(result2.data);

            const result3 = await fetchCompareProductData3({
              variables: { id: compareItems[2].id },
            });
            console.log("data3", result3.data);
            setPro3(result3.data);
          } else {
            const result1 = await fetchCompareProductData1({
              variables: { id: compareItems[0].id },
            });
            console.log("data1", result1.data);
            setPro1(result1.data);

            const result2 = await fetchCompareProductData2({
              variables: { id: compareItems[1].id },
            });
            console.log("data2", result2.data);
            setPro2(result2.data);
          }
        } catch (error) {
          // Handle errors here
          console.error("Error fetching data:", error.message);
        }
      }
    };

    fetchData();
  }, [compareItems]);

  // Log the data when it becomes available
  // console.log("data1", data1);
  // console.log("data2", data2);
  // console.log("data3", data3);

  return (
    <section className="compare-page-table-wrp">
      <div className="">
        <div className="row">
          <div className="col-md-12">
            <div className="table-in">
              <ProductCompareTable
                product1={Pro1?.compareGroupMain?.compareGroupMain}
                product2={Pro2?.compareGroupMain?.compareGroupMain}
                product3={Pro3?.compareGroupMain?.compareGroupMain || null}
                loading={loading || loading2 || loading3}
              />
              {/* <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Card Level</th>
                    <th scope="col">Standard</th>
                    <th scope="col">Premium</th>
                    <th scope="col">Standard</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Number Free Supplementary</th>
                    <td>99</td>
                    <td>00</td>
                    <td>03</td>
                  </tr>
                  <tr>
                    <th scope="row">Interest Free Days</th>
                    <td>45</td>
                    <td>50</td>
                    <td>55</td>
                  </tr>
                  <tr>
                    <th scope="row">Annual Fee</th>
                    <td>
                      <b>AED 0</b>
                      <span>(For 12 months thereafter AED 48)</span>
                    </td>
                    <td>
                      <b>AED 375</b>
                      <span>(For 12 months thereafter AED 48)</span>
                    </td>
                    <td>
                      <b>AED 0</b>
                      <span>(For 12 months thereafter AED 48)</span>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Maximum Credit Limit</th>
                    <td>AED 80K</td>
                    <td>AED 75K</td>
                    <td>No set max</td>
                  </tr>
                  <tr>
                    <th scope="row">Late Payment Fee</th>
                    <td>
                      <b>AED 0</b>
                    </td>
                    <td>
                      <b>AED 35</b>
                    </td>
                    <td>
                      <b>AED 0</b>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Minimum Credit Limit</th>
                    <td>AED 500</td>
                    <td>AED 15K</td>
                    <td>AED 500</td>
                  </tr>
                  <tr>
                    <th scope="row">Purchase Rate</th>
                    <td>
                      <b>10.50%</b>P.A
                    </td>
                    <td>
                      <b>15.50%</b>P.A
                    </td>
                    <td>
                      <b>12.20%</b>P.A
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Cash Advance Rate</th>
                    <td>
                      <b>17.99%</b>P.A
                    </td>
                    <td>
                      <b>21.24%</b>P.A
                    </td>
                    <td>
                      <b>17.20%</b>P.A
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Cash Advance Fee</th>
                    <td>-</td>
                    <td>3%</td>
                    <td>3%</td>
                  </tr>
                </tbody>
              </table> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Comparetable;
