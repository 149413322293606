import React, { useState } from "react";
import Blog1 from "../../../src/assets/images/blog1.jpg";
import Blog2 from "../../../src/assets/images/blog-m.jpg";
import { Link } from "react-router-dom";
import PlaceHolder from "../../assets/images/PlaceHolder.png";
const Financesingle = ({ blog }) => {
  const getTextFromHtml = (htmlContent) => {
    // Create a temporary element
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlContent;

    // Get text content
    const textFromHTML = tempElement.textContent || tempElement.innerText;
    return textFromHTML.slice(0, 300) + "...";
  };
  if (!blog?.image) {
    return (
      <div className="col-md-12" key={blog?.ID}>
        <div className="FLSingleBlog l-blog">
          <div className="rgt">
            <div className="date">{blog?.publish_time}</div>
            {/* <div className="posted-by">Posted By : {blog?.author}</div> */}

            <h4>
              <Link to={`/Financial Literacy/${blog?.slug}`} style={{ display: "flex", color: "inherit" }}>
                {blog?.title}{" "}
              </Link>
            </h4>

            {/* <p className="small-p">
              <a href={blog?.blog_url} target="_blank" style={{ display: "flex", color: "inherit" }}>
                {blog?.blog_url}
              </a>
            </p> */}
            <p>
              {getTextFromHtml(blog?.content)}
              <Link className="read-more" to={`/Financial Literacy/${blog?.slug}`}>
                Read more
              </Link>
            </p>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="col-md-12" key={blog?.ID}>
      <div className="FLSingleBlog l-blog">
        <a>
          <div className="b-img">
            <img src={blog?.image} alt={blog?.ID} title={blog?.title} />
          </div>
          <div className="rgt">
            <div className="date">{blog?.publish_time}</div>
            {/* <div className="posted-by">Posted By : {blog?.author}</div> */}

            <h4>
              <Link to={`/Financial Literacy/${blog?.slug}`} style={{ display: "flex", color: "inherit" }}>
                {blog?.title}{" "}
              </Link>
            </h4>

            {/* <p className="small-p">
              <a href={blog?.blog_url} target="_blank" style={{ display: "flex", color: "inherit" }}>
                {blog?.blog_url}
              </a>
            </p> */}
            {/* <p>{blog?.content}</p> */}

            <p>
              {getTextFromHtml(blog?.content)}
              {/* {blog?.content.slice(0, 300)} */}

              <Link className="read-more" to={`/Financial Literacy/${blog?.slug}`}>
                Read more
              </Link>
            </p>
            {/* <Link
              to={`/Financial Literacy/${blog?.slug}`}
              className="read-more"
            >
              Read More
            </Link> */}
          </div>
        </a>
      </div>
    </div>
  );
};

export default Financesingle;
