import React, { useEffect, useState } from "react";
import Blog1 from "../../assets/images/blog1.jpg";
import OwlCarousel from "react-owl-carousel";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { BlogBaseUrl } from "../..";

const Recentcoments = ({ Blog }) => {
  const { BlogSlug } = useParams();
  const [loading, setLoading] = useState(false);
  const [RelatedBlog, setRelatedBlog] = useState([]);
  useEffect(() => {
    if (Blog?.ID?.ID) {
      setLoading(true);
      axios
        .get(BlogBaseUrl + `wp-json/custom/v1/related-posts/${Blog?.ID?.ID}`)
        .then((response) => {
          setRelatedBlog(response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    }
  }, [Blog]);
  const owlCarouselOptions = {
    loop: false,
    dots: false,
    items: 4,
    autoplay: false,
    nav: true,
    margin: 30,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 2,
      },
      768: {
        items: 3,
      },
      1180: {
        items: 4,
      },
    },
    responsiveBaseElement: ".recent-coment-wrp",
  };
  console.log("Blog", RelatedBlog);
  if (!RelatedBlog?.length > 0) {
    return null;
  }
  return (
    <section className="recent-coment-wrp pt-0">
      <div className="container">
        <div className="">
          <h4 className="mb-4 detailRelatedTitle">Related Posts</h4>
          <OwlCarousel className="client-review-slider owl-carousel" {...owlCarouselOptions}>
            {RelatedBlog?.map((blg) => (
              <div className="related-post-single" key={blg?.ID}>
                {blg?.image && (
                  <div className="b-img">
                    <img src={blg?.image} alt={blg?.title} title={blg?.title} />
                  </div>
                )}
                <div className="recent-content">
                  <h4>
                    <Link to={`/Financial Literacy/${blg?.slug}`} style={{ display: "flex", color: "inherit" }}>
                      {blg?.title}{" "}
                    </Link>
                  </h4>
                  {/* <p className="small-p">
                    <a href={blg?.blog_url} target="_blank" style={{ color: "inherit", wordWrap: "break-word" }}>
                      {blg?.blog_url}
                    </a>
                  </p> */}
                  <div className="date">{blg?.publish_time}</div>
                </div>
              </div>
            ))}
          </OwlCarousel>
        </div>
      </div>
    </section>
  );
};

export default Recentcoments;
