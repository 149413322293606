import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./KnowledgeNuggetList.css";
import { Dropdown } from "bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentNuggetId, setCurrentNuggetTerm } from "../../stores/rootReducer";
import NoResultFound from "../../components/NoResultFound/NoResultFound";
import { useQuery } from "@apollo/client";
import { GET_KNOWLEDGE_NUGGETS_WITH_TERM } from "../../query/query";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import GetMessage from "../../utils/GetMessage";
import { GetSinglePagesDataFromSlug } from "../../query/Graphql";
import BottomBannerSlider from "../../utils/BannerLayout/BottomBannerSlider";
import Html from "../../utils/Html";
import WithBannersLayout from "../../utils/WithBannersLayout/WithBannersLayout";
import WithMetaByData from "../../utils/WithMetaByData";
import TopBannerSlider from "../../utils/BannerLayout/TopBannerSlider";

const KnowledgeNuggetList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const NuggetsTerm = useSelector((state) => state.rootReducer.currentNuggetTerm || "A");
  const [SearchInput, setSearchInput] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [dataList, setDataList] = useState([]);
  const [HideLoadMore, setHideLoadMore] = useState(false);

  const { loading, error, data, refetch } = useQuery(GET_KNOWLEDGE_NUGGETS_WITH_TERM, {
    variables: { keyword: NuggetsTerm, pageCount, page },
  });
  const pageData = GetSinglePagesDataFromSlug("knowledge_nuggets");
  // When data is loaded or refetched, update the dataList state
  useEffect(() => {
    if (!loading && data && data?.searchknowledgenuggets?.searchknowledgenuggets) {
      const newDataList = data?.searchknowledgenuggets?.searchknowledgenuggets?.filter((newItem) => !dataList.some((item) => item.id === newItem.id));

      if (newDataList.length > 0) {
        setDataList((prevDataList) => [...prevDataList, ...newDataList]);
      }

      if (dataList.length == data?.searchknowledgenuggets?.totalCount) {
        setHideLoadMore(true);
      }
    }
  }, [loading, data, dataList]);

  useEffect(() => {
    setDataList([]);
    setPageCount(10);
    setPage(1);
    refetch({ keyword: NuggetsTerm, pageCount: pageCount, page: page });
  }, [NuggetsTerm]);

  useEffect(() => {
    return () => {
      setDataList([]);
    };
  }, []);

  const handleLoadMore = () => {
    setPage(page + 1);
  };

  // console.log("dataList", NuggetsTerm, dataList);
  const renderLoader = () => {
    if (loading && page == 1) {
      return Array.from({ length: 3 }).map((_, index) => <div className="skeleton-container mb-4" style={{ minHeight: "130px" }}></div>);
    }
    return <NoResultFound title={"No nuggets found!!"} subTitle={"Please try again with another keyword or maybe use generic term"} />;
  };
  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
    if (fetchingNewBySearch) {
      clearTimeout(fetchingNewBySearch);
    } else {
      var fetchingNewBySearch = setTimeout(() => {
        if (e.target.value?.length > 2) {
          dispatch(setCurrentNuggetTerm(e.target.value));
        }
      }, 1000);
    }
  };
  return (
    <section className="KnowledgeNuggetList">
      <Breadcrumb />
      <div className="mb-3" />
      <WithBannersLayout
        leftBanners={pageData?.banners?.filter((item) => item.banner_position == "3")}
        rightBanners={pageData?.banners?.filter((item) => item.banner_position == "4")}
      >
        <WithMetaByData data={pageData?.page_meta} />
        <div className="container">
          <TopBannerSlider topBanners={pageData?.banners?.filter((item) => item.banner_position == "0")} />
          <div className="KnowledgeNuggetList-row one">
            <div className="title big-p text-black font-bold d-flex justify-content-between">
              <h5>Knowledge Nuggets</h5>
              <div className="search-dv">
                <form className="search" action="search.php">
                  <input placeholder="Search..." type="search" value={SearchInput} onChange={handleSearchInputChange} />
                  <a className="src-icn">
                    <img src="/static/media/search-icn.0ddd2811f5c381afba35f97f9afb79a9.svg" alt />
                  </a>
                </form>
              </div>
            </div>
          </div>
          <div className="KnowledgeNuggetList-row abcd-sec">
            <ul className="d-flex">
              <li>
                <Link
                  to={"/Knowledge Nuggets"}
                  onClick={() => {
                    dispatch(setCurrentNuggetTerm("A"));
                    setSearchInput("");
                    setHideLoadMore(false);
                  }}
                  className={`${NuggetsTerm == "A" && "KnowledgeNuggetList-term-active"}`}
                >
                  A
                </Link>
              </li>
              <li>
                <Link
                  to={"/Knowledge Nuggets"}
                  onClick={() => {
                    dispatch(setCurrentNuggetTerm("B"));
                    setSearchInput("");
                    setHideLoadMore(false);
                  }}
                  className={`${NuggetsTerm == "B" && "KnowledgeNuggetList-term-active"}`}
                >
                  B
                </Link>
              </li>
              <li>
                <Link
                  to={"/Knowledge Nuggets"}
                  onClick={() => {
                    dispatch(setCurrentNuggetTerm("C"));
                    setSearchInput("");
                    setHideLoadMore(false);
                  }}
                  className={`${NuggetsTerm == "C" && "KnowledgeNuggetList-term-active"}`}
                >
                  C
                </Link>
              </li>
              <li>
                <Link
                  to={"/Knowledge Nuggets"}
                  onClick={() => {
                    dispatch(setCurrentNuggetTerm("D"));
                    setSearchInput("");
                    setHideLoadMore(false);
                  }}
                  className={`${NuggetsTerm == "D" && "KnowledgeNuggetList-term-active"}`}
                >
                  D
                </Link>
              </li>
              <li>
                <Link
                  to={"/Knowledge Nuggets"}
                  onClick={() => {
                    dispatch(setCurrentNuggetTerm("E"));
                    setSearchInput("");
                    setHideLoadMore(false);
                  }}
                  className={`${NuggetsTerm == "E" && "KnowledgeNuggetList-term-active"}`}
                >
                  E
                </Link>
              </li>
              <li>
                <Link
                  to={"/Knowledge Nuggets"}
                  onClick={() => {
                    dispatch(setCurrentNuggetTerm("F"));
                    setSearchInput("");
                    setHideLoadMore(false);
                  }}
                  className={`${NuggetsTerm == "F" && "KnowledgeNuggetList-term-active"}`}
                >
                  F
                </Link>
              </li>
              <li>
                <Link
                  to={"/Knowledge Nuggets"}
                  onClick={() => {
                    dispatch(setCurrentNuggetTerm("G"));
                    setSearchInput("");
                    setHideLoadMore(false);
                  }}
                  className={`${NuggetsTerm == "G" && "KnowledgeNuggetList-term-active"}`}
                >
                  G
                </Link>
              </li>
              <li>
                <Link
                  to={"/Knowledge Nuggets"}
                  onClick={() => {
                    dispatch(setCurrentNuggetTerm("H"));
                    setSearchInput("");
                    setHideLoadMore(false);
                  }}
                  className={`${NuggetsTerm == "H" && "KnowledgeNuggetList-term-active"}`}
                >
                  H
                </Link>
              </li>
              <li>
                <Link
                  to={"/Knowledge Nuggets"}
                  onClick={() => {
                    dispatch(setCurrentNuggetTerm("I"));
                    setSearchInput("");
                    setHideLoadMore(false);
                  }}
                  className={`${NuggetsTerm == "I" && "KnowledgeNuggetList-term-active"}`}
                >
                  I
                </Link>
              </li>
              <li>
                <Link
                  to={"/Knowledge Nuggets"}
                  onClick={() => {
                    dispatch(setCurrentNuggetTerm("J"));
                    setSearchInput("");
                    setHideLoadMore(false);
                  }}
                  className={`${NuggetsTerm == "J" && "KnowledgeNuggetList-term-active"}`}
                >
                  J
                </Link>
              </li>
              <li>
                <Link
                  to={"/Knowledge Nuggets"}
                  onClick={() => {
                    dispatch(setCurrentNuggetTerm("K"));
                    setSearchInput("");
                    setHideLoadMore(false);
                  }}
                  className={`${NuggetsTerm == "K" && "KnowledgeNuggetList-term-active"}`}
                >
                  K
                </Link>
              </li>
              <li>
                <Link
                  to={"/Knowledge Nuggets"}
                  onClick={() => {
                    dispatch(setCurrentNuggetTerm("L"));
                    setSearchInput("");
                    setHideLoadMore(false);
                  }}
                  className={`${NuggetsTerm == "L" && "KnowledgeNuggetList-term-active"}`}
                >
                  L
                </Link>
              </li>
              <li>
                <Link
                  to={"/Knowledge Nuggets"}
                  onClick={() => {
                    dispatch(setCurrentNuggetTerm("M"));
                    setSearchInput("");
                    setHideLoadMore(false);
                  }}
                  className={`${NuggetsTerm == "M" && "KnowledgeNuggetList-term-active"}`}
                >
                  M
                </Link>
              </li>
              <li>
                <Link
                  to={"/Knowledge Nuggets"}
                  onClick={() => {
                    dispatch(setCurrentNuggetTerm("N"));
                    setSearchInput("");
                    setHideLoadMore(false);
                  }}
                  className={`${NuggetsTerm == "N" && "KnowledgeNuggetList-term-active"}`}
                >
                  N
                </Link>
              </li>
              <li>
                <Link
                  to={"/Knowledge Nuggets"}
                  onClick={() => {
                    dispatch(setCurrentNuggetTerm("O"));
                    setSearchInput("");
                    setHideLoadMore(false);
                  }}
                  className={`${NuggetsTerm == "O" && "KnowledgeNuggetList-term-active"}`}
                >
                  O
                </Link>
              </li>
              <li>
                <Link
                  to={"/Knowledge Nuggets"}
                  onClick={() => {
                    dispatch(setCurrentNuggetTerm("P"));
                    setSearchInput("");
                    setHideLoadMore(false);
                  }}
                  className={`${NuggetsTerm == "P" && "KnowledgeNuggetList-term-active"}`}
                >
                  P
                </Link>
              </li>
              <li>
                <Link
                  to={"/Knowledge Nuggets"}
                  onClick={() => {
                    dispatch(setCurrentNuggetTerm("Q"));
                    setSearchInput("");
                    setHideLoadMore(false);
                  }}
                  className={`${NuggetsTerm == "Q" && "KnowledgeNuggetList-term-active"}`}
                >
                  Q
                </Link>
              </li>
              <li>
                <Link
                  to={"/Knowledge Nuggets"}
                  onClick={() => {
                    dispatch(setCurrentNuggetTerm("R"));
                    setSearchInput("");
                    setHideLoadMore(false);
                  }}
                  className={`${NuggetsTerm == "R" && "KnowledgeNuggetList-term-active"}`}
                >
                  R
                </Link>
              </li>
              <li>
                <Link
                  to={"/Knowledge Nuggets"}
                  onClick={() => {
                    dispatch(setCurrentNuggetTerm("S"));
                    setSearchInput("");
                    setHideLoadMore(false);
                  }}
                  className={`${NuggetsTerm == "S" && "KnowledgeNuggetList-term-active"}`}
                >
                  S
                </Link>
              </li>
              <li>
                <Link
                  to={"/Knowledge Nuggets"}
                  onClick={() => {
                    dispatch(setCurrentNuggetTerm("T"));
                    setSearchInput("");
                    setHideLoadMore(false);
                  }}
                  className={`${NuggetsTerm == "T" && "KnowledgeNuggetList-term-active"}`}
                >
                  T
                </Link>
              </li>
              <li>
                <Link
                  to={"/Knowledge Nuggets"}
                  onClick={() => {
                    dispatch(setCurrentNuggetTerm("U"));
                    setSearchInput("");
                    setHideLoadMore(false);
                  }}
                  className={`${NuggetsTerm == "U" && "KnowledgeNuggetList-term-active"}`}
                >
                  U
                </Link>
              </li>
              <li>
                <Link
                  to={"/Knowledge Nuggets"}
                  onClick={() => {
                    dispatch(setCurrentNuggetTerm("V"));
                    setSearchInput("");
                    setHideLoadMore(false);
                  }}
                  className={`${NuggetsTerm == "V" && "KnowledgeNuggetList-term-active"}`}
                >
                  V
                </Link>
              </li>
              <li>
                <Link
                  to={"/Knowledge Nuggets"}
                  onClick={() => {
                    dispatch(setCurrentNuggetTerm("W"));
                    setSearchInput("");
                    setHideLoadMore(false);
                  }}
                  className={`${NuggetsTerm == "W" && "KnowledgeNuggetList-term-active"}`}
                >
                  W
                </Link>
              </li>
              <li>
                <Link
                  to={"/Knowledge Nuggets"}
                  onClick={() => {
                    dispatch(setCurrentNuggetTerm("X"));
                    setSearchInput("");
                    setHideLoadMore(false);
                  }}
                  className={`${NuggetsTerm == "X" && "KnowledgeNuggetList-term-active"}`}
                >
                  X
                </Link>
              </li>
              <li>
                <Link
                  to={"/Knowledge Nuggets"}
                  onClick={() => {
                    dispatch(setCurrentNuggetTerm("Y"));
                    setSearchInput("");
                    setHideLoadMore(false);
                  }}
                  className={`${NuggetsTerm == "Y" && "KnowledgeNuggetList-term-active"}`}
                >
                  Y
                </Link>
              </li>
              <li>
                <Link
                  to={"/Knowledge Nuggets"}
                  onClick={() => {
                    dispatch(setCurrentNuggetTerm("Z"));
                    setSearchInput("");
                    setHideLoadMore(false);
                  }}
                  className={`${NuggetsTerm == "Z" && "KnowledgeNuggetList-term-active"}`}
                >
                  Z
                </Link>
              </li>
              {/* <li>
              <Link to={"/Knowledge Nuggets"}>View All</Link>
            </li> */}
            </ul>
          </div>
          <div className="Terms-Beginning font-bold">
            {GetMessage("knowledge_nugget_list_title") || "Terms Beginning With"} '{NuggetsTerm}'
          </div>
          <ul className="listingsArray">
            {dataList.length > 0 ? (
              dataList.map((listItem) => (
                <li>
                  <div className="heading">{listItem.keyword}</div>
                  {listItem.short_desc}{" "}
                  <span>
                    <Link
                      to={`/Knowledge Nuggets/${listItem.keyword}`}
                      onClick={() => {
                        dispatch(setCurrentNuggetId(listItem?.id));
                        localStorage.setItem("CurrentNuggetId", listItem?.id);
                      }}
                    >
                      Read More
                    </Link>
                  </span>
                </li>
              ))
            ) : (
              <>{renderLoader()}</>
            )}
          </ul>
          {!HideLoadMore && dataList.length > 0 && (
            <div className="viewmore-div">
              {loading ? (
                <>
                  <div className="spinner-border text-primary" role="status" style={{ height: "22px", width: "22px" }} />
                </>
              ) : (
                <Link className="viewmore" onClick={() => handleLoadMore()}>
                  Load more
                </Link>
              )}
            </div>
          )}
        </div>
        <BottomBannerSlider bottomBanner={pageData?.banners?.filter((item) => item.banner_position == "1")} />
        <div className="py-4">
          <Html html={pageData?.footer?.description} />
        </div>
      </WithBannersLayout>
    </section>
  );
};

export default KnowledgeNuggetList;
