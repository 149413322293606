import React, { useState } from "react";
import "./SignUp.css";
import newFKCROPLOGO from "../../assets/images/newFKCROPLOGO.svg";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { LOGIN_MUTATION, REGISTER_MUTATION, RESET_PASS_MUTATION } from "../../query/mutation";
import sendNotification from "../../utils/SendNotification";
import EmailIcon from "../../assets/icons/EmailIcon";
import PasswordIcon from "../../assets/icons/PasswordIcon";
import { GetSinglePagesDataFromSlug } from "../../query/Graphql";
import AuthLayoutBySlug from "./AuthLayoutBySlug";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { FacebookAppId, GoogleClientId } from "../..";
// import GoogleLogin from "react-google-login";
import { GoogleLogin } from "@react-oauth/google";
import { useGoogleLogin } from "@react-oauth/google";
import GoogleIcon from "../../assets/icons/GoogleIcon";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import FacebookIcon from "../../assets/icons/FacebookIcon";
import Html from "../../utils/Html";
import ticvk from "../../assets/images/view-more-rgt.svg";

const SignIn = () => {
  const navigate = useNavigate();
  const pageData = GetSinglePagesDataFromSlug("customer-login");
  const CurrentUserData = JSON.parse(sessionStorage.getItem("User"));
  if (CurrentUserData?.id) {
    navigate("/");
  }
  const [Email, setEmail] = useState();
  const [Password, setPassword] = useState();
  const [IsForgetPassword, setIsForgetPassword] = useState(false);
  const [EmailError, setEmailError] = useState(false);
  const [PassError, setPassError] = useState(false);
  const [ResetPassLinkSet, setResetPassLinkSet] = useState(false);
  const [login, { loading, error }] = useMutation(LOGIN_MUTATION);
  const [resetPass, {}] = useMutation(RESET_PASS_MUTATION);
  const [register, {}] = useMutation(REGISTER_MUTATION);

  const handleInputChange = (e) => {
    switch (e.target.id) {
      case "email":
        setEmail(e.target.value);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (e.target.value && emailRegex.test(e.target.value)) {
          setEmailError(false);
        } else {
          setEmailError(true);
        }
        break;
      case "password":
        setPassword(e.target.value);
        if (e.target.value) {
          setPassError(false);
        } else {
          setPassError(true);
        }
        break;
      default:
        break;
    }
  };
  const handleSubmitClick = async (e) => {
    e.preventDefault();
    let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (IsForgetPassword) {
      if (Email && emailRegex.test(Email)) {
        try {
          const { data } = await resetPass({
            variables: {
              email: Email,
            },
          });
          // Handle the response data
          console.log(data?.requestpasswordreset?.success);
          if (data?.requestpasswordreset?.success == true) {
            // alert(data?.requestpasswordreset?.success);
            // sendNotification("success", data?.requestpasswordreset?.message);
            setResetPassLinkSet(true);
            // navigate("/");
          } else {
            sendNotification("error", data?.requestpasswordreset?.message);
          }
        } catch (error) {
          sendNotification("error", error);
        }
      } else {
        if (!Email || !emailRegex.test(Email)) {
          EmailError && setEmailError(false);
          setTimeout(() => {
            setEmailError(true);
          }, 50);
        }
      }
    } else {
      if (Email && emailRegex.test(Email) && Password) {
        try {
          const { data } = await login({
            variables: {
              email: Email,
              password: Password,
            },
          });
          // Handle the response data
          console.log(data?.login?.success);
          if (data?.login?.success == true) {
            // alert(data?.login?.success);
            sendNotification(
              "success",
              data?.login?.message + ", Welcome " + (data?.login?.loginuser?.first_name || data?.login?.loginuser?.last_name || "")
            );
            sessionStorage.setItem("User", JSON.stringify(data?.login?.loginuser));
            navigate("/");
            // setTimeout(() => document.getElementById("myAccountIconWrapper")?.click(), 200);
          } else {
            sendNotification("error", data?.login?.message);
          }
        } catch (error) {
          sendNotification("error", error);
        }
      } else {
        if (!Email || !emailRegex.test(Email)) {
          EmailError && setEmailError(false);
          setTimeout(() => {
            setEmailError(true);
          }, 50);
        }
        if (!Password) {
          PassError && setPassError(false);
          setTimeout(() => {
            setPassError(true);
          }, 50);
        }
      }
    }
  };

  const faceBookLogin = async (FBResponse) => {
    console.log("Facebook", FBResponse);
    // setUser(FBResponse)
    if (FBResponse) {
      try {
        const { data } = await register({
          variables: {
            first_name: FBResponse?.name || "Anonymous",
            last_name: "",
            email: FBResponse?.email ? FBResponse?.email : "",
            password: "",
            country_code: "",
            mobileno: "",
            social_id: FBResponse?.userID ? FBResponse?.userID : "",
            social_type: "facebook",
          },
        });
        // Handle the response data
        console.log(data?.register?.success);
        if (data?.register?.success == true) {
          // alert(data?.register?.success);
          sendNotification("success", data?.register?.message);
          sessionStorage.setItem("User", JSON.stringify(data?.register?.regcustomer));
          navigate("/");
          // setTimeout(() => document.getElementById("myAccountIconWrapper")?.click(), 200);
        } else {
          sendNotification("error", data?.register?.message);
        }
      } catch (error) {
        sendNotification("error", error);
      }
    }
  };
  const componentClicked = () => {
    console.log("Facebook clicked");
  };
  const GLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);
      // fetching userinfo can be done on the client or the server
      const userInfo = await axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then((res) => res.data);

      console.log(userInfo);
      try {
        const { data } = await register({
          variables: {
            first_name: userInfo?.given_name ? userInfo?.given_name : "",
            last_name: userInfo?.family_name ? userInfo?.family_name : "",
            email: userInfo?.email ? userInfo?.email : "",
            password: "",
            country_code: "",
            mobileno: "",
            social_id: userInfo?.sub ? userInfo?.sub : "",
            social_type: "google",
          },
        });
        // Handle the response data
        console.log(data?.register?.success);
        if (data?.register?.success == true) {
          // alert(data?.register?.success);
          sendNotification("success", data?.register?.message);
          sessionStorage.setItem("User", JSON.stringify(data?.register?.regcustomer));
          navigate("/");
          // setTimeout(() => document.getElementById("myAccountIconWrapper")?.click(), 200);
        } else {
          sendNotification("error", data?.register?.message);
        }
      } catch (error) {
        sendNotification("error", error);
      }
    },

    onError: () => {
      console.log("Login Failed");
    },
  });

  const renderCardContent = () => {
    if (IsForgetPassword && ResetPassLinkSet) {
      return (
        <div className="alert alert-success" role="alert">
          Password reset link has been sent successfully, kindly please check your email address.
        </div>
      );
      // return <div style={{ textAlign: "center" }}>Password reset link has been sent successfully, kindly please check your email address.</div>;
    }
    return (
      <>
        <div className="floating-label">
          <input
            value={Email}
            onChange={(e) => handleInputChange(e)}
            placeholder="Email"
            type="email"
            name="email"
            id="email"
            autoComplete="off"
            required
            pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
            title="Enter a valid email address"
            className={`${EmailError ? "error" : ""}`}
          />
          <label htmlFor="email">Email:</label>
          <div className={`icon ${EmailError ? "error" : ""}`}>
            <EmailIcon />
          </div>
        </div>
        {!IsForgetPassword && (
          <div className="floating-label">
            <input
              value={Password}
              onChange={(e) => handleInputChange(e)}
              placeholder="Password"
              type="password"
              name="password"
              id="password"
              autoComplete="off"
              required
              title="Enter Password"
              className={`${PassError ? "error" : ""}`}
            />
            <label htmlFor="password">Password:</label>
            <div className={`icon ${PassError ? "error" : ""}`}>
              <PasswordIcon />
            </div>
          </div>
        )}
        <div className="discrete" onClick={() => setIsForgetPassword(!IsForgetPassword)}>
          {IsForgetPassword ? "Back" : "Forgot password?"}
        </div>
        <button className="action-btn small-btn w-100 my-2 py-2 " onClick={(e) => handleSubmitClick(e)}>
          {loading ? (
            <div className="spinner-border text-primary" role="status" style={{ margin: "auto", height: "15px", width: "15px" }}>
              {/* <span className="sr-only">Loading...</span> */}
            </div>
          ) : IsForgetPassword ? (
            "Submit"
          ) : (
            "Log in"
          )}
          {/* {IsForgetPassword ? "Submit" : "Log in"} */}
        </button>
        {!IsForgetPassword && (
          <>
            <div className="d-flex w-100 align-items-center gap-3 my-3">
              <hr className="hr w-100" />
              <div>or</div>
              <hr className="hr w-100" />
            </div>
            <button className="action-btn small-btn w-100 my-2 py-2" onClick={() => navigate("/SignUp")}>
              Register
            </button>
          </>
        )}
        {/* {!IsForgetPassword && (
          <Link className="discrete" to={"/SignUp"}>
            Don't have an Account?
          </Link>
        )} */}
        {!IsForgetPassword && <Html html={pageData?.cms?.find((item) => item.slug == "customer-login-new")?.description} />}
      </>
    );
  };
  return (
    <AuthLayoutBySlug pageData={pageData} contentSlug="customer-login">
      <div className="wrapper">
        <div className="log-in formDiv">
          {/* <img className="authLogo" src={newFKCROPLOGO} alt="find Kaizen" title="find Kaizen" /> */}
          <div className="w-100">
            <h5 className="text-center formTitle mb-4">{IsForgetPassword ? "Forgot Password" : "SignIn to your Account"}</h5>
          </div>
          {!IsForgetPassword && (
            <>
              <FacebookLogin
                appId={FacebookAppId}
                autoLoad={false}
                fields="name, email"
                scope="public_profile,email"
                icon="fa-facebook"
                callback={faceBookLogin}
                onClick={componentClicked}
                render={(renderProps) => (
                  <button
                    className="action-btn small-btn w-100 my-2 py-2 d-flex align-items-center gap-3 justify-content-center"
                    onClick={renderProps.onClick}
                  >
                    <FacebookIcon /> Continue with Facebook
                  </button>
                )}
              />
              {/* <GoogleLogin
                clientId={GoogleClientId}
                buttonText="Login with Google"
                isSignedIn={true}
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={"single_host_origin"}
              /> */}
              {/* following is default button */}
              {/* <GoogleLogin
                onSuccess={(credentialResponse) => {
                  console.log(credentialResponse);
                }}
                onError={() => {
                  console.log("Login Failed");
                }}
                // useOneTap={false}
                text="continue_with"
                theme="filled_blue"
                shape="rectangular"
                size="large"
                width="500"
              /> */}
              <button
                className="action-btn small-btn w-100 my-2 py-2 d-flex align-items-center gap-3 justify-content-center"
                onClick={() => GLogin()}
              >
                <GoogleIcon /> <div>Continue with Google</div>
              </button>

              <div className="d-flex w-100 align-items-center gap-3 my-3">
                <hr className="hr w-100" />
                <div>or</div>
                <hr className="hr w-100" />
              </div>
            </>
          )}
          {renderCardContent()}
        </div>
      </div>
    </AuthLayoutBySlug>
  );
};

export default SignIn;
