import React from "react";
import { Link } from "react-router-dom";
import Card from "../../src/assets/images/card1.svg";
import { Dropdown } from "bootstrap";
import Html from "../utils/Html";
import { GetCMSData } from "../query/Graphql";

const Testimonial = ({ HomePageData }) => {
  // const TestimonialDescription = GetCMSData("home-about");
  return (
    <section className="py-5">
      <div className="container">
        {HomePageData?.cms ? (
          HomePageData?.cms?.find((item) => item.slug == "home-about") ? (
            <div className="test-s">
              <Html
                html={
                  HomePageData?.cms?.find((item) => item.slug == "home-about")
                    ?.description
                }
              />
            </div>
          ) : (
            <></>
          )
        ) : (
          <div className="skeleton-text" style={{ minHeight: "200px" }}></div>
        )}
      </div>
    </section>
  );
};

export default Testimonial;
