import { useQuery } from "@apollo/client";
import React from "react";
import { Link } from "react-router-dom";
import { GET_CRYPTO_TRADE_LINK } from "../../query/query";

const RenderTradeBtn = ({ code }) => {
  const { loading, error, data } = useQuery(GET_CRYPTO_TRADE_LINK, {
    variables: { code: code, title: "" },
  });
  //   const tradLink = GetCryptoTradLinkFromCode(code);
  //   console.log("tradLink", data?.singlecrypto?.singlecrypto?.trade_button_link);
  if (loading) {
    return (
      <div
        className="skeleton-text"
        style={{ width: "55px", height: "32px", margin: "auto" }}
      />
    );
  }
  if (!loading && !data?.singlecrypto?.singlecrypto?.trade_button_link) {
    return <div className="" style={{ width: "55px" }} />;
  }
  return (
    <a
      href={data?.singlecrypto?.singlecrypto?.trade_button_link}
      target="_blank"
    >
      <button type="button" className="btn btn-primary">
        Trade
      </button>
    </a>
  );
};

export default RenderTradeBtn;
