import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import TableWithPagination from "../../components/TableWithPagination/TableWithPagination";
import { GetIdFromUrlCategory, GetSinglePagesDataFromSlug } from "../../query/Graphql";
import { GET_CURRENT_CAT_DATA } from "../../query/query";
import BottomBannerSlider from "../../utils/BannerLayout/BottomBannerSlider";
import TopBannerSlider from "../../utils/BannerLayout/TopBannerSlider";
import Html from "../../utils/Html";
import WithBannersLayout from "../../utils/WithBannersLayout/WithBannersLayout";
import WithMetaByData from "../../utils/WithMetaByData";
import "./CryptoPricesList.css";

const CryptoPricesList = () => {
  const { SubNameCrypto } = useParams();
  // const CryptoPageData = GetSinglePagesDataFromSlug("crypto");
  // console.log("CryptoPageData", CryptoPageData);
  const [SearchInput, setSearchInput] = useState();
  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };
  const location = useLocation();
  // following is set static for crypto type
  const [CurrentId, setCurrentId] = useState(8);
  const [CurrentPageData, setCurrentPageData] = useState();
  // const IDArrayFromURL = GetIdFromUrlCategory([decodeURIComponent(location.pathname?.substring(1))]);
  const [fetchCategory, { loading: loadingForCatData, error: errorForCatData, data: currentCatData }] = useLazyQuery(GET_CURRENT_CAT_DATA, {
    variables: { id: CurrentId },
  });
  // useEffect(() => {
  //   if (IDArrayFromURL?.length > 0) {
  //     setCurrentId(IDArrayFromURL[IDArrayFromURL.length - 1].id);
  //   }
  // }, [IDArrayFromURL]);
  useEffect(() => {
    if (CurrentId) {
      fetchCategory();
    }
  }, [CurrentId]);
  useEffect(() => {
    setCurrentPageData(currentCatData?.singlecategory?.singlecategory);
  }, [currentCatData]);

  console.log("CurrentId", CurrentPageData);
  return (
    <>
      <Breadcrumb />
      <div className="my-4">
        <WithBannersLayout
          leftBanners={CurrentPageData?.catbanner?.filter((item) => item.banner_position == "3")}
          rightBanners={CurrentPageData?.catbanner?.filter((item) => item.banner_position == "4")}
        >
          <WithMetaByData data={CurrentPageData}>
            <section className="CryptoPricesList">
              <div className="container">
                <TopBannerSlider topBanners={CurrentPageData?.catbanner?.filter((item) => item.banner_position == "0")} />
                <div className="CryptoPricesList-row one">
                  <div className="title big-p text-black font-bold d-flex justify-content-between align-items-center">
                    <h5>{SubNameCrypto}</h5>
                    <div className="search-dv">
                      <form className="search" action="search.php">
                        <input placeholder="Search..." type="search" value={SearchInput} onChange={handleSearchInputChange} />
                        <a className="src-icn">
                          <img src="/static/media/search-icn.0ddd2811f5c381afba35f97f9afb79a9.svg" alt />
                        </a>
                      </form>
                    </div>
                  </div>
                </div>
                <div  style={{ marginBottom: "50px" }}>               
                  <TableWithPagination SearchInput={SearchInput} />
                </div>
                <div className="CryptoDivider"></div>
                {/* {CryptoPageData?.cms?.length > 0 ? (
                  <div className="py-4">
                    <Html html={CryptoPageData?.cms[0]?.description} />
                  </div>
                ) : CryptoPageData ? null : (
                  <div className="skeleton-text" style={{ height: "330px", marginBottom: "20px" }} />
                )} */}
                <BottomBannerSlider bottomBanner={CurrentPageData?.catbanner?.filter((item) => item.banner_position == "1")} />
                {/* {CryptoPageData?.cms?.length > 1 ? (
                  <div className="py-4">
                    <Html html={CryptoPageData?.cms[1]?.description} />
                  </div>
                ) : CryptoPageData ? null : (
                  <div className="skeleton-text" style={{ height: "330px", marginBottom: "20px" }} />
                )}
                {CryptoPageData?.footer?.description ? (
                  <div className="py-4">
                    <Html html={CryptoPageData?.footer?.description} />
                  </div>
                ) : CryptoPageData ? null : (
                  <div className="skeleton-text" style={{ height: "330px", marginBottom: "20px" }} />
                )} */}
                <div className="py-4">
                  <Html html={CurrentPageData?.footer?.description} />
                </div>
              </div>
            </section>
          </WithMetaByData>
        </WithBannersLayout>
      </div>
    </>
  );
};

export default CryptoPricesList;
