import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./CompanyDirectoryList.css";
import { Dropdown } from "bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentCompanyId, setCurrentNuggetId, setCurrentNuggetTerm } from "../../stores/rootReducer";
import NoResultFound from "../../components/NoResultFound/NoResultFound";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_COMPANY_LIST, GET_CURRENT_CAT_DATA, GET_KNOWLEDGE_NUGGETS_WITH_TERM } from "../../query/query";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import GetMessage from "../../utils/GetMessage";
import { GetCompanyList, GetIdFromUrlCategory } from "../../query/Graphql";
import WithBannersLayout from "../../utils/WithBannersLayout/WithBannersLayout";
import TopBannerSlider from "../../utils/BannerLayout/TopBannerSlider";
import WithMetaByData from "../../utils/WithMetaByData";
import BottomBannerSlider from "../../utils/BannerLayout/BottomBannerSlider";
import Html from "../../utils/Html";

const CompanyDirectoryList = () => {
  // const allCompanyList = GetCompanyList();
  const { loading, error, data: allCompanyList } = useQuery(GET_COMPANY_LIST);
  // const sortedData = allCompanyList && [...allCompanyList?.allcompany?.allcompany]?.sort((a, b) => a.name.localeCompare(b.name));

  const dispatch = useDispatch();
  const location = useLocation();
  const NuggetsTerm = useSelector((state) => state.rootReducer.currentNuggetTerm || "A");
  const [CompanyTerm, setCompanyTerm] = useState("");
  const [SearchInput, setSearchInput] = useState();
  const [ArrayByAlphabet, setArrayByAlphabet] = useState([]);
  const [dataList, setDataList] = useState();
  useEffect(() => {
    if (allCompanyList) {
      const sortedData = allCompanyList && [...allCompanyList?.allcompany?.allcompany]?.sort((a, b) => a.name.localeCompare(b.name));
      if (sortedData) {
        if (CompanyTerm) {
          setDataList(filterDataByAlphabet(sortedData, CompanyTerm));
        } else {
          setDataList(sortedData);
        }
      }
    }
  }, [allCompanyList, CompanyTerm]);
  useEffect(() => {
    const result = {};
    if (dataList?.length > 0) {
      dataList.forEach((item) => {
        const firstLetter = item.name.charAt(0).toUpperCase();
        if (!result[firstLetter]) {
          result[firstLetter] = [];
        }
        result[firstLetter].push(item);
      });
      const resultArray = Object.keys(result).map((startWith) => ({
        startWith,
        value: result[startWith],
      }));
      if (resultArray) {
        setArrayByAlphabet(resultArray);
      }
    } else {
      setArrayByAlphabet([]);
    }
  }, [dataList]);
  const filterDataByAlphabet = (data, alphabet) => {
    const filteredData = data.filter((item) => {
      return item.name.toUpperCase().startsWith(alphabet.toUpperCase());
    });
    return filteredData;
  };

  // const { loading, error, data, refetch } = useQuery(
  //   GET_KNOWLEDGE_NUGGETS_WITH_TERM,
  //   {
  //     variables: { keyword: NuggetsTerm, pageCount, page },
  //   }
  // );
  // When data is loaded or refetched, update the dataList state
  // useEffect(() => {
  //   if (
  //     !loading &&
  //     data &&
  //     data?.searchknowledgenuggets?.searchknowledgenuggets
  //   ) {
  //     const newDataList =
  //       data?.searchknowledgenuggets?.searchknowledgenuggets?.filter(
  //         (newItem) => !dataList.some((item) => item.id === newItem.id)
  //       );

  //     if (newDataList.length > 0) {
  //       setDataList((prevDataList) => [...prevDataList, ...newDataList]);
  //     }

  //   }
  // }, [loading, data, dataList]);

  // useEffect(() => {
  //   setDataList([]);
  //   refetch({ keyword: NuggetsTerm, pageCount: pageCount, page: page });
  // }, [NuggetsTerm]);

  // useEffect(() => {
  //   return () => {
  //     setDataList([]);
  //   };
  // }, []);

  const [CurrentId, setCurrentId] = useState();
  const [CurrentPageData, setCurrentPageData] = useState();
  const IDArrayFromURL = GetIdFromUrlCategory([decodeURIComponent(location.pathname?.substring(1))]);
  const [fetchCategory, { loading: loadingForCatData, error: errorForCatData, data: currentCatData }] = useLazyQuery(GET_CURRENT_CAT_DATA, {
    variables: { id: CurrentId },
  });
  useEffect(() => {
    if (IDArrayFromURL?.length > 0) {
      setCurrentId(IDArrayFromURL[IDArrayFromURL.length - 1].id);
    }
  }, [IDArrayFromURL]);
  useEffect(() => {
    if (CurrentId) {
      fetchCategory();
    }
  }, [CurrentId]);
  useEffect(() => {
    setCurrentPageData(currentCatData?.singlecategory?.singlecategory);
  }, [currentCatData]);

  console.log("CurrentId", CurrentPageData);

  // console.log("dataList", NuggetsTerm, dataList);
  const renderLoader = () => {
    if (loading) {
      return Array.from({ length: 3 }).map((_, index) => (
        <>
          <div className="skeleton-container mb-4" style={{ minHeight: "50px" }}></div>
          <div className="skeleton-container mb-4" style={{ minHeight: "140px" }}></div>
        </>
      ));
    }
    return <NoResultFound title={"No Companies found!!"} subTitle={"Please try again with another keyword or maybe use generic term"} />;
  };
  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
    if (e.target.value.length > 2) {
      setCompanyTerm(e.target.value);
    }
  };
  return (
    <>
      <Breadcrumb />
      <div className="mb-2" />
      <WithBannersLayout
        leftBanners={CurrentPageData?.catbanner?.filter((item) => item.banner_position == "3")}
        rightBanners={CurrentPageData?.catbanner?.filter((item) => item.banner_position == "4")}
      >
        {CurrentPageData && <WithMetaByData data={CurrentPageData}></WithMetaByData>}
        <section className="CompanyDirectoryList">
          <div className="container">
            <TopBannerSlider topBanners={CurrentPageData?.catbanner?.filter((item) => item.banner_position == "0")} />
            <div className="CompanyDirectoryList-row one">
              <div className="title big-p text-black font-bold d-flex justify-content-between">
                <div>
                  <h5>Company Directory</h5>
                  {/* <p>1234 Companies</p> */}
                </div>
                <div className="search-dv">
                  <form className="search" action="search.php">
                    <input placeholder="Search by company name..." type="search" value={SearchInput} onChange={handleSearchInputChange} />
                    <a className="src-icn">
                      <img src="/static/media/search-icn.0ddd2811f5c381afba35f97f9afb79a9.svg" alt />
                    </a>
                  </form>
                </div>
              </div>
            </div>
            <div className="CompanyDirectoryList-row abcd-sec">
              <ul className="d-flex">
                <li>
                  <Link
                    to={"/Company Directory"}
                    onClick={() => {
                      setCompanyTerm("");
                      setSearchInput("");
                    }}
                    className={`${CompanyTerm == "" && "CompanyDirectoryList-term-active"}`}
                  >
                    View All
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/Company Directory"}
                    onClick={() => {
                      setCompanyTerm("A");
                      setSearchInput("");
                    }}
                    className={`${CompanyTerm == "A" && "CompanyDirectoryList-term-active"}`}
                  >
                    A
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/Company Directory"}
                    onClick={() => {
                      setCompanyTerm("B");
                      setSearchInput("");
                    }}
                    className={`${CompanyTerm == "B" && "CompanyDirectoryList-term-active"}`}
                  >
                    B
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/Company Directory"}
                    onClick={() => {
                      setCompanyTerm("C");
                      setSearchInput("");
                    }}
                    className={`${CompanyTerm == "C" && "CompanyDirectoryList-term-active"}`}
                  >
                    C
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/Company Directory"}
                    onClick={() => {
                      setCompanyTerm("D");
                      setSearchInput("");
                    }}
                    className={`${CompanyTerm == "D" && "CompanyDirectoryList-term-active"}`}
                  >
                    D
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/Company Directory"}
                    onClick={() => {
                      setCompanyTerm("E");
                      setSearchInput("");
                    }}
                    className={`${CompanyTerm == "E" && "CompanyDirectoryList-term-active"}`}
                  >
                    E
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/Company Directory"}
                    onClick={() => {
                      setCompanyTerm("F");
                      setSearchInput("");
                    }}
                    className={`${CompanyTerm == "F" && "CompanyDirectoryList-term-active"}`}
                  >
                    F
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/Company Directory"}
                    onClick={() => {
                      setCompanyTerm("G");
                      setSearchInput("");
                    }}
                    className={`${CompanyTerm == "G" && "CompanyDirectoryList-term-active"}`}
                  >
                    G
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/Company Directory"}
                    onClick={() => {
                      setCompanyTerm("H");
                      setSearchInput("");
                    }}
                    className={`${CompanyTerm == "H" && "CompanyDirectoryList-term-active"}`}
                  >
                    H
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/Company Directory"}
                    onClick={() => {
                      setCompanyTerm("I");
                      setSearchInput("");
                    }}
                    className={`${CompanyTerm == "I" && "CompanyDirectoryList-term-active"}`}
                  >
                    I
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/Company Directory"}
                    onClick={() => {
                      setCompanyTerm("J");
                      setSearchInput("");
                    }}
                    className={`${CompanyTerm == "J" && "CompanyDirectoryList-term-active"}`}
                  >
                    J
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/Company Directory"}
                    onClick={() => {
                      setCompanyTerm("K");
                      setSearchInput("");
                    }}
                    className={`${CompanyTerm == "K" && "CompanyDirectoryList-term-active"}`}
                  >
                    K
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/Company Directory"}
                    onClick={() => {
                      setCompanyTerm("L");
                      setSearchInput("");
                    }}
                    className={`${CompanyTerm == "L" && "CompanyDirectoryList-term-active"}`}
                  >
                    L
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/Company Directory"}
                    onClick={() => {
                      setCompanyTerm("M");
                      setSearchInput("");
                    }}
                    className={`${CompanyTerm == "M" && "CompanyDirectoryList-term-active"}`}
                  >
                    M
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/Company Directory"}
                    onClick={() => {
                      setCompanyTerm("N");
                      setSearchInput("");
                    }}
                    className={`${CompanyTerm == "N" && "CompanyDirectoryList-term-active"}`}
                  >
                    N
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/Company Directory"}
                    onClick={() => {
                      setCompanyTerm("O");
                      setSearchInput("");
                    }}
                    className={`${CompanyTerm == "O" && "CompanyDirectoryList-term-active"}`}
                  >
                    O
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/Company Directory"}
                    onClick={() => {
                      setCompanyTerm("P");
                      setSearchInput("");
                    }}
                    className={`${CompanyTerm == "P" && "CompanyDirectoryList-term-active"}`}
                  >
                    P
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/Company Directory"}
                    onClick={() => {
                      setCompanyTerm("Q");
                      setSearchInput("");
                    }}
                    className={`${CompanyTerm == "Q" && "CompanyDirectoryList-term-active"}`}
                  >
                    Q
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/Company Directory"}
                    onClick={() => {
                      setCompanyTerm("R");
                      setSearchInput("");
                    }}
                    className={`${CompanyTerm == "R" && "CompanyDirectoryList-term-active"}`}
                  >
                    R
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/Company Directory"}
                    onClick={() => {
                      setCompanyTerm("S");
                      setSearchInput("");
                    }}
                    className={`${CompanyTerm == "S" && "CompanyDirectoryList-term-active"}`}
                  >
                    S
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/Company Directory"}
                    onClick={() => {
                      setCompanyTerm("T");
                      setSearchInput("");
                    }}
                    className={`${CompanyTerm == "T" && "CompanyDirectoryList-term-active"}`}
                  >
                    T
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/Company Directory"}
                    onClick={() => {
                      setCompanyTerm("U");
                      setSearchInput("");
                    }}
                    className={`${CompanyTerm == "U" && "CompanyDirectoryList-term-active"}`}
                  >
                    U
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/Company Directory"}
                    onClick={() => {
                      setCompanyTerm("V");
                      setSearchInput("");
                    }}
                    className={`${CompanyTerm == "V" && "CompanyDirectoryList-term-active"}`}
                  >
                    V
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/Company Directory"}
                    onClick={() => {
                      setCompanyTerm("W");
                      setSearchInput("");
                    }}
                    className={`${CompanyTerm == "W" && "CompanyDirectoryList-term-active"}`}
                  >
                    W
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/Company Directory"}
                    onClick={() => {
                      setCompanyTerm("X");
                      setSearchInput("");
                    }}
                    className={`${CompanyTerm == "X" && "CompanyDirectoryList-term-active"}`}
                  >
                    X
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/Company Directory"}
                    onClick={() => {
                      setCompanyTerm("Y");
                      setSearchInput("");
                    }}
                    className={`${CompanyTerm == "Y" && "CompanyDirectoryList-term-active"}`}
                  >
                    Y
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/Company Directory"}
                    onClick={() => {
                      setCompanyTerm("Z");
                      setSearchInput("");
                    }}
                    className={`${CompanyTerm == "Z" && "CompanyDirectoryList-term-active"}`}
                  >
                    Z
                  </Link>
                </li>
                {/* <li>
              <Link to={"/Company Directory"}>View All</Link>
            </li> */}
              </ul>
            </div>
            {ArrayByAlphabet.length > 0 ? (
              ArrayByAlphabet.map((arr) => (
                <>
                  <div className="Terms-Beginning font-bold">{arr?.startWith}</div>
                  <ul className="listingsArray">
                    {arr?.value?.length > 0 &&
                      arr?.value?.map((listItem) => (
                        <li>
                          <Link to={`/Company Directory/${listItem.name}`} onClick={() => dispatch(setCurrentCompanyId(listItem?.id))}>
                            <div className="companyImage">
                              <img src={listItem.logo_url} alt={listItem.name} title={listItem.name} />
                            </div>
                            <div className="companyName">{listItem.name}</div>
                          </Link>
                          {/* <div className="heading">{listItem.keyword}</div>
                {listItem.short_desc}{" "}
                <span>
                  <Link
                    to={`/Company Directory/${listItem.keyword}`}
                    onClick={() => {
                      dispatch(setCurrentNuggetId(listItem?.id));
                      localStorage.setItem("CurrentNuggetId", listItem?.id);
                    }}
                  >
                    Read More
                  </Link>
                </span> */}
                        </li>
                      ))}
                  </ul>
                </>
              ))
            ) : (
              <>{renderLoader()}</>
            )}
            <div className="mb-4">
              <BottomBannerSlider bottomBanner={CurrentPageData?.catbanner?.filter((item) => item.banner_position == "1")} />
            </div>
            <div className="container py-4">
              <Html html={CurrentPageData?.footer?.description} />
            </div>
          </div>
        </section>
      </WithBannersLayout>
    </>
  );
};

export default CompanyDirectoryList;
