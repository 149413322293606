import React from "react";
import { GetFAQDataForProduct } from "../../query/Graphql";
import Html from "../../utils/Html";

const Productdetailaccodian = ({ product }) => {
  if (!product?.productfaq?.length > 0) {
    return null;
  }

  return (
    <div className="CompareProducts-wrp pro-list-acodian-tab pb-4">
      <div className="">
        <div className="row">
          <div className="col-md-12">
            <h3>FAQs</h3>
          </div>
        </div>
      </div>

      <div className="">
        <div className="CompareProductsInner">
          <div className="accordion" id="accordionExample">
            {product?.productfaq?.map((FAQ, index) => (
              <div className="accordion-item">
                <h2 className="accordion-header" id={FAQ.question}>
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#${FAQ.id}`}
                    aria-expanded="false"
                    aria-controls={FAQ.id}
                  >
                    {FAQ.question}
                  </button>
                </h2>
                <div id={FAQ.id} className="accordion-collapse collapse" aria-labelledby={FAQ.question} data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <Html html={FAQ.answer} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Productdetailaccodian;
