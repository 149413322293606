import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UserNameIcon from "../../assets/icons/UserNameIcon";
import CountryCodes from "../../assets/CountryPhoneCode/CountryCodes.json";
import axios from "axios";
import { useLazyQuery, useQuery } from "@apollo/client";
import { APPLY_FORM_DATA, APPLY_NOW_QUERY } from "../../query/query";
import sendNotification from "../../utils/SendNotification";
import "./ApplyForm.css";
import { useDispatch } from "react-redux";
import { setApplyProductLink } from "../../stores/rootReducer";
import GetMessage from "../../utils/GetMessage";
import Html from "../../utils/Html";

const ApplyForm = ({ product, loadingProduct }) => {
  const { subcategoryName, categoryName, parentCatName } = useParams();
  let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  let PhoneRegex = /^(\+\d{1,2})?\d{6,11}$/;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const CurrentUserData = JSON.parse(sessionStorage.getItem("User"));
  const [GeoState, setGeoState] = useState({
    ip: "",
    countryName: "",
    countryCode: "",
    city: "",
    timezone: "",
  });
  const [ApplyTitle, setApplyTitle] = useState("");
  const [ApplySubTitle, setApplySubTitle] = useState("");
  const [ApplyContent, setApplyContent] = useState("");
  const [FName, setFName] = useState(CurrentUserData?.first_name);
  const [LName, setLName] = useState(CurrentUserData?.last_name);
  const [Email, setEmail] = useState(CurrentUserData?.email);
  const [selectedCountryCode, setSelectedCountryCode] = useState(CurrentUserData?.country_code || GeoState?.countryCode || "");
  const [Phone, setPhone] = useState(CurrentUserData?.mobileno);
  const [FNameError, setFNameError] = useState(false);
  const [LNameError, setLNameError] = useState(false);
  const [EmailError, setEmailError] = useState(false);
  const [SelectedCountryCodeError, setSelectedCountryCodeError] = useState(false);
  const [PhoneError, setPhoneError] = useState(false);
  const [SuccessMessage, setSuccessMessage] = useState("");
  const [FailMessage, setFailMessage] = useState("");
  const [applyNow, { loading, error }] = useLazyQuery(APPLY_NOW_QUERY);
  const [getTitleFromProductId, {}] = useLazyQuery(APPLY_FORM_DATA);

  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setGeoState({
          ...GeoState,
          ip: data.ip,
          countryName: data.country_name,
          countryCode: data.country_calling_code,
          city: data.city,
          timezone: data.timezone,
        });
        setSelectedCountryCode(data?.country_calling_code);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getGeoInfo();
  }, []);

  const handleInputChange = (e) => {
    if (FName && LName && Email && emailRegex.test(Email) && selectedCountryCode && Phone && PhoneRegex.test(Phone)) {
      FailMessage && setFailMessage("");
    }
    switch (e.target.id) {
      case "Fname":
        setFName(e.target.value);
        if (e.target.value) {
          setFNameError(false);
        } else {
          setFNameError(true);
        }
        break;
      case "Lname":
        setLName(e.target.value);
        if (e.target.value) {
          setLNameError(false);
        } else {
          setLNameError(true);
        }
        break;
      case "email":
        setEmail(e.target.value);
        if (e.target.value && emailRegex.test(e.target.value)) {
          setEmailError(false);
        } else {
          setEmailError(true);
        }
        break;
      case "PhoneCode":
        setSelectedCountryCode(e.target.value);
        if (e.target.value) {
          setSelectedCountryCodeError(false);
        } else {
          setSelectedCountryCodeError(true);
        }
        break;
      case "Phone":
        setPhone(e.target.value);
        if (e.target.value && PhoneRegex.test(e.target.value)) {
          setPhoneError(false);
        } else {
          setPhoneError(true);
        }
        break;

      default:
        break;
    }
  };
  const handleSubmitClick = async (e) => {
    // if (!CurrentUserData?.id) {
    //   dispatch(setApplyProductLink(window.location?.pathname));
    //   navigate("/SignIn");
    // }

    let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let PhoneRegex = /^(\+\d{1,2})?\d{6,11}$/;
    if (FName && LName && Email && emailRegex.test(Email) && selectedCountryCode && Phone && PhoneRegex.test(Phone) && product?.id) {
      try {
        const { data } = await applyNow({
          variables: {
            customer_id: CurrentUserData?.id,
            first_name: FName,
            last_name: LName,
            email: Email,
            country_code: selectedCountryCode,
            phone: Phone,
            pro_id: product?.id,
          },
        });
        // Handle the response data
        console.log(data?.productinquiry?.success);
        if (data?.productinquiry?.success == true) {
          // alert(data?.productinquiry?.success);
          sendNotification("success", data?.productinquiry?.message);
          setFailMessage("");
          setSuccessMessage(data?.productinquiry?.message);
        } else {
          sendNotification("error", data?.productinquiry?.message);
          setFailMessage(data?.productinquiry?.message);
        }
      } catch (error) {
        sendNotification("error", error);
        setSuccessMessage("");
        setFailMessage(error);
      }
    } else {
      setSuccessMessage("");
      setFailMessage("Please enter valid data for all fields.");
      if (!FName) {
        FNameError && setFNameError(false);
        setTimeout(() => {
          setFNameError(true);
        }, 50);
      }
      if (!LName) {
        LNameError && setLNameError(false);
        setTimeout(() => {
          setLNameError(true);
        }, 50);
      }

      if (!Email || !emailRegex.test(Email)) {
        EmailError && setEmailError(false);
        setTimeout(() => {
          setEmailError(true);
        }, 50);
      }
      if (!selectedCountryCode) {
        SelectedCountryCodeError && setSelectedCountryCodeError(false);
        setTimeout(() => {
          setSelectedCountryCodeError(true);
        }, 50);
      }

      if (!Phone || !PhoneRegex.test(Phone)) {
        PhoneError && setPhoneError(false);
        setTimeout(() => {
          setPhoneError(true);
        }, 50);
      }
    }
  };
  const getTitle = async () => {
    const { data } = await getTitleFromProductId({
      variables: {
        pid: product?.id,
      },
    });
    // Handle the response data
    console.log("data?.catform,", data);
    if (data?.catform?.success == true) {
      setApplyTitle(data?.catform?.catform?.apply_now_form_title);
      setApplySubTitle(data?.catform?.catform?.apply_now_form_sub_title);
      setApplyContent(data?.catform?.catform?.apply_now_form_content);
    } else {
      setApplyTitle();
      setApplySubTitle();
      setApplyContent();
    }
  };
  useEffect(() => {
    if (product) {
      getTitle();
    }
  }, [product]);

  return (
    <div className="ApplyForm my-4 p-4" id="ApplyForm">
      {loadingProduct ? (
        <>
          <h5>
            <div className="skeleton-text" style={{ height: "20px", width: "30%" }} />
          </h5>
          <p>
            <div className="skeleton-text" style={{ height: "20px", width: "50%" }} />
          </p>
        </>
      ) : (
        <>
          {ApplyTitle && (
            <>
              <h5>
                {ApplyTitle} {subcategoryName || categoryName || parentCatName || ""}
              </h5>
            </>
          )}
          {ApplySubTitle && (
            <>
              <p style={{ color: "gray" }}>{ApplySubTitle}</p>
            </>
          )}
        </>
      )}
      {SuccessMessage && (
        <div className="alert alert-success" role="alert">
          {SuccessMessage}
        </div>
      )}
      {FailMessage && (
        <div className="alert alert-danger" role="alert">
          {FailMessage}
        </div>
      )}
      {!SuccessMessage && (
        <>
          <div className="d-flex gap-3 w-100">
            <div className="floating-label w-100">
              <input
                value={FName}
                onChange={(e) => handleInputChange(e)}
                placeholder="First Name"
                type="name"
                name="Fname"
                id="Fname"
                autoComplete="off"
                required
                className={`${FNameError ? "error" : ""} w-100`}
                style={{ border: FNameError ? "solid 1px red" : "1px solid #00000033", borderRadius: "4px" }}
              />
            </div>
            <div className="floating-label w-100">
              <input
                value={LName}
                onChange={(e) => handleInputChange(e)}
                placeholder="Last Name"
                type="name"
                name="Lname"
                id="Lname"
                autoComplete="off"
                required
                className={`${LNameError ? "error" : ""} w-100`}
                style={{ border: LNameError ? "solid 1px red" : "1px solid #00000033", borderRadius: "4px" }}
              />
            </div>
          </div>
          <div className="d-flex gap-3 w-100 mt-3">
            <div className="floating-label w-100 d-flex">
              <select
                value={selectedCountryCode}
                id="PhoneCode"
                onChange={(event) => handleInputChange(event)}
                style={{ border: SelectedCountryCodeError ? "solid 1px red" : "" }}
              >
                <option value="" disabled selected>
                  Select
                </option>
                {CountryCodes?.map((cntry) => (
                  <option key={cntry.dial_code} value={cntry?.dial_code}>
                    {cntry?.dial_code}
                  </option>
                ))}
              </select>
              <input
                value={Phone}
                onChange={(e) => handleInputChange(e)}
                placeholder="Phone Number"
                type="Phone"
                name="Phone"
                id="Phone"
                autoComplete="off"
                required
                pattern="^\d{6,11}$"
                title="Enter a valid mobile number"
                // className={` ${PhoneError ? "error" : ""} w-100`}
                className={`inputWithLeftSpace ${PhoneError ? "error" : ""}`}
                style={{ border: PhoneError ? "solid 1px red" : "1px solid #00000033", borderRadius: "4px" }}
              />
              {/* <label htmlFor="Phone">Phone:</label> */}
            </div>
            <div className="floating-label w-100">
              <input
                value={Email}
                onChange={(e) => handleInputChange(e)}
                placeholder="Email"
                type="email"
                name="email"
                id="email"
                autoComplete="off"
                required
                pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
                title="Enter a valid email address"
                className={`${EmailError ? "error" : ""}  w-100`}
                style={{ border: EmailError ? "solid 1px red" : "1px solid #00000033", borderRadius: "4px" }}
              />
              {/* <label htmlFor="email">Email:</label> */}
            </div>
          </div>
          {/* <div className="mt-3">
            
          </div> */}
          <div className="w-100 text-center">
            <button className="action-btn small-btn mt-3" onClick={(e) => handleSubmitClick(e)} style={{}}>
              {loading ? (
                <div className="spinner-border text-primary" role="status" style={{ margin: "auto", height: "15px", width: "15px" }}></div>
              ) : (
                "Submit"
              )}
            </button>
            {ApplyContent && (
              <div className="mt-4">
                <Html html={ApplyContent} />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ApplyForm;
