import React from "react";
import Blog1 from "../../assets/images/blog1.jpg";
import Blog2 from "../../assets/images/blog-m.jpg";
import Financesingle from "./Financesingle";
import FinanceMultiple from "./FinanceMultiple";

const BlogLayout = ({ title, subTitle, blogs }) => {
  if (!blogs?.length > 0) {
    return null;
  }
  return (
    <section className="articles-wrp">
      <div className="container">
        <div className="article-s">
          <h3>{title}</h3>
          <p className="big-p">{subTitle}</p>
          <div className="article-main">
            <div className="row">
              {blogs?.length > 1 ? (
                <FinanceMultiple blogs={blogs} />
              ) : (
                <Financesingle blog={blogs[0]} />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogLayout;
