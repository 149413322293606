import { useLazyQuery } from "@apollo/client";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SEND_REVIEW_QUERY } from "../../query/query";
import { setApplyProductLink, setReviewingProductLink } from "../../stores/rootReducer";
import sendNotification from "../../utils/SendNotification";
import "./ReviewModal.css";
import StarRatingPicker from "./StarRatingPicker";

const ReviewModal = ({ productId }) => {
  const [Comment, setComment] = useState("");
  const [rating, setRating] = useState(5);
  const [CommentError, setCommentError] = useState(false);
  const [SuccessMessage, setSuccessMessage] = useState("");
  const [FailMessage, setFailMessage] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const CurrentUserData = JSON.parse(sessionStorage.getItem("User"));
  const [sendReview, { loading, error }] = useLazyQuery(SEND_REVIEW_QUERY);

  const handleStarClick = (selectedRating) => {
    setRating(selectedRating);
  };
  const handleSubmitClick = async (e) => {
    e.preventDefault();
    if (!CurrentUserData?.id) {
      dispatch(setReviewingProductLink(window.location?.pathname));
      navigate("/SignIn");
    }
    if (CurrentUserData?.id && productId && Comment) {
      try {
        const { data } = await sendReview({
          variables: {
            customer_id: CurrentUserData?.id,
            type: "product",
            pro_id: productId,
            company_id: "",
            comments: Comment,
            rating: rating,
          },
        });
        // Handle the response data
        console.log(data?.sendreview?.success);
        if (data?.sendreview?.success == true) {
          // alert(data?.sendreview?.success);
          //   sendNotification("success", data?.sendreview?.message);
          setFailMessage("");
          setSuccessMessage(data?.sendreview?.message);
        } else {
          //   sendNotification("error", data?.sendreview?.message);
          setFailMessage(data?.sendreview?.message);
        }
      } catch (error) {
        // sendNotification("error", error);
        setSuccessMessage("");
        setFailMessage(error);
      }
    } else {
      setSuccessMessage("");
      setFailMessage("Comment is mandatory.");
      if (!Comment) {
        CommentError && setCommentError(false);
        setTimeout(() => {
          setCommentError(true);
        }, 50);
      }
    }
  };

  return (
    <div
      className="modal fade"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title ps-3" id="staticBackdropLabel">
              Write a review
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body">
            {/* <StarRatingPicker /> */}
            {!SuccessMessage && (
              <div className="d-flex flex-column m-4 text-center" id="user-ratings">
                <div className="mb-3">
                  <label className="modalTitleFont required" style={{ marginBottom: "20px", marginTop: "10px" }}>
                    How would you rate it?
                  </label>
                  <div className="bigStarPicker">
                    <StarRatingPicker rating={rating} totalStars={5} onStarClick={handleStarClick} />
                  </div>
                  {/* <span>{rating}</span> */}
                </div>
                <label htmlFor="Comment" className="modalTitleFont required" style={{ marginBottom: "20px", marginTop: "20px" }}>
                  Your review
                </label>
                Tell us about your experience with the product or service. What did you like or dislike?
                <textarea
                  className="calculatorFont p-3"
                  value={Comment}
                  rows="4"
                  onChange={(e) => {
                    CommentError && setCommentError("");
                    FailMessage && setFailMessage("");
                    setComment(e.target.value);
                  }}
                  placeholder="Comment"
                  type="text"
                  name="Comment"
                  id="Comment"
                  autoComplete="off"
                  required
                  title="Comment"
                  disabled={loading}
                  style={{ background: "transparent", border: CommentError ? "solid 1px red" : "1px solid #00000033", borderRadius: "4px" }}
                />
                {FailMessage && (
                  <div className="alert alert-danger mt-4" role="alert">
                    {FailMessage}
                  </div>
                )}
                <button onClick={(e) => handleSubmitClick(e)} type="button" className="action-btn small-btn mt-4">
                  {loading ? (
                    <div className="spinner-border text-primary" role="status" style={{ margin: "auto", height: "15px", width: "15px" }}></div>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            )}
            {SuccessMessage && (
              <div className="alert alert-success mt-4" role="alert">
                {SuccessMessage}
              </div>
            )}
          </div>
          {/* <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
              Cancel
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ReviewModal;
