import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GetSinglePagesDataFromSlug } from "../../query/Graphql";
import { VERIFY_EMAIL_MUTATION } from "../../query/mutation";
import Html from "../../utils/Html";
import sendNotification from "../../utils/SendNotification";
import AuthLayoutBySlug from "../Auth/AuthLayoutBySlug";

const VerifyEmailTokenSuccess = () => {
  const { verifyEmailToken } = useParams();
  const navigate = useNavigate();
  const pageData = GetSinglePagesDataFromSlug("customer-verification");
  const [verifyEmail, { data, loading, error }] = useMutation(VERIFY_EMAIL_MUTATION);
  const [IsCallDone, setIsCallDone] = useState(false);
  useEffect(() => {
    if (!verifyEmailToken) {
      navigate("/");
    } else {
      if (!IsCallDone) {
        setIsCallDone(true);
        fetchVerifyMutation();
      }
    }
  }, []);
  const fetchVerifyMutation = async () => {
    try {
      const { data } = await verifyEmail({
        variables: {
          token: verifyEmailToken,
        },
      });
      // Handle the response data
      console.log(data?.verifycustomer?.success);
      if (data?.verifycustomer?.success == true) {
        // alert(data?.verifycustomer?.success);
        sendNotification("success", data?.verifycustomer?.message);
        // sessionStorage.setItem("User", JSON.stringify(data?.verifycustomer?.loginuser));
        // navigate("/SignIn");
      } else {
        sendNotification("error", data?.verifycustomer?.message);
      }
    } catch (error) {
      sendNotification("error", error);
    }
  };
  return (
    <AuthLayoutBySlug pageData={pageData}>
      <div className="my-4 d-flex justify-content-center align-items-center">
        <div className="col-md-4">
          <div className="border border-3 border-success" />
          <div className="card  bg-white shadow p-5">
            <div className="mb-4 text-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="text-success" width={75} height={75} fill="currentColor" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
              </svg>
            </div>
            {!loading && data?.verifycustomer?.success ? (
              <div className="text-center">
                <h1>{pageData?.cms?.find((item) => item.slug == "customer-verification")?.title}</h1>
                <p className="mt-3">
                  <Html html={pageData?.cms?.find((item) => item.slug == "customer-verification")?.description} />
                </p>
                <button className="action-btn small-btn" onClick={() => navigate("/SignIn")}>
                  LogIn
                </button>
              </div>
            ) : (
              <div className="text-center">
                <h1>Verification failed!</h1>
                <p className="mt-3">Please try again.</p>
                <button className="action-btn small-btn" onClick={() => navigate("/")}>
                  Home
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </AuthLayoutBySlug>
  );
};

export default VerifyEmailTokenSuccess;
