import React from "react";
import Blog1 from "../../assets/images/blog1.jpg";
import Blog2 from "../../assets/images/blog-m.jpg";
import Financesingle from "./Financesingle";
import FinanceMultiple from "./FinanceMultiple";

const BlogLayoutForHome = ({ title, subTitle, Centerblogs, homeListBlog }) => {
  const renderBlogByCenterAndList = () => {
    let finalArray = [];
    if (Centerblogs?.length > 0 && homeListBlog?.length > 0) {
      finalArray = [Centerblogs[0], ...homeListBlog];
    } else if (Centerblogs?.length > 0 && !homeListBlog?.length > 0) {
      finalArray = [Centerblogs[0]];
    } else if (!Centerblogs?.length > 0 && homeListBlog?.length > 0) {
      finalArray = [...homeListBlog];
    }
    if (!finalArray?.length > 0) {
      return null;
    }
    if (finalArray.length > 1) {
      return <FinanceMultiple blogs={finalArray} />;
    } else {
      return <Financesingle blog={finalArray[0]} />;
    }
  };
  if (!Centerblogs?.length > 0 && !homeListBlog?.length > 0) {
    return null;
  }
  return (
    <section className="articles-wrp">
      <div className="container">
        <div className="article-s">
          <h3>{title}</h3>
          <p className="big-p">{subTitle}</p>
          <div className="article-main">
            <div className="row">
              {renderBlogByCenterAndList()}
              {/* {Centerblogs?.length > 1 ? <FinanceMultiple blogs={Centerblogs} /> : <Financesingle blog={Centerblogs[0]} />} */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogLayoutForHome;
