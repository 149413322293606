import React from "react";
import { Link } from "react-router-dom";
import why from "../../src/assets/images/why1.svg";
import { Dropdown } from "bootstrap";
import { GetCMSData } from "../query/Graphql";
import Html from "../utils/Html";

const Whyhome = () => {
  const whyFindKizen = GetCMSData("home-why-findkizen");
  return (
    <section className="home-why-wrp">
      <div className="container">
        {whyFindKizen && <Html html={whyFindKizen[0]?.description} />}
        {/* <h3>
          why <span className="blu-txt">Find</span>
          <span className="green-txt">Kaizen</span>?
        </h3>

        <div className="row">
          <div className="col-md-4">
            <div className="why-in">
              <div className="why-icn">
                <img src={why} alt="" />
              </div>
              <div className="why-content">
                <h4>Experience and Expertise</h4>
                <p>
                  Choose us for our decades of experience and financial
                  expertise. Our team of experts is dedicated to helping you
                  make informed financial decisions.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="why-in">
              <div className="why-icn">
                <img src={why} alt="" />
              </div>
              <div className="why-content">
                <h4>Experience and Expertise</h4>
                <p>
                  Choose us for our decades of experience and financial
                  expertise. Our team of experts is dedicated to helping you
                  make informed financial decisions.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="why-in">
              <div className="why-icn">
                <img src={why} alt="" />
              </div>
              <div className="why-content">
                <h4>Experience and Expertise</h4>
                <p>
                  Choose us for our decades of experience and financial
                  expertise. Our team of experts is dedicated to helping you
                  make informed financial decisions.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="why-in">
              <div className="why-icn">
                <img src={why} alt="" />
              </div>
              <div className="why-content">
                <h4>Experience and Expertise</h4>
                <p>
                  Choose us for our decades of experience and financial
                  expertise. Our team of experts is dedicated to helping you
                  make informed financial decisions.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="why-in">
              <div className="why-icn">
                <img src={why} alt="" />
              </div>
              <div className="why-content">
                <h4>Experience and Expertise</h4>
                <p>
                  Choose us for our decades of experience and financial
                  expertise. Our team of experts is dedicated to helping you
                  make informed financial decisions.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="why-in">
              <div className="why-icn">
                <img src={why} alt="" />
              </div>
              <div className="why-content">
                <h4>Experience and Expertise</h4>
                <p>
                  Our dedicated customer service team is always here to help you
                  with any questions or concerns you may have. We pride
                  ourselves on providing exceptional customer service.
                </p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default Whyhome;
