import { gql } from "@apollo/client";

export const GET_ALL_CATEGORY_BY_LEVEL = gql`
  query {
    allcategory {
      success
      message
      allcategory {
        id
        catname
        cat_icon
        iconimage_url
        cat_mini_image
        minibannerimage_url
        cat_mini_image_alt_tag
        compare_widget
        contact_type
        other_category_display
        popular
        short_description
        subcategories {
          id
          catname
          cat_icon
          iconimage_url
          cat_mini_image
          minibannerimage_url
          cat_mini_image_alt_tag
          compare_widget
          contact_type
          other_category_display
          popular
          short_description
          subcategories {
            id
            catname
            cat_icon
            iconimage_url
            cat_mini_image
            minibannerimage_url
            cat_mini_image_alt_tag
            compare_widget
            contact_type
            other_category_display
            popular
            short_description
          }
        }
      }
    }
  }
`;
export const GET_CATEGORIES = gql`
  query GetCategories($id: ID!) {
    result(id: $id) {
      category {
        id
        catname
        cat_icon
        iconimage_url
        cat_mini_image
        minibannerimage_url
        cat_mini_image_alt_tag
        short_description
        popular
        compare_widget
        other_category_display
        contact_type
      }
    }
  }
`;
export const GET_COMPANY_DATA = gql`
  query GetCompanyData($id: ID!) {
    companydata(id: $id) {
      success
      message
      companydata {
        reviewscore
        id
        name
        logo
        logo_url
        logo_alt_tag
        about_us
        contact_name
        address
        phonenumber
        email
        website
        facebook
        instagram
        twitter
        linkedin
        youtubelink
        page_title
        meta_title
        meta_tag
        meta_keyword
        meta_viewport
        meta_robots
        meta_desc
        company_user_link {
          id
          company_id
          name
          link
          alt_tag
          icon
          icon_url
        }
        footer {
          id
          attr_type
          company_id
          description
        }
        companyfaq {
          id
          company_id
          question
          answer
        }
        companybannergallery {
          id

          company_id

          banner_upload_type
          banner_script

          banner_position
          banner_html
          banner_image {
            id
            banner_id
            banner_url
            banner_image
            banner_image_url
            banner_alt_tag
          }
        }
        bannergallery {
          id

          company_id

          banner_upload_type
          banner_script

          banner_position
          banner_html
          banner_image {
            id
            banner_id
            banner_url
            banner_image
            banner_image_url
            banner_alt_tag
          }
        }
      }
    }
  }
`;
export const GET_HEADER_TICKER = gql`
  query {
    headertickerdata {
      success
      message
      headertickerdata {
        id
        url
        ticker_message
      }
    }
  }
`;
export const GET_SYSTEM_MESSAGE = gql`
  query {
    systemmessage {
      success
      message
      system_message {
        id
        system_key
        system_message
      }
    }
  }
`;
export const GET_TESTIMONIALS = gql`
  query {
    testimonial {
      success
      message
      testimonial {
        id
        name
        content
        testimonial_image
        position
        testimonial_image_url
      }
    }
  }
`;
export const GET_BANNER = gql`
  query GetBanners($name: String!) {
    singlebannerdata(name: $name) {
      success
      message
      singlebannerdata {
        id

        banner_upload_type
        banner_script

        banner_position
        banner_html
        banner_image {
          id
          banner_url
          banner_image
          banner_image_url
          banner_alt_tag
        }
      }
    }
  }
`;
export const GET_CMS_DATA = gql`
  query GetCmsData($slug: String!) {
    cmsdata(slug: $slug) {
      success
      message
      cmsdata {
        id
        title
        slug
        description
        meta_tag
        meta_keyword
        meta_desc
        meta_viewport
        meta_robots
      }
    }
  }
`;
export const GET_FAQ_DATA = gql`
  query SingleFaqData($type: String!, $id: ID!) {
    singlefaqdata(filter: { Type: $type, Id: $id }) {
      success
      message
      singlefaqdata {
        id
        attr_type
        attr_value
        question
        answer
      }
    }
  }
`;
export const GET_GUIDES_DATA = gql`
  query singleguidesdata($type: String!, $id: ID!) {
    singleguidesdata(filter: { Type: $type, Id: $id }) {
      success
      message
      singleguidesdata {
        id
        attr_type
        cat_id
        description
      }
    }
  }
`;
export const GET_KNOWLEDGE_NUGGETS_WITH_TERM = gql`
  query searchknowledgenuggets($keyword: String!, $pageCount: Int!, $page: Int!) {
    searchknowledgenuggets(keyword: $keyword, pagecount: $pageCount, page: $page) {
      success
      message
      searchknowledgenuggets {
        id
        keyword
        short_desc
        meta_title
        meta_tag
        meta_keyword
        meta_desc
        meta_viewport
        meta_robots
      }
      totalCount
    }
  }
`;
export const GET_KNOWLEDGE_NUGGETS_WITH_ID = gql`
  query Knowledgenuggetsdata($id: ID!) {
    knowledgenuggetsdata(id: $id) {
      success
      message
      knowledgenuggetsdata {
        id
        keyword
        short_desc
        description
        meta_title
        meta_tag
        meta_keyword
        meta_desc
        meta_viewport
        meta_robots
        footer {
          id
          attr_type
          knowledge_nuggets_id
          description
          status
        }
        banner {
          id
          banner_attr_type
          knowledge_nuggets_id
          banner_layout
          banner_upload_type
          banner_script
          banner_script_type
          banner_position
          banner_html
          status
          banner_image {
            id
            banner_id
            banner_url
            banner_image
            banner_image_url
            banner_alt_tag
          }
        }
      }
    }
  }
`;
export const GET_META_DATA_ONLY = gql`
  query singlepagedata($name: String!) {
    singlepagedata(name: $name) {
      success
      message
      singlepagedata {
        page_meta {
          id
          page_id
          meta_title
          meta_tag
          meta_keyword
          meta_desc
          meta_viewport
          meta_robots
        }
      }
    }
  }
`;
export const GET_META_DATA = gql`
  query singlepagedata($name: String!) {
    singlepagedata(name: $name) {
      success
      message
      singlepagedata {
        id
        name
        slug
        is_custom_page
        footer {
          id
          attr_type
          page_id
          description
        }
        page_meta {
          page_title
          id
          page_id
          meta_title
          meta_tag
          meta_keyword
          meta_desc
          meta_viewport
          meta_robots
        }
        cms {
          id
          page_id
          title
          slug
          description
        }
        banners {
          id

          page_id

          banner_upload_type
          banner_script

          banner_position
          banner_html
          banner_image {
            id
            banner_id
            banner_url
            banner_image
            banner_image_url
            banner_alt_tag
          }
        }
      }
    }
  }
`;
export const GET_COMPANY_LIST = gql`
  query {
    allcompany {
      success
      message
      allcompany {
        id
        name
        logo
        logo_url
      }
    }
  }
`;
export const GET_POPULAR_CATS = gql`
  query filtercategory($popular: ID!, $comparewidget: ID!) {
    filtercategory(popular: $popular, comparewidget: $comparewidget) {
      success
      message
      filtercategory {
        id
        catname
        cat_icon
        iconimage_url
        action_button_text
        cat_mini_image
        minibannerimage_url
        cat_mini_image_alt_tag
        short_description
        cat_upload_type
        cat_image
        bannerimage_url
        cat_alt_tag
        cat_url
        cat_script_type
        cat_script
        banner_html
        description
        listing_page_banner
        listing_banner_image_url
        listing_page_banner_alt_tag
        listing_page_banner_url
        cms_page_content
        ordering
        meta_title
        meta_tag
        meta_keyword
        meta_desc
        meta_viewport
        meta_robots
        status
        popular
        compare_widget
        other_category_display
        contact_type
        parent_catId
        parent {
          id
          catname
          cat_icon
          iconimage_url
          parent_catId
          parent {
            id
            catname
            cat_icon
            iconimage_url
            parent_catId
          }
        }
      }
    }
  }
`;

export const GET_CRYPTO_DETAILS = gql`
  query GetSingleCryptoData($code: String!) {
    singlecrypto(code: $code, title: "") {
      success
      message
      singlecrypto {
        id
        code
        title
        detail
        trade_button_link
        status
        meta_title
        meta_tag
        meta_keyword
        meta_viewport
        meta_robots
        meta_desc
        footer {
          id
          attr_type
          crypto_id
          description
          status
        }
        banner {
          id
          banner_attr_type
          crypto_id
          banner_layout
          banner_upload_type
          banner_script
          banner_script_type
          banner_position
          banner_html
          status
          banner_image {
            id
            banner_id
            banner_url
            banner_image
            banner_image_url
            banner_alt_tag
          }
        }
      }
    }
  }
`;
export const GET_CRYPTO_TRADE_LINK = gql`
  query GetSingleCryptoData($code: String!) {
    singlecrypto(code: $code, title: "") {
      singlecrypto {
        trade_button_link
      }
    }
  }
`;
export const GET_ALL_URL_DATA = gql`
  query GetAllUrlData($pagetype: String!, $name: [String!]!) {
    allurldata(pagetype: $pagetype, name: $name) {
      success
      message
      urldata {
        id
      }
    }
  }
`;
export const GET_CURRENT_CAT_DATA = gql`
  query singlecategory($id: ID!) {
    singlecategory(cid: $id) {
      success
      message
      singlecategory {
        id
        catname
        short_description
        description
        cms_page_content
        meta_title
        meta_tag
        meta_keyword
        meta_desc
        meta_viewport
        meta_robots
        compare_widget
        footer {
          id
          attr_type
          cat_id
          description
        }
        categoryfaq {
          id
          cat_id
          question
          answer
        }
        catbanner {
          id
          cat_id
          banner_upload_type
          banner_script
          banner_position
          banner_html
          banner_image {
            id
            banner_id
            banner_url
            banner_image
            banner_image_url
            banner_alt_tag
          }
        }
        guides {
          id
          cat_id
          description
          status
        }
      }
    }
  }
`;

// export const PRODUCT_LISTING_QUERY = gql`
//   query ProductListingData(
//     $categoryId: Int!
//     $page: Int!
//     $per_page: Int!
//     $sort: String!
//     $filterCategory: String
//     $filters: [UserFilterInput!]!
//   ) {
//     productlistingdata(
//       categoryId: $categoryId
//       page: $page
//       per_page: $per_page
//       sort: $sort
//       filterCategory: $filterCategory
//       filters: $filters
//     ) {
//       success
//       message
//       data {
//         product {
//           id
//           name
//           detail
//           eligibility
//           fees_and_charges
//           terms_and_conditions
//           redirect_url
//           applynow_button
//           applynow_button_url
//           header
//           short_desc
//           tag
//           meta_title
//           meta_tag
//           meta_keyword
//           meta_desc
//           meta_viewport
//           meta_robots
//           product_image_url
//           product_banner_image_url
//         }
//         category {
//           id
//           catname
//           total_product
//         }
//         attributes {
//           id
//           cat_id
//           attr_id
//           is_assign
//           is_compare
//           is_listing
//           is_filter
//           filter_type
//           filter_range_min
//           filter_range_max
//           attrName
//           attrSlug
//           attrType
//           value
//           decvalue
//           stringval
//           cbvalue
//           cbtext
//           cbimage
//           attribute_attr_id
//         }
//       }
//       total
//       per_page
//       current_page
//     }
//   }
// `;
export const PRODUCT_LISTING_QUERY = gql`
  query ProductListingData(
    $categoryId: Int!
    $page: Int!
    $per_page: Int!
    $sort: String!
    $filterCategory: String
    $company: String
    $filters: [UserFilterInput!]!
  ) {
    productlistingdata(
      categoryId: $categoryId
      page: $page
      per_page: $per_page
      sort: $sort
      filterCategory: $filterCategory
      company: $company
      filters: $filters
    ) {
      success
      message
      data {
        product {
          id
          cat_id
          catname
          name
          detail
          average_rating
          is_popular
          eligibility
          fees_and_charges
          terms_and_conditions
          applynow_button
          applynow_button_url
          header
          short_desc
          tag
          meta_title
          meta_tag
          meta_keyword
          meta_desc
          meta_viewport
          meta_robots
          product_image_url
          company_name
          is_compare
          is_review
          decimalAttributes {
            id
            attr_id
            attr_type
            attrName
            attrSlug
            pro_attr_decimal {
              value
            }
          }
          masterAttributes {
            id
            attr_id
            attr_type
            attrName
            attrSlug
            pro_attr_integer_with_cond {
              id
              value
              slug
              label
            }
          }
          stringAttributes {
            id
            attr_id
            attr_type
            attrName
            attrSlug
            pro_attr_string {
              value
            }
          }
        }
        category {
          id
          catname
          total_product
          parent_catId
          level
        }
        attributes {
          id
          cat_id
          attr_id
          is_assign
          is_compare
          is_listing
          is_filter
          filter_type
          filter_range_min
          filter_range_max
          attrName
          attrSlug
          attrType
          value
          decvalue
          stringval
          cbvalue
          cbtext
          cbimage
          attribute_attr_id
        }
        topCatId
      }
      total
      per_page
      current_page
      total_pages
    }
  }
`;
export const GET_SINGLE_PRODUCT_DATA = gql`
  query SingleProductData($id: ID!) {
    singleproductdata(Id: $id) {
      success
      message
      singleproductdata {
        reviewscore
        id
        name
        applynow_button
        applynow_button_url
        status
        header
        short_desc
        tag
        feature_title
        benefits_title
        detail_is_visible
        detail_title
        detail
        eligibility_is_visible
        eligibility_title
        eligibility
        fees_and_charges_is_visible
        fees_and_charges_title
        fees_and_charges
        terms_and_conditions_is_visible
        terms_and_conditions_title
        terms_and_conditions
        image
        image_alt_tag
        product_image_url
        meta_title
        meta_tag
        meta_keyword
        meta_desc
        meta_viewport
        meta_robots
        company {
          name
        }
        category {
          is_compare
        }
        productfaq {
          id
          attr_type
          pro_id
          question
          answer
          status
        }
        top_benefits {
          id
          title
          image
          short_desc
          benefits_image_url
        }
        product_features {
          id
          title
          image
          feature_image_url
        }
        product_fund_manger {
          id
          pro_id
          name
          position
          description
          status
          profile
          fundmanger_profile_image_url
        }
        guides {
          id
          pro_id
          description
          status
        }
        banners {
          id
          banner_attr_type
          pro_id
          banner_layout
          banner_upload_type
          banner_script
          banner_script_type
          banner_position
          banner_html
          status
          banner_image {
            id
            banner_id
            banner_url
            banner_image
            banner_image_url
            banner_alt_tag
          }
        }
        footer {
          id
          pro_id
          attr_type
          description
          status
        }
      }
    }
  }
`;

export const GET_FINANCIAL_BANNER = gql`
  query GetFinancialBanner($postslug: String!) {
    financialbanner(postslug: $postslug) {
      success
      message
      financialmeta {
        id
        blog_id
        meta_title
        meta_tag
        meta_keyword
        meta_desc
        meta_viewport
        meta_robots
      }
      financialbanner {
        id
        banner_attr_type
        financial_id
        banner_attr_value
        banner_layout
        banner_upload_type
        banner_html
        banner_script
        banner_script_type
        banner_position
        status
        banner_image {
          id
          banner_id
          banner_url
          banner_image
          banner_image_url
          banner_alt_tag
        }
      }
    }
  }
`;

export const GET_COMPARE_GROUP_BY_SINGLE_PID = gql`
  query compareGroupMain($id: ID!) {
    compareGroupMain(pid: $id) {
      success
      message
      compareGroupMain {
        id
        cat_id
        group_name
        status
        compare_group_attribute_id
        pro_attr_id
        compare_group_attributes {
          id
          compare_group_id
          cat_id
          attribute_id
          product_attribute {
            id
            product_id
            attr_id
            attr_type
            attribute_library {
              id
              attrName
              attrSlug
              attrType
              isRequired
              reqMessage
              isActive
            }
            pro_attr_integer_with_cond {
              id
              pro_attr_id
              value
              slug
              label
            }
            pro_attr_string {
              id
              pro_attr_id
              value
            }
            pro_attr_decimal {
              id
              pro_attr_id
              value
            }
          }
        }
      }
    }
  }
`;
export const GET_COMPANY_BY_CAT = gql`
  query catcomapnylisting($id: ID!) {
    catcomapnylisting(cid: $id) {
      success
      message
      catcomapnylisting {
        id
        name
        logo_url
        logo_alt_tag
      }
    }
  }
`;
export const GET_POPULAR_PRO_RELATED_PID = gql`
  query relatedpro($id: ID!) {
    relatedpro(pid: $id) {
      success
      message
      procatdata {
        id
        catname
        detail_page_widget_title
        detail_page_widget_sub_title
      }
      relatedpro {
        id
        name
        detail
        eligibility
        fees_and_charges
        terms_and_conditions
        applynow_button
        applynow_button_url
        header
        short_desc
        tag
        page_title
        meta_title
        meta_tag
        meta_keyword
        meta_desc
        meta_viewport
        meta_robots
        image
        image_alt_tag
        product_image_url
      }
    }
  }
`;
export const GET_POPULAR_PRO_RELATED_CID = gql`
  query istrendingproduct($id: ID!) {
    istrendingproduct(cid: $id) {
      success
      message
      catdata {
        id
        catname
        listing_page_widget_title
        listing_page_widget_sub_title
      }
      istrendingproduct {
        id
        name
        short_desc
        image
        image_alt_tag
        product_image_url
      }
    }
  }
`;
export const COMPARE_WIDGET_QUERY = gql`
  query comparewidgetcat($comparewidget: ID!, $cat_id: ID!) {
    comparewidgetcat(comparewidget: $comparewidget, cat_id: $cat_id) {
      success
      message
      comparewidgetcat {
        id
        catname
        subcategories {
          id
          catname
          subcategories {
            id
            catname
          }
        }
      }
      comparewidgetPro {
        id
        catname
        compare_widget
        products {
          id
          cat_id
          name
          image
          image_alt_tag
          image_redirect_url
          short_desc
          product_image_url
        }
      }
    }
  }
`;
export const COMPARE_COMPANY_WIDGET_QUERY = gql`
  query singlecompanycategorypro($companyid: ID!, $cat_id: ID!) {
    singlecompanycategorypro(companyid: $companyid, cat_id: $cat_id) {
      success
      message
      singlecompanycategorypro {
        id
        name
        short_desc
        image
        product_image_url
        image_alt_tag
        image_redirect_url
      }
      singlecompanycategory {
        id
        catname
        subcategories {
          id
          catname
          subcategories {
            id
            catname
          }
        }
      }
    }
  }
`;
export const APPLY_NOW_QUERY = gql`
  query productinquiry(
    $customer_id: String
    $first_name: String
    $last_name: String
    $email: String
    $country_code: String
    $phone: String
    $pro_id: ID
  ) {
    productinquiry(
      customer_id: $customer_id
      first_name: $first_name
      last_name: $last_name
      email: $email
      country_code: $country_code
      phone: $phone
      pro_id: $pro_id
    ) {
      success
      message
      errors
    }
  }
`;
export const SEND_REVIEW_QUERY = gql`
  query sendreview($customer_id: ID!, $type: String, $rating: ID, $comments: String, $pro_id: String, $company_id: String) {
    sendreview(customer_id: $customer_id, type: $type, rating: $rating, comments: $comments, pro_id: $pro_id, company_id: $company_id) {
      success
      message
      sendreview {
        id
      }
    }
  }
`;
export const APPLY_FORM_DATA = gql`
  query catform($pid: ID!) {
    catform(pid: $pid) {
      success
      message
      catform {
        apply_now_form_title
        apply_now_form_sub_title
        apply_now_form_content
      }
    }
  }
`;
export const GET_ALL_REVIEW = gql`
  query allreview($pro_id: String, $company_id: String, $perPage: ID, $page: ID) {
    allreview(pro_id: $pro_id, company_id: $company_id, perPage: $perPage, page: $page) {
      success
      message
      total_reviews
      average_rating
      is_review
      allreview {
        data {
          id
          comments
          rating
          cname
        }
      }
    }
  }
`;
