import React, { useEffect } from "react";
import cardm from "../assets/images/pro-card.png";
import cardb from "../assets/images/card-back.jpg";
import RenderInternalTabContent from "./RenderInternalTabContent";
import arrowSubDown from "../assets/images/arrow-sub-down.svg";

const RenderInternalTab = ({ activeTab, mainTabCatName, subcategories, activeInternalTab, setActiveInternalTab }) => {
  // useEffect(() => {
  //   console.log("useing subcategories", subcategories);
  //   if (subcategories?.length > 0) {
  //   }
  //   setActiveInternalTab(subcategories[0]?.id);
  // }, [activeTab]);

  return (
    <div className="d-flex">
      <div className="innerNav nav flex-column nav-pills me-3" id="internalTabs" role="tablist" aria-orientation="vertical">
        {subcategories?.length > 0 &&
          subcategories.map((subCat, index) => (
            <>
              <button
                style={{ textAlign: "start" }}
                className={`nav-link ${activeInternalTab === subCat?.id ? "active" : ""}`}
                onClick={() => setActiveInternalTab(subCat?.id)}
                key={subCat?.id}
              >
                {subCat?.catname}
              </button>
              {subCat?.subcategories?.length > 0 &&
                subCat?.subcategories.map((thirdCat, i) => (
                  <>
                    <button
                      style={{ textAlign: "start" }}
                      className={`nav-link ${activeInternalTab === thirdCat?.id ? "active" : ""} ps-4`}
                      onClick={() => setActiveInternalTab(thirdCat?.id)}
                      key={thirdCat?.id}
                    >
                      {i == 0 && <img className="WidgetSubCatArrowIcon" src={arrowSubDown} alt="" />}
                      {thirdCat?.catname}
                    </button>
                  </>
                ))}
            </>
          ))}
      </div>
      <div className="tab-content">
        <RenderInternalTabContent activeInternalTab={activeInternalTab} mainTabCatName={mainTabCatName} />
      </div>
    </div>
  );
};

export default RenderInternalTab;
