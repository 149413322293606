import React from "react";
import { useSelector } from "react-redux";

const GetMessage = (key) => {
  const { messages } = useSelector((state) => state.rootReducer);
  // console.log("messages", messages);
  if (messages) {
    for (const item of messages) {
      if (item.system_key === key) {
        return item.system_message;
      }
    }
  }
  return null; // Return null if no match is found
};

export default GetMessage;
