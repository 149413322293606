import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "../../src/assets/images/card1.svg";
import { Dropdown } from "bootstrap";
import { GetCMSData, GetPopularCategory } from "../query/Graphql";
import Html from "../utils/Html";
import OwlCarousel from "react-owl-carousel";

const Homesaving = ({ HomePageData }) => {
  const popularCategory = GetPopularCategory();
  const navigate = useNavigate();

  const [IsViewMore, setIsViewMore] = useState(false);
  // const owlCarouselOptions = {
  //   loop: true,
  //   dots: false,
  //   items: 7,
  //   autoplay: false,
  //   nav: true,
  //   margin: 20,
  //   responsive: {
  //     0: {
  //       items: 2,
  //     },
  //     480: {
  //       items: 4,
  //     },
  //     768: {
  //       items: 5,
  //     },
  //     1024: {
  //       items: 6,
  //     },
  //     1440: {
  //       items: 7,
  //     },
  //   },
  //   responsiveBaseElement: ".saving-in",
  // };
  const redirectToLink = (category) => {
    if (!category?.parent_catId) {
      navigate(`/productList/${category?.catname}`);
    } else {
      if (!category?.parent?.parent_catId) {
        navigate(`/productList/${category?.parent?.catname}/${category?.catname}`);
      } else {
        navigate(`/productList/${category?.parent?.parent?.catname}/${category?.parent?.catname}/${category?.catname}`);
      }
    }
  };
  return (
    <section className="home-saving">
      <div className="container">
        <div className="saving-in">
          {HomePageData?.cms ? (
            HomePageData?.cms?.find((item) => item.slug == "privacy-policy") ? (
              <Html html={HomePageData?.cms?.find((item) => item.slug == "privacy-policy")?.description} />
            ) : (
              <></>
            )
          ) : (
            <div className="skeleton-text" style={{ minHeight: "90px", marginBottom: "20px" }}></div>
          )}
          <ul>
            {popularCategory && popularCategory != "error" ? (
              <>
                {IsViewMore
                  ? popularCategory.map((category) => (
                      <li className="popularCatHomeCard" onClick={() => redirectToLink(category)}>
                        <div className="inner-sv">
                          <img src={category.iconimage_url} alt={category.catname} title={category.catname} />
                          <p className="font-16">{category.catname}</p>
                        </div>
                      </li>
                    ))
                  : popularCategory.slice(0, 6).map((category) => (
                      <li className="popularCatHomeCard" onClick={() => redirectToLink(category)}>
                        <div className="inner-sv">
                          <img src={category.iconimage_url} alt={category.catname} title={category.catname} />
                          <p className="font-16">{category.catname}</p>
                        </div>
                      </li>
                    ))}
              </>
            ) : popularCategory != "error" ? null : (
              <div className="skeleton-text" style={{ minHeight: "150px" }}></div>
            )}
          </ul>
          {popularCategory?.length > 6 &&
            (IsViewMore ? (
              <div className="viewmore-div c-center">
                <a className="viewless font-16" onClick={() => setIsViewMore(!IsViewMore)} style={{ cursor: "pointer" }}>
                  View less
                </a>
              </div>
            ) : (
              <div className="viewmore-div c-center">
                <a className="viewmore font-16" onClick={() => setIsViewMore(!IsViewMore)} style={{ cursor: "pointer" }}>
                  View more
                </a>
              </div>
            ))}
          {/* <ul>
            {popularCategory && popularCategory != "error" ? (
              <OwlCarousel className="rate-slider owl-carousel" {...owlCarouselOptions}>
                {popularCategory.map((category) => (
                  <li>
                    <div className="inner-sv">
                      <img src={category.iconimage_url} alt={category.catname} title={category.catname} />
                      <p className="font-16">{category.catname}</p>
                    </div>
                  </li>
                ))}
              </OwlCarousel>
            ) : popularCategory != "error" ? null : (
              <div className="skeleton-text" style={{ minHeight: "150px" }}></div>
            )}
          </ul> */}
        </div>
      </div>
    </section>
  );
};

export default Homesaving;
