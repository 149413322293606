import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CompanyDirectoryGallery from "./CompanyDirectoryGallery/CompanyDirectoryTop.js";
import CompanyDirectoryProducts from "./CompanyDirectoryProducts/CompanyDirectoryProducts.js";
import { GetCompanyDetails, GetIdFromUrlCompany } from "../../query/Graphql";
import WithBannersLayout from "../../utils/WithBannersLayout/WithBannersLayout";
import WithMetaByData from "../../utils/WithMetaByData";
import Breadcrumb from "../Breadcrumb/Breadcrumb.js";
import { useLocation, useParams } from "react-router-dom";
import RenderCompanyDetails from "./RenderCompanyDetails.js";

const CompanyDirectoryDetails = () => {
  const location = useLocation();
  const { companyName } = useParams();
  const IDArrayFromURL = companyName && GetIdFromUrlCompany(companyName);
  const [CurrentId, setCurrentId] = useState();
  useEffect(() => {
    setCurrentId();
  }, [location.pathname]);
  // const companyId = useSelector((state) => state.rootReducer.currentCompanyId);
  // useEffect(() => {
  //   localStorage.setItem("companyId", companyId);
  // }, [companyId]);

  // const companyData = GetCompanyDetails(
  //   companyId || localStorage.getItem("companyId")
  // );
  useEffect(() => {
    if (IDArrayFromURL?.length > 0) {
      setCurrentId(IDArrayFromURL[IDArrayFromURL.length - 1].id);
    }
  }, [IDArrayFromURL]);

  return (
    <>
      <Breadcrumb />
      <RenderCompanyDetails CurrentId={CurrentId} />
    </>
  );
};

export default CompanyDirectoryDetails;
