import React, { useEffect, useState } from "react";
import "./SignUp.css";
import newFKCROPLOGO from "../../assets/images/newFKCROPLOGO.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import EmailIcon from "../../assets/icons/EmailIcon";
import PasswordIcon from "../../assets/icons/PasswordIcon";
import PhoneIcon from "../../assets/icons/PhoneIcon";
import UserNameIcon from "../../assets/icons/UserNameIcon";
import { useMutation } from "@apollo/client";
import sendNotification from "../../utils/SendNotification";
import { CHANGE_PASSWORD_MUTATION, REGISTER_MUTATION, RESET_PASSWORD_MUTATION } from "../../query/mutation";
import { GetSinglePagesDataFromSlug } from "../../query/Graphql";
import BottomBannerSlider from "../../utils/BannerLayout/BottomBannerSlider";
import Html from "../../utils/Html";
import TopBannerSlider from "../../utils/BannerLayout/TopBannerSlider";
import WithMetaByData from "../../utils/WithMetaByData";
import WithBannersLayout from "../../utils/WithBannersLayout/WithBannersLayout";
import AuthLayoutBySlug from "./AuthLayoutBySlug";

const ChangePassword = () => {
  const navigate = useNavigate();
  const pageData = GetSinglePagesDataFromSlug("customer-change-password");
  const [Password, setPassword] = useState();
  const [ConfPassword, setConfPassword] = useState();
  const [CurrentPassword, setCurrentPassword] = useState();
  const [PassError, setPassError] = useState(false);
  const [ConfPassError, setConfPassError] = useState(false);
  const [CurrentPasswordError, setCurrentPasswordError] = useState(false);
  const [ChangePassword, { loading, error }] = useMutation(CHANGE_PASSWORD_MUTATION);
  let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  let PhoneRegex = /^\d{6,11}$/;
  let strongPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/;
  let mediumPassword = /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/;
  const [UserData, setUserData] = useState(sessionStorage.getItem("User") && JSON.parse(sessionStorage.getItem("User")));
  useEffect(() => {
    if (sessionStorage.getItem("User")) {
      setUserData(JSON.parse(sessionStorage.getItem("User")));
    } else {
      navigate("/");
    }
  }, []);
  //   useEffect(() => {
  //     if (!resetToken) {
  //       navigate("/");
  //     }
  //   }, []);

  const handleInputChange = (e) => {
    switch (e.target.id) {
      case "CurrentPassword":
        setCurrentPassword(e.target.value);
        if (e.target.value) {
          setCurrentPasswordError(false);
        } else {
          setCurrentPasswordError(true);
        }
        break;
      case "password":
        setPassword(e.target.value);
        if (e.target.value && strongPassword.test(e.target.value)) {
          setPassError(false);
        } else {
          setPassError(true);
        }
        break;
      case "ConfPassword":
        setConfPassword(e.target.value);
        if (e.target.value) {
          setConfPassError(false);
        } else {
          setConfPassError(true);
        }
        break;
      default:
        break;
    }
  };
  const handleSubmitClick = async (e) => {
    if (CurrentPassword && Password && ConfPassword && Password == ConfPassword) {
      try {
        const { data } = await ChangePassword({
          variables: {
            userid: UserData?.id,
            oldPassword: CurrentPassword,
            newPassword: Password,
          },
        });
        // Handle the response data
        console.log(data?.changePassword?.success);
        if (data?.changePassword?.success == true) {
          sendNotification("success", data?.changePassword?.message);
          navigate("/");
        } else {
          sendNotification("error", data?.changePassword?.message);
        }
      } catch (error) {
        sendNotification("error", error);
      }
    } else {
      if (!CurrentPassword) {
        CurrentPasswordError && setCurrentPasswordError(false);
        setTimeout(() => {
          setCurrentPasswordError(true);
        }, 50);
      }
      if (!Password) {
        PassError && setPassError(false);
        setTimeout(() => {
          setPassError(true);
        }, 50);
      }
      if (!ConfPassword || ConfPassword !== Password) {
        ConfPassError && setConfPassError(false);
        setTimeout(() => {
          setConfPassError(true);
        }, 50);
      }
    }
  };
  return (
    <AuthLayoutBySlug pageData={pageData} contentSlug="customer-change-password">
      <div>
        <div className="log-in formDiv">
          {/* <img className="authLogo" src={newFKCROPLOGO} alt="find Kaizen" title="find Kaizen" /> */}
          <div className="w-100">
            <h5 className="text-center text-center formTitle mb-4">Change Password</h5>
          </div>
          <div className="floating-label">
            <input
              value={CurrentPassword}
              onChange={(e) => handleInputChange(e)}
              placeholder="CurrentPassword"
              type="password"
              name="CurrentPassword"
              id="CurrentPassword"
              autoComplete="off"
              required
              title="Enter Current Password"
              className={`${CurrentPasswordError ? "error" : ""}`}
            />
            <label htmlFor="CurrentPassword">Current Password:</label>
            <div className={`icon ${CurrentPasswordError ? "error" : ""}`}>
              <PasswordIcon />
            </div>
          </div>
          <div className="floating-label">
            <input
              value={Password}
              onChange={(e) => handleInputChange(e)}
              placeholder="New Password"
              type="password"
              name="password"
              id="password"
              autoComplete="off"
              required
              pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
              title="Enter Password"
              className={`${PassError ? "error" : ""}`}
            />
            <label htmlFor="password" className="w-100">
              New Password:
              <span
                style={{
                  float: "right",
                  marginTop: "15px",
                  fontSize: "18px",
                  lineHeight: "28px",
                  letterSpacing: "0.4px",
                  opacity: 0.8,
                  fontFamily: "GilroyMedium",
                  color: strongPassword.test(Password) ? "#42B700" : mediumPassword.test(Password) ? "#ff7600" : "red",
                }}
              >
                {strongPassword.test(Password) ? "Strong" : mediumPassword.test(Password) ? "Medium" : "Weak"}
              </span>
            </label>
            <div className={`icon ${PassError ? "error" : ""}`}>
              <PasswordIcon />
            </div>
          </div>
          <div className="floating-label">
            <input
              value={ConfPassword}
              onChange={(e) => handleInputChange(e)}
              placeholder="Confirm New Password"
              type="Password"
              name="ConfPassword"
              id="ConfPassword"
              autoComplete="off"
              required
              pattern={Password}
              title="Passwords must match"
              className={`${ConfPassError ? "error" : ""}`}
            />
            <label htmlFor="ConfPassword">Confirm New Password:</label>
            <div className={`icon ${ConfPassError ? "error" : ""}`}>
              <PasswordIcon />
            </div>
          </div>

          <button className="action-btn small-btn" onClick={(e) => handleSubmitClick(e)}>
            {loading ? (
              <div className="spinner-border text-primary" role="status" style={{ margin: "auto", height: "15px", width: "15px" }}>
                {/* <span className="sr-only">Loading...</span> */}
              </div>
            ) : (
              "Change Password"
            )}
          </button>
          {/* <Link to="/SignIn" className="discrete">
              Already have an account?
            </Link> */}
        </div>
      </div>
    </AuthLayoutBySlug>
  );
};

export default ChangePassword;
