import React from "react";
import { useSelector } from "react-redux";

const GetBreadCrumbArray = () => {
  const BreadCrumbsArray =
    useSelector((state) => state.rootReducer.BreadCrumbsArray) ||
    JSON.parse(localStorage.getItem("BreadCrumbsArray"));
  return BreadCrumbsArray;
};

export default GetBreadCrumbArray;
