import React, { useEffect } from "react";
import { GetMetaData } from "../query/Graphql";

const WithMetaByData = ({ children, data }) => {
  const keysToKeep = ["meta_desc", "meta_keyword", "meta_robots", "meta_tag", "meta_title", "meta_viewport", "page_title"];
  function filterObject(obj, keysToKeep) {
    return Object.keys(obj).reduce((filteredObj, key) => {
      if (keysToKeep.includes(key)) {
        filteredObj[key] = obj[key];
      }
      return filteredObj;
    }, {});
  }
  const metaData = data && filterObject(data, keysToKeep); // page_meta;

  useEffect(() => {
    // console.log("metaData", metaData);
    // Create a new <meta> element for each metadata property and append it to the document's <head>
    for (const key in metaData) {
      if (metaData.hasOwnProperty(key)) {
        const metaElement = document.createElement("meta");
        metaElement.name = key;
        metaElement.content = metaData[key];
        document.head.appendChild(metaElement);
      }
    }

    // Set the document title
    if (metaData?.page_title || metaData?.meta_title) {
      document.title = metaData?.page_title || metaData?.meta_title;
    }
    // else {
    //   document.title = "FindKaizen";
    // }

    return () => {
      // Cleanup: Remove the added <meta> elements when the component unmounts
      for (const key in metaData) {
        if (metaData.hasOwnProperty(key)) {
          const existingMetaElement = document.querySelector(`meta[name="${key}"]`);
          if (existingMetaElement) {
            existingMetaElement.remove();
          }
        }
      }
      document.title = "FindKaizen";
    };
  }, [metaData]);

  return <>{children}</>;
};

export default WithMetaByData;
